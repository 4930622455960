<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Hoja frontal de expediente</h2>      
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>
        <b-container>
            <p class="txt_secondary text-uppercase pt-3"><strong>Información del paciente</strong></p>
            <b-row >
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>DATOS DE IDENTIFICACIÓN</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>   
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Genero</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Colonia/Comunidad</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>
             <b-table-simple class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Área/Especialidad</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="lastExp.especialidad" disabled readonly class="border-0 input-disabled" size="sm" placeholder="área/especialidad"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Nombre del médico tratante</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="lastExp.medico" disabled readonly class="border-0 input-disabled" size="sm" placeholder="médico tratante"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Expediente</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="datosPaciente.no_expediente" disabled readonly class="border-0 input-disabled" size="sm" placeholder="# de expediente" />
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datosPaciente.no_expediente_legacy">
                        <b-th class="th_style">Expediente físico</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="datosPaciente.no_expediente_legacy" disabled readonly class="border-0 input-disabled" size="sm" placeholder="# de expediente" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

             <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <b-tr>
                                <b-th class="th_style size_th_segunda_tabla">Fecha de nacimiento</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datosPaciente.fecha_nacimiento" disabled readonly class="border-0 input-disabled" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Habitación</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="lastExp.no_habitacion" disabled readonly class="border-0 input-disabled" type="text" size="sm" placeholder="# de habitación"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th_sec_table">Fecha</b-th>
                                <b-td class="border_table">
                                    <b-form-input type="date" disabled readonly v-model="datos.fecha" class="border-0 input-disabled" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <b-tr>
                                <b-th class="th_style size_th_segunda_tabla">Género</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="lastExp.genero" disabled readonly class="border-0 input-disabled" placeholder="género" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Edad</b-th>
                                <b-td class="border_table">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{lastExp.paciente_edad_years}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">años</span>
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{lastExp.paciente_edad_months}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">meses</span>
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <!-- <b-row>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><small>{{hojaFrontal[hojaFrontal.length-1].paciente_edad_years}}</small></span>
                                                <span class="float-right px-1 py-auto">
                                                    <small class="txt_gray">años</small>
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-3"><small>{{hojaFrontal[hojaFrontal.length-1].paciente_edad_months}}</small></span>
                                                <span class="float-right px-1 py-auto">
                                                    <small class="txt_gray">meses</small>
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row> -->
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Peso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="lastExp.peso" disabled readonly class="border-0 input-disabled" size="sm" placeholder="Sin registro"/>
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">kg</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row class="mb-5">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <p class="txt_secondary text-uppercase mt-3"><strong>historial de ingresos</strong></p>
                    <b-table-simple class="table-sm mt-3 table-responsive">
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style historial text-center">Fecha de ingreso</b-th>
                                <b-th class="th_style historial text-center">Diagnóstico</b-th>
                                <b-th class="th_style historial text-center">Padecimiento quirúrgico</b-th>
                                <b-th class="th_style historial text-center">Médico tratante</b-th>
                                <b-th class="th_style historial text-center">Fecha de egreso</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="hojaFrontal.length>0">  
                            <b-tr v-for="(dts,index) in hojaFrontal" :key="index">
                                <b-td class="border_table text-center">
                                    <b-form-input v-model="dts.fecha_ingreso" disabled readonly class="border-0 input-disabled" size="sm" />
                                </b-td>
                                <b-td class="border_table">
                                    <span class="size-span">{{dts.diagnostico}}</span>
                                </b-td>
                                <b-td class="border_table">
                                    <span class="size-span" v-if="dts.procedimiento_quirurgico">{{ dts.procedimiento_quirurgico }}</span>
                                    <span class="size-span text-center" v-else>No hay registro</span>
                                </b-td>
                                <b-td class="border_table">
                                    <span class="size-span">{{ dts.medico }}</span>
                                </b-td>
                                <b-td class="border_table text-center" >
                                    <span class="size-span text-center" v-if="dts.fecha_egreso != null">{{ dts.fecha_egreso }}</span>
                                    <span class="size-span text-center" v-else>No ha egresado</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </b-container>
        
         <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !loading)">
            <ul class="sticky">
                <li @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div> 
        <Loading :show="loading"/>
    </b-container>
</template>

<script>
import PDFMerger from 'pdf-merger-js'
import Loading from '@/components/Loader/Loading.vue'
import Foto from "@/components/Paciente/Foto.vue"
import CryptoJS from 'crypto-js'
const urlec = `${url}/api/catalogos/estado_civil`
import { url } from "../../../settings";

export default {
    name: 'FrontalDeExpediente',
    components: { Foto, Loading },

    data:() => ({
        cargado:false,
        ecivil:[],
        info:false,
        disabled:true,
        pacientes:[{}],
        idPacienteSeleccionado: "",
        nombres: [],
        loading:true,

        hojaFrontal: {
            id: null,
            no: '',
            paciente_edad_years: '',
            paciente_edad_months: '',
            fecha_ingreso: '',
            no_habitacion: '',
            especialidad: '',
            medico: '',
            genero: '',
            peso: '',
            diagnostico: '',
            procedimiento_quirurgico: '',
            fecha_egreso: ''
        },

        lastExp:{
            id: null,
            no: '',
            paciente_edad_years: '',
            paciente_edad_months: '',
            fecha_ingreso: '',
            no_habitacion: '',
            especialidad: '',
            medico: '',
            genero: '',
            peso: '',
            diagnostico: '',
            procedimiento_quirurgico: '',
            fecha_egreso: ''
        },

        hojaFrontalClear: {
            id: null,
            no: '',
            paciente_edad_years: '',
            paciente_edad_months: '',
            fecha_ingreso: '',
            no_habitacion: '',
            especialidad: '',
            medico: '',
            genero: '',
            peso: '',
            diagnostico: '',
            procedimiento_quirurgico: '',
            fecha_egreso: ''
        },

        datos: {
            fecha: ''
        },
        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
            no_expediente_legacy:""
        },
    }),
    
    computed: {
        validation() {
            return this.idPacienteSeleccionado.length != 0
        },
    },

    methods: {
        clearData(){
            this.hojaFrontal=this.hojaFrontalClear
        },

        async imprimir() {
            // var can = false;
            // var este = this;
            this.loading = true
            var ts= this
            let urlim = `${url}/api/notas/hoja_frontal/descargar/${this.datosPaciente.no_expediente}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                ts.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.no_expediente+" Hoja frontal de expediente");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        async getHojaFrontal() {
            try {
                const res = await fetch(`${url}/api/notas/hoja_frontal/${this.idPacienteSeleccionado}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                const dta = data.hoja_frontal.expedientes
                console.log(data);
                this.hojaFrontal=dta
                if (dta) {
                    this.lastExp=this.hojaFrontal[this.hojaFrontal.length-1]
                }
                this.loading=false

            } catch (error) {
                this.loading=false
            }
        },

        async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${urlec}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();


                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift( { value: null, text: 'seleccione una opcion' },)


            } catch (error) {
                console.log("el error es"+error);
            }
        },
        
        setData(){
            this.obtenerEstadoCivil()
            this.getFecha();
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.datosPaciente =paciente.paciente;
            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.idPacienteSeleccionado=paciente.paciente.id
        
            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.getHojaFrontal()
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        getFecha(){
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha = yyyy + '-' + mm + '-' + dd ;
        },
     },

    mounted() {
        // this.setData()
        // this.loading=true
        
    },
    
    activated(){
        Object.assign(this.$data, this.$options.data());
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.setData()
        this.loading=true
    },

}
</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 25vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 28vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
    }

    /* Segunda dos tablas */
    @media only screen and (min-width: 1200px) {
        .size_th_segunda_tabla {
            width: 12vw !important;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th_segunda_tabla {
            width: 14vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th_segunda_tabla {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th_segunda_tabla {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th_segunda_tabla {
            width: auto;
        }
    }

    @media only screen and (min-width: 768px) {
        .historial {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @media only screen and (max-width: 1220px) {
        .historial {
            padding-left: 60px;
            padding-right: 60px;
        }
    }
</style>