<template>
    <div>
        <b-modal class="rounded-lg" size="md" v-model="openPacienteExistente" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-user fa-2x user text-white"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Paciente existente!</strong></span><br>
            </div>
            <hr>
            <b-tabs
                active-nav-item-class="text-uppercase"
                content-class="mt-3">
                <b-tab v-for="(paciente,index) of pacientes" :key="index">
                    <template #title>
                        <strong class="text-style">Paciente {{ paciente.numero }}</strong>
                    </template>
                    <div class="d-flex justify-content-center text-center mt-3">
                    <p class="subttitle-modal"><strong>Nombre: </strong>{{paciente.nombre}}</p>
                    </div>
                    <div class="d-flex justify-content-center text-center mt-3">
                        <p class="subttitle-modal"><strong>Género: </strong>{{paciente.genero.nombre}}</p>
                        <p class="subttitle-modal ml-3"><strong>Edad: </strong>{{changeYears(paciente.fecha_nacimiento)+" años "+changeMonths(paciente.fecha_nacimiento)+" meses"}}</p>
                    </div>
                    <div class="d-flex justify-content-center text-center mt-3">
                        <p class="subttitle-modal"><strong>Dirección: </strong>{{paciente.domicilio+", "+paciente.colonia+", "+paciente.ciudad}}</p>
                    </div>
                    <div class="d-flex justify-content-center text-center mt-3">
                        <p class="subttitle-modal"><strong>Estado: </strong>{{paciente.estado.nombre}}</p>
                        <p class="subttitle-modal ml-3"><strong>Municipio: </strong>{{paciente.municipio.nombre}}</p>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end mt-3 mr-3">
                        <button @click="visualizar(paciente.id)" class="btn-modal-success border-0 px-3 pb-2 pt-1 mr-3">Ver información</button>
                        <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1">Cancelar</button>
                    </div>
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
import router from '../../router/'
export default {
    name: 'PacientesExistentes',
    props: ['openPacienteExistente'],

    data:()=>({
        pacientes:[{
            id:null,
            nombre:"",
            genero:{
                id:"",
                nombre:""
            },
            fecha_nacimiento:"",
            estado:{
                id:"",
                nombre:""
            },
            domicilio:"",
            colonia:"",
            ciudad:"",
            municipio:{
                id:"",
                nombre:""
            },
        }],
        year:"",
        month:""
    }),
    
    methods: {
        visualizar(id){
            router.push("/notas-medicas/"+id)
            this.cerrarModal()
        },
        changeYears:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           
            return (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());
		},
        changeMonths:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);

            return (function() {
                if(today.getMonth() >= dob.getMonth()) {
                    if(today.getDate() >= dob.getDate()) {
                        return today.getMonth() - dob.getMonth();
                    }
                    else {
                        if((today.getMonth() - 1) >= dob.getMonth()) {
                            return (today.getMonth() - 1) - dob.getMonth();
                        }
                        else {
                            return ((today.getMonth() - 1) + 12) - dob.getMonth();
                        }
                    }
                }
                else {
                    if(today.getDate() >= dob.getDate()) {
                        return (today.getMonth() + 12) - dob.getMonth();
                    }
                    else {
                        return ((today.getMonth() - 1) + 12) - dob.getMonth();
                    }
                }
            }());
		},

        cerrarModal(){
            this.$emit("cerrarPacienteExistente")
        },

        onEscapeKeyUp(event) {
            if (this.abrir) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }
            }
        },

        onEnterKeyUp(event) {
            if (this.abrir) {
                if (event.which === 13) {
                    this.visualizar()       
                }
            }
        },
        existPaciente(dta){
            let i = 0
            for (const item of dta) {
                i = i+1
                const nombre = 'numero'
                const valor = i
                item[nombre] = valor
            }
            this.pacientes=dta
        }
    },
    
    activated() {
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
            this.onEnterKeyUp(evt)
        })
    },

    mounted(){
    }
}
</script>

<style scoped>
    .icono {
        border: none;
        border-radius: 50%;
        background-color: #15742b;
    }

    .user {
        padding-left: 4px;
        padding-right: 4px;
    }

    .text-style{
        color: rgb(126, 124, 124) !important;
        font-weight: 700 !important;
        font-size: 0.9rem !important;
    }

</style>