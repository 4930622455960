<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="abrir" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center" align="center">
                    <i class="fa fa-close fa-4x icon-color"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Error!</strong></span><br>
            </div>
            <div v-if="error" class="d-flex justify-content-center text-center mt-1">
                <p class="subttitle-modal">Ha ocurrido un error en el servidor</p>
            </div>
            <div v-else class="d-flex justify-content-center text-center mt-1">
                <p class="subttitle-modal">Verifica los datos que has ingresado</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <button @click="cerrarModal()" class="btn-modal-success border-0 px-3 pb-2 pt-1">Aceptar</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: 'Error',
    props: ['abrir'],
    data:()=>({
        error:false
    }),
    created() {
        this.$watch('abrir', (newAbrir) => {
            newAbrir ?  this.error = Vue.prototype.$error500 : this.error=false
        })
    },
    
    methods: {
        cerrarModal(){
            this.$emit("cerrarModalError");
            Vue.prototype.$error500 = false
        },

        onEscapeKeyUp(event) {
            if (this.abrir) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }
            }
        },

        onEnterKeyUp(event) {
            if (this.abrir) {
                if (event.which === 13) {
                    this.cerrarModal()        
                }
            }
        }
    },

    activated() {
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
            this.onEnterKeyUp(evt)
        })
    },
}
</script>

<style scoped>
    .icon-color {
        color: rgb(169, 11, 11);
    }

    .icono {
        border-radius: 50%;
        background-color: white;
    }
    
</style>