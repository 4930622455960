<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Aviso de privacidad</h2>      
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>
        <b-container class="py-4">
            <b-row>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        De conformidad con lo dispuesto en la Ley Federal de Datos Personales en posesión de los particulares, Medical Hidrocalida,
                        S. A. de C. V., A través de Centro Quirúrgico Colosio, con domicilio en Boulevard Luis Donaldo Colosio No. 645 en la ciudad de
                        Aguascalientes, hace de su conocimiento que sus datos personales, incluso los sensibles, patrimoniales o financieros recabados o
                        generados con motivo de la prestaciòn de servicios de este Hospital, por medio de solicitudes, declaraciones, medios electrónicos,
                        impresos en todas sus modalidades, videos, serán utilizados y tratados exclusivamente para los fines vinculados con su atención
                        médica, como para el efectivo cumplimiento de todas y cada una de las obligaciones derivadas de tal relación entre los diversos
                        profesionales, técnicos y auxiliares de la salud que intervengan en dichos servicios, tales como médicos tratantes,
                        interconsultantes, personal de apoyo a los mismos y de las subsidiarias, afiliadas o de las sociedades que forme parte del hospital.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        Se informa que para limitar el uso y divulgación de sus datos, mantendremos politicas y procedimientos de seguridad
                        administrativas, técnicas y fisicas y confidencialidad internas
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        Para trasferir datos para el pago de servicios a favor del titular por parte de terceros ajenos a esta institución con quien
                        tenga contratados servicios de cobertura médica, pudiendo ser entre ellos en forma enunciativa más no limitativa, compañias
                        aseguradoras, expresamos que estos serán tratados en los términos previstos en el Artículo 37 Fracc. IV y VII de la Ley aplicable,
                        asumiendo el tercero receptor, las obligaciones inherentes al responsable y salvaguardando la privacidad de los mismos.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        De igual forma, sus datos personales incluso los sensibles, podrán ser compartidos con Fedatarios Públicos, y/o Abogados
                        del Hospital, con motivo de los actos jurídicos generados de dicha prestación de servicios.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        El ejercicio de los derechos de acceso, rectificación, cancelación u oposición de sus datos, podrá efectuarse por escrito a
                        partir del 06 de Enero de 2012, de igual forma en todo momento podrá llevarse a cabo la renovación de su consntimiento, hasta
                        en tanto no se tenga celebrado algún acto con el Hospital, los cuales deberán realizarse por escrito presentado en el domicilio
                        antes citado, recabándose sello de recepción, de conformidad con lo dispuesto en la legislación aplicable.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        Si usted manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que a otorgado su
                        consentimiento para ello, siendo válido para todos los servicios de atención médica, que de ahora en adelante reciba esta
                        Institución Médica, independientemente del motivo por el cual se le otorguen los mismos.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="txt_secondary mt-1"><strong>Tratamiento de Datos Personales Sensibles:</strong></p>
                    <p class="size-span contenedor">
                        Les informamos que para cumplir con las finalidades previstas en este aviso, serán recabados y tratados datos personales y
                        sensibles, principalmente aquellos que se refieren o se encuentran vinculados a su estado de salud presente o futuro.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad,
                        para la atención de novedades legislativas, jurisprudenciales, políticas internas y/o nuevos requerimientos para la prestación de
                        nuestros servicios, el cual estará a su disposición en la página: www.medical..........etc. y/o a través de comunicados colocados
                        en nuestro establecimiento o informando mediante cualquier medio de comunicación que tengamos con usted.
                    </p>
                </b-col>
                <b-col cols="12">
                    <p class="size-span contenedor">
                        Doy mi consentimiento para que mis datos personales y sensibles sean tratados conforme a los términos y condiciones del
                        presente aviso de privacidad.
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'AvisoDePrivacidad',
    data:() => ({

    }),
}
</script>

<style scoped>
.contenedor {
    line-height: 25px;
    overflow: initial;
    text-overflow: ellipsis;
    text-align: justify;
}
</style>