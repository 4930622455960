<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="abrirPreSave" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="text-center p-2" align="center">
                    <i class="fa fa-save fa-2x icono"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Alerta!</strong></span><br>
            </div>
            <div class="d-flex justify-content-center text-center mt-2">
                <p class="subttitle-modal">Actualmente hay datos preguardados de este paciente. ¿Desea visualizarlos?</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <button @click="showDta()" class="btn-modal-success px-3 pb-2 pt-1">Aceptar</button>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cerrar</button>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'DatosPreguardados',
    props: ['abrirPreSave'],
    
    methods: {
        cerrarModal() {
            this.$emit("cerrarModalPreSave",localStorage.view);
        },

        showDta() {
            this.$emit('showDtaPreSave',localStorage.view)
        },

        onEscapeKeyUp(event) {
            if (this.abrir) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }
            }
        },

        onEnterKeyUp(event) {
            if (this.abrir) {
                if (event.which === 13) {
                    this.showDta()       
                }
            }
        }
    },

    activated() {
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
            this.onEnterKeyUp(evt)
        })
    },
    
}
</script>

<style scoped>
    .icono {
        color: green;
    }
</style>