<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Nota pre-operatoria</h2>
            </b-col>
            <b-col cols="4" class="d-none d-lg-block d-md-block">
                <div class="float-right my-auto">
                    <Foto />
                </div>
            </b-col>
        </b-row>

        <b-container class="mt-4">
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente"
                                @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id"
                                        :value="all.id">{{ all.no_expediente }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong
                        class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(datos.firma.is_firmado == 1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(datos.firma.is_firmado == 0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <Datos :changeData="changeDta" />

            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Información del paciente</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Genero</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.paciente_edad_years">
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><small>{{datosExpediente.paciente_edad_years}}</small></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><small>{{datosExpediente.paciente_edad_months}}</small></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.id">
                        <b-th class="th_style">Idioma</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.idioma}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.id">
                        <b-th class="th_style">Religion</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.religion}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                             <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Colonia/Comunidad</b-th>
                        <b-td class="border_table">
                             <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->

            <!-- <b-table-simple class="table-sm" v-if="datosExpediente.id">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Número de expediente</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.no_expediente}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datosExpediente.tiene_alergias===1">
                        <b-th class="th_style">Alergias</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.alergias}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.especialidad.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Médico tratante</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.medico.nombre}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->

            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th">Fecha</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="fecha"
                                        autocomplete="off" :state="fecha" v-model="datos.fecha" type="date" class="border-0"
                                        size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hora</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="hora"
                                            autocomplete="off" type="time" min="00:00:01" max="24:59:59" :state="hour"
                                            v-model="datos.hora" class="border-0" size="sm"></b-form-input>
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">hora</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Fecha de cirugía</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)"
                                        name="fechaCirujia" autocomplete="off" :state="fecha_cirugia"
                                        v-model="datos.fecha_cirugia" type="date" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-th class="th_style">Quirófano</b-th>
                            <b-td class="border_table">
                                <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="quirofano"
                                    autocomplete="off" :state="sala" v-model="datos.sala" type="text" class="border-0"
                                    size="sm" placeholder="# de sala" />
                            </b-td>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <p class="txt_secondary">Diagnóstico</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="diagnostico"
                        autocomplete="off" :state="diagnostico" v-model="datos.diagnostico" class="txtArea_border" size="sm"
                        no-resize rows="3" max-rows="3" />
                </b-col>
                <b-col cols="12">
                    <p class="txt_secondary mt-3">Plan quirúrgico</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="planQuirurgico"
                        autocomplete="off" :state="plan_quirurgico" v-model="datos.plan_quirurgico" class="txtArea_border"
                        size="sm" no-resize rows="3" max-rows="3" />
                </b-col>
            </b-row>

            <div>
                <b-table-simple class="table-sm mt-4">
                    <b-tbody>
                        <b-tr>
                            <b-th class="th_style th_size_intervencion">Tipo de intervención quirúrgica</b-th>
                            <b-td class="border_table">
                                <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                    :state="tipo_intervencion_id" v-model="datos.tipo_intervencion_id"
                                    :options="tipo_intervencion_quirurgica" value-field="id" text-field="nombre"
                                    class="border-0" size="sm"></b-form-select>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Riesgo quirúrgico</b-th>
                            <b-td class="border_table">
                                <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                    :state="riesgo_quirurgico_id" v-model="datos.riesgo_quirurgico_id"
                                    :options="riesgo_quirurgico" value-field="id" text-field="nombre" class="border-0"
                                    size="sm"></b-form-select>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div style="margin-top: -16px;">
                <small class="txt_gray">* Incluidos tabaquismo, alcoholismo o adicciones</small>
            </div>

            <b-row class="mt-4">
                <b-col cols="12">
                    <p class="txt_secondary">Cuidados y plan terapéutico preoperatorio</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="cuiaddos"
                        autocomplete="off" :state="cuidados_plan_terapeotico" v-model="datos.cuidados_plan_terapeotico"
                        class="txtArea_border" size="sm" no-resize rows="3" max-rows="3" />
                </b-col>
            </b-row>

            <b-table-simple class="table-sm mt-4">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_size_intervencion th_style">Pronóstico</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="pronostico_id"
                                v-model="datos.pronostico_id" :options="pronosticos" value-field="id" text-field="nombre"
                                class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div v-if="datos.firma.is_firmado == 1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico:
                    {{ datos.firma.firmado_por.nombre }}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha:
                    {{ datos.firma.fecha_firmado }}</span>
            </div>

            <b-row v-if="error" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-if="errores.password"><i class="fa fa-warning px-1" />| {{ errores.password[0] }} <br></li>
                            <li v-if="errores.fecha"><i class="fa fa-warning px-1" />| {{ errores.fecha[0] }} <br></li>
                            <li v-if="errores.hora"><i class="fa fa-warning px-1" />| {{ errores.hora[0] }} <br></li>
                            <li v-if="errores.fecha_cirugia"><i class="fa fa-warning px-1" />| {{ errores.fecha_cirugia[0] }}
                                <br></li>
                            <li v-if="errores.diagnostico"><i class="fa fa-warning px-1" />| {{ errores.diagnostico[0] }} <br>
                            </li>
                            <li v-if="errores.plan_quirurgico"><i class="fa fa-warning px-1" />|
                                {{ errores.plan_quirurgico[0] }} <br></li>
                            <li v-if="errores.tipo_intervencion_id"><i class="fa fa-warning px-1" />|
                                {{ errores.tipo_intervencion_id[0] }} <br></li>
                            <li v-if="errores.riesgo_quirurgico_id"><i class="fa fa-warning px-1" />|
                                {{ errores.riesgo_quirurgico_id[0] }} <br></li>
                            <li v-if="errores.cuidados_plan_terapeotico"><i class="fa fa-warning px-1" />|
                                {{ errores.cuidados_plan_terapeotico[0] }} <br></li>
                            <li v-if="errores.pronostico_id"><i class="fa fa-warning px-1" />| {{ errores.pronostico_id[0] }}
                                <br></li>
                            <li v-if="errores.sala"><i class="fa fa-warning px-1" />| {{ errores.sala[0] }}</li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="(!dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy"
                            @click="preOperatoria">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busyUpdate" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()"
                            :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <!-- <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->

            <div v-if="(datos.firma.is_firmado == 0 && !actualizar && !state.oldExp && idResponsable === usuario.id && !isEgresado)"
                class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y
                            GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="(cancelar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
        </b-container>
        <div class="sticky-container"
            v-if="(idPacienteSeleccionado != '' && !openFirmaPsw && !openSuccess && !openError && !openPreSave && !regNewExp && !pswUpdate && !loading)">
            <ul class="sticky">
                <li v-if="(!dtaExist && !state.oldExp && !isEgresado)" @click="preGuardar()">
                    <img :src="require('../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating && !state.oldExp && datos.firma.is_firmado == 0 && !isEgresado)"
                    @click="editar()">
                    <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating)" @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()" />
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()" />
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <NuevoExpediente :openRegExp="regNewExp" />
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading" />

    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from 'pdf-merger-js';
import router from '../../router';
import Loading from '@/components/Loader/Loading.vue'
import FirmaPassword from '@/components/Modals/FirmaPassword.vue'
import { url } from '../../../settings'
import Foto from '@/components/Paciente/Foto.vue'
const urlp = `${url}/api/pacientes`
import Success from '@/components/Modals/Success.vue'
import Error from '@/components/Modals/Error.vue'
import { getCatalogo } from '../../helpers/catalogos'
import CryptoJS from 'crypto-js'
import DatosPreguardados from '@/components/Modals/DatosPreguardados.vue'
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue'
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'

export default {
    name: "NotaPreOperatoria",
    components: { Datos, Foto, Success, Error, FirmaPassword, DatosPreguardados, NuevoExpediente, FirmaPswUpdate, Loading },

    computed: {
        hour() {
            return this.datos.hora.length != 0
        },

        fecha() {
            return this.datos.fecha != 0
        },

        fecha_cirugia() {
            return this.datos.fecha_cirugia != 0
        },

        validation() {
            return this.idPacienteSeleccionado != ""
        },

        diagnostico() {
            return this.datos.diagnostico.length != 0;
        },

        plan_quirurgico() {
            return this.datos.plan_quirurgico.length != 0;
        },

        riesgo_quirurgico_id() {
            return this.datos.riesgo_quirurgico_id != null;
        },

        tipo_intervencion_id() {
            return this.datos.tipo_intervencion_id != null;
        },

        cuidados_plan_terapeotico() {
            return this.datos.cuidados_plan_terapeotico.length != 0;
        },

        pronostico_id() {
            return this.datos.pronostico_id != null;
        },

        sala() {
            return this.datos.sala != ""
        }
    },

    data: () => ({
        changeDta: null,
        isEgresado: false,
        usuario: {
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable: null,

        state: {
            idExpediente: "",
            idPaciente: "",
            oldExp: false,
        },
        allExp: [],

        regNewExp: false,

        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,

        loading: true,

        showDta: false,
        disabled: true,
        openFirmaPsw: false,
        openPreSave: false,
        btnGuardar: false,
        btnFirmar: true,
        info: false,
        error: false,
        errores: "",
        busy: false,
        openSuccess: false,
        openError: false,
        cuarto: "",
        datosPreGuardados: [],
        datos: {
            sala: "",
            expediente_id: "",
            password: '',
            fecha: "",
            hora: "",
            fecha_cirugia: "",
            diagnostico: "",
            plan_quirurgico: "",
            tipo_intervencion_id: null,
            riesgo_quirurgico_id: null,
            cuidados_plan_terapeotico: "",
            pronostico_id: null,
            firma: {
                fecha_firmado: null,
                firmado_por: {
                    id: null,
                    nombre: ""
                },
                is_firmado: 0
            },
        },

        tipo_intervencion_quirurgica: [],

        datosPaciente: {
            password: "",
            edad: "",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id: null,
                nombre: "",
            },
            municipio: {
                id: null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero: {
                id: null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        pacientes: [{}],
        nombres: [],
        idPacienteSeleccionado: "",
        riesgo_quirurgico: [],
        pronosticos: [],
        done: false,
        datosExpediente: {}
    }),

    methods: {
        verNotaDeEgreso() {
            this.$emit('toEgreso')
        },

        changeExp(id) {
            this.clearData()
            this.loading = true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp = false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta = currentExp.id
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/` + this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp = data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es" + error);
            }
        },

        cancelEdit() {
            this.obtenerNotaPreOperatoria(this.datos.expediente_id)
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            // this.pswUpdate = true
            this.pswUpdate = false
            // this.datos.password = psw
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.disableInput = false
            this.updating = false
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.error = false
            this.errores = []
            this.busyUpdate = true

            try {
                const res = await fetch(`${url}/api/notas/pre-operatoria/update/${this.datos.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status === 500
                var warn = false
                var err = []
                var promise = Promise.resolve(data)
                await promise.then(function (val) {
                    console.log(val);
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.obtenerNotaPreOperatoria(this.datos.expediente_id)
                    this.openSuccess = true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate = false
                    this.actualizar = false
                    this.cancelar = false
                } else {
                    this.openError = true
                    this.error = true
                    this.errores = err
                    this.busyUpdate = false
                    if (this.errores.password) {
                        this.btnFirmaUpdate = true
                        this.disableInput = false
                        this.actualizar = false
                        this.busyUpdate = false
                    } else {
                        this.busyUpdate = false
                        this.actualizar = true
                        this.cancelar = true
                    }
                }

            } catch (error) {
                this.busyUpdate = false
                console.log({ Error: error })
            }
        },

        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
                title: "Datos preguardados",
                variant: variant,
                solid: true
            })
        },

        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/notas/pre-operatoria/descargar/${this.datos.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                }
            }).then(function (resp) {
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function (blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false 
                // }
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob) {
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente + " Nota pre-operatoria");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        preGuardar() {
            var existe = false;
            try {
                if (localStorage.preGuardadoNPreOperatoria) {
                    console.log("si hay datos");
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNPreOperatoria)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            this.datos.password = ""
            var i = 0;
            for (let val of this.datosPreGuardados) {
                console.log("valor: " + val.expediente_id);
                if (this.datos.expediente_id === val.expediente_id) {
                    console.log("ya esta we");
                    this.datosPreGuardados[i] = this.datos
                    existe = true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push(this.datos);
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNPreOperatoria', dta);
            this.makeToast('success');
        },

        verificarPreGuardado() {
            console.log("verifico");
            try {
                if (localStorage.preGuardadoNPreOperatoria) {
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNPreOperatoria)
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id === val.expediente_id) {
                    if (this.showDta === true) {
                        this.datos = val
                    } else {
                        this.openPreSave = true
                    }
                }
            }
        },
        firmar() {
            this.openFirmaPsw = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.pacientes = data.pacientes
                this.pacientes.unshift({ id: "", nombre: "Seleccionar paciente" })
            } catch (error) {
                console.log("el error es" + error);
            }
        },

        async preOperatoria() {
            this.busy = true
            this.error = false
            this.errores = []
            try {
                const res = await fetch(`${url}/api/notas/pre-operatoria/store`, {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var promise = Promise.resolve(data);
                var warn = false
                var err = []
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.dtaExist = true
                    this.openSuccess = true;
                    this.error = false
                    this.errores = []
                    this.busy = false
                    var date = new Date;
                    var minutes = ("0" + date.getMinutes()).slice(-2);
                    var hour = ("0" + date.getHours()).slice(-2);
                    this.datos.hora = "" + hour + ":" + minutes + ":00"
                    var today = new Date();
                    var dd = ("0" + (today.getDate())).slice(-2);
                    var mm = ("0" + (today.getMonth() + 1)).slice(-2);
                    var yyyy = today.getFullYear();
                    this.datos.fecha = yyyy + '-' + mm + '-' + dd;
                    this.datos.fecha_cirugia = yyyy + '-' + mm + '-' + dd;
                    this.obtenerPacientes()
                    this.getCatalogos()
                    this.obtenerNotaPreOperatoria(this.datos.expediente_id)
                } else {
                    this.error = true
                    this.errores = err
                    this.openError = true
                    this.busy = false
                }

                if (this.errores.password) {
                    this.busy = false
                    this.actualizar = false
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }
            } catch (error) {
                console.log(error);
                this.busy = false
            }
        },

        async obtenerNotaPreOperatoria(value) {
            var dataAux = {};
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/notas/pre-operatoria/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.status == false) {
                    this.disableInput = false
                    this.btnFirmar = true
                } else {
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnFirmar = false
                    this.btnGuardar = false
                }

                dataAux = data.preoperatoria;
                if (data.preoperatoria) {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.loading = false
                    this.datos = data.preoperatoria;
                    console.log(this.datos);
                    this.datos.tipo_intervencion_id = data.preoperatoria.tipo_intervencion.id;
                    this.datos.riesgo_quirurgico_id = data.preoperatoria.riesgo_quirurgico.id;
                    this.datos.pronostico_id = data.preoperatoria.pronostico.id;
                    this.done = true
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.loading = false
                    this.verificarPreGuardado()
                    this.done = false
                }
                if (this.done) {
                    this.datos.tipo_intervencion_id = dataAux.tipo_intervencion.id;
                    this.datos.riesgo_quirurgico_id = dataAux.riesgo_quirurgico.id;
                    this.datos.pronostico_id = dataAux.pronostico.id;
                }
                this.loading = false

            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading = false
                console.log(error);
            }
        },

        clearData() {
            this.btnGuardar = false;
            this.btnFirmar = true;
            this.showDta = false
            this.datos.sala = ""
            this.datos.expediente_id = ""
            this.datos.password = ''
            this.datos.fecha = ""
            this.datos.hora = ""
            this.datos.fecha_cirugia = ""
            this.datos.diagnostico = ""
            this.datos.plan_quirurgico = ""
            this.datos.tipo_intervencion_id = null
            this.datos.riesgo_quirurgico_id = null
            this.datos.cuidados_plan_terapeotico = ""
            this.datos.pronostico_id = null
            this.getFecha();
        },

        getFecha() {
            var date = new Date;
            var minutes = ("0" + date.getMinutes()).slice(-2);
            var hour = ("0" + date.getHours()).slice(-2);
            this.datos.hora = "" + hour + ":" + minutes + ":00"
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() + 1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha = yyyy + '-' + mm + '-' + dd;
            this.datos.fecha_cirugia = yyyy + '-' + mm + '-' + dd;
        },

        setData() {
            this.getFecha()
            this.getCatalogos()
            this.obtenerPacientes()

            const pacienteEncrypt = localStorage.expedienteEncrypt
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente)
            this.changeDta = paciente.id
            this.idResponsable = paciente.medico.id
            this.state.idExpediente = paciente.id
            this.state.idPaciente = paciente.paciente.id
            this.datos.plan_quirurgico = paciente.plan_manejo_tratamiento ? paciente.plan_manejo_tratamiento : ""
            this.datos.pronostico_id = paciente.pronostico_id.id ? paciente.pronostico_id.id : null
            this.datos.diagnostico = paciente.diagnostico ? paciente.diagnostico : ""


            if (paciente.paciente.lastExpediente.expediente_id != paciente.id) {
                this.state.oldExp = true
            } else {
                this.state.oldExp = false
            }

            if (paciente.paciente.telefono == null) {
                paciente.paciente.telefono = "-----"
            }
            if (paciente.paciente.email == null) {
                paciente.paciente.email = "-----"
            }
            this.idPacienteSeleccionado = paciente.paciente.id
            this.datos.expediente_id = paciente.id;
            this.datosPaciente = paciente.paciente
            this.datosExpediente = paciente
            this.btnGuardar = false;
            this.btnFirmar = true;

            this.datosPaciente.noExpediente = paciente.paciente.no_expediente
            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad = yyyy - year.getFullYear();
            this.obtenerNotaPreOperatoria(this.datos.expediente_id);
            this.allExpedientes()
        },

        getCatalogos() {
            //  PRONÓSTICO **********************************************************************************************************************************
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/pronostico'))
            }).then((data) => {
                try {
                    this.pronosticos = data.pronostico
                    this.pronosticos.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })

            //  RIESGOS QUIRURGICOS **********************************************************************************************************************************
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/riesgo_quirurgico'))
            }).then((data) => {
                try {
                    this.riesgo_quirurgico = data.riesgo_quirurgico
                    this.riesgo_quirurgico.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })

            //  TIPO DE INTERVENCIÓN QUIRÚRGICA **********************************************************************************************************************************
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/tipo_intervencion_quirurgica'))
            }).then((data) => {
                try {
                    this.tipo_intervencion_quirurgica = data.tipo_intervencion_quirurgica
                    this.tipo_intervencion_quirurgica.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })

            new Promise((resolve) => {
                resolve(getCatalogo('/api/usuarios/logged'))
            }).then((data) => {
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            })
        },
        cerrarModal() {
            this.openSuccess = false
        },
        cerrarModalError() {
            console.log('cerrarModalError');
            this.openError = false
        },
        firma_psw(psw) {
            this.datos.password = psw
            if (!this.dtaExist) {
                this.preOperatoria()
            } else {
                this.updateData();
            }
            this.openFirmaPsw = false
        },
        showDtaPreSave(){
            console.log('presave');
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
        }
    },

    mounted() {
    },
    activated() {
        Object.assign(this.$data, this.$options.data());

        const egreso = localStorage.egreso
        if (egreso) {
            const egresoDE = JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado = egresoDE.egreso
        } else {
            router.push("/main")
        }

        this.loading = true
        this.setData()
        this.showDta === false

    }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
    .size_th {
        width: 10vw;
    }
}

@media only screen and (max-width: 960px) {
    .size_th {
        width: 15vw;
    }
}

@media only screen and (min-width: 960px) {
    .th_size_intervencion {
        width: 21vw;
    }
}

@media only screen and (max-width: 960px) {
    .th_size_intervencion {
        width: 35vw;
    }
}</style>
