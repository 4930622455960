<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="openRegExp" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-warning fa-3x"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Sin expediente!</strong></span><br>
            </div>
            <div class="d-flex justify-content-center text-center mt-1">
                <p class="subttitle-modal">El paciente no cuenta con un expediente</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <button @click="registrar()" class="btn-modal-success border-0 px-3 pb-2 pt-1">Registar</button>
            </div>
        </b-modal>
    </div>    
</template>

<script>

export default {
    name: 'NuevoExpediente',
    props: ['openRegExp'],

    data:() => ({
        id: ''
    }),
    
    methods: {

        registrar() {
            this.$emit("nuevoExpediente", this.id)
            this.id = ''
        },

        onEnterKeyUp(event) {
            if (this.abrir) {
                if (event.which === 13) {
                    this.registrar()      
                }
            }
        }
    },

    activated() {
        window.addEventListener('keyup', (evt) => {
            // this.onEscapeKeyUp(evt)
            this.onEnterKeyUp(evt)
        })
    },

    mounted() {
    }
}
</script>

<style scoped>
    .fa-warning {
        color: #d15460;
    }
</style>