<template>
    <div>
        <b-modal class="rounded-lg" size="sm" v-model="abrir" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center py-2">
                <span class="icono text-center" align="center">
                    <i class="fa fa-heart fa-3x icon-color corazon"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Cuidado!</strong></span><br>
            </div>
            <div class="d-flex justify-content-center text-center mt-3">
                <p class="subttitle-modal">Presión arterial fuera de rangos normales</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <button @click="cerrarModal()" class="btn-modal-success border-0 px-3 pb-2 pt-1">Aceptar</button>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'AlertPresionArterial',
    props: ['abrir'],
    
    methods: {
        cerrarModal(){
            this.$emit("cerrarModalAlertPresionArterial");
        },
        
        onEscapeKeyUp(event) {
            if (this.abrir) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }
            }
        },

        onEnterKeyUp(event) {
            if (this.abrir) {
                if (event.which === 13) {
                    this.cerrarModal()        
                }
            }
        }
    },

    mounted() {
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
            this.onEnterKeyUp(evt)
        })
    },
}
</script>

<style scoped>
    .icon-color {
        color: rgb(169, 11, 11);
    }

    .icono {
        border-radius: 50%;
        background-color: white;
    }

    .btn_finalizar {
        border-radius: 5px;
        background-color: #689aaf;
        color: white;
    }

    @keyframes latidos {
        from { transform: none; }
        50% { transform: scale(1.4); }
        to { transform: none; }
    }
    
    .corazon {
        display: inline-block;
        text-shadow: 0 0 4px #222,1px 1px  0 #450505;
        color: rgb(255, 14, 14);
        animation: latidos .6s infinite;
        transform-origin: center;
    }
    
</style>