<template>
    <b-overlay no-wrap fixed bg-color="grey" opacity="0.4" :show="show" rounded="md">
        <template #overlay>
            <div class="text-center bg">
                <i class="fa fa-heart fa-lg px-2 corazon"/>
                <p class="mt-3 message" id="cancel-label">Por favor, espere...</p>
            </div>
        </template>
    </b-overlay>
</template>

<script>
export default {
    name: 'Loading',
    props: ['show']
}
</script>

<style scoped>
    @keyframes latidos {
        from { transform: none; }
        50% { transform: scale(1.1); }
        to { transform: none; }
    }

    .corazon {
        display: inline-block;
        text-shadow: 0 0 4px #222,1px 1px  0 #450505;
        color: rgb(255, 14, 14);
        animation: latidos .6s infinite;
        transform-origin: center;
        font-size: 3rem;
    }
    
    .message {
        color: rgb(201, 46, 46);
        font-weight: 600;
    }
</style>