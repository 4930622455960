<template>
    <div>
        <b-modal class="rounded-lg" size="md" v-model="abrir" title="Ingresa tu contraseña" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer centered>
            <div class="d-flex justify-content-center">
                <b-col cols="12" sm="12" md="12" lg="12" class="mt-2">
                        <div class="d-flex">
                            <i class="icon_color_main fa fa-lock fa-lg  my-auto mr-2"></i>
                            <b-form-input v-model="data.psw" @keydown.enter="firmar()" autocomplete="off" class="form_input-color border-0 mt-1 psw" size="md" type="password" placeholder="Contraseña"></b-form-input>
                        </div>
                        <div>
                            <span class="msjError ml-4">{{msjError}}</span> 
                        </div>
                </b-col>
            </div>
            <div class="d-flex justify-content-end mt-3 pr-3">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                >
                    <button @click="firmar()" :disabled="busy" class="btn-modal-success border-0 px-3 pb-2 pt-1">Firmar documento</button>
                </b-overlay>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>
    </div>    
</template>

<script>

export default {
    name: 'FirmaPswUpdate',
    props: ['abrir'],

    data:() => ({
        msjError: '',
        busy: false,
        data: {
            psw: ''
        }
    }),

    methods: {
        firmar() {
            if (!this.data.psw) {
                this.msjError= 'La contraseña es obligatoria';
            } else {
                this.msjError = '';
                this.data.psw = ''
            }
        },

        cerrarModal(){
            console.log("Me sali del modal firma");
            this.msjError='';
            this.data.psw = "";
        },

        onEscapeKeyUp(event) {
            if (this.abrir) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }
            }
        }
    },

    mounted() {
       
    },
    activated(){
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
        })
    },
} 
</script>

<style scoped>

</style>