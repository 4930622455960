<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="12" md="6" lg="6">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Registro de paciente</h2>
                <h4 v-if="!existeExpediente" class="txt_primary pl-3">Hoja de ingreso</h4>
                <h4 v-if="existeExpediente" class="txt_primary pl-3">Reingreso</h4>
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="6" lg="6">
                <div class="float-right pr-3">
                    <button class="btn_foto">
                        <i class="fa fa-camera fa-lg mx-auto my-auto" />
                    </button>
                </div>
            </b-col>
        </b-row>
        <b-container>
            <div v-if="existeExpediente">
                <span class="txt_secondary size-span"><i class="fa fa-file-text-o mr-1"></i>Último expediente: {{lastExp}}</span> <br>
                <small class="txt_gray">* El expediente se generará automaticamente</small>
            </div>

            <b-row v-if="!existeExpediente">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Registrar numero de expediente físico del paciente </p>
                        <label class="switch">
                            <input :disabled="state!='paciente'" v-model="generarNoExp" value=false type="checkbox">
                            <!-- @click="datos.no_expediente=''" -->
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>

            <b-table-simple v-if="generarNoExp" class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Expediente físico</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="nuevoExp || state!='paciente'" maxlength="14" name="nh" autocomplete="off" :state="Vexp" v-model="no_expediente_legacy" v-on:keypress="soloNumerosExpediente($event)" v-on:keyup="soloNumerosExpedienteUp($event)" class="border-0" size="sm" placeholder="# Expediente físico" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <div v-if="generarNoExp" style="margin-top: -20px;">
                <small class="autoExp" >*Se generará un nuevo numero de expediente para el control del sistema</small>
            </div>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>
            <b-table-simple class="table-sm border_table" :disabled="true">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                             <b-form-input :disabled="nuevoExp || state!='paciente'" type="text" name="nombrePaciente" autocomplete="off" v-on:keypress="soloLetras($event)" @change="existePaciente()" :state="validation" v-model="datos.nombre" class="border-0 text-value-capitalize" size="sm" placeholder="Ingresa nombre del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="nuevoExp || state!='paciente'" :state="genero_id" v-model="datos.genero_id" :options="genero" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="nuevoExp || state!='paciente'" name="fechaPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha" v-model="datos.fecha_nacimiento" @change="change(datos.fecha_nacimiento)" maxlength="10" class="border-0" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input disabled name="agePaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="expediente.edad" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input disabled name="monthPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="datos.meses" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="nuevoExp || state!='paciente'" name="ecPaciente"  :state="estado_civil_id" v-model="selectedecivil" :options="ecivil" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="nuevoExp || state!='paciente'" list="input-list" name="cp" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" @input="searchDataCP" :state="cp" v-model="datos.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="nuevoExp || state!='paciente'" type="text" name="domicilioPaciente" autocomplete="off" v-on:keypress="rgexDomicilio($event)" :state="domicilio"  v-model="datos.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="nuevoExp || state!='paciente'" list="my-list-id" type="text" name="coloniaPaciente" autocomplete="off" :state="colonia" v-model="datos.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                            <datalist id="my-list-id">
                            <option v-for="size in listaAsentamientos" :key="size">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="nuevoExp || state!='paciente'" type="text" name="ciudadPaciente" autocomplete="off" v-on:keypress="soloLetras($event)" :state="ciudad" v-model="datos.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="nuevoExp || state!='paciente'" name="edoPaciente" :state="estado" v-model="datos.estado_id" :options="dtaEstados"  @change="getMunicipiosPaciente(datos.estado_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select name="municipioPaciente" :disabled="disabled_select_mcpio_paciente || nuevoExp || state!='paciente'" :state="municipio" v-model="datos.municipio_id" :options="dtaMunicipiosPaciente" value-field="value" text-field="text" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th id="formResp" class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="nuevoExp || state!='paciente'" name="telefonoPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="datos.telefono" :state="telefono" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="nuevoExp || state!='paciente'" :state="email" type="email" name="emailPaciente" autocomplete="off" v-model="datos.email" class="border-0" size="sm" placeholder="Ingresa correo electrónico" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error && state=='paciente'" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err1,index) of erroresForm1" :key="index"><i class="fa fa-warning px-1" v-if="!err1.expediente_id" />| {{err1[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="btnGuardar && state=='paciente'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="btnGuardarPaciente()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="btnFirmar && state=='paciente'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="firmar()">FIRMAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <p class="txt_secondary text-uppercase pt-3"><strong>Responsable</strong></p>
            <b-row v-if="state=='responsable'">
                <b-col cols="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Misma ubicación</p>
                        <label class="switch">
                            <input v-model="mismaUbicacion" @change="getMismaUbicacion()" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='responsable'" type="text" list="my-list-R" name="resp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="nombreR" v-model="responsable.nombre" class="border-0 text-value-capitalize" size="sm" placeholder="Ingresa nombre de responsable del paciente" />
                            <datalist id="my-list-R">
                            <option v-for="size in listaAsentamientos" :key="size">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='responsable'" name="fechaResp" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="responsable.fecha_nacimiento" @change="change(datos.fecha_nacimiento)" maxlength="10" class="border-0" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="state!='responsable'" :state="genero_idResp" v-model="responsable.genero_id" :options="genero" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Parentesco</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='responsable'" type="text" name="parentesco" autocomplete="off" v-on:keypress="soloLetras($event)" :state="parentezco" v-model="responsable.parentezco" class="border-0" size="sm" placeholder="Ingresa parentesco con el paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="state!='responsable'" list="input-list" name="cp" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" @input="searchDataCPResponsable" :state="cpr" v-model="responsable.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='responsable'" type="text" name="domicilioResp" autocomplete="off" v-on:keypress="rgexDomicilio($event)" :state="domicilior" v-model="responsable.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                            <!-- <b-form-input list="my-list-id" name="coloniaResp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="coloniar" v-model="responsable.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" /> -->
                            <b-form-input :disabled="state!='responsable'" list="my-list-id2" type="text" name="coloniaPaciente" autocomplete="off" :state="coloniar" v-model="responsable.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                            <datalist id="my-list-id2">
                                <option v-for="size in listaAsentamientosr" :key="size">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='responsable'" type="text" name="ciudadResp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="ciudadr" v-model="responsable.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="state!='responsable'" name="edoResp" :state="estador" v-model="responsable.estado_id" :options="dtaEstados" @change="getMunicipiosResp(responsable.estado_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select name="municipioResp" :disabled="disabled_select_mcpio_resp || state!='responsable'" :state="municipioR" v-model="responsable.municipio_id" :options="dtaMunicipiosResp" value-field="value" text-field="text" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th id="formFact" class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='responsable'" type="text" name="telefonoResp" autocomplete="off" :state="telefonor" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="responsable.telefono" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="state!='responsable'" type="email" name="emailResp" autocomplete="off" :state="emailr" v-model="responsable.email" class="border-0" size="sm" placeholder="Ingresa correo electrónico" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error && state=='responsable'" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err2,index) of erroresForm2" :key="index"><i class="fa fa-warning px-1" v-if="!err2.expediente_id" />| {{err2[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="btnGuardar && state=='responsable'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="registroResponsable(idPaciente)">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="btnFirmar && state=='responsable'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="firmar()">FIRMAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <p id="continuar" class="txt_secondary text-uppercase pt-3"><strong>Datos de facturación</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Facturación</p>
                        <label class="switch">
                            <input :disabled="state!='factura'" v-model="dtaFacturacion" @change="datosFacturacion()" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>

            <b-table-simple v-if="form_dta_facturacion" class="table-sm mt-3">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre / Razón social</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='factura'" type="text" name="nombreRazonSocial" v-model="dataFacturacion.razon_social" :state="VRazonSocial" autocomplete="off" v-on:keypress="soloLetras($event)" maxlength="50" class="border-0" size="sm" placeholder="Ingresa nombre o razón social" max="5" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='factura'" type="email" name="emailFactura" v-model="dataFacturacion.email" :state="VFactEmail" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el email"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">RFC</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='factura'" type="text" name="RFC" v-model="dataFacturacion.rfc" :state="VRcf" @keyup="upperCase()" autocomplete="off" maxlength="13" class="border-0" size="sm" placeholder="Ingresa el RFC" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Método de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="state!='factura'" name="metodoPago" v-model="dataFacturacion.metodo_pago_id" :options="metodoPago" :state="VMetodoPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Forma de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="state!='factura'" name="formaPago" v-model="dataFacturacion.forma_pago_id" :options="formaPago" :state="VFormaPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th id="formExp" class="th_style">Uso del CFDI</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="state!='factura'" name="usoCFDI" v-model="dataFacturacion.uso_cfdi_id" :options="usoCfdi" :state="VUsoCFDI" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='factura'" type="text" name="domicilioFactura"  v-on:keypress="rgexDomicilio($event)" v-model="dataFacturacion.domicilio" :state="VFactDomicilio" autocomplete="off" class="border-0" maxlength="250" size="sm" placeholder="Ingresa el domicilio" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error && state=='factura'" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(errFact,index) of erroresFormFact" :key="index"><i class="fa fa-warning px-1" v-if="!errFact.expediente_id" />| {{errFact[0]}} <br>
                            </li>
                        </ul> 
                    </div>
                </b-col>
            </b-row>
            <div v-if="!form_dta_facturacion && state=='factura'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="toExpediente()">CONTINUAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="form_dta_facturacion && btnGuardar && state=='factura'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="registroFacturacion(idResponsable)">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            
            <div v-if="form_dta_facturacion && btnFirmar && state=='factura'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="firmar()">FIRMAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos médicos</strong></p>

            <b-table-simple class="table-sm">
                <b-tbody>

                    <b-tr>
                        <b-th class="th_style size_th">Fecha de ingreso</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input disabled name="fechaI" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha_ingreso" v-model="expediente.fecha_ingreso" type="date" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Hora de ingreso</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <div class="d-flex">
                                        <b-form-input disabled type="time" min="00:01" max="23:59" autocomplete="off" name="horas" v-on:keypress="soloNumeros($event)" :state="hora_ingreso" v-model="expediente.hora_ingreso" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">horas</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Número de habitación</b-th>
                        <b-td colspan="2" class="border_table">
                            <!-- <b-form-input :disabled="state!='expediente'" name="nh" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="no_habitacion"  v-model="expediente.no_habitacion" class="border-0" size="sm" placeholder="Ingresa número de habitación" /> -->
                            <b-form-select :state="no_habitacion" :disabled="state!='expediente'"  v-model="expediente.habitacion_id" text-field="name" value-field="id" :options="abit" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="state!='expediente'" :state="especialidad_id" v-model="expediente.especialidad_id" :options="dtaEspecialidades" @change="obtenerMedicosPerEspecialidad(expediente.especialidad_id)" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Médico tratante</b-th>
                        <b-td class="border_table">
                            <b-form-select :state="VmedicoId" :disabled="disabled_select_medico" v-model="expediente.medico_id" :options="dtaMedicos" class="border-0" size="sm" />
                        </b-td>
                        <b-td class="border_table justify-content-center text-center my-auto">
                            <button :disabled="disabled_select_medico || state!='expediente'" class="border-0 bg_secondary txt_white rounded-circle pd-add-medic" title="Añadir médico" @click="modalAddMedic()">
                                <i class="fa fa-plus fa-md" />
                            </button>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Diagnóstico</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="state!='expediente'" name="diag" autocomplete="off" :state="diagnostico" v-model="expediente.diagnostico" class="border-0" size="sm" placeholder="Ingresa diagnóstico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Tratamiento</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="state!='expediente'" :state="VTratamiento" v-model="expediente.tratamiento_id" :options="dtaTratamiento" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Plan de manejo de tratamiento</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="state!='expediente'" name="planTratamiento" autocomplete="off" :state="VPlanTratamiento" v-model="expediente.plan_manejo_tratamiento" class="border-0" size="sm" placeholder="Ingresa el plan de manejo de tratamiento" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Pronóstico</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="state!='expediente'" :state="VPronostico" v-model="expediente.pronostico_id" :options="dtaPronostico" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Alergias</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" v-model="expediente.tiene_alergias" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-row v-if="expediente.tiene_alergias">
                <b-col cols="12" sm="12" md="7" lg="7">
                    <div class="d-flex">
                        <span class="px-2 pt-2 txt_secondary">
                            ¿Cuál?
                        </span>
                        <b-form-input :disabled="state!='expediente'" type="text" name="cualAergia" autocomplete="off" :state="alergias" v-model="expediente.alergias" placeholder="Ingrese las alergias" class="float-right border_bottom" size="sm"></b-form-input>
                    </div>
                </b-col>
            </b-row>
            
            <p class="txt_secondary text-uppercase pt-3"><strong>Identificación de barreras</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Escolaridad</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="state!='expediente'" :state="escolaridad" v-model="expediente.escolaridad_id" :options="escolaridades" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ocupación</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='expediente'" type="text" name="ocupacion" autocomplete="off" :state="ocupacion" v-model="expediente.ocupacion" class="border-0" size="sm" placeholder="Ingresa ocupación laboral" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Religión</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='expediente'" type="text" name="religion" autocomplete="off" v-on:keypress="soloLetras($event)" :state="religion" v-model="expediente.religion" class="border-0" size="sm"  placeholder="Ingresa religión" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Idioma</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="state!='expediente'" type="text" name="idioma" autocomplete="off" v-on:keypress="soloLetras($event)" :state="idioma" v-model="expediente.idioma" class="border-0" size="sm" placeholder="Ingresa idioma que usa para comunicarse" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th_grupoVulnerable">Grupo vulnerable</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" v-model="expediente.grupo_vulnerable" @change="GB(expediente.grupo_vulnerable)" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para hablar</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" v-model="expediente.dificultar_hablar" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para oír</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" v-model="expediente.dificultad_oir" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.dificultad_oir">
                        <b-th class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Porta equipo de audición?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.porta_equipo_audicion" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex float-left">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.porta_equipo_audicion" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>    
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para ver</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" v-model="expediente.dificultad_ver" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>  
                    <b-tr v-if="expediente.dificultad_ver">
                        <b-th class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Usa lentes?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.usa_lentes" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.usa_lentes" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>              
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para caminar</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" type="checkbox" v-model="expediente.dificultad_caminar" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th v-if="expediente.dificultad_caminar" class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Utiliza algún tipo de apoyo?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.usa_apoyo" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.usa_apoyo" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-if="expediente.usa_apoyo" class="py-2 pr-3">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <div class="d-flex">
                                        <span class="px-2 pt-2 txt_secondary">
                                            ¿Cuál?
                                        </span>
                                        <b-form-input :disabled="state!='expediente'" type="text" name="cualApoyo" autocomplete="off" v-on:keypress="soloLetras($event)" :state="tipo_apoyo" v-model="expediente.tipo_apoyo" class="float-right border_bottom" size="sm"></b-form-input>
                                    </div>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para comprender indicaciones</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="state!='expediente'" type="checkbox" v-model="expediente.dificultar_comprender_indicaciones" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Condición psicológica o emocional inestable</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                 <input :disabled="state!='expediente'" type="checkbox" v-model="expediente.tiene_condicion_psicologica" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.tiene_condicion_psicologica">
                        <b-th class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Está bajo algún tratamiento?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.bajo_tratamiento" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="state!='expediente'" v-model="expediente.bajo_tratamiento" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-if="expediente.bajo_tratamiento" class="py-2 pr-3">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <div class="d-flex">
                                        <span class="px-2 pt-2 txt_secondary">
                                            ¿Cuál?
                                        </span>
                                        <b-form-input :disabled="state!='expediente'" type="text" name="cualTratamiento" autocomplete="off" :state="tratamiento" v-model="expediente.tratamiento_psicologico" class="float-right border_bottom" size="sm"></b-form-input>
                                    </div>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <p class="txt_secondary pt-3"><strong>NOTAS A CONSIDERAR</strong> (de acuerdo a costumbres, valores y/o necesidades)</p>
            <b-form-textarea :disabled="state!='expediente'" autocomplete="off" v-model="expediente.notas" class="txtArea_border" size="sm" no-resize rows="5" max-rows="5"/>
            
            <b-row v-if="error && state=='expediente'" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err3,index) of erroresForm3" :key="index"><i class="fa fa-warning px-1" v-if="!err3.expediente_id" />| {{err3[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <div v-if="btnGuardar && state=='expediente'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="registroExpediente(idPaciente)">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="btnFirmar && state=='expediente'" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="firmar()">FIRMAR</button>
                    </b-overlay>
                </b-col>
            </div>
        </b-container>
        
        
        <b-button v-if="cancelarRegistro" title="Cancelar registro" @click="resetForm()" variant="btn_red" class="float-btn-back pt-1">
            <span class="fa fa-close my-float-btn"></span>
        </b-button>
        <div class="sticky-container" v-if="((deAdmision && idPaciente) && !openSuccess && !openError && !openFirmaPsw && !openAddMedic && !openMdlNewExp && !loading)">
            <ul class="sticky">
                <li @click="PdfHojaAdmision(idPaciente)">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <RegistroMedico :openModalAddMedic="openAddMedic" @closeModalAddMedic="closeModalAddMedic()" @getMedicPerEsp="getMedicPerEsp($event)"/>
        <PacientesExistentes ref="pacientesExistentes" :openPacienteExistente="openMdlNewExp" @cerrarPacienteExistente="cerrarPacienteExistente()"/>
        <Loading :show="loading"/>

    </b-container> 
</template>

<script>
import Vue from 'vue'
import PDFMerger from 'pdf-merger-js'
import PacientesExistentes from '@/components/Modals/PacientesExistentes.vue'
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import RegistroMedico from '@/components/Medico/Registro.vue'
import {url} from '../../../settings.js'
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
const link = `${url}/api/datos_medicos/datos/`;
import {debounce} from 'lodash';
import CryptoJS from 'crypto-js'
import router from '../../router/index.js'
import { getCatalogo } from '../../helpers/catalogos';
import Loading from '@/components/Loader/Loading.vue'

export default {
    name: 'Registro',
    components:{ Success, Error, FirmaPassword, RegistroMedico, PacientesExistentes, Loading },

    computed: {
        Vexp(){
            const patternExp = /^[0-9]{4}-[0-9]{5}-[0-9]{1,3}$/;
            // const patternExp = /^[0-9]{2,3}[.]{0,1}[0-9]{0,2}$/;
            return patternExp.test(this.no_expediente_legacy)
        },

        Vedad() {
            return this.expediente.edad != ""
        },

        Vmeses() {
            return this.datos.meses != ""
        },

        fecha() {
            return this.datos.fecha_nacimiento != ""
        },

        genero_id(){
            return this.datos.genero_id !=null
        },

        validation() {
            return this.datos.nombre.length >=10
        },

        estado_civil_id() {
            return this.selectedecivil != null
        },

        cp() {
            let flag = true;
            if (!this.regexNumber(this.datos.cp)) {
                flag = false;
            }

            if (this.datos.cp.length !== 5) {
                flag = false;
            }
            return flag;
        },
        
        domicilio() {
            return this.datos.domicilio != ""
        },

        colonia() {
            return this.datos.colonia != ""
        },

        ciudad() {
            return this.datos.ciudad != ""
        },

        estado() {
            return this.datos.estado_id != null
        },

        municipio() {
            return this.datos.municipio_id != null
        },

        telefono() {
            let flag = null;
            if (this.datos.telefono) {
                if ((this.datos.telefono.length>0 && this.datos.telefono.length<10)) {
                    flag = false;
                }else if (this.datos.telefono.length == 10) {
                    flag=true;
                }else{
                    flag=null
                }
            }
            return flag;
        },

        email() {
            var flag = null
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.datos.email=="" || this.datos.email==null) {
                flag=null
            }else if (pattern.test(this.datos.email)){
                flag=true
            }else{
                flag=false
            }
            return flag
        },

        fechar() {
            return this.responsable.fecha_nacimiento != ""
        },

        genero_idResp(){
            return this.responsable.genero_id !=null
        },

        emailr() {
            var flag = null
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.responsable.email=="") {
                flag=null
            }else if (pattern.test(this.responsable.email)){
                flag=true
            }else{
                flag=false
            }
            return flag
        },

        parentezco() {
            return this.responsable.parentezco != ""
        },

        nombreR() {
            return this.responsable.nombre.length >= 10
        },

        cpr() {
            let flag = true;
            if (!this.regexNumber(this.responsable.cp)) {
                flag = false;
            }

            if (this.responsable.cp.length !== 5) {
                flag = false;
            }
            return flag;
        },

        domicilior() {
            return this.responsable.domicilio != ""
        },

        estador() {
            return this.responsable.estado_id != null
        },

        municipioR() {
            return this.responsable.municipio_id != null
        },

        ciudadr() {
            return this.responsable.ciudad != ""
        },

        telefonor() {
            let flag = true;
            if (!this.regexNumber(this.responsable.telefono)) {
                flag = false;
            }

            if (this.responsable.telefono.length !== 10) {
                flag=false;
            }
            return flag;
        },

        coloniar() {
            return this.responsable.colonia != ""
        },

        fecha_ingreso() {
            return this.expediente.fecha_ingreso != ""
        },

        hora_ingreso() {
            return this.expediente.hora_ingreso != ""
        },
        no_habitacion() {
            return this.expediente.habitacion_id != ""
        },

        alergias() {
            return this.expediente.alergias != ""
        },

        especialidad_id() {
            return this.expediente.especialidad_id != null
        },

        diagnostico() {
            return this.expediente.diagnostico.length != 0
        },

        VTratamiento() {
            return this.expediente.tratamiento_id != null
        },

        VmedicoId() {
            return this.expediente.medico_id != null
        },

        escolaridad() {
            return this.expediente.escolaridad_id != null
        },

        religion() {
            return this.expediente.religion != ""
        },

        ocupacion() {
            return this.expediente.ocupacion != ""
        },

        idioma() {
            return this.expediente.idioma != ""
        },

        tipo_apoyo() {
            return this.expediente.tipo_apoyo != ""
        },

        tratamiento() {
            return this.expediente.tratamiento_psicologico != ""
        },

        VPlanTratamiento() {
            return this.expediente.plan_manejo_tratamiento != ''
        },

        VPronostico() {
            return this.expediente.pronostico_id != null
        },

        notas() {
            return this.expediente.notas != ""
        },

        /* Form datos de facturación */
        VRazonSocial() {
            return this.dataFacturacion.razon_social.length>4
        },

        VRcf() {
            let flag = false;
            if (this.dataFacturacion.rfc.length >= 13) {
                flag=true;
            }
            return flag;
        },

        VFactEmail() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.dataFacturacion.email)
        },

        VMetodoPago() {
            return this.dataFacturacion.metodo_pago_id != null
        },

        VFormaPago() {
            return this.dataFacturacion.forma_pago_id != null
        },

        VUsoCFDI() {
            return this.dataFacturacion.uso_cfdi_id != null
        },

        VFactDomicilio() {
            return this.dataFacturacion.domicilio != ""
        }
    },
    
    data:()=>({
        abit:[
                {'text':'Seleccionar habitacion', 'value':''},
                {'text':'1', 'value':'1'},
                {'text':'2', 'value':'2'},
                {'text':'3', 'value':'3'},
                {'text':'4', 'value':'4'},
                {'text':'5', 'value':'5'},
                {'text':'6', 'value':'6'},
                {'text':'7', 'value':'7'},
                {'text':'8', 'value':'8'},
                {'text':'9', 'value':'9'},
                {'text':'10', 'value':'10'},
                {'text':'11', 'value':'11'},
                {'text':'12', 'value':'12'},
                {'text':'13', 'value':'13'},
                {'text':'14', 'value':'14'},
                {'text':'15', 'value':'15'},
            ],
        existeExpediente: false,
        state:"paciente",//paciente - responsable - factura - expedinete
        no_expediente_legacy: "", 
        idPaciente:null,
        idResponsable:null,
        loading:false,
        reIngreso:false,
        lastExp:"",

        generarNoExp:false,

        cancelarRegistro: false,
        pacientes:[],
        openMdlNewExp: false,
        openAddMedic: false,
        datosCP: [],

        disabled_select_medico: true,
        disabled_select_mcpio_paciente: true,
        disabled_select_mcpio_resp: true,

        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,

        deAdmision:false,


        dtaFacturacion: false,
        form_dta_facturacion: false,
        mismaUbicacionDtaFact: false,
        
        mismaUbicacion: false,
        genero: [],
        
        dataFacturacion: {
            responsable_id: null, 
            razon_social: "",
            rfc: "",
            email: "",
            domicilio: "",
            metodo_pago_id: null,
            forma_pago_id: null,
            uso_cfdi_id: null,
        },

        metodoPago: [],
        formaPago: [],
        usoCfdi: [],
        dtaPronostico: [],

        year: '',
        month: '',
        error:false,
        erroresForm1:{},
        erroresForm2:{},
        erroresForm3:{},
        erroresFormFact:{},
        busy: false,
        openSuccess:false,
        openError:false,
        selectedecivil:null,
        dtaEspecialidades: [],
        ecivil: [],
        dtaEstados: [],
        dtaMunicipiosPaciente: [{value:null, text:"seleccione una opción"}],
        dtaMunicipiosResp: [{value:null, text:"seleccione una opción"}],
        dtaTratamiento: [],
        dtaMedicos: [], 
        dtaEstadosFacturacion: [],
        años:"",
        escolaridades:[],

        datos:{
            genero_id: null,
            edad:"",
            meses: "",
            nombre: "",
            password: '',
            fecha_nacimiento: "",
            estado_civil_id: "",
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            telefono: "",
            email: "",
            estado_id: null,
            municipio_id: null,
            no_expediente:"",
            no_expediente_legacy:""
        },
        password:"",
        responsable:{
            genero_id: null,
            password: '',
            paciente_id: "",
            email: "",
            parentezco: "",
            fecha_nacimiento: "",
            nombre: "",
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            telefono: "",
            estado_id: null,
            municipio_id: null
        },

        expediente:{
            edad:"",
            paciente_id: "",
            password: '',
            fecha_ingreso: "",
            hora_ingreso: "",
            habitacion_id: "",
            tiene_alergias: false,
            alergias: "",
            diagnostico: "",
            especialidad_id: null,
            medico_id: null,
            mayor_18_yaers: false,
            mayor_60_yaers: false,
            escolaridad_id: null,
            religion: "",
            ocupacion: "",
            idioma: "",
            grupo_vulnerable: false,
            dificultar_hablar: false,
            dificultad_oir: false,
            porta_equipo_audicion: false,
            dificultad_ver: false,
            usa_lentes: false,
            dificultad_caminar: false,
            usa_apoyo: false,
            tipo_apoyo: "",
            dificultar_comprender_indicaciones: false,
            tiene_condicion_psicologica: false,
            bajo_tratamiento: false,
            tratamiento_psicologico: "",
            notas: "",
            tratamiento_id: null,
            plan_manejo_tratamiento: '',
            pronostico_id: null,
            no_expediente:""
        },
        listaAsentamientos:[],
        listaAsentamientosr:[],

        nuevoExp: false,
        donePacientes:false,
        idPacienteSeleccionado:"",
    }),

    methods:{
        async PdfHojaAdmision(id) {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/hoja_admision/admision/pdf/${id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob()
            }).then(function(blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datos.nombre+ "-Hoja de admisión");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        resetForm() {
            this.dtaFacturacion=false
            this.busy=false

            this.nuevoExp = false
            this.datos.nombre = ''
            this.datos.genero_id = null
            this.datos.fecha_nacimiento = ''
            this.expediente.edad = ''
            this.datos.meses = ''
            this.datos.estado_id = null
            this.datos.cp = ''
            this.datos.domicilio = ''
            this.datos.colonia = ''
            this.datos.estado_id = null
            this.datos.municipio_id = null
            this.datos.telefono = ''
            this.datos.email = ''
            this.cancelarRegistro = false

            
            this.dataFacturacion.responsable_id=null,
            this.dataFacturacion.razon_social=""
            this.dataFacturacion.rfc=""
            this.dataFacturacion.email=""
            this.dataFacturacion.domicilio=""
            this.dataFacturacion.metodo_pago_id=null
            this.dataFacturacion.forma_pago_id=null
            this.dataFacturacion.uso_cfdi_id=null

            
            this.responsable.genero_id=null
            this.responsable.password=''
            this.responsable.paciente_id=""
            this.responsable.email=""
            this.responsable.parentezco=""
            this.responsable.fecha_nacimiento=""
            this.responsable.nombre=""
            this.responsable.cp=""
            this.responsable.domicilio=""
            this.responsable.colonia=""
            this.responsable.ciudad=""
            this.responsable.telefono=""
            this.responsable.estado_id=null
            this.responsable.municipio_id=null

            
            this.expediente.edad=""
            this.expediente.paciente_id=""
            this.expediente.password=''
            this.expediente.fecha_ingreso=""
            this.expediente.hora_ingreso=""
            this.expediente.habitacion_id=""
            this.expediente.tiene_alergias=false
            this.expediente.alergias=""
            this.expediente.diagnostico=""
            this.expediente.especialidad_id=null
            this.expediente.medico_id=null
            this.expediente.mayor_18_yaers=false
            this.expediente.mayor_60_yaers=false
            this.expediente.escolaridad_id=null
            this.expediente.religion=""
            this.expediente.ocupacion=""
            this.expediente.idioma=""
            this.expediente.grupo_vulnerable=false
            this.expediente.dificultar_hablar=false
            this.expediente.dificultad_oir=false
            this.expediente.porta_equipo_audicion=false
            this.expediente.dificultad_ver=false
            this.expediente.usa_lentes=false
            this.expediente.dificultad_caminar=false
            this.expediente.usa_apoyo=false
            this.expediente.tipo_apoyo=""
            this.expediente.dificultar_comprender_indicaciones=false
            this.expediente.tiene_condicion_psicologica=false
            this.expediente.bajo_tratamiento=false
            this.expediente.tratamiento_psicologico=""
            this.expediente.notas=""
            this.expediente.tratamiento_id=null
            this.expediente.plan_manejo_tratamiento=''
            this.expediente.pronostico_id=null

            this.setFechaHora();
            this.getCatalogos()
        },

        existePaciente(){
            let arr = (this.datos.nombre).split(" ");
            let res = this.pacientes
            let aux = res
            let finded = false
            let similitudes = 0
            for (const val of arr) {
                aux = res.filter(p => ((p.nombre).toLowerCase()).includes(val.toLowerCase()))
                if ((aux!=undefined && aux.length>0) ) {
                    similitudes = similitudes+1
                    res = aux
                    finded=true
                }else{
                    console.log("");
                }
            }
            if ((this.datos.nombre.length>=10 && res.length>0 && finded && similitudes>arr.length-2)||(this.datos.nombre.length>=10 && res.length>0 && finded && similitudes>4)) {
                this.openMdlNewExp = true
                this.$refs.pacientesExistentes.existPaciente(res)
            }
        },

        upperCase() {
            this.dataFacturacion.rfc = this.dataFacturacion.rfc.toUpperCase();
        },

        upperCaseFirstWord() {
            const regex = /\b[a-z]/g;
            const val = this.datos.nombre.toLowerCase().replace(regex, function(letter) {
                return letter.toUpperCase();
            });
            this.datos.nombre = val;
        },

        async getMunicipiosPaciente(idEstado) {
            if (this.datos.estado_id != null) {
                this.disabled_select_mcpio_paciente = false;
            } else {
                this.disabled_select_mcpio_paciente = true;
            }

            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEstado}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.dtaMunicipiosPaciente=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosPaciente.unshift({value:null,text:"seleccione una opción"})
                console.log(this.dtaMunicipiosPaciente);
            } catch (error) {
                console.log(error);
            }
        },

        async getMunicipiosResp(idEdo) {
             if (this.responsable.estado_id != null) {
                this.disabled_select_mcpio_resp = false;
            } else {
                this.disabled_select_mcpio_resp = true;
            }

            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEdo}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMunicipiosResp=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosResp.unshift({value:null,text:"seleccione una opción"})
                
            } catch (error) {
                console.log(error);
            }
        },

        /* Obtener datos a partir del cp */
        searchDataCP: debounce(function(){
            if (this.datos.cp.length > 4) {
                fetch(`${url}/api/catalogos/codigo_postal/${this.datos.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if (data.asentamientos.length>0) {
                        var estado="";
                        var municipio="";
                        this.listaAsentamientos=[];
                        this.datos.colonia=""
                        data.asentamientos.forEach(element => {
                            estado =  element.estado.id
                            municipio = element.municipio.id
                            this.listaAsentamientos.push(element.asentamiento)
                        });
                        this.datos.estado_id=estado
                        this.getMunicipiosPaciente(estado)
                        this.datos.municipio_id=municipio
                    }else{
                        console.log("no hay datos cp");
                    }
                })
            }
        },500),

        searchDataCPResponsable: debounce(function(){
            if (this.responsable.cp.length > 4) {
                fetch(`${url}/api/catalogos/codigo_postal/${this.responsable.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if (data.asentamientos.length>0) {
                        this.responsable.colonia=""
                        var estado="";
                        var municipio="";
                        this.listaAsentamientosr=[];
                        data.asentamientos.forEach(element => {
                            estado =  element.estado.id
                            municipio = element.municipio.id
                            this.listaAsentamientosr.push(element.asentamiento)
                        });
                        this.responsable.estado_id=estado
                        this.getMunicipiosResp(estado)
                        this.responsable.municipio_id=municipio
                    }
                })
            }
        },500),

        async obtenerMedicosPerEspecialidad(idEspecialidad) {
            this.expediente.medico_id = null
            
            if (this.expediente.especialidad_id != null) {
                this.disabled_select_medico = false;
            } else {
                this.disabled_select_medico = true;
                this.expediente.medico_id = null
            }

            try {
                const res = await fetch(`${url}/api/catalogos/medicos/${idEspecialidad}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMedicos=data.medicos.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMedicos.unshift({value:null,text:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },

        getMismaUbicacion() {
            if (this.mismaUbicacion == true) {
                this.responsable.domicilio = this.datos.domicilio
                this.responsable.cp = this.datos.cp
                this.responsable.colonia = this.datos.colonia
                this.responsable.ciudad = this.datos.ciudad
                this.responsable.estado_id = this.datos.estado_id
                this.getMunicipiosResp(this.datos.estado_id)
                this.disabled_select_mcpio_resp = false
                this.responsable.municipio_id = this.datos.municipio_id
            } else {
                this.responsable.domicilio = ""
                this.responsable.cp = ""
                this.responsable.colonia = ""
                this.responsable.ciudad = ""
                this.responsable.estado_id = null
                this.disabled_select_mcpio_resp = true
                this.responsable.municipio_id = null
            }
        },

        datosFacturacion() {
            if (this.dtaFacturacion == true) {
                this.form_dta_facturacion = true;
            } else {
                this.form_dta_facturacion = false;
            }
        },

        modalAddMedic() {
            this.openAddMedic = true;
        },

        regexNumber(number){
            let reg = new RegExp('^[0-9]*$');
            return reg.test(number);
        },

        firmar() {
            this.busy=true
            this.openFirmaPsw = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloNumerosExpediente(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;

            }
        },

        soloNumerosExpedienteUp(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (this.no_expediente_legacy.length==4 && charCode!=8 ) {
                this.no_expediente_legacy=this.no_expediente_legacy+"-"
            }
            if (this.no_expediente_legacy.length==5 && charCode!=8 && this.no_expediente_legacy.slice(4,5)!='-') {
                const x = this.no_expediente_legacy.substring(0,4)+"-"+this.no_expediente_legacy.substring(4,this.no_expediente_legacy.length)
                this.no_expediente_legacy = x
            }
            if (this.no_expediente_legacy.length==10 && charCode!=8 ) {
                this.no_expediente_legacy=this.no_expediente_legacy+"-"
            }
            if (this.no_expediente_legacy.length==11 && charCode!=8 && this.no_expediente_legacy.slice(10,11)!='-') {
                const x = this.no_expediente_legacy.substring(0,10)+"-"+this.no_expediente_legacy.substring(10,this.no_expediente_legacy.length)
                this.no_expediente_legacy = x
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ.\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        rgexDomicilio(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z0-9-ZÀ-ÿ \u00f1\u00d1]*$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        GB(){
            if (!this.expediente.grupo_vulnerable) {
                this.expediente.dificultar_hablar=false
                this.expediente.porta_equipo_audicion=false
                this.expediente.dificultad_ver=false
                this.expediente.usa_lentes=false
                this.expediente.dificultad_caminar=false 
                this.expediente.usa_apoyo=false;
                this.expediente.dificultad_oir=false
                this.expediente.porta_equipo_audicion=false
                this.expediente.dificultar_comprender_indicaciones=false
                this.expediente.tiene_condicion_psicologica=false
                this.expediente.bajo_tratamiento=false
                this.expediente.tratamiento_psicologico=""
            }
        },
        btnGuardarPaciente(){
            if (Vue.prototype.$reIngreso || Vue.prototype.$deAdmision) {
                this.updatePaciente()
            } else {
                this.registroPaciente()
            }
        },

        async registroPaciente() {
            this.error=false
            this.datos.estado_civil_id=this.selectedecivil
            this.busy=true
            if (this.generarNoExp) {
                this.datos.no_expediente=this.no_expediente_legacy
            }else{this.datos.no_expediente=""}
            try {
                const res = await fetch(`${url}/api/pacientes/store`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var id = ""
                var warn = false
                var err =[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        id = val.paciente.id 
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
               
                if (warn) {
                    this.busy=false
                    this.idPaciente=id
                    this.state='responsable'
                    var containerSave1 = this.$el.querySelector("#formResp");
                    containerSave1.scrollIntoView({inline: "start",behavior: "smooth"})
                }else{
                    this.erroresForm1=err
                    this.openError=true
                    this.error=true
                    this.busy=false
                }

                if (this.erroresForm1.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                    this.busy=false
                }

            } catch (error) {
                this.erroresForm1=err
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy=false
            }
        },
        toExpediente(){
            this.state='expediente'
            var containerSave1 = this.$el.querySelector("#continuar");
            containerSave1.scrollIntoView({inline: "start",behavior: "smooth"})
        },

        async registroFacturacion(id) {
            this.busy=true
            this.error=false
            this.dataFacturacion.responsable_id=id
            try {
                const res = await fetch(`${url}/api/facturacion`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataFacturacion),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err=[]
                await promise.then(function(val) {
                    // if (!val.class=="success") {
                    //     this.busy=false
                    // }
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.state='expediente'
                    var containerSave1 = this.$el.querySelector("#formExp");
                    containerSave1.scrollIntoView({inline: "start",behavior: "smooth"})
                }else{
                    this.btnGuardar = true;
                    this.btnFirmar = false;
                    this.busy=false
                    this.erroresFormFact=err
                    this.openError=true
                    this.error=true
                }

                if (this.erroresFormFact.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                    this.busy=false
                }

            } catch (error) {
                this.busy=false
            }
        },

        async registroResponsable(id) {
            this.error=false
            this.busy=true
            this.responsable.paciente_id=id
            try {
                const res = await fetch(`${url}/api/responsables_paciente/store`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.responsable),
                });
                const data = res.json();    
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err=[]
                var resp=[]
                await promise.then(function(val) {    
                    if (val.class=="success") {
                        resp=val.responsable.id
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.idResponsable=resp
                    this.state='factura'
                    var containerSave1 = this.$el.querySelector("#formFact");
                    containerSave1.scrollIntoView({inline: "start",behavior: "smooth"})
                }else{
                    this.btnGuardar = true;
                    this.btnFirmar = false;
                    this.busy=false
                    this.error=true
                    this.erroresForm2=err
                    this.openError=true
                }

                if (this.erroresForm2.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                    this.busy=false
                }
            } catch (error) {
                this.btnGuardar = true;
                this.btnFirmar = false;
                this.busy=false
            }
        },

        async registroExpediente(id) {
            this.busy=true
            this.error=false
            this.erroresForm3=[]
            this.erroresForm2=[]
            this.erroresForm1=[]
            this.expediente.paciente_id=id
            this.expediente.password=""
            this.expediente.notas=this.expediente.notas==''?'Sin notas a considerar':this.expediente.notas
            try {
                const res = await fetch(`${url}/api/datos_medicos/store`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.expediente),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err = []
                var ide = ""
                var withoutPassword = false
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                        ide = val.expediente.id
                    }else if(val.message=="This action is unauthorized."){
                        warn = false
                        withoutPassword = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    ide=id
                    this.resetForm();
                    this.openSuccess=true
                    this.setFechaHora()
                    try {
                        this.getExpediente(ide)
                    } catch (error) {
                        console.log("");
                    }
                }else if(withoutPassword){
                    this.expediente.password=this.password
                    this.registroExpediente(id)
                }else{
                    this.error=true
                    this.erroresForm3=err
                    this.busy=false
                    this.openError=true
                }

                if (this.erroresForm3.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                    this.busy=false
                }

            } catch (error) {
                this.busy=false
                this.btnGuardar = true;
                this.btnFirmar = false;
            }
        },
        
        async getExpediente(value) {
            try {
                const res = await fetch(`${link}${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                localStorage.removeItem("expediente_id")
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(data.expediente), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                router.push("/notas-medicas/"+value)
            } catch (error) {
                console.log(error);
            }  
        },

        change:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           
            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());

            this.month = (function() {
                if(today.getMonth() >= dob.getMonth()) {
                    if(today.getDate() >= dob.getDate()) {
                        return today.getMonth() - dob.getMonth();
                    }
                    else {
                        if((today.getMonth() - 1) >= dob.getMonth()) {
                            return (today.getMonth() - 1) - dob.getMonth();
                        }
                        else {
                            return ((today.getMonth() - 1) + 12) - dob.getMonth();
                        }
                    }
                }
                else {
                    if(today.getDate() >= dob.getDate()) {
                        return (today.getMonth() + 12) - dob.getMonth();
                    }
                    else {
                        return ((today.getMonth() - 1) + 12) - dob.getMonth();
                    }
                }
            }());

            if (this.datos.fecha_nacimiento!="") {
                this.expediente.edad = this.year
                this.datos.meses = this.month
            }else{
                this.expediente.edad=""
                this.datos.meses=""
            }
		},

        setFechaHora(){
            var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.expediente.hora_ingreso=""+hour+":"+minutes
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();

            this.expediente.fecha_ingreso = yyyy + '-' + mm + '-' + dd ;
            setTimeout(() => {
                this.setFechaHora()
            }, 1000);
        },

        // get Catalogos
        getCatalogos(){
            //  DATOS DE FACTURACION **************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/facturacion'))
            }).then((data)=>{
                try {
                    this.formaPago = data.forma_pago.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.formaPago.unshift({value:null, text:"seleccione una opción"})

                    this.metodoPago = data.metodo_pago.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.metodoPago.unshift({value:null, text:"seleccione una opción"})

                    this.usoCfdi = data.uso_cfdi.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.usoCfdi.unshift({value:null, text:"seleccione una opción"})
                } catch (error) {
                    console.log(error);
                }
            })

            //  ESTADOS ***************************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/estados'))
            }).then((data)=>{
                try {
                    this.dtaEstados=data.estados.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.dtaEstados.unshift({value:null, text:"seleccione una opción"})

                    this.dtaEstadosFacturacion = data.estados.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.dtaEstadosFacturacion.unshift({value:null, text:"seleccione una opción"})
                } catch (error) {
                    console.log(error);
                }
            });
            
            //  GENEROS ***************************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/genero'))
            }).then((data)=>{
                try {
                    this.genero=data.genero
                    this.genero.unshift({ id:null, nombre:"seleccione una opción" })
                } catch (error) {
                    console.log(error);
                }
            })

            //  TRATAMIENTOS **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/tratamientos'))
            }).then((data)=>{
                try {
                    this.dtaTratamiento=data.tratamientos
                    this.dtaTratamiento.unshift({ id:null,nombre:"seleccione una opción"})
                } catch (error) {
                    console.log(error);
                }
            })
            
            //  ESPECIALIDADES **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/especialidades'))
            }).then((data)=>{
                try {
                    this.dtaEspecialidades = data.especialidades
                    this.dtaEspecialidades.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })
            
            //  ESTADO CIVIL **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/estado_civil'))
            }).then((data)=>{
                try {
                    this.ecivil = data.estado_civil
                    this.ecivil.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })
            
            //  PRONOSTICOS **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/pronostico'))
            }).then((data)=>{
                try {
                    this.dtaPronostico = data.pronostico
                    this.dtaPronostico.unshift({ id:null, nombre:"seleccione una opción" })
                } catch (error) {
                    console.log(error);
                }
            })
            
            //  ESCOLARIDADES **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/escolaridades'))
            }).then((data)=>{
                try {
                    this.escolaridades=data.escolaridades
                    this.escolaridades.unshift({ id:null, nombre:"seleccione una opción" })
                } catch (error) {
                    console.log(error);
                }
            })
            
            //  PACIENTES **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/pacientes'))
            }).then((data)=>{
                try {
                    this.pacientes=data.pacientes
                    this.donePacientes=true
                } catch (error) {
                    console.log(error);
                }
            })

            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/habitacion'))
            }).then((data)=>{
                function comparar(a, b) {
                    return a.id - b.id;
                }
                this.abit=data.habitaciones
                this.abit.unshift({ id:"", name:"seleccione una opción" })
                console.log(this.abit.sort(comparar));
            })
            // catalogos/habitaciones
        },
        
        async setDataPAciente(id,deAdmi){
            this.idPacienteSeleccionado=id
            try {
                const res = await fetch(`${url}/api/hoja_admision/admision/${id}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                if (data.data.paciente) {
                    this.datos=data.data.paciente
                    if (this.datos.email==null) {
                        this.datos.email=""
                    }
                    try {
                        this.datos.telefono=data.data.paciente.telefono
                    } catch (error) {
                        this.datos.telefono=""
                    }
                    this.change(this.datos.fecha_nacimiento)
                    this.getMunicipiosPaciente(this.datos.estado.id)
                    this.selectedecivil=this.datos.estado_civil_id
                    this.datos.estado_id=data.data.paciente.estado.id
                    this.datos.municipio_id=data.data.paciente.municipio.id
                    this.datos.genero_id=data.data.paciente.genero.id
                    this.idPaciente = data.data.paciente.id
                    this.lastExp=data.data.paciente.no_expediente
                    this.selectedecivil=data.data.paciente.estado_civil.id

                    if (data.data.facturacion){ 
                        this.dataFacturacion=data.data.facturacion
                        this.dtaFacturacion=true
                        this.form_dta_facturacion=true
                        this.dataFacturacion.domicilio=this.dataFacturacion.domicilio+", Col."+this.dataFacturacion.colonia+", "+this.dataFacturacion.ciudad
                    }

                    this.expediente.especialidad_id=data.data.especialidad.id
                    this.obtenerMedicosPerEspecialidad(data.data.especialidad.id)
                    this.expediente.diagnostico=data.data.diagnostico
                    this.expediente.medico_id=data.data.medico_id.id
                    this.responsable.nombre=data.data.responsable_paciente
                    this.responsable.parentezco=data.data.parentesco_paciente
                    this.responsable.telefono=data.data.telefono_responsable
                    this.expediente.religion=data.data.paciente.religion
                    this.deAdmision=true

                    if (deAdmi) {
                        this.getDataPacienteAlt(id,deAdmi)
                    }else{
                        this.loading=false
                    }

                }
            } catch (error) {
                console.log(error);
            }
        },
        async updatePaciente(){
            if (this.$route.params.id) {
                this.idPacienteSeleccionado=this.$route.params.id
            }
            if (this.generarNoExp) {
                this.datos.no_expediente=this.no_expediente_legacy
            }else{
                this.datos.no_expediente=""
            }
            this.busy=true 
            this.datos.estado_civil_id=this.selectedecivil
            try {
                const res = await fetch(`${url}/api/pacientes/update/${this.idPacienteSeleccionado}`, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err =[]
                let permisos = true
                await promise.then(function(val) {
                    if (val.message.includes("Sin permiso")) {
                        permisos=false
                    }else{
                        permisos=true
                    }
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
               
                if (warn) {
                    this.busy=false
                    this.idPaciente=this.idPacienteSeleccionado
                    this.state='responsable'
                    var containerSave1 = this.$el.querySelector("#formResp");
                    containerSave1.scrollIntoView({inline: "start",behavior: "smooth"})
                }else{
                    this.erroresForm1=err
                    this.openError=true
                    this.error=true
                    this.busy=false
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                    if (err && Object.keys(err).length>0) {
                        if (!permisos) {
                            console.log(permisos);
                        }
                    }
                }

                if (this.erroresForm1.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                    this.busy=false
                }

            } catch (error) {
                this.busy=false
            }
        },

        async getDataPacienteAlt(id,deadm) {
            try {
                const res = await fetch(`${url}/api/pacientes/show/`+id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();

                if (data.paciente) {
                    if (!deadm) {
                        this.datos=data.paciente
                        this.change(this.datos.fecha_nacimiento)
                        this.selectedecivil=data.paciente.estado_civil.id
                        this.datos.genero_id=data.paciente.genero.id
                        this.datos.estado_id=data.paciente.estado.id
                        this.getMunicipiosPaciente(this.datos.estado_id)
                        this.datos.municipio_id=data.paciente.municipio.id
                        this.lastExp=data.paciente.no_expediente
                        if (data.paciente.no_expediente_legacy) {
                            this.generarNoExp=true
                            this.no_expediente_legacy=data.paciente.no_expediente_legacy
                        }else{
                            this.generarNoExp=false
                            this.no_expediente_legacy=""
                        }

                        if (data.paciente.lastExpediente.expediente_id) {
                            this.existeExpediente=true
                            this.verifyNotaEgresoFirmada(data.paciente.lastExpediente.expediente_id)
                        }else{
                            this.existeExpediente=false
                            this.state='paciente'
                        }

                    }else{

                        this.datos.email=data.paciente.email
                        if (data.paciente.lastExpediente.expediente_id) {
                            this.existeExpediente=true
                            this.verifyNotaEgresoFirmada(data.paciente.lastExpediente.expediente_id)
                        }else{
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                            this.existeExpediente=false
                            this.state='paciente'
                        }

                    }
                    this.loading=false
                }else{
                    Object.assign(this.$data, this.$options.data());
                    this.cancel()
                    router.push("/registro").catch(()=>{});
                }
            } catch (error) {
                Object.assign(this.$data, this.$options.data());
                this.cancel()
                router.push("/registro").catch(()=>{});
            }
        },
        async verifyNotaEgresoFirmada(id) {
            try {
                const res = await fetch(`${url}/api/notas/egreso/`+id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                var containerSave1 = this.$el.querySelector("#formResp");

                if (data.egreso.firma.is_firmado==1) {
                    containerSave1.scrollIntoView({inline: "start",behavior: "smooth"})
                    this.loading=false
                }else{
                    Object.assign(this.$data, this.$options.data());
                    this.cancel()
                    router.push("/registro").catch(()=>{});
                }
            } catch (error) {
                Object.assign(this.$data, this.$options.data());
                this.cancel()
                router.push("/registro").catch(()=>{});
            }
        },

        cancel(){
            this.getCatalogos()
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            console.log('cerrarModalError');
            this.openError=false
        },
        closeModalAddMedic(){
            this.openAddMedic = false
        },
        cerrarPacienteExistente(){
            this.openMdlNewExp = false
        },
        getMedicPerEsp(idEsp){
            console.log('getMedicPerEsp');
            this.obtenerMedicosPerEspecialidad(idEsp)
            this.expediente.especialidad_id = idEsp
            this.openAddMedic = false
            this.openSuccess = true
        },
        firma_psw(psw){
            this.datos.password = psw
            this.responsable.password = psw
            this.expediente.password = psw
            this.password=psw
            this.btnGuardar = true;
            this.btnFirmar = false;
            this.openFirmaPsw=false
            this.busy=false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
            this.busy=false
        },
        savedAdmisionBol(){
            this.deAdmision=true
            this.openSuccess=true
            this.loading=true
            if (this.$route.params.id) {
                Vue.prototype.$reIngreso = true
                this.state='responsable'
                this.setDataPAciente(this.$route.params.id,true)
            }else{
                Vue.prototype.$reIngreso = false
                this.setDataPAciente(Vue.prototype.$idPacienteAdmision,true)
                this.state='paciente'
            }
        }

    },
    beforeCreate(){
        if (this.$route.params.id && isNaN(this.$route.params.id)) {
            router.push("/registro").catch(()=>{});
        }
    },

    mounted(){
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if (!Vue.prototype.$deAdmision) {
            if (this.$route.params.id) {
                this.idPaciente = this.$route.params.id
                this.loading=true
                Vue.prototype.$reIngreso = true
                this.state='responsable'
                this.getDataPacienteAlt(this.$route.params.id,false)
            }else{
                this.loading=false
                Vue.prototype.$reIngreso = false
                this.state='paciente'
            }
        }


        this.getCatalogos()
        this.setFechaHora()

    },
    activated(){
    },
    destroyed() {  
        this.$destroy()
    }
}
</script>

<style scoped>
    @media only screen and (max-width: 900px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (min-width: 900px) {
        .size_th {
            width: 15vw;
        }
    }

    @media only screen and (max-width: 1000px) {
        .size_th_grupoVulnerable {
            width: 100vw;
        }
    }

    @media only screen and (min-width: 1000px) {
        .size_th_grupoVulnerable {
            width: 32vw;
        }
    }

    .btn_foto {
        width: 5rem;
        height: 5rem;
        border-radius: 50px;
        background-color: white;
        color: #4c87a0;
        border: 1px solid #4c87a0;
        text-align: center;
        font-size: 1.5rem;  
    }

    .pd-add-medic {
        padding-left:7px; 
        padding-right:7px;
    }

    .btn_add_user {
        border: 1px solid #6096ad;
    }

    .text-value-capitalize::placeholder {
        text-transform: none;
    }

    .text-value-capitalize {
        text-transform: capitalize;
    }
    .autoExp {
        color: gray;
    }
</style>