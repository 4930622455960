<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Nota de egreso</h2>      
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>
        <b-container class="py-4">
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top px-3">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(datos.firma.is_firmado==1)">
                        <i class="fa fa-edit fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(datos.firma.is_firmado==0)">
                        <i class="fa fa-edit fa-lg my-auto pr-2" />
                        Guardado correctamente, es requerido firmar la nota
                    </div>
                </b-alert>
            </div>
            <Datos :changeData="changeDta"/>
            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Información del paciente</strong></p> -->
            <!-- <b-row >
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row> -->
            <b-form @submit.stop.prevent>
                <b-row>
                <b-col cols="12">
                    <!-- <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th th_style">Nombre</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Fecha de nacimiento</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Genero</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.paciente_edad_years">
                                <b-th class="th_style">Edad</b-th>
                                <b-td class="border_table">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_years}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">años</span>
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_months}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">meses</span>
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.id">
                                <b-th class="th_style">Idioma</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.idioma}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.id">
                                <b-th class="th_style">Religion</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.religion}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Estado civil</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Código postal</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Domicilio</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Colonia/Comunidad</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Ciudad</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Estado</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Municipio</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Teléfono</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Email</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->

                    <!-- <b-table-simple class="table-sm" v-if="datosExpediente.id">
                        <b-tbody>
                            <b-tr v-if="datosExpediente.tiene_alergias===1">
                                <b-th class="th_style">Alergias</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.alergias}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th">Especialidad (Área de procedimiento)</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.especialidad.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Medico tratante</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.medico.nombre}}</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->

                    <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="size_th th_style">Fecha / Hora de ingreso</b-th>
                                <b-td class="border_table">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="4" lg="4">
                                            <b-form-input type="date" v-model="ingreso.fecha" class="border-0 input_disabled_transparent" disabled size="sm" />
                                        </b-col>
                                        <b-col cols="12" sm="12" md="4" lg="4">
                                            <b-form-input type="time" v-model="ingreso.hora" min="00:00:01" max="23:59:59" class="border-0 input_disabled_transparent" disabled size="sm" />
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Fecha de egreso</b-th>
                                <b-td class="border_table">
                                    <b-form-input disabled type="date" name="fecha" autocomplete="off" v-model="datos.fecha" :state="fecha" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hora de egreso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input disabled type="time" name="hora" autocomplete="off" v-on:keypress="soloHoras($event)" v-model="datos.hora" :state="hour" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">horas</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <!-- <b-tr>
                                <b-th class="th_style">Habitación</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="true" name="habitacion" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="cuarto" :state="habitacion" class="border-0" size="sm" placeholder="# de habitación" />
                                </b-td>
                            </b-tr> -->
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th th_style">Motivo de egreso</b-th>
                                <b-td colspan="3" class="border_table">
                                    <b-form-select :disabled="disableInput || state.oldExp" v-model="datos.motivo_egreso_id" :state="motivoEgreso" :options="motivos_egresos" value-field="id" text-field="nombre" @change="cambioMotivo(datos.motivo_egreso_id)" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row v-if="showDefuncion">
                <b-col cols="12">
                    <p class="txt_secondary">Causa de muerte</p>
                    <b-form-textarea :disabled="disableInput || state.oldExp" name="causaMuerte" autocomplete="off" :state="VCausaMuerte" v-model="datos.causas_muerte" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style size_th">Pronóstico</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="disableInput || state.oldExp" v-model="datos.pronostico_id" :state="VPronostico" :options="pronostico" value-field="id" text-field="nombre" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <p class="txt_secondary text-uppercase pt-3"><strong>Signos vitales</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="disableInput || state.oldExp" v-model="datos.frecuencia_cardiaca" :state="frecuenciaCardiaca" v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="FC" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="disableInput || state.oldExp" v-model="datos.temperatura" :state="temperatura" v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="temperatura" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th_frecuencias">Tensión arterial</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <div>
                                            <b-form-input :disabled="disableInput || state.oldExp" type="text" name="pa1" autocomplete="off" maxlength="3" v-on:keypress="soloNumeros($event)" v-model="presion1" class="text-center border-0" size="sm" placeholder="#"/>
                                        </div>
                                        <div class="txt_gray px-1">/</div>
                                        <div>
                                            <b-form-input :disabled="disableInput || state.oldExp" type="text" name="pa2" autocomplete="off" maxlength="3" v-on:keypress="soloNumeros($event)" v-model="presion2" :state="VPresionArterialDos" @keyup="tensionArterial()" class="text-center border-0" size="sm" placeholder="#"/>
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="disableInput || state.oldExp" v-model="datos.frecuencia_respiratoria" :state="frecRespiratoria" v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="FR" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr> 
                            <b-tr>
                                <b-th class="th_style">SPO2</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="disableInput || state.oldExp" v-model="datos.spo" :state="spo2" v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="SPO2" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">%</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <p class="txt_secondary mt-2">Diagnósticos finales</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="diagnosticoFinal" autocomplete="off" v-model="datos.diagnosticos_finales" :state="VDiagnosticoFinal" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Resumen de la evolución y estado actual</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="resumenEvo" autocomplete="off" v-model="datos.evolucion_estado_actual" :state="VEvolucionAct" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Manejo durante la estancia hospitalaria</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="manejoEstancia" autocomplete="off" v-model="datos.manejo_estancia_hospitalaria" :state="VManejoEstanciaHos" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Estudios clínicos pendientes</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="problemasClinicos" autocomplete="off" v-model="datos.problemas_clinicos_pendientes" :state="VProblemasClinicos" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Plan de manejo y tratamiento</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="planManejo" autocomplete="off" v-model="datos.plan_manejo_tratamiento" :state="VPlanManejo" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Recomendaciones para vigilancia ambulatoria</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="recomendaciones" autocomplete="off" v-model="datos.recomendaciones_vigilancia_ambulatoria" :state="VRecomendaciones" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Atención a factores de riesgo</p>
            <b-form-textarea :disabled="disableInput || state.oldExp" name="atencionFactRiesgo" autocomplete="off" v-model="datos.atencion_factores_riesgos" :state="VAtencionFacRiesgo" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <div v-if="datos.firma.is_firmado==1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Medico: {{datos.firma.firmado_por.nombre}}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha: {{datos.firma.fecha_firmado}}</span>
            </div>

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err.expediente_id" />| {{err[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="!dtaExist && !state.oldExp" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                     <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button :disabled="busy" class="btn_formluario border-0 btn-block py-2 txt_white" @click="egreso()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="actualizar && !state.oldExp" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <!-- <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->
            <div v-if="datos.firma.is_firmado==0 && !actualizar && !state.oldExp && idResponsable===usuario.id" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                     <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="cancelar && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
            </b-form>
        </b-container>
        <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openSuccess && !openError && !openFirmaPsw && !openPreSave && !openAlertPresion && !regNewExp && !pswUpdate && !loading)">
            <ul class="sticky">
                <li v-if="!dtaExist && !state.oldExp" @click="preGuardar()">
                    <img :src="require('../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="dtaExist && updating && !state.oldExp && datos.firma.is_firmado==0" @click="editar()">
                    <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating)" @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>
        
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <AltertPresionArterial :abrir="openAlertPresion"  @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()"/>
        <NuevoExpediente :openRegExp="regNewExp"/>
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading"/>

    </b-container>  
</template>

<script>
import Vue from 'vue'
import Loading from '@/components/Loader/Loading.vue'
import FirmaPassword from '@/components/Modals/FirmaPassword.vue'
import Foto from '@/components/Paciente/Foto.vue'
import Success from '@/components/Modals/Success.vue'
import Error from '@/components/Modals/Error.vue'
import DatosPreguardados from '@/components/Modals/DatosPreguardados.vue'
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue'
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue'
import CryptoJS from 'crypto-js'

import { url } from "../../../settings"
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import { getCatalogo } from '../../helpers/catalogos'
import router from '../../router';
import Datos from '@/components/Paciente/Visualizar/Datos.vue'
import PDFMerger from 'pdf-merger-js'
// var merger 

export default {

    name: "NotaDeEgreso",
    components: { Datos, Foto, Success, Error, FirmaPassword, DatosPreguardados, AltertPresionArterial, NuevoExpediente, FirmaPswUpdate, Loading },

    data:()=>({
        changeDta:null,
        isEgresado:false,
        usuario:{
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable:null,
        //option buttos: save, update, print 
        stateButtons:"save",
        arrayUrl: [],

        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },
        allExp:[],

        regNewExp: false,
        
        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,

        loading:true,

        presion2:"",
        presion1:"",

        datosPreGuardados:[],
        showDta: false,
        openPreSave: false,
        info:false,
        disabled:true,
        error:false,
        errores:"",
        busy: false,
        openSuccess:false,
        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,
        openError:false,
        cuarto:"",
        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },
        datos: {
            expediente_id: null,
            password: '',
            fecha: "",
            hora: "",
            motivo_egreso_id: null,
            habitacion: "",
            nombre: '',
            dtaEjemplo: '',
            diagnosticos_finales: "",
            evolucion_estado_actual: "",
            manejo_estancia_hospitalaria: "",
            problemas_clinicos_pendientes: "",
            plan_manejo_tratamiento: "",
            recomendaciones_vigilancia_ambulatoria: "",
            atencion_factores_riesgos: "",
            pronostico_id: null,
            causas_muerte: '',
            frecuencia_cardiaca: '',
            frecuencia_respiratoria: '',
            tension_arterial: '',
            temperatura: '',
            spo: '',
            firma:{
                fecha_firmado:null,
                firmado_por:{
                    id:null,
                    nombre:"",
                },
                is_firmado:0
            },
        },

        showDefuncion:false,

        pronostico: [],
        motivos_egresos: [],
        pacientes: [{}],
        nombres: [],
        idPacienteSeleccionado: "",
        datosExpediente:{},
        openAlertPresion: false,
        ingreso:{
            fecha:"",
            hora:"",
        },
        no_expediente:"",
        pdfDone:{
            pdf1:false,
            pdf2:false,
            pdf3:false,
            pdf4:false,
            pdf5:false,
            pdf6:false,
            pdf7:false,
            pdf8:false,
            pdf9:false,
            pdf10:false,
            pdf11:false,            
        },
    }),

    computed: {
        VPresionArterialDos() {
            return this.presion2 != "" && this.presion1!="" 
        },

        hour() {
            return this.datos.hora.length != 0
        },
        
        motivoEgreso() {
            return this.datos.motivo_egreso_id != null
        },

        fecha() {
            return this.datos.fecha != 0
        },

        validation() {
            return this.idPacienteSeleccionado.length != 0
        },

        habitacion() {
            return this.cuarto.length != 0
        },

        VCausaMuerte() {
            return this.datos.causas_muerte != ""
        },

        VPronostico() {
            return this.datos.pronostico_id != null
        },

        frecuenciaCardiaca() {
            return this.datos.frecuencia_cardiaca != ''
        },

        temperatura() {
            return this.datos.temperatura != ''
        },

        frecRespiratoria() {
            return this.datos.frecuencia_respiratoria != ''
        },

        spo2() {
            return this.datos.spo != ''
        },

        VDiagnosticoFinal() {
            return this.datos.diagnosticos_finales != "" && this.datos.diagnosticos_finales.length <= 250
        },

        VEvolucionAct() {
            return this.datos.evolucion_estado_actual != "" && this.datos.evolucion_estado_actual.length <= 1000
        },

        VManejoEstanciaHos() {
            return this.datos.manejo_estancia_hospitalaria != "" && this.datos.manejo_estancia_hospitalaria.length <= 250
        },

        VProblemasClinicos() {
            return this.datos.problemas_clinicos_pendientes != "" && this.datos.problemas_clinicos_pendientes.length <= 250
        },

        VPlanManejo() {
            return this.datos.plan_manejo_tratamiento != "" && this.datos.plan_manejo_tratamiento.length <= 250
        },

        VRecomendaciones() {
            return this.datos.recomendaciones_vigilancia_ambulatoria != "" && this.datos.recomendaciones_vigilancia_ambulatoria.length <= 250
        },

        VAtencionFacRiesgo() {
            return this.datos.atencion_factores_riesgos != "" && this.datos.atencion_factores_riesgos.length <= 250
        }
    },

    methods:{
        
        changeExp(id){
            this.clearData()
            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)

            if (currentExp) {
                this.sinExp=false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta=currentExp.id
                this.setData(true)
            }
        },
        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        cancelEdit() {
            console.log("Entre al cancelar");
            this.obtenerNotaDeEgreso(this.datos.expediente_id)
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            // this.datos.password = psw
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.disableInput = false
            this.updating = false
            // this.pswUpdate = true
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.busy=true
            this.error=false
            this.errores=[]
            this.busyUpdate = true

            this.datos.tension_arterial=this.presion1+"/"+this.presion2
            
            try {
                const res = await fetch(`${url}/api/notas/egreso/update/${this.datos.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.obtenerNotaDeEgreso(this.datos.expediente_id)
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.busy=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.error=true
                    this.errores=err
                    this.busyUpdate = false
                    if (this.errores.password) {
                        this.btnFirmaUpdate = true
                        this.disableInput = false
                        this.actualizar = false
                        this.busyUpdate = false
                        this.busy=false
                    } else {
                        this.busy=false
                        this.busyUpdate=false
                        this.actualizar = true
                        this.cancelar = true
                    }
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },
        
        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true

            let urlim = `${url}/api/notas/egreso/descargar/${this.datos.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)
            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente+ " Nota de egreso");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
            title: "Datos preguardados",
            variant: variant,
            solid: true
            })
        },
      
        preGuardar() {
            var existe=false;
            this.datos.tension_arterial = this.presion1+"/"+this.presion2
            try {
                if (localStorage.preGuardadoNEgreso) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoNEgreso)
                }
            } catch (error) {
                console.log(error);
            }
            this.datos.password=""
             var i =0;
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id===val.expediente_id) {
                    this.datosPreGuardados[i]=this.datos
                    existe=true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push(this.datos);
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNEgreso', dta);
            this.makeToast('success');
        },

        verificarPreGuardado(){
            try {
                if (localStorage.preGuardadoNEgreso) {
                    console.log("si hay datos");
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoNEgreso)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id===val.expediente_id) {
                    console.log("ya esta we");
                    if (this.showDta === true) {
                        this.datos=val
                        var ta = this.datos.tension_arterial
                        var ta1 = ta.indexOf("/")
                        this.presion1=ta.substring(0,ta1)
                        this.presion2 = ta.substring(ta1+1,ta.length)
                    } else {
                        this.openPreSave = true
                    }
                }
            }
        },

        tensionArterial() {
            if (this.presion2.length >= 2 && this.presion1.length >= 2) {
                setTimeout(() => {
                if (this.presion1 < 100 || this.presion1 > 140 || this.presion2 < 60 || this.presion2 > 90) {
                        this.openAlertPresion = true
                }
                }, 500);
            }
        },

        setData(){            
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.changeDta=paciente.id
            this.idResponsable=paciente.medico.id
            console.log(this.idResponsable);


            this.no_expediente=paciente.no_expediente

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                this.state.oldExp=true
            }else{
                this.state.oldExp=false
            }

            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.cuarto=paciente.no_habitacion
            this.idPacienteSeleccionado=paciente.paciente.id
            this.datos.expediente_id=paciente.id;
            this.datosPaciente=paciente.paciente
            this.datosExpediente=paciente
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.obtenerNotaDeEgreso(paciente.id)
            this.getNotaDeIngreso(paciente.id)
            this.allExpedientes()

        },

        firmar() {
            this.busy=true
            this.openFirmaPsw = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        clearData() {
            this.showDefuncion=false
            this.btnFirmar=true
            this.btnGuardar=false
            this.showDta=false
            this.datos.habitacion= ""
            this.datos.nombre= ''
            this.datos.diagnosticos_finales= ""
            this.datos.evolucion_estado_actual= ""
            this.datos.manejo_estancia_hospitalaria= ""
            this.datos.problemas_clinicos_pendientes= ""
            this.datos.plan_manejo_tratamiento= ""
            this.datos.recomendaciones_vigilancia_ambulatoria= ""
            this.datos.atencion_factores_riesgos= ""
            this.datos.pronostico_id=null
            this.datos.motivo_egreso_id = null
            this.datos.causas_muerte= ''
            this.datos.frecuencia_cardiaca= ''
            this.datos.frecuencia_respiratoria= ''
            this.datos.tension_arterial= ''
            this.datos.temperatura= ''
            this.datos.spo= ''
            this.presion1=""
            this.presion2=""
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${url}/api/pacientes`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.pacientes=data.pacientes
                this.pacientes.unshift({id:"", nombre:"Seleccionar paciente"})
            } catch (error) {
                console.log(error);
            }
        },

        cambioMotivo(id){
            if (id===2) {
                this.showDefuncion=true
            }
            else{
                this.showDefuncion=false
            }
        },

        async egreso() {
            this.busy=true
            this.error=false
            this.errores=[]
            this.datos.tension_arterial=this.presion1+"/"+this.presion2
            try {
                const res = await fetch(`${url}/api/notas/egreso/store`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err=[];
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });

                if (warn) {

                    var egresoLS = CryptoJS.AES.encrypt(JSON.stringify({egreso:true}), 'secret key heal4701tech')
                    localStorage.setItem('egreso', egresoLS);
                    this.isEgresado=true

                    this.openSuccess=true
                    this.busy=false
                    this.obtenerPacientes();
                    var date = new Date;
                    var minutes = ("0"+date.getMinutes()).slice(-2);
                    var hour = ("0"+date.getHours()).slice(-2);
                    this.datos.hora=""+hour+":"+minutes+":00"
                    var today = new Date();
                    var dd = ("0" + (today.getDate())).slice(-2);
                    var mm = ("0" + (today.getMonth() +1)).slice(-2);
                    var yyyy = today.getFullYear();
                    this.datos.fecha = yyyy + '-' + mm + '-' + dd ;
                    this.errores=""
                    this.error=false
                    this.setData()
                }else{
                    this.errores=err
                    this.openError=true
                    this.busy=false
                    this.error=true
                }

                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }
            } catch (error) {
                console.log(error);
            }
       },

       async obtenerNotaDeEgreso(value) {
            this.clearData()
            try {
                const res = await fetch(`${url}/api/notas/egreso/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.status == false) {
                    this.disableInput = false
                    this.btnFirmar = true
                } else {
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnFirmar = false
                    this.btnGuardar = false
                }

                if (data.egreso) {
                    // this.getNotaDeIngreso(this.datos.expediente_id)
                    this.datos = data.egreso;
                    this.datos.motivo_egreso_id = data.egreso.motivo.id;
                    this.datos.pronostico_id = data.egreso.pronostico_id;
                    if (this.datos.motivo_egreso_id===2) {
                        this.showDefuncion=true
                    }
                    var ta = this.datos.tension_arterial
                    var ta1 = ta.indexOf("/")
                    this.presion1=ta.substring(0,ta1)
                    this.presion2 = ta.substring(ta1+1,ta.length)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.loading=false
                }else{
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.loading=false
                    this.getFecha()
                    this.verificarPreGuardado()
                }
           } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log(error);
           }
       },

       getFecha(){
            var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.datos.hora=""+hour+":"+minutes+":00"
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha = yyyy + '-' + mm + '-' + dd ;
       },

        async getNotaDeIngreso(id) {
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/nota_ingreso/${id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log({Ingreso:data});
                this.ingreso.fecha=data.nota_ingreso.fecha_ingreso
                this.ingreso.hora=data.nota_ingreso.hora_ingreso
                
            } catch (error) {
                console.log("");
            }
        },

        getCatalogos() {
            //  MOTIVO DE EGRESO **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/motivos_egresos'))
            }).then((data)=>{
                try {
                    this.motivos_egresos = data.motivos_egresos
                    this.motivos_egresos.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })

            //  PRONÓSTICO **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/pronostico'))
            }).then((data)=>{
                try {
                    this.pronostico = data.pronostico
                    this.pronostico.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })

            new Promise((resolve)=>{
                resolve(getCatalogo('/api/usuarios/logged'))
            }).then((data)=>{
                console.log("medico log");
                console.log(data);
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            })
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        cerrarModalAlertPresionArterial(){

            this.openAlertPresion = false
        },
        firma_psw(psw){
            this.datos.password = psw
            if (!this.dtaExist) {
                this.egreso()
            }else{
                this.updateData();
            }
            this.openFirmaPsw=false
        },

        showDtaPreSave(){
                this.showDta = true
                this.verificarPreGuardado()
                this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
            this.busy=false
        }
    },
    
    mounted(){
    },
    destroyed() {  
        this.$destroy();
        // this.$el.parentNode.removeChild(this.$el);
    },
    activated(){

        Object.assign(this.$data, this.$options.data());

        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }

        this.getCatalogos()
        this.obtenerPacientes()
        this.loading=true
        this.setData()
        // setTimeout(() => {
        //     window.scrollTo({ top: 0, behavior: 'smooth' })
        //     this.loading=false
        // }, 1500);
    }
}
</script>

<style scoped>
   @media only screen and (min-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
    }

</style>