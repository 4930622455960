<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="title_forms txt_primary text-uppercase pl-3 d-block d-sm-block d-lg-none d-md-none">Nota de
                    evolucion y ordenes médicas</h2>
                <h2 class="title_forms txt_primary text-uppercase pl-3 d-none d-lg-block d-md-block d-sm-none">Nota de
                    evolucion</h2>
                <h4 class="txt_primary pl-3 d-none d-lg-block d-md-block d-sm-none">y ordenes médicas</h4>
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto />
                </div>
            </b-col>
        </b-row>


        <b-container>
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th_info">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="updating" v-model="state.idExpediente"
                                @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id"
                                        :value="all.id">{{ all.no_expediente }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong
                        class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <Datos :changeData="changeDta" />


            <b-row v-if="!state.oldExp" class="justify-content-between mt-4">
                <b-col cols="4">
                    <p v-if="headerHistorial" class="txt_secondary text-uppercase py-2"><strong>Añadir
                            actualización</strong></p>
                    <p v-if="updating" id="titleUpd" class="txt_secondary text-uppercase py-2"><strong>Actualizar
                            historial</strong></p>
                </b-col>
                <b-col v-if="headerHistorial" cols="4">
                    <div class="float-right">
                        <button class="btn_redondo border-0 bg_secondary txt_white" @click="nuevo = !nuevo">
                            <i class="fa fa-plus fa-lg mx-auto my-auto" />
                        </button>
                    </div>
                </b-col>
            </b-row>

            <div class="mt-3" v-if="nuevo">
                <b-table-simple class="table-sm">
                    <b-tbody>
                        <b-tr>
                            <b-th class="th_style size_th_info">Médico</b-th>
                            <b-td class="border_table">
                                <b-form-select v-model="medico_id" value-field="id" text-field="nombre" :options="medicos"
                                    class="border-0" size="sm" placeholder="Seleccionar" />
                            </b-td>
                            <b-td class="border_table justify-content-center text-center my-auto">
                                <button class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                    title="Añadir médico" @click="modalAddMedic()">
                                    <i class="fa fa-plus fa-md" />
                                </button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <hr>
            <b-table-simple class="table-responsive table-sm mt-3" v-if="nuevo">
                <b-thead>
                    <b-tr>
                        <b-th class="th_style size_th">Actualización</b-th>
                        <b-th class="th_style size_th">T.A.</b-th>
                        <b-th class="th_style size_th">F.C.</b-th>
                        <b-th class="th_style size_th">F.R.</b-th>
                        <b-th class="th_style size_th">T.</b-th>
                        <b-th class="th_style size_th">SPO2</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td rowspan="8" class="border_table vertical_align">
                            <b-form-input disabled name="fecha" autocomplete="off" v-model="horaFecha"
                                class="border-0 text-center" size="sm" placeholder="DD/MM/AAAA HORA" />
                        </b-td>
                        <b-td class="border_table">
                            <div class="d-flex">
                                <div>
                                    <b-form-input type="text" name="ta1" autocomplete="off"
                                        v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension1"
                                        class="text-center border-0" size="sm" placeholder="#" />
                                </div>
                                <div class="txt_gray px-1">/</div>
                                <div>
                                    <b-form-input type="text" name="ta2" autocomplete="off" :state="tension_arterial"
                                        v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension2"
                                        @keyup="tensionArterial()" class="text-center border-0" size="sm" placeholder="#" />
                                </div>
                            </div>
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input type="text" name="fc" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                :state="frecuencia_cardiaca" maxlength="6" v-model="datos.frecuencia_cardiaca"
                                class="border-0" size="sm" />
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input type="text" name="fr" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                :state="frecuencia_respiratoria" maxlength="6" v-model="datos.frecuencia_respiratoria"
                                class="border-0" size="sm" />
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input type="text" name="temperatura" autocomplete="off"
                                v-on:keypress="soloNumeros($event)" :state="temperatura" maxlength="6"
                                v-model="datos.temperatura" class="border-0" size="sm" />
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input type="text" name="saturacionOxigeno" autocomplete="off"
                                v-on:keypress="soloNumeros($event)" :state="saturacion_oxigeno" maxlength="6"
                                v-model="datos.saturacion_oxigeno" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th colspan="5" class="th_style">
                            Resumen y actualización del cuadro clínico
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="5" class="border_table">
                            <b-form-textarea name="resumenAct" autocomplete="off" :state="resumen" v-model="datos.resumen"
                                value="tablas.resumen" class="border-0" size="sm" no-resize rows="3" max-rows="3" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th colspan="5" class="th_style">
                            Estudios de laboratorio y gabinete
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="5" class="border_table">
                            <b-form-textarea name="estudiosLab" autocomplete="off" :state="estudios_lab_gabinete"
                                v-model="datos.estudios_lab_gabinete" value="tablas[1].estudios_lab_gabinete"
                                class="border-0" size="sm" no-resize rows="3" max-rows="3" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th colspan="5" class="th_style">
                            Tratamiento e indicaciones
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="5" class="border_table">
                            <b-form-textarea name="tratamiento" autocomplete="off" :state="tratamiento_indicaciones"
                                v-model="datos.tratamiento_indicaciones" value="tablas.tratamiento_indicaciones"
                                class="border-0" size="sm" no-resize rows="3" max-rows="3" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <!-- <b-tr>
                        <b-th class="th_style">Médico tratante</b-th>
                        <b-td class="border_table">
                            <b-form-select :state="VmedicoId" :disabled="disabled_select_medico" v-model="expediente.medico_id" :options="dtaMedicos" class="border-0" size="sm" />
                        </b-td>
                        <b-td class="border_table justify-content-center text-center my-auto">
                            <button :disabled="disabled_select_medico" class="border-0 bg_secondary txt_white rounded-circle pd-add-medic" title="Añadir médico" @click="modalAddMedic()">
                                <i class="fa fa-plus fa-md" />
                            </button>
                        </b-td>
                    </b-tr> -->

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err, index) of errores" :key="index"><i class="fa fa-warning px-1"
                                    v-if="!err.expediente_id" />| {{ err[0] }} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <!-- // aqui -->

            <div v-if="(!actualizar && nuevo && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy"
                            @click="getNumber">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="(actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busyUpdate" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()"
                            :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <!-- <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->
            <!-- <div v-if="btnFirmar && !state.oldExp" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmar()" v-if="nuevo" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->
            <div v-if="(cancelar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>

            <b-table-simple class="table-responsive table-sm mt-3" :items="tabla" v-if="m">
                <p id="titleHist" class="txt_secondary text-uppercase pt-3" v-if="m"><strong>Historial de
                        actualizaciones</strong></p>
                <b-card no-body class="mb-1 border-0" v-for="user in tabla" :key="user.id">
                    <b-card-header header-tag="header" class="p-1 bg_header" role="tab">
                        <div class="d-flex">
                            <b-button @click="changeArrow(user.index)" class="text-left btn_acordeon txt_secondary" block
                                v-b-toggle="'accordion-' + user.index" variant="btn_acordeon">
                                Fecha: {{ user.fecha_evolucion }} - Hora: {{ user.hora_evolucion }}
                                <span id="iconTwo" class="float-right">
                                    <i v-if="user.show" class="fa fa-angle-down fa-lg" />
                                    <i v-else class="fa fa-angle-down fa-lg" />
                                </span>
                            </b-button>
                        </div>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + user.index" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-row align-h="between">
                                <b-col v-if="user.medico" class="my-auto py-auto" cols="12" sm="12" md="6" lg="7">
                                    <span class="txt_secondary size-span">Médico: {{ user.medico.nombre }}.</span>
                                </b-col>
                                <b-col v-if="!state.oldExp" class="text-start" cols="12" sm="12" md="4" lg="4">
                                    <button title="Editar" class="btn_formluario border-0 btn-block py-2 txt_white"
                                        @click="editar(user)"><span class="fa fa-edit fa-lg my-auto"></span></button>
                                </b-col>
                            </b-row>

                            <b-table-simple class="table-responsive table-sm mt-3" :items="tabla" v-if="m">
                                <b-thead>
                                    <b-tr>
                                        <b-th class="th_style size_th text-center">T.A.</b-th>
                                        <b-th class="th_style size_th text-center">F.C.</b-th>
                                        <b-th class="th_style size_th text-center">F.R.</b-th>
                                        <b-th class="th_style size_th text-center">T.</b-th>
                                        <b-th class="th_style size_th text-center">SPO2</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="border_table text-center">
                                            <b-form-input type="text" v-model="user.tension_arterial" disabled
                                                class="border-0 text-center input_disabled_transparent" size="sm" />
                                        </b-td>
                                        <b-td class="border_table text-center">
                                            <b-form-input type="text" v-model="user.frecuencia_cardiaca" disabled
                                                class="border-0 text-center input_disabled_transparent" size="sm" />
                                        </b-td>
                                        <b-td class="border_table text-center">
                                            <b-form-input type="text" v-model="user.frecuencia_respiratoria" disabled
                                                class="border-0 text-center input_disabled_transparent" size="sm" />
                                        </b-td>
                                        <b-td class="border_table text-center">
                                            <b-form-input type="text" v-model="user.temperatura" disabled
                                                class="border-0 text-center input_disabled_transparent" size="sm" />
                                        </b-td>
                                        <b-td class="border_table text-center">
                                            <b-form-input type="text" v-model="user.saturacion_oxigeno" disabled
                                                class="border-0 text-center input_disabled_transparent" size="sm" />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th colspan="5" class="th_style">
                                            Resumen y actualización del cuadro clínico
                                        </b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td colspan="5" class="border_table">
                                            <b-form-textarea v-model="user.resumen" disabled
                                                class="border-0 input_disabled_transparent" size="sm" no-resize rows="3"
                                                max-rows="3" />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th colspan="5" class="th_style">
                                            Estudios de laboratorio y gabinete
                                        </b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td colspan="5" class="border_table">
                                            <b-form-textarea v-model="user.estudios_lab_gabinete" disabled
                                                class="border-0 input_disabled_transparent" size="sm" no-resize rows="3"
                                                max-rows="3" />
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th colspan="5" class="th_style">
                                            Tratamiento e indicaciones
                                        </b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td colspan="5" class="border_table">
                                            <b-form-textarea v-model="user.tratamiento_indicaciones" disabled
                                                class="border-0 input_disabled_transparent" size="sm" no-resize rows="3"
                                                max-rows="3" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-table-simple>
            <!-- <div v-if="datos.firma.is_firmado==0 && !actualizar && !state.oldExp && idResponsable===usuario.id" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                     <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div> -->
        </b-container>
        <div class="sticky-container"
            v-if="(idPacienteSeleccionado != '' && !openFirmaPsw && !openSuccess && !openError && !openAlertPresion && !openPreSave && !regNewExp && !pswUpdate && !loading && !openAddMedic)">
            <ul class="sticky">
                <!-- <li v-if="!dtaExist" @click="preGuardar()">
                    <img :src="require('../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li> -->
                <!-- <li>
                    <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li> -->
                <li v-if="dtaExist && !updating" @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <FirmaPassword :abrir="openFirmaPsw" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()" />
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()" />
        <AltertPresionArterial :abrir="openAlertPresion"
            @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()" />
        <NuevoExpediente :openRegExp="regNewExp" />
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading" />
        <RegistroMedico :openModalAddMedic="openAddMedic" @closeModalAddMedic="closeModalAddMedic()"
            @getMedicPerEsp="getMedicPerEsp()" />

    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from 'pdf-merger-js';
import router from '../../router';
import RegistroMedico from '@/components/Medico/Registro.vue'
import { getCatalogo } from '../../helpers/catalogos'
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import { url } from "../../../settings";
import Foto from "@/components/Paciente/Foto.vue"
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import CryptoJS from 'crypto-js'
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
const urlec = `${url}/api/catalogos/estado_civil`
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import Loading from '@/components/Loader/Loading.vue'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'



export default {
    name: "NotaEvolucionMedica",
    components: { Datos, Foto, Success, Error, FirmaPassword, AltertPresionArterial, NuevoExpediente, FirmaPswUpdate, Loading, RegistroMedico },

    computed: {
        hour() {
            return this.datos.hora_ingreso.length != 0
        },

        fecha() {
            return this.datos.fecha_ingreso != 0
        },

        validation() {
            return this.idPacienteSeleccionado != ""
        },

        tension_arterial() {
            if (this.tension1.length != 0 && this.tension2.length != 0) {
                return true
            }
            return false;
        },

        frecuencia_cardiaca() {
            return this.datos.frecuencia_cardiaca.length != 0;
        },

        temperatura() {
            return this.datos.temperatura.length != 0;
        },

        frecuencia_respiratoria() {
            return this.datos.frecuencia_respiratoria.length != 0;
        },

        saturacion_oxigeno() {
            return this.datos.saturacion_oxigeno.length != 0;
        },

        peso() {
            return this.datos.peso.length != 0;
        },

        talla() {
            return this.datos.talla.length != 0;
        },

        registro_interrogatorio_exploracion_fisica() {
            return this.datos.registro_interrogatorio_exploracion_fisica.length != 0;
        },

        ocular_id() {
            return this.datos.ocular_id != null;
        },

        verbal_id() {
            return this.datos.verbal_id != null;
        },

        motriz_id() {
            return this.datos.motriz_id != null;
        },

        estudios_lab_gabinete() {
            return this.datos.estudios_lab_gabinete.length != 0;
        },

        tratamiento_indicaciones() {
            return this.datos.tratamiento_indicaciones.length != 0;
        },

        resumen() {
            return this.datos.resumen.length != 0;
        },
    },

    data: () => ({
        changeDta: null,
        isEgresado: false,
        openAddMedic: false,
        medicos: [],
        usuario: {
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable: null,

        state: {
            idExpediente: "",
            idPaciente: "",
            oldExp: false,
        },
        allExp: [],

        /* Update data */
        headerHistorial: true,
        btnFirmaUpdate: false,
        updating: false,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        pswUpdate: false,

        loading: true,

        dtaExist: false,
        regNewExp: false,
        cargado: false,
        openPreSave: false,
        datosPreGuardados: [],
        showDta: false,

        formUpdate: [],
        ecivil: [{ value: null, text: 'seleccione una opcion' }, { text: '...' }],
        info: false,
        openAlertPresion: false,
        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,
        idExpediente: "",
        tension1: "",
        tension2: "",
        error: false,
        errores: "",
        busy: false,
        openSuccess: false,
        openError: false,
        ultimaVez: "",
        nuevo: true,
        m: false,
        nombres: [],
        idPacienteSeleccionado: "",
        datosPaciente: {
            password: "",
            edad: "",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id: null,
                nombre: "",
            },
            municipio: {
                id: null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero: {
                id: null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },
        medico_id: null,
        datos: {
            expediente_id: null,
            fecha_evolucion: "",
            hora_evolucion: "",
            tension_arterial: "",
            frecuencia_cardiaca: "",
            frecuencia_respiratoria: "",
            temperatura: "",
            saturacion_oxigeno: "",
            resumen: "",
            estudios_lab_gabinete: "",
            tratamiento_indicaciones: "",
            no_habitacion: "",
            password: '',
            id: '',
            firma: {
                fecha_firmado: null,
                firmado_por: null,
                is_firmado: 0
            },
        },

        datosLimpios: {
            expediente_id: null,
            fecha_evolucion: "",
            hora_evolucion: "",
            tension_arterial: "",
            frecuencia_cardiaca: "",
            frecuencia_respiratoria: "",
            temperatura: "",
            saturacion_oxigeno: "",
            resumen: "",
            estudios_lab_gabinete: "",
            tratamiento_indicaciones: "",
            no_habitacion: "",
            password: '',
            id: '',
        },

        horaFecha: "",
        tabla: [{}],
        datosExpediente: {}
    }),

    methods: {
        verNotaDeEgreso() {
            this.$emit('toEgreso')
        },

        modalAddMedic() {
            this.openAddMedic = true;
        },

        changeExp(id) {
            this.clearData()
            this.loading = true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp = false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta = currentExp.id
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/` + this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp = data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es" + error);
            }
        },

        /* Update data */
        scrollInto() {
            setTimeout(() => {
                const section = document.getElementById('titleUpd');
                section.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 500);
        },

        scrollTitleHist() {
            const section = document.getElementById('titleHist');
            section.scrollIntoView({
                behavior: 'smooth'
            });
        },

        cancelEdit() {
            this.scrollTitleHist()
            this.headerHistorial = true
            this.btnFirmar = true
            this.nuevo = false
            this.datos = this.datosLimpios
            this.tension1 = ''
            this.tension2 = ''
            this.disableInput = true
            this.actualizar = false
            this.updating = false
            this.cancelar = false
            this.btnFirmaUpdate = false
            this.error = false
            this.errores = []
            this.setData()
        },

        editar(user) {
            // this.pswUpdate = true
            this.headerHistorial = false
            // this.datos.password = psw
            this.error = false
            this.errores = []
            this.nuevo = true
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.btnFirmar = false
            this.updating = true
            this.scrollInto()
            this.pswUpdate = false

            this.datos = user
            var ta = this.datos.tension_arterial
            var ta1 = ta.indexOf("/")
            this.tension1 = ta.substring(0, ta1)
            this.tension2 = ta.substring(ta1 + 1, ta.length)
            try {
                console.log("sijalo");
                if (user.medico) {
                    this.medico_id = user.medico.id
                } else {
                    this.medico_id = localStorage.id
                }
            } catch (error) {
                this.medico_id = localStorage.id
            }

            console.log({ user: user });
        },

        firmaUpdate() {
            // this.pswUpdate = true
            this.headerHistorial = false
            // this.datos.password = psw
            this.error = false
            this.errores = []
            this.nuevo = true
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.btnFirmar = false
            this.updating = true
            this.scrollInto()
            this.pswUpdate = false

        },

        async updateData() {
            this.error = false
            this.errores = []
            this.busyUpdate = true
            this.datos.tension_arterial = this.tension1 + "/" + this.tension2
            this.datos.hora_evolucion = this.datos.hora_evolucion.substring(0, 5)
            this.datos.medico_id = this.medico_id
            try {
                const res = await fetch(`${url}/api/nota_evolucion/update/${this.datos.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status === 500
                var warn = false
                var err = []
                var promise = Promise.resolve(data)
                await promise.then(function (val) {
                    console.log(val);
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.openSuccess = true
                    this.busyUpdate = false
                    this.cancelEdit()
                } else {
                    this.openError = true
                    this.error = true
                    this.errores = err
                    this.busyUpdate = false
                    if (this.errores.password) {
                        this.btnFirmaUpdate = true
                        this.actualizar = false
                        this.busyUpdate = false
                    } else {
                        this.busyUpdate = false
                        this.actualizar = true
                        this.cancelar = true
                    }
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },


        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/nota_evolucion/descargar/${this.datos.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                }
            }).then(function (resp) {
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function (blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob) {
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente + " Nota de evolucion");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        preGuardar() {
            var existe = false;
            this.datos.tension_arterial = this.tension1 + "/" + this.tension2
            try {
                if (localStorage.preGuardadoNE) {
                    console.log("si hay datos");
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNE)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            this.datos.password = ""
            var i = 0;
            for (let val of this.datosPreGuardados) {
                console.log("valor: " + val.expediente_id);
                if (this.datos.expediente_id === val.expediente_id) {
                    console.log("ya esta we");
                    this.datosPreGuardados[i] = this.datos
                    existe = true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push(this.datos);
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNE', dta);
            this.makeToast('success');
            console.log(this.datosPreGuardados);
        },
        clearPresave() {
            try {
                if (localStorage.preGuardadoNE) {
                    console.log("si hay datos");
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNE)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            var i = 0;
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id === val.expediente_id) {
                    this.datosPreGuardados.splice(i, 1)
                    localStorage.setItem('preGuardadoNE', this.datosPreGuardados);
                    return 0
                }
                i = i + 1
            }
        },

        changeArrow(n) {
            this.tabla[n].show = !this.tabla[n].show
            console.log(n + "-" + this.tabla[n].show);

        },
        tensionArterial() {
            if (this.tension2.length >= 2 && this.tension1.length >= 2) {
                setTimeout(() => {
                    if (this.tension1 < 100 || this.tension1 > 140 || this.tension2 < 60 || this.tension2 > 90) {
                        this.openAlertPresion = true
                    }
                }, 500);
            }
        },

        firmar() {
            this.openFirmaPsw = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        clearData() {
            this.nuevo = false
            this.showDta = false
            this.tension1 = ""
            this.tension2 = ""
            this.datos.frecuencia_cardiaca = ""
            this.datos.frecuencia_respiratoria = ""
            this.datos.temperatura = ""
            this.datos.saturacion_oxigeno = ""
            this.m = false
            this.datos.resumen = ""
            this.datos.estudios_lab_gabinete = ""
            this.datos.tratamiento_indicaciones = ""
            this.datos.password = ""
            this.btnGuardar = false;
            this.btnFirmar = true;
            this.medico_id = this.usuario.id;
        },
        async getdata() {
            this.clearData()
            try {
                const res = await fetch(`${url}/api/nota_evolucion/${this.idExpediente}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log(data.listado_evolucion);
                if (data.listado_evolucion.length > 0) {
                    this.dtaExist = true
                } else {
                    this.dtaExist = false
                }
                if (data.listado_evolucion) {

                    this.tabla = data.listado_evolucion;
                    this.formUpdate = []
                    let i = 0
                    for (let val of this.tabla) {
                        val.index = i
                        val.show = true
                        this.formUpdate.push(false)
                        i++
                        this.nuevo = false
                    }
                    console.log(this.tabla);
                    this.m = true
                } else {
                    this.nuevo = true
                }
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading = false
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading = false
                console.log(" ");
            }
        },

        async evolucion() {
            this.datos.medico_id = this.medico_id
            this.error = false
            this.errores = []
            this.datos.tension_arterial = this.tension1 + "/" + this.tension2
            console.log(this.datos);
            try {
                const res = await fetch(`${url}/api/nota_evolucion/store`, {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                console.log(data);
                var warn = false
                var err = false
                var promise = Promise.resolve(data);
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.setData()
                    this.busy = false
                    this.nuevo = false
                    this.openSuccess = true
                    this.clearPresave()
                } else {
                    this.error = true
                    this.errores = err
                    this.busy = false
                    this.openError = true
                }

                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }

            } catch (error) {
                this.busy = false
                this.openError = true
                console.log(error);
            }
        },

        getNumber() {
            this.busy = true
            this.evolucion()
        },

        setFechaHora() {
            var date = new Date;
            var minutes = ("0" + date.getMinutes()).slice(-2)
            var hour = ("0" + date.getHours()).slice(-2)
            this.datos.hora_evolucion = "" + hour + ":" + minutes + ""
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() + 1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha_evolucion = yyyy + '-' + mm + '-' + dd;
            this.horaFecha = this.datos.fecha_evolucion + " " + this.datos.hora_evolucion;
        },
        dataUser() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/usuarios/logged'))
            }).then((data) => {
                try {
                    this.usuario = data.usuario
                    this.medico_id = this.usuario.id
                } catch (error) {
                    console.log(error);
                }
            })
        },
        setData() {
            this.dataUser()
            this.setFechaHora();
            this.obtenerMedicos()
            const pacienteEncrypt = localStorage.expedienteEncrypt
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente)
            this.changeDta = paciente.id
            this.idResponsable = paciente.medico.id

            this.state.idExpediente = paciente.id
            this.state.idPaciente = paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id != paciente.id) {
                this.state.oldExp = true
            } else {
                this.state.oldExp = false
            }

            if (paciente.paciente.telefono == null) {
                paciente.paciente.telefono = "-----"
            }
            if (paciente.paciente.email == null) {
                paciente.paciente.email = "-----"
            }

            this.idPacienteSeleccionado = paciente.paciente.id
            this.datos.expediente_id = paciente.id;
            this.datos.no_habitacion = paciente.no_habitacion
            this.datosPaciente = paciente.paciente
            this.datosPaciente.noExpediente = paciente.paciente.no_expediente
            this.datosExpediente = paciente
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad = yyyy - year.getFullYear();

            this.idExpediente = paciente.id
            this.tabla = [{}]
            this.getdata()
            this.allExpedientes()
        },
        async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${urlec}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();


                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift({ value: null, text: 'seleccione una opcion' },)


            } catch (error) {
                console.log("el error es" + error);
            }
        },
        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
                title: "Datos preguardados",
                variant: variant,
                solid: true
            })
        },

        obtenerMedicos() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/medicos'))
            }).then((data) => {
                try {
                    this.medicos = data.medicos
                    console.log(this.medicos);
                } catch (error) {
                    console.log(error)
                }
            })
        },
        changemedico(v) {
            console.log(v);
        },
        // event on
        cerrarModal() {
            this.openSuccess = false
        },
        cerrarModalError() {
            console.log('cerrarModalError');
            this.openError = false
        },
        cerrarModalAlertPresionArterial() {

            this.openAlertPresion = false
        },
        closeModalAddMedic() {
            this.openAddMedic = false
        },
        getMedicPerEsp() {

            this.obtenerMedicos()
            this.openAddMedic = false
            this.openSuccess = true
        },
        cerrarModalFirmarPswd(){
            console.log('cerrarModalFirmarPswd');
            this.openFirmaPsw = false
        }
    },

    mounted() {
    },
    activated() {
        Object.assign(this.$data, this.$options.data());

        const egreso = localStorage.egreso
        if (egreso) {
            const egresoDE = JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado = egresoDE.egreso
        } else {
            router.push("/main")
        }

        this.setData()
        this.loading = true
    },

}
</script>

<style scoped>
@media only screen and (max-width: 1220px) {
    .size_th {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media only screen and (min-width: 768px) {
    .size_th {
        padding-left: 30px;
        padding-right: 30px;
    }
}


/* Info paciente */
@media only screen and (min-width: 1200px) {
    .size_th_info {
        width: 20vw;
    }
}

@media only screen and (max-width: 1200px) {
    .size_th_info {
        width: 20vw;
    }
}

@media only screen and (max-width: 992px) {
    .size_th_info {
        width: 30vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th_info {
        width: 20vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th_info {
        width: auto;
    }
}

.btn_redondo {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 20px;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.42857;
}

.pd-add-medic {
    padding-left: 7px;
    padding-right: 7px;
}

/* Acordeon */
.bg {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.bg_header {
    border: none !important;
    box-shadow: none !important;
    background-color: white !important;
}</style>