<template>
    <div>
        <b-modal class="rounded-lg" size="lg" title="Agregar médico" v-model="openModalAddMedic" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer centered>
            <div class="mt-2">
                <b-table-simple class="table-sm">
                    <b-tbody>  
                        <b-tr>
                            <b-th class="th_style">Nombre</b-th>
                            <b-td class="border_table">
                                <b-form-input v-model="medico.nombre" autocomplete="off" v-on:keypress="soloLetras($event)" :state="nombre" class="border-0" size="sm" placeholder="ingrese nombre del médico"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Numero de teléfono</b-th>
                            <b-td class="border_table">
                                <b-form-input v-model="medico.celular" autocomplete="off" maxlength="10" v-on:keypress="soloNumeros($event)" :state="celular" class="border-0" size="sm" placeholder="ingrese numero de teléfono celular"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Email</b-th>
                            <b-td class="border_table">
                                <b-form-input v-model="medico.email" autocomplete="off" :state="VUserEmail" class="border-0" size="sm" placeholder="ingrese correo"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Cédula general</b-th>
                            <b-td class="border_table">
                                <b-form-input v-model="medico.cedula" :state="cedula" maxlength="12" minlength="7" autocomplete="off" class="border-0" size="sm" placeholder="ingrese cédula"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Especialidad</b-th>
                            <b-td class="border_table">
                                <b-form-select v-model="medico.especialidad_id" :options="dtaEspecialidadesModal" class="border-0" size="sm"/>
                            </b-td>
                        </b-tr>
                        <b-tr v-if="medico.especialidad_id != null">
                            <b-th class="th_style">Cédula especialidad</b-th>
                            <b-td class="border_table">
                                <b-form-input v-model="medico.especialidad_cedula" :state="cedulaEspecialidad" maxlength="12" minlength="7" autocomplete="off" class="border-0" size="sm" placeholder="ingrese cédula de especialidad"/>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-row v-if="errorModal">
                    <b-col cols="12">
                        <div class="contenedor-de-errores pt-3 pb-1">
                            <ul class="lista-errores px-2">
                                <li v-for="(value,key) in erroresModal" :key="key.value"><i class="fa fa-warning px-1 icon-errror" />| {{value[0]}} <br></li>
                            </ul>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <hr>
            <div class="d-flex justify-content-center mt-2">
                <b-overlay
                :show="busyModal"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                >
                    <button class="btn-modal-success px-3 pb-2 pt-1 btn_add_user" :disabled="busyModal" @click="addUser()">GUARDAR</button>
                </b-overlay>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {url} from '../../../settings.js'
import Vue from 'vue'

export default {
    name: 'Registrar',
    props: ['openModalAddMedic'],

    data:() => ({
        busyModal: false,
        errorModal: false,
        erroresModal: "",

        medico: {
            nombre:"",
            email:"",
            celular:"",
            perfil_id: 3,
            especialidad_id:null,
            especialidad_cedula: '',
            cedula:"",
        },
        dtaEspecialidadesModal: [{value:null, text:'seleccionar'},{text: '...'}],
    }),

    computed: {
        nombre() {
            return this.medico.nombre.length >= 10
        },

        celular() {
            return this.medico.celular.length === 10 ? true : false
        },

        VUserEmail() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.medico.email)
        },

        cedulaEspecialidad() {
            let flag = false
            if (this.medico.especialidad_cedula.length >= 7 && this.medico.especialidad_cedula.length <= 12) {
                flag = true
            }
            return flag
        },

        cedula() {
            let flag = false
            if (this.medico.cedula.length >= 7 && this.medico.cedula.length <= 15) {
                flag = true
            }
            return flag
        }

    },

    methods: {
        async addUser() {
            this.busyModal = true
            this.errorModal = false
            this.erroresModal=[]
            try {
                const res = await fetch(`${url}/api/usuarios/fast_users`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.medico),
                });
                const data = res.json()
                Vue.prototype.$error500 = res.status===500
                var warn=false
                var promise = Promise.resolve(data)
                var err=[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors
                    }
                });
                
                if (warn) {
                    this.$emit('getMedicPerEsp', this.medico.especialidad_id)
                    this.busyModal = false
                    this.cerrarModal();
                    this.limpiarDatosModal()
                }else{
                    this.erroresModal=err
                    this.errorModal=true
                    this.busyModal = false
                } 
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message)
                }
                const data = await res.json()
                this.dtaEspecialidadesModal = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
                this.dtaEspecialidadesModal.unshift({ value: null, text: 'seleccionar' })

            } catch (error) {
                console.log(error);
            }
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        limpiarDatosModal() {
            this.medico.nombre = '',
            this.medico.email = ''
            this.medico.celular = ''
            this.medico.especialidad_id = null
            this.medico.especialidad_cedula = ''
            this.medico.cedula = ''
        },

        cerrarModal() {
            this.limpiarDatosModal()
            this.$emit("closeModalAddMedic")
        }
    },

    mounted() {
        this.obtenerEspecialidades()
    }

}
</script>

<style>

</style>