<template>
    <div>
        <b-modal class="rounded-lg" :size="sizeModal" v-model="abrir" :hide-header="hideHeader" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer centered>
            <div v-if="alertFirma">
                <div class="d-flex justify-content-center">
                    <b-col cols="12" sm="12" md="12" lg="12" class="mt-2">
                            <div class="d-flex">
                                <i class="icon_color_main fa fa-lock fa-lg  my-auto mr-2"></i>
                                <b-form-input v-model="data.psw" @keyup.enter="adv()" autocomplete="off" class="form_input-color border-0 mt-1 psw" size="md" type="password" placeholder="Ingresa tu contraseña"></b-form-input>
                            </div>
                            <div>
                                <span class="msjError ml-4">{{msjError}}</span> 
                            </div>
                    </b-col>
                </div>
                <div class="d-flex justify-content-end mt-3 pr-3">
                    <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                    >
                        <button @click="adv()" :disabled="busy" class="btn-modal-success border-0 px-3 pb-2 pt-1">Firmar documento</button>
                    </b-overlay>
                    <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
                </div>
            </div>
            <div v-if="advertencia">
                <div class="d-flex justify-content-center">
                    <span class="icono text-center p-2" align="center">
                        <i class="fa fa-warning fa-3x"></i>
                    </span>
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <span><strong>¡Alerta!</strong></span><br>
                </div>
                <div class="d-flex justify-content-center text-center mt-1">
                    <p class="subttitle-modal">Una vez firmada el documento ya no podrá ser actualizado</p>
                </div>
                <div class="d-flex justify-content-end mt-3 pr-3">
                    <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                    >
                        <button @click="firmar()" :disabled="busy" class="btn-modal-success border-0 px-4 pb-2 pt-1">Firmar</button>
                    </b-overlay>
                    <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-5">Cancelar</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'FirmaPassword',
    props: ['abrir'],

    data:() => ({
        hideHeader: false,
        sizeModal: 'md',
        title: 'Ingresa tu contraseña',
        alertFirma:true,
        advertencia:false,
        msjError: '',
        busy: false,
        data: {
            psw: ''
        }
    }),

    methods: {
        adv(){
            if (localStorage.view!="" && localStorage.view!="Informacion" && localStorage.view!=null && localStorage.view!="Registro" && localStorage.view != "NotaEvolucionMedica") {
                if (!this.data.psw) {
                    this.msjError= 'La contraseña es obligatoria';
                } else {
                    setTimeout(() => {
                        this.hideHeader = true
                        this.sizeModal = 'sm'
                        this.alertFirma = false
                        this.advertencia = true
                    }, 200);
                }
            }else{
                this.firmar()
            }
        },

        firmar() {
            if (!this.data.psw) {
                this.msjError= 'La contraseña es obligatoria';
            } else {
                this.msjError = '';
                this.$emit('firma_psw',this.data.psw)
                this.data.psw = ''
                setTimeout(() => {
                    this.hideHeader = false
                    this.sizeModal = 'md'
                    this.alertFirma = true
                    this.advertencia = false
                }, 500);
            }
        },

        cerrarModal(){
            this.msjError='';
            this.data.psw = "";
            this.$emit("cerrarModalFirmarPswd");
            setTimeout(() => {
                this.hideHeader = false
                this.sizeModal = 'md'
                this.alertFirma = true
                this.advertencia = false
            }, 500);
        },

        onEscapeKeyUp(event) {
            if (this.abrir) {
                if (event.which === 27) {
                    this.cerrarModal()        
                }else if (this.advertencia) {
                    if (event.which === 13) {
                        this.firmar()        
                    }
                }
            }
        }
    },

    activated() {
        window.addEventListener('keyup', (evt) => {
            this.onEscapeKeyUp(evt)
        })
    },
}
</script>

<style scoped>
    .fa-warning {
        color: #d15460;
    }
</style>