<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Indicaciones médicas</h2>
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto />
                </div>
            </b-col>
        </b-row>
        <b-container>
            <!-- LISTADO DE EXPEDIENTES -->
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente"
                                @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id"
                                        :value="all.id">{{ all.no_expediente }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong
                        class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(hayDta && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(infoIndicacion.firma.is_firmado == 1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(infoIndicacion.firma.is_firmado == 0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <Datos :changeData="changeDta" />


            <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>

            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th">Alergias a medicamentos</b-th>
                                <b-td class="border-0">
                                    <label class="switch">
                                        <input :disabled="(disableInput || isEgresado)" type="checkbox"
                                            v-model="infoIndicacion.alergiaMed" value=false>
                                        <span class="slider round"></span>
                                    </label>
                                </b-td>
                            </b-tr>

                            <b-tr v-if="infoIndicacion.alergiaMed">
                                <b-th class="th_option_color">
                                    <b-form-input :disabled="(disableInput || isEgresado)"
                                        v-model="infoIndicacion.alergias_medicamentos" :state="valAlergiaMedic"
                                        name="DesAlergiaMedicamentos" autocomplete="off" type="text" class="border-0"
                                        size="sm" placeholder="¿Cuáles?" />
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th">Otras alergias</b-th>
                                <b-td class="border-0">
                                    <label class="switch">
                                        <input :disabled="(disableInput || isEgresado)" type="checkbox"
                                            v-model="infoIndicacion.otrasAlergias" value="false">
                                        <span class="slider round"></span>
                                    </label>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="infoIndicacion.otrasAlergias">
                                <b-th class="th_option_color">
                                    <b-form-input :disabled="(disableInput || isEgresado)"
                                        v-model="infoIndicacion.otras_alergias" :state="valOtrasAlergias"
                                        name="DesOtrasAlergias" autocomplete="off" type="text" class="border-0" size="sm"
                                        placeholder="¿Cuáles?" />
                                </b-th>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th_sec_table">Número de indicación</b-th>
                                <b-td class="border_table">
                                    <b-form-input disabled v-model="infoIndicacion.no_indicacion"
                                        v-on:keypress="soloNumeros($event)" name="numIndicacion" autocomplete="off"
                                        type="text" class="border-0" size="sm" placeholder="1" />
                                </b-td>
                            </b-tr>
                            <b-tr v-if="hayDta">
                                <b-th class="th_style">Última modificación</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2"><span
                                            class="size-span">{{ sendFecha(infoIndicacion.ultima_modificacion) }}</span></span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>


            <b-row v-if="error" class="mt-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err, index) of errores" :key="index"><i class="fa fa-warning px-1"
                                    v-if="!err.expediente_id" />| {{ err[0] }} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="actualizar" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busyUpdate" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()"
                            :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <!-- <div v-if="btnFirmaUpdate" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->

            <div v-if="cancelar" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>

            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 border-0">
                    <b-card-header header-tag="header" class="p-1 bg_header" role="tab">
                        <div class="d-flex">
                            <b-button @click="changeIcon('dieta')" class="text-left btn_acordeon txt_secondary" block
                                v-b-toggle.accordion-1 variant="btn_acordeon">
                                DIETA
                                <span id="iconOne" class="float-right">
                                    <i v-if="errorDieta" title="Error" class="fa fa-warning fa-md icon-color" />
                                    <i v-else-if="formDietaAngle" class="fa fa-angle-down fa-lg" />
                                    <i v-else class="fa fa-angle-up fa-lg" />
                                </span>
                            </b-button>
                        </div>
                    </b-card-header>
                    <b-collapse class="bg" id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-table-simple class="table-sm mt-3"
                                v-if="hayDta && infoIndicacion.indicacion_medica_dietas && infoIndicacion.indicacion_medica_dietas.length > 0">
                                <b-thead>
                                    <b-tr>
                                        <b-th class="th_style size_th text-center">Fecha y hora</b-th>
                                        <b-th class="th_style size_th text-center">Tipo de dieta</b-th>
                                        <b-th class="th_style size_th text-center">Indicaciones de dieta</b-th>
                                        <b-th v-if="!state.oldExp" class="th_style size_th text-center">Acciones</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody v-for="(dto, index) of infoIndicacion.indicacion_medica_dietas" :key="index">
                                    <b-tr>
                                        <b-td class="border_table vertical_align text-center">
                                            <span class="size-span">{{ sendFecha(dto.fecha) }} {{ dto.hora }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dietas.find(dieta => dieta.value ===
                                                dto.dieta_id).text }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dto.indicacion }}</span>
                                        </b-td>
                                        <b-td v-if="!state.oldExp" class="border_table">
                                            <b-row align-h="center">
                                                <b-col cols="12" sm="12" md="3" lg="3">
                                                    <div @click="editDieta(dto)" class="btnAccion" title="Editar"><span
                                                            class="fa fa-edit fa-lg my-auto icon_color"></span></div>
                                                </b-col>
                                            </b-row>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                class="justify-content-between mt-4" id="scrolling1">
                                <b-col cols="4">
                                    <p v-if="headerDieta" class="txt_secondary text-uppercase py-2"><strong>Añadir
                                            actualización</strong></p>
                                    <p v-if="updatingDieta" class="txt_secondary text-uppercase py-2"><strong>Actualizar
                                            dieta</strong></p>
                                </b-col>
                                <b-col v-if="headerDieta" cols="4">
                                    <div class="float-right">
                                        <button @click="addActualizacion('dieta')"
                                            class="btnAddUpdate border-0 bg_secondary txt_white">
                                            <i class="fa fa-plus fa-lg mx-auto my-auto" />
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                v-if="infoIndicacion.indicacion_medica_dietas && ((formDieta && !hayDta) || (hayDta && !(infoIndicacion.indicacion_medica_dietas.length > 0)) || ((infoIndicacion.indicacion_medica_dietas.length > 0) && formDieta))">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm">
                                        <b-tbody>
                                            <b-tr>
                                                <b-th class="th_style size_th">Dieta</b-th>
                                                <b-td class="border_table size_th">
                                                    <b-form-select v-model="dieta.dieta_id" :options="dietas"
                                                        :state="valDieata" name="text" value="value" class="border-0"
                                                        size="sm" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm mt-3">
                                        <b-thead>
                                            <b-tr>
                                                <b-th v-if="dateDieta" class="th_style size_th">Fecha y hora</b-th>
                                                <b-th class="th_style size_th">Indicaciones de dieta</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-td v-if="dateDieta" class="border_table">
                                                    <b-form-input disabled v-model="dieta.fecha" type="date" name="fecha"
                                                        autocomplete="off" class="border-0 mb-1" size="sm"
                                                        placeholder="DD/MM/AAAA" />
                                                    <b-form-input disabled v-model="dieta.hora"
                                                        v-on:keypress="soloNumeros($event)" type="time" min="00:00:01"
                                                        max="23:59:59" name="hora" autocomplete="off" class="border-0"
                                                        size="sm" />
                                                </b-td>
                                                <b-td class="border_table">
                                                    <b-form-textarea v-model="dieta.indicacion" :state="valIndicacion"
                                                        name="indicacionDieta" autocomplete="off" class="border-0" size="sm"
                                                        no-resize rows="3" max-rows="3" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>

                            <b-row v-if="errorDieta" class="mt-3 mb-3">
                                <b-col cols="12">
                                    <div class="contenedor-de-errores pt-3 pb-1">
                                        <ul class="lista-errores px-2">
                                            <li v-for="(err, index) of erroresDieta" :key="index"><i
                                                    class="fa fa-warning px-1" />| {{ err[0] }} <br>
                                            </li>
                                        </ul>
                                    </div>
                                </b-col>
                            </b-row>
                            <div :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)">
                                <div v-if="(infoIndicacion.indicacion_medica_dietas && formDieta && btnSave && hayDta) || (infoIndicacion.indicacion_medica_dietas && (!infoIndicacion.indicacion_medica_dietas.length > 0)) && hayDta"
                                    class="d-flex justify-content-end mb-4">
                                    <b-col cols="12" sm="12" md="5" lg="5">
                                        <b-overlay :show="busy" rounded opacity="0.6" spinner-small
                                            spinner-variant="primary">
                                            <button id="scrollingSave1" :disabled="busyDieta"
                                                class="btn_formluario border-0 btn-block py-2 txt_white"
                                                @click="saveDieta()">Guardar</button>
                                        </b-overlay>
                                    </b-col>
                                </div>
                            </div>
                            <div v-if="updatingDieta && hayDta" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <b-overlay :show="busyUpdDieta" rounded opacity="0.6" spinner-small
                                        spinner-variant="primary">
                                        <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                            @click="updateDieta()" :disabled="busyUpdDieta">Actualizar</button>
                                    </b-overlay>
                                </b-col>
                            </div>
                            <div v-if="updatingDieta" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                        @click="cancelUpdDieta()">Cancelar</button>
                                </b-col>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1 border-0">
                    <b-card-header header-tag="header" class="p-1 bg_header" role="tab">
                        <div class="d-flex">
                            <b-button @click="changeIcon('soluciones')" class="text-left btn_acordeon txt_secondary" block
                                v-b-toggle.accordion-2 variant="btn_acordeon">
                                SOLUCIONES
                                <span id="iconTwo" class="float-right">
                                    <i v-if="errorSoluciones" title="Error" class="fa fa-warning fa-md icon-color" />
                                    <i v-else-if="formSolucionesAngle" class="fa fa-angle-down fa-lg" />
                                    <i v-else class="fa fa-angle-up fa-lg" />
                                </span>
                            </b-button>
                        </div>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-table-simple class="table-sm mt-3"
                                v-if="hayDta && infoIndicacion.indicacion_medica_soluciones.length > 0">
                                <b-thead>
                                    <b-tr>
                                        <b-th class="th_style size_th text-center">Fecha y hora</b-th>
                                        <b-th class="th_style size_th text-center">Tipo de solución</b-th>
                                        <b-th class="th_style size_th text-center">Volumen de la solución (cc)</b-th>
                                        <b-th class="th_style size_th text-center">Tiempo de aplicación (horas)</b-th>
                                        <b-th v-if="!state.oldExp" class="th_style size_th text-center">Acciones</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody v-for="(dto, index) of infoIndicacion.indicacion_medica_soluciones" :key="index">
                                    <b-tr>
                                        <b-td rowspan="3" class="border_table vertical_align text-center">
                                            <span class="size-span">{{ sendFecha(dto.fecha) }} {{ dto.hora }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ tipoSolucion.find(tipo => tipo.value ===
                                                dto.tipo_solucion_id).text }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ volSolucion.find(volumen => volumen.value ===
                                                dto.volumen_solucion_id).text }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dto.tiempo_aplicacion }}</span>
                                        </b-td>
                                        <b-td v-if="!state.oldExp" rowspan="3" class="border_table">
                                            <b-row align-h="center">
                                                <b-col cols="12" sm="12" md="3" lg="3">
                                                    <div @click="editSoluciones(dto)" class="btnAccion" title="Editar"><span
                                                            class="fa fa-edit fa-lg my-auto icon_color"></span></div>
                                                </b-col>
                                            </b-row>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th colspan="3" class="th_style size_th">
                                            Indicaciones
                                        </b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td colspan="3" class="border_table size-span">{{ dto.indicacion }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                class="justify-content-between mt-4" id="scrolling2">
                                <b-col cols="4">
                                    <p v-if="headerSoluciones" class="txt_secondary text-uppercase py-2"><strong>Añadir
                                            actualización</strong></p>
                                    <p v-if="updatingSoluciones" class="txt_secondary text-uppercase py-2">
                                        <strong>Actualizar solución</strong></p>
                                </b-col>
                                <b-col v-if="headerSoluciones" cols="4">
                                    <div class="float-right">
                                        <button @click="addActualizacion('soluciones')"
                                            class="btnAddUpdate border-0 bg_secondary txt_white">
                                            <i class="fa fa-plus fa-lg mx-auto my-auto" />
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                v-if="infoIndicacion.indicacion_medica_soluciones && ((formSoluciones && !hayDta) || (hayDta && !(infoIndicacion.indicacion_medica_soluciones.length > 0)) || ((infoIndicacion.indicacion_medica_soluciones.length > 0) && formSoluciones))">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm">
                                        <b-tbody>
                                            <b-tr>
                                                <b-th class="th_style size_th">Tipo de solución</b-th>
                                                <b-td class="border_table size_th">
                                                    <b-form-select v-model="soluciones.tipo_solucion_id"
                                                        :state="valTipSolucion" :options="tipoSolucion" name="text"
                                                        value="value" autocomplete="off" class="border-0" size="sm" />
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Volumen de solución</b-th>
                                                <b-td class="border_table">
                                                    <b-form-select v-model="soluciones.volumen_solucion_id"
                                                        :state="valVolSolucion" :options="volSolucion" name="text"
                                                        value="value" autocomplete="off" class="border-0" size="sm" />
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Tiempo de aplicación</b-th>
                                                <b-td class="border_table">
                                                    <b-form-input v-model="soluciones.tiempo_aplicacion"
                                                        :state="valTimeAplicacion" type="text" autocomplete="off"
                                                        class="border-0" size="sm"
                                                        placeholder="Indique el tiempo de aplicación" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm mt-3">
                                        <b-thead>
                                            <b-tr>
                                                <b-th v-if="dateSoluciones" class="th_style size_th">Fecha y hora</b-th>
                                                <b-th class="th_style size_th">Indicaciones de solución</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-td v-if="dateSoluciones" class="border_table">
                                                    <b-form-input disabled v-model="soluciones.fecha" type="date"
                                                        name="fecha" autocomplete="off" class="border-0 mb-1" size="sm"
                                                        placeholder="DD/MM/AAAA" />
                                                    <b-form-input disabled v-model="soluciones.hora" type="time"
                                                        min="00:00:01" max="23:59:59" name="hora" autocomplete="off"
                                                        class="border-0" size="sm" />
                                                </b-td>
                                                <b-td class="border_table">
                                                    <b-form-textarea v-model="soluciones.indicacion"
                                                        :state="valSolIndicaciones" name="indicacionSolucion"
                                                        autocomplete="off" class="border-0" size="sm" no-resize rows="3"
                                                        max-rows="3" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                            <b-row v-if="errorSoluciones" class="mt-3">
                                <b-col cols="12">
                                    <div class="contenedor-de-errores pt-3 pb-1">
                                        <ul class="lista-errores px-2">
                                            <li v-for="(err, index) of erroresSoluciones" :key="index"><i
                                                    class="fa fa-warning px-1" />| {{ err[0] }} <br>
                                            </li>
                                        </ul>
                                    </div>
                                </b-col>
                            </b-row>
                            <div :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)">
                                <div v-if="(infoIndicacion.indicacion_medica_soluciones && formSoluciones && saveSolution && hayDta) || (infoIndicacion.indicacion_medica_soluciones && !infoIndicacion.indicacion_medica_soluciones.length > 0) && hayDta"
                                    class="d-flex justify-content-end mb-4">
                                    <b-col cols="12" sm="12" md="5" lg="5">
                                        <b-overlay :show="busy" rounded opacity="0.6" spinner-small
                                            spinner-variant="primary">
                                            <button id="scrollingSave2" :disabled="busySol"
                                                class="btn_formluario border-0 btn-block py-2 txt_white"
                                                @click="saveSoluciones()">Guardar</button>
                                        </b-overlay>
                                    </b-col>
                                </div>
                            </div>
                            <div v-if="updatingSoluciones && hayDta" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <b-overlay :show="busyUpdSoluciones" rounded opacity="0.6" spinner-small
                                        spinner-variant="primary">
                                        <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                            @click="updateSoluciones()" :disabled="busyUpdSoluciones">Actualizar</button>
                                    </b-overlay>
                                </b-col>
                            </div>
                            <div v-if="updatingSoluciones && hayDta" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                        @click="cancelUpdSoluciones()">Cancelar</button>
                                </b-col>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1 border-0">
                    <b-card-header header-tag="header" class="p-1 bg_header" role="tab">
                        <div class="d-flex">
                            <b-button @click="changeIcon('medicamento')" class="text-left btn_acordeon txt_secondary" block
                                v-b-toggle.accordion-3 variant="btn_acordeon">
                                MEDICAMENTOS
                                <span id="iconThree" class="float-right">
                                    <i v-if="errorMedicamentos" title="Error" class="fa fa-warning fa-md icon-color" />
                                    <i v-else-if="formMedicamentosAngle" class="fa fa-angle-down fa-lg" />
                                    <i v-else class="fa fa-angle-up fa-lg" />
                                </span>
                            </b-button>
                        </div>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-table-simple class="table-sm mt-3"
                                v-if="hayDta && infoIndicacion.indicacion_medica_medicamentos.length > 0">
                                <b-thead>
                                    <b-tr>
                                        <b-th class="th_style size_th text-center">Fecha y hora</b-th>
                                        <b-th class="th_style size_th text-center">Medicamento</b-th>
                                        <b-th class="th_style size_th text-center">Presentación</b-th>
                                        <b-th class="th_style size_th text-center">Dosis (mg)</b-th>
                                        <b-th v-if="!state.oldExp" class="th_style size_th text-center">Acciones</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody v-for="(dto, index) of infoIndicacion.indicacion_medica_medicamentos" :key="index">
                                    <b-tr>
                                        <b-td rowspan="5" class="border_table vertical_align text-center">
                                            <span class="size-span">{{ sendFecha(dto.fecha) }} {{ dto.hora }}</span>
                                        </b-td>
                                        <b-td class="border_table text-justify">
                                            <span v-if="farmacos.length > 0" class="size-span">{{ farmacos.find(farmaco =>
                                                farmaco.value === dto.medicamento_id).text }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dto.presentacion }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dto.dosis }}</span>
                                        </b-td>
                                        <b-td v-if="!state.oldExp" rowspan="5" class="border_table">
                                            <b-row align-h="center">
                                                <b-col cols="12" sm="12" md="3" lg="3">
                                                    <div @click="editMedicamentos(dto)" class="btnAccion" title="Editar">
                                                        <span class="fa fa-edit fa-lg my-auto icon_color"></span></div>
                                                </b-col>
                                            </b-row>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th class="th_style size_th text-center">Vía de administración</b-th>
                                        <b-th class="th_style size_th text-center">Tiempo de administración</b-th>
                                        <b-th class="th_style size_th text-center">Frecuencia</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ viaAdministracion.find(admin => admin.value ===
                                                dto.via_administracion_id).text }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dto.tiempo_administracion }}</span>
                                        </b-td>
                                        <b-td class="border_table">
                                            <span class="size-span">{{ dto.frecuencia }}</span>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th colspan="3" class="th_style size_th">Indicaciones de medicamento</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td colspan="3" class="border_table">
                                            <span class="size-span">{{ dto.indicacion }}</span>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                class="justify-content-between mt-4" id="scrolling3">
                                <b-col cols="4">
                                    <p v-if="headerMedicamentos" class="txt_secondary text-uppercase py-2"><strong>Añadir
                                            actualización</strong></p>
                                    <p v-if="updatingMedicamentos" class="txt_secondary text-uppercase py-2">
                                        <strong>Actualizar medicamentos</strong></p>
                                </b-col>
                                <b-col v-if="headerMedicamentos" cols="4">
                                    <div class="float-right">
                                        <button @click="addActualizacion('medicamentos')"
                                            class="btnAddUpdate border-0 bg_secondary txt_white">
                                            <i class="fa fa-plus fa-lg mx-auto my-auto" />
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                v-if="infoIndicacion.indicacion_medica_medicamentos && ((formMedicamentos && !hayDta) || (hayDta && !(infoIndicacion.indicacion_medica_medicamentos.length > 0)) || ((infoIndicacion.indicacion_medica_medicamentos.length > 0) && formMedicamentos))">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm">
                                        <b-tbody>
                                            <b-tr>
                                                <b-th class="th_style">Nombre de medicamento</b-th>
                                                <b-td class="border_table size_th_medicamentos">
                                                    <b-form-select v-model="medicamentos.medicamento_id" :options="farmacos"
                                                        :state="valMedicamentos" name="text" autocomplete="off"
                                                        class="border-0" size="sm" />
                                                </b-td>
                                                <b-td class="border_table justify-content-center text-center my-auto">
                                                    <button
                                                        class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                                        title="Añadir medicamento" @click="modalAddMedicamento()">
                                                        <i class="fa fa-plus fa-md" />
                                                    </button>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Presentación</b-th>
                                                <b-td colspan="2" class="border_table">
                                                    <b-form-input v-model="medicamentos.presentacion"
                                                        :state="valPresentacion" type="text" name="medPresentacion"
                                                        autocomplete="off" class="border-0" size="sm"
                                                        placeholder="Indique presentación del medicamento" />
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Dosis</b-th>
                                                <b-td colspan="2" class="border_table">
                                                    <b-form-input v-model="medicamentos.dosis" :state="valDosis" type="text"
                                                        name="dosis" autocomplete="off" class="border-0" size="sm"
                                                        placeholder="Indique dosis de aplicación" />
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Vía de administración</b-th>
                                                <b-td colspan="2" class="border_table">
                                                    <b-form-select v-model="medicamentos.via_administracion_id"
                                                        :options="viaAdministracion" :state="valViaAdministracion"
                                                        name="text" value="value" autocomplete="off" class="border-0"
                                                        size="sm" />
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Tiempo de administración</b-th>
                                                <b-td colspan="2" class="border_table">
                                                    <b-form-input v-model="medicamentos.tiempo_administracion"
                                                        :state="valTimeAdmin" type="text" name="timeAdministracion"
                                                        autocomplete="off" class="border-0" size="sm"
                                                        placeholder="Indique el tiempo de administración" />
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th class="size_th th_style">Frecuencia</b-th>
                                                <b-td colspan="2" class="border_table">
                                                    <b-form-input v-model="medicamentos.frecuencia" :state="valFrecuencia"
                                                        type="text" name="medFrecuencia" autocomplete="off" class="border-0"
                                                        size="sm" placeholder="Indique el horario de administración" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm mt-3">
                                        <b-thead>
                                            <b-tr>
                                                <b-th class="th_style size_th_medicamentos">Fecha y hora</b-th>
                                                <b-th class="th_style">Indicaciones de medicamento</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-td class="border_table size_th_medicamentos">
                                                    <b-form-input disabled v-model="medicamentos.fecha" type="date"
                                                        name="fecha" autocomplete="off" class="border-0 mb-1" size="sm"
                                                        placeholder="DD/MM/AAAA" />
                                                    <b-form-input disabled v-model="medicamentos.hora" type="time"
                                                        min="00:00:01" max="23:59:59" name="hora" autocomplete="off"
                                                        class="border-0" size="sm" />
                                                </b-td>
                                                <b-td class="border_table">
                                                    <b-form-textarea v-model="medicamentos.indicacion"
                                                        :state="valMedIndicaciones" name="indicacionMedicamentos"
                                                        autocomplete="off" class="border-0" size="sm" no-resize rows="3"
                                                        max-rows="3" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                            <b-row v-if="errorMedicamentos" class="mt-3">
                                <b-col cols="12">
                                    <div class="contenedor-de-errores pt-3 pb-1">
                                        <ul class="lista-errores px-2">
                                            <li v-for="(err, index) of erroresMedicamentos" :key="index"><i
                                                    class="fa fa-warning px-1" />| {{ err[0] }} <br>
                                            </li>
                                        </ul>
                                    </div>
                                </b-col>
                            </b-row>
                            <div :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)">
                                <div v-if="(infoIndicacion.indicacion_medica_medicamentos && formMedicamentos && saveDrug && hayDta) || (infoIndicacion.indicacion_medica_medicamentos && !infoIndicacion.indicacion_medica_medicamentos.length > 0) && hayDta"
                                    class="d-flex justify-content-end mb-4">
                                    <b-col cols="12" sm="12" md="5" lg="5">
                                        <b-overlay :show="busy" rounded opacity="0.6" spinner-small
                                            spinner-variant="primary">
                                            <button id="scrollingSave3" :disabled="busyMedic"
                                                class="btn_formluario border-0 btn-block py-2 txt_white"
                                                @click="saveMedicamentos()">Guardar</button>
                                        </b-overlay>
                                    </b-col>
                                </div>
                            </div>
                            <div v-if="updatingMedicamentos" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <b-overlay :show="busyUpdMedicamentos" rounded opacity="0.6" spinner-small
                                        spinner-variant="primary">
                                        <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                            @click="updateMedicamentos()"
                                            :disabled="busyUpdMedicamentos">Actualizar</button>
                                    </b-overlay>
                                </b-col>
                            </div>
                            <div v-if="updatingMedicamentos" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                        @click="cancelUpdMedicamentos()">Cancelar</button>
                                </b-col>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1 border-0">
                    <b-card-header header-tag="header" class="p-1 bg_header" role="tab">
                        <div class="d-flex">
                            <b-button @click="changeIcon('cuidados')" class="text-left btn_acordeon txt_secondary" block
                                v-b-toggle.accordion-4 variant="btn_acordeon">
                                CUIDADOS
                                <span id="iconFour" class="float-right">
                                    <i v-if="errorCuidados" title="Error" class="fa fa-warning fa-md icon-color" />
                                    <i v-else-if="formCuidadosAngle" class="fa fa-angle-down fa-lg" />
                                    <i v-else class="fa fa-angle-up fa-lg" />
                                </span>
                            </b-button>
                        </div>
                    </b-card-header>
                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm mt-3"
                                        v-if="hayDta && infoIndicacion.indicacion_medica_cuidados.length > 0">
                                        <b-thead>
                                            <b-tr>
                                                <b-th class="th_style size_th text-center">Fecha y hora</b-th>
                                                <b-th class="th_style size_th text-center">Indicaciones de cuidados</b-th>
                                                <b-th v-if="!state.oldExp"
                                                    class="th_style size_th text-center">Acciones</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody v-for="(dto, index) of infoIndicacion.indicacion_medica_cuidados"
                                            :key="index">
                                            <b-tr>
                                                <b-td class="border_table">
                                                    <span class="size-span">{{ sendFecha(dto.fecha) }} {{ dto.hora }}</span>
                                                </b-td>
                                                <b-td class="border_table">
                                                    <span class="size-span">{{ dto.indicacion }}</span>
                                                </b-td>
                                                <b-td v-if="!state.oldExp" class="border_table">
                                                    <b-row align-h="center">
                                                        <b-col cols="12" sm="12" md="3" lg="3">
                                                            <div @click="editCuidado(dto)" class="btnAccion" title="Editar">
                                                                <span class="fa fa-edit fa-lg my-auto icon_color"></span>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                class="justify-content-between mt-4" id="scrolling4">
                                <b-col cols="4">
                                    <p v-if="headerCuidado" class="txt_secondary text-uppercase py-2"><strong>Añadir
                                            actualización</strong></p>
                                    <p v-if="updatingCuidado" class="txt_secondary text-uppercase py-2"><strong>Actualizar
                                            cuidado</strong></p>
                                </b-col>
                                <b-col v-if="headerCuidado" cols="4">
                                    <div class="float-right">
                                        <button @click="addActualizacion('cuidados')"
                                            class="btnAddUpdate border-0 bg_secondary txt_white">
                                            <i class="fa fa-plus fa-lg mx-auto my-auto" />
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)"
                                v-if="infoIndicacion.indicacion_medica_cuidados && ((formCuidados && !hayDta) || (hayDta && !(infoIndicacion.indicacion_medica_cuidados.length > 0)) || ((infoIndicacion.indicacion_medica_cuidados.length > 0) && formCuidados))">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <b-table-simple class="table-sm mt-3">
                                        <b-thead>
                                            <b-tr>
                                                <b-th v-if="dateCuidado" class="th_style size_th">Fecha y hora</b-th>
                                                <b-th class="th_style size_th">Indicaciones de cuidados</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-td v-if="dateCuidado" class="border_table">
                                                    <b-form-input disabled v-model="cuidados.fecha" type="date" name="fecha"
                                                        autocomplete="off" class="border-0 mb-1" size="sm"
                                                        placeholder="DD/MM/AAAA" />
                                                    <b-form-input disabled v-model="cuidados.hora" type="time"
                                                        min="00:00:01" max="23:59:59" name="hora" autocomplete="off"
                                                        class="border-0" size="sm" />
                                                </b-td>
                                                <b-td class="border_table">
                                                    <b-form-textarea v-model="cuidados.indicacion" :state="valCuiadosInd"
                                                        name="indicacionCuidados" autocomplete="off" class="border-0"
                                                        size="sm" no-resize rows="3" max-rows="3" />
                                                </b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                            <b-row v-if="errorCuidados" class="mt-3">
                                <b-col cols="12">
                                    <div class="contenedor-de-errores pt-3 pb-1">
                                        <ul class="lista-errores px-2">
                                            <li v-for="(err, index) of erroresCuidados" :key="index"><i
                                                    class="fa fa-warning px-1" />| {{ err[0] }} <br>
                                            </li>
                                        </ul>
                                    </div>
                                </b-col>
                            </b-row>
                            <div :hidden="(state.oldExp || infoIndicacion.firma.is_firmado == 1 || isEgresado)">
                                <div v-if="(infoIndicacion.indicacion_medica_cuidados && formCuidados && saveCuidado && hayDta) || (infoIndicacion.indicacion_medica_cuidados && !infoIndicacion.indicacion_medica_cuidados.length > 0) && hayDta"
                                    class="d-flex justify-content-end mb-4">
                                    <b-col cols="12" sm="12" md="5" lg="5">
                                        <b-overlay :show="busy" rounded opacity="0.6" spinner-small
                                            spinner-variant="primary">
                                            <button id="scrollingSave4" :disabled="busyCuidados"
                                                class="btn_formluario border-0 btn-block py-2 txt_white"
                                                @click="saveCuidados()">Guardar</button>
                                        </b-overlay>
                                    </b-col>
                                </div>
                            </div>
                            <div v-if="updatingCuidado" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <b-overlay :show="busyUpdCuidado" rounded opacity="0.6" spinner-small
                                        spinner-variant="primary">
                                        <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                            @click="updateCuidado()" :disabled="busyUpdCuidado">Actualizar</button>
                                    </b-overlay>
                                </b-col>
                            </div>
                            <div v-if="updatingCuidado" class="d-flex justify-content-end mb-4">
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <button class="btn_formluario border-0 btn-block py-2 txt_white"
                                        @click="cancelUpdCuidado()">Cancelar</button>
                                </b-col>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <hr>

            <div v-if="infoIndicacion.firma.is_firmado == 1" style="margin-top: 16px;" class="mb-4">
                <span class="txt_secondary">Nota firmada.</span><br>
                <span class="txt_secondary size-span">Médico: Emmanuel Gomez Miron</span> <br>
                <span class="txt_secondary size-span">Fecha y hora: {{ infoIndicacion.firma.fecha_firmado }}</span>
            </div>

            <div v-if="(!hayDta && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button :disabled="busy" class="btn_formluario border-0 btn-block py-2 txt_white"
                            @click="registrarIndicaciones()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="(infoIndicacion.firma.is_firmado == 0 && !state.oldExp && idResponsable === usuario.id && hayDta && updating && !isEgresado)"
                class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y
                            GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div class="sticky-container"
                v-if="(idPacienteSeleccionado != '' && !openSuccess && !openError && !openFirmaPsw && !regNewExp && !pswUpdate && !loading && !addMedicamento)">
                <ul class="sticky">
                    <li v-if="(hayDta && updating && !state.oldExp && infoIndicacion.firma.is_firmado == 0 && !isEgresado)"
                        @click="editar()">
                        <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                        <p>
                            <a>Editar</a>
                        </p>
                    </li>
                    <li @click="imprimir()" v-if="hayDta && updating">
                        <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                        <p>
                            <a>Imprimir</a>
                        </p>
                    </li>
                </ul>
            </div>

            <Success :abrir="openSuccess" @cerrarModal="cerrarModal()" />
            <Error :abrir="openError" @cerrarModalError="cerrarModalError()" />
            <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
            <NuevoExpediente :openRegExp="regNewExp" />
            <FirmaPswUpdate :abrir="pswUpdate" />
            <Loading :show="loading" />
            <Medicamento :modalMedicamento="addMedicamento" @savedMedicamento="savedMedicamento()" @closeAddMedicamento="closeAddMedicamento()" />
        </b-container>
    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from 'pdf-merger-js';
import router from '../../router';
import Loading from '@/components/Loader/Loading.vue'
import Foto from '@/components/Paciente/Foto.vue'
import FirmaPassword from '@/components/Modals/FirmaPassword.vue'
import Success from '@/components/Modals/Success.vue'
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue'
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import Medicamento from '@/components/Catalogos/Registrar/Medicamento.vue'
import Error from '@/components/Modals/Error.vue'
import CryptoJS from 'crypto-js'
import { getCatalogo } from '../../helpers/catalogos'
import { url } from '../../../settings'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'

const urlp = `${url}/api/pacientes`

export default {
    name: 'Indicaciones',
    components: { Datos, Foto, FirmaPassword, Success, Error, NuevoExpediente, FirmaPswUpdate, Loading, Medicamento },

    data: () => ({
        changeDta: null,
        isEgresado: false,
        usuario: {
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable: null,
        firstSave: false,
        state: {
            idExpediente: "",
            idPaciente: "",
            oldExp: false,
        },
        allExp: [],

        addMedicamento: false,
        /* Actualizar datos de indicacion */
        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        pswUpdate: false,

        loading: true,

        /* Update Dieta */
        dateDieta: true,
        headerDieta: true,
        btnSave: true,
        busyUpdDieta: false,
        updatingDieta: false,

        /* Update soluciones */
        dateSoluciones: true,
        headerSoluciones: true,
        saveSolution: true,
        busyUpdSoluciones: false,
        updatingSoluciones: false,

        /* Update medicamentos */
        dateMedicamentos: true,
        headerMedicamentos: true,
        saveDrug: true,
        busyUpdMedicamentos: false,
        updatingMedicamentos: false,

        /* Update cuidados */
        dateCuidado: true,
        headerCuidado: true,
        saveCuidado: true,
        busyUpdCuidado: false,
        updatingCuidado: false,

        /* Botones de guardar para cada indicación */
        error: false,
        errores: [],
        errorDieta: false,
        erroresDieta: [],
        errorSoluciones: false,
        erroresSoluciones: [],
        errorMedicamentos: false,
        erroresMedicamentos: [],
        errorCuidados: false,
        erroresCuidados: [],
        hayDta: false,
        btnSaveDieta: false,
        busyDieta: false,
        btnSaveSoluciones: false,
        busySol: false,
        btnSaveMedic: false,
        busyMedic: false,
        btnSaveCuidados: false,
        busyCuidados: false,
        cargado: false,

        regNewExp: false,
        info: false,
        disabled: true,
        formDieta: false,
        formSoluciones: false,
        formMedicamentos: false,
        formCuidados: false,

        formDietaAngle: false,
        formSolucionesAngle: false,
        formMedicamentosAngle: false,
        formCuidadosAngle: false,
        currentAngle: "",

        idPacienteSeleccionado: "",
        cuarto: "",
        pacientes: [{}],
        nombres: [],
        fecha: '',

        infoIndicacion: {
            password: "",
            alergiaMed: false,
            otrasAlergias: false,
            /* Datos requeridos */
            expediente_id: '',
            no_indicacion: "",
            alergias_medicamentos: '',
            otras_alergias: '',
            firma: {
                fecha_firmado: null,
                firmado_por: null,
                is_firmado: 0
            },
            indicacion_medica_dietas: [],
            indicacion_medica_cuidados: [],
            indicacion_medica_medicamentos: [],
            indicacion_medica_soluciones: [],
        },

        dieta: {
            indicacion_medica_id: '',
            dieta_id: null,
            fecha: '',
            hora: '',
            indicacion: '',
            id: ''
        },
        cdieta: {
            indicacion_medica_id: '',
            dieta_id: null,
            fecha: "",
            hora: "",
            indicacion: ''
        },

        soluciones: {
            indicacion_medica_id: '',
            tipo_solucion_id: null,
            volumen_solucion_id: null,
            tiempo_aplicacion: '',
            fecha: '',
            hora: '',
            indicacion: '',
            id: ''
        },

        csoluciones: {
            indicacion_medica_id: '',
            tipo_solucion_id: null,
            volumen_solucion_id: null,
            tiempo_aplicacion: '',
            fecha: '',
            hora: '',
            indicacion: ''
        },

        medicamentos: {
            indicacion_medica_id: '',
            medicamento_id: null,
            presentacion: '',
            dosis: '',
            via_administracion_id: null,
            tiempo_administracion: '',
            frecuencia: '',
            fecha: '',
            hora: '',
            indicacion: '',
            id: ''
        },
        cmedicamentos: {
            indicacion_medica_id: '',
            medicamento_id: null,
            presentacion: '',
            dosis: '',
            via_administracion_id: null,
            tiempo_administracion: '',
            frecuencia: '',
            fecha: '',
            hora: '',
            indicacion: ''
        },

        cuidados: {
            indicacion_medica_id: '',
            fecha: '',
            hora: '',
            indicacion: '',
            id: ''
        },
        ccuidados: {
            indicacion_medica_id: '',
            fecha: '',
            hora: '',
            indicacion: ''
        },

        /* Catálogos */
        dietas: [],
        tipoSolucion: [],
        volSolucion: [],
        farmacos: [],
        viaAdministracion: [],

        datosPaciente: {
            password: "",
            edad: "",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id: null,
                nombre: "",
            },
            municipio: {
                id: null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero: {
                id: null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        busy: false,

        btnGuardar: false,
        btnFirmar: true,

        openFirmaPsw: false,
        openSuccess: false,
        openError: false,
        datosExpediente: {}
    }),

    computed: {
        validation() {
            return this.idPacienteSeleccionado.length != 0
        },

        valNoIndicacion() {
            return this.infoIndicacion.no_indicacion != '' && this.infoIndicacion.no_indicacion != null
        },

        valAlergiaMedic() {
            try {
                return this.infoIndicacion.alergias_medicamentos.length > 0
            } catch (error) {
                return false
            }
        },

        valOtrasAlergias() {
            try {
                return this.infoIndicacion.otras_alergias.length > 0
            } catch (error) {
                return false
            }
        },

        valDieata() {
            return this.dieta.dieta_id != null
        },

        valIndicacion() {
            return this.dieta.indicacion.length > 4
        },

        valTipSolucion() {
            return this.soluciones.tipo_solucion_id != null
        },

        valVolSolucion() {
            return this.soluciones.volumen_solucion_id != null
        },

        valTimeAplicacion() {
            return this.soluciones.tiempo_aplicacion.length > 2
        },

        valSolIndicaciones() {
            return this.soluciones.indicacion.length > 4
        },

        valMedicamentos() {
            return this.medicamentos.medicamento_id != null
        },

        valPresentacion() {
            return this.medicamentos.presentacion.length > 4
        },

        valDosis() {
            return this.medicamentos.dosis.length >= 2
        },

        valViaAdministracion() {
            return this.medicamentos.via_administracion_id != null
        },

        valTimeAdmin() {
            return this.medicamentos.tiempo_administracion.length > 4
        },

        valFrecuencia() {
            return this.medicamentos.frecuencia.length > 4
        },

        valMedIndicaciones() {
            return this.medicamentos.indicacion.length > 4
        },

        valCuiadosInd() {
            return this.cuidados.indicacion.length >= 5
        }

    },

    methods: {
        verNotaDeEgreso() {
            this.$emit('toEgreso')
        },

        changeExp(id) {
            this.loading = true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp = false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta = currentExp.id
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/` + this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp = data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es" + error);
            }
        },

        modalAddMedicamento() {
            this.addMedicamento = true
        },

        addActualizacion(v) {
            switch (v) {
                case 'dieta':
                    console.log(this.infoIndicacion.indicacion_medica_dietas);
                    if (this.errorDieta) {
                        this.errorDieta = false
                        this.erroresDieta = {}
                    }
                    this.formDieta = !this.formDieta
                    setTimeout(() => {
                        try {
                            var containerSave1 = this.$el.querySelector("#scrollingSave1");
                            containerSave1.scrollIntoView({ inline: "start", behavior: "smooth" })
                        } catch (error) {
                            console.log(error);
                            console.log("");
                        }
                    }, 400);
                    break;
                case 'medicamentos':
                    if (this.errorMedicamentos) {
                        this.errorMedicamentos = false
                        this.erroresMedicamentos = {}
                    }
                    this.formMedicamentos = !this.formMedicamentos
                    setTimeout(() => {
                        try {
                            var containerSave3 = this.$el.querySelector("#scrollingSave3");
                            containerSave3.scrollIntoView({ inline: "start", behavior: "smooth" })
                        } catch (error) {
                            console.log("");
                        }
                    }, 400);
                    break;
                case 'soluciones':
                    if (this.errorSoluciones) {
                        this.errorSoluciones = false
                        this.erroresSoluciones = {}
                    }
                    this.formSoluciones = !this.formSoluciones
                    setTimeout(() => {
                        try {
                            var containerSave2 = this.$el.querySelector("#scrollingSave2");
                            containerSave2.scrollIntoView({ inline: "start", behavior: "smooth" })
                        } catch (error) {
                            console.log("");
                        }
                    }, 400);
                    break;
                case 'cuidados':
                    if (this.errorCuidados) {
                        this.errorCuidados = false
                        this.erroresCuidados = {}
                    }
                    this.formCuidados = !this.formCuidados
                    setTimeout(() => {
                        try {
                            var containerSave4 = this.$el.querySelector("#scrollingSave4");
                            containerSave4.scrollIntoView({ inline: "start", behavior: "smooth" })
                        } catch (error) {
                            console.log("");
                        }
                    }, 400);
                    break;

                default:
                    break;
            }
        },
        // CAMBIAR FLECHA DE LOS ACORDEON
        scrollingAcordeon(v) {
            switch (v) {
                case 1:
                    setTimeout(() => {
                        var container1 = this.$el.querySelector("#scrolling1");
                        container1.scrollIntoView({ inline: "start", behavior: "smooth" })
                    }, 400);
                    break;
                case 2:
                    setTimeout(() => {
                        var container2 = this.$el.querySelector("#scrolling2");
                        container2.scrollIntoView({ inline: "start", behavior: "smooth" })
                    }, 400);
                    break;
                case 3:
                    setTimeout(() => {
                        var container3 = this.$el.querySelector("#scrolling3");
                        container3.scrollIntoView({ inline: "start", behavior: "smooth" })
                    }, 400);
                    break;
                case 4:
                    setTimeout(() => {
                        var container4 = this.$el.querySelector("#scrolling4");
                        container4.scrollIntoView({ behavior: "smooth" })
                    }, 400);

                    break;

                default:
                    break;
            }
        },
        changeIcon(v) {
            switch (v) {
                case 'dieta':
                    if (this.currentAngle === v) {
                        this.formDietaAngle = false
                        this.currentAngle = ""
                    } else {
                        this.formDietaAngle = true
                        this.formSolucionesAngle = false
                        this.formMedicamentosAngle = false
                        this.formCuidadosAngle = false
                        this.currentAngle = v
                        this.scrollingAcordeon(1)
                    }
                    break;
                case 'medicamento':
                    if (this.currentAngle === v) {
                        this.formMedicamentosAngle = false
                        this.currentAngle = ""
                    } else {
                        this.formDietaAngle = false
                        this.formSolucionesAngle = false
                        this.formMedicamentosAngle = true
                        this.formCuidadosAngle = false
                        this.currentAngle = v
                        this.scrollingAcordeon(3)
                    }
                    break;
                case 'soluciones':
                    if (this.currentAngle === v) {
                        this.currentAngle = ""
                        this.formSolucionesAngle = false
                    } else {
                        this.formMedicamentosAngle = true
                        this.formDietaAngle = false
                        this.formSolucionesAngle = true
                        this.formMedicamentosAngle = false
                        this.formCuidadosAngle = false
                        this.currentAngle = v
                        this.scrollingAcordeon(2)
                    }
                    break;
                case 'cuidados':
                    if (this.currentAngle === v) {
                        this.currentAngle = ""
                        this.formCuidadosAngle = false
                    } else {
                        this.formDietaAngle = false
                        this.formSolucionesAngle = false
                        this.formMedicamentosAngle = false
                        this.formCuidadosAngle = true
                        this.currentAngle = v
                        this.scrollingAcordeon(4)
                    }
                    break;

                default:
                    break;
            }
        },
        /* Actualizar indicacion médica */
        cancelEdit() {
            this.setData()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            // this.pswUpdate = true
            this.pswUpdate = false
            // this.infoIndicacion.password = psw
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.disableInput = false
            this.updating = false
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.error = false
            this.errores = []
            this.busyUpdate = true
            if (this.verificar()) {
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas/${this.infoIndicacion.expediente_id}`, {
                        method: 'put',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.infoIndicacion),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        // this.obtenerDatosIndicaciones()
                        this.setData()
                        this.openSuccess = true
                        this.updating = true
                        this.disableInput = true
                        this.busyUpdate = false
                        this.actualizar = false
                        this.cancelar = false
                    } else {
                        this.openError = true
                        this.error = true
                        this.errores = err
                        this.busyUpdate = false
                        if (this.errores.password) {
                            this.btnFirmaUpdate = true
                            this.disableInput = false
                            this.actualizar = false
                            this.busyUpdate = false
                        } else {
                            this.busyUpdate = false
                            this.actualizar = true
                            this.cancelar = true
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.error = false
                this.busy = false
            }
        },

        /* Actualizar Dieta */
        clearUpdDieta() {
            this.formDieta = false
            this.updatingDieta = false
            this.dateDieta = true
            this.headerDieta = true
            this.btnSave = true

            this.dieta.id = '',
                this.dieta.dieta_id = null,
                this.dieta.indicacion = ''
        },

        cancelUpdDieta() {
            this.clearUpdDieta()
        },

        editDieta(dieta) {
            this.formDieta = true
            this.headerDieta = false
            this.updatingDieta = true
            this.btnSave = false
            this.dateDieta = false

            this.dieta.dieta_id = dieta.dieta_id
            this.dieta.indicacion = dieta.indicacion
            this.dieta.id = dieta.id
        },

        async updateDieta() {
            if (this.verificarDieta()) {
                this.busyUpdDieta = true
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_dietas/${this.dieta.id}`, {
                        method: 'put',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.dieta),
                    });
                    const data = res.json();
                    var promise = Promise.resolve(data);
                    Vue.prototype.$error500 = res.status === 500
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.dieta = this.cdieta
                        this.clearUpdDieta()
                        this.obtenerDatosIndicaciones()
                        this.openSuccess = true
                        this.busyUpdDieta = false
                        this.erroresDieta = ""
                        this.errorDieta = false
                    } else {
                        this.erroresDieta = err
                        this.openError = true
                        this.busyUpdDieta = false
                        this.errorDieta = true
                    }
                } catch (error) {
                    console.log(error)
                }
            } else {
                this.busyUpdDieta = false
            }
        },

        /* Actualizar solucion */
        clearUpdSoluciones() {
            this.formSoluciones = false
            this.saveSolution = true
            this.updatingSoluciones = false
            this.dateSoluciones = true
            this.headerSoluciones = true

            this.soluciones.tipo_solucion_id = null
            this.soluciones.volumen_solucion_id = null
            this.soluciones.tiempo_aplicacion = ''
            this.soluciones.indicacion = ''
            this.soluciones.id = ''
        },

        cancelUpdSoluciones() {
            this.clearUpdSoluciones()
        },

        editSoluciones(solucion) {
            this.formSoluciones = true
            this.headerSoluciones = false
            this.updatingSoluciones = true
            this.saveSolution = false
            this.dateSoluciones = false

            this.soluciones.tipo_solucion_id = solucion.tipo_solucion_id
            this.soluciones.volumen_solucion_id = solucion.volumen_solucion_id
            this.soluciones.tiempo_aplicacion = solucion.tiempo_aplicacion
            this.soluciones.indicacion = solucion.indicacion
            this.soluciones.id = solucion.id
        },

        async updateSoluciones() {
            if (this.verificarSoluciones()) {
                this.busyUpdSoluciones = true
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_soluciones/${this.soluciones.id}`, {
                        method: 'put',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.soluciones),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.soluciones = this.csoluciones
                        this.clearUpdSoluciones()
                        this.obtenerDatosIndicaciones()
                        this.openSuccess = true
                        this.busyUpdSoluciones = false
                        this.erroresSoluciones = ""
                        this.errorSoluciones = false
                    } else {
                        this.erroresSoluciones = err
                        this.openError = true
                        this.busyUpdSoluciones = false
                        this.errorSoluciones = true
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.busyUpdSoluciones = false
            }
        },

        /* Actualizar solucion */
        clearUpdMedicamentos() {
            this.formMedicamentos = false
            this.saveDrug = true
            this.updatingMedicamentos = false
            this.dateMedicamentos = true
            this.headerMedicamentos = true

            this.medicamentos.medicamento_id = null
            this.medicamentos.presentacion = ''
            this.medicamentos.dosis = ''
            this.medicamentos.via_administracion_id = null
            this.medicamentos.tiempo_administracion = ''
            this.medicamentos.frecuencia = ''
            this.medicamentos.indicacion = ''
            this.medicamentos.id = ''
        },

        cancelUpdMedicamentos() {
            this.clearUpdMedicamentos()
        },

        editMedicamentos(medicamento) {
            this.formMedicamentos = true
            this.headerMedicamentos = false
            this.updatingMedicamentos = true
            this.saveDrug = false
            this.dateMedicamentos = false

            this.medicamentos.medicamento_id = medicamento.medicamento_id
            this.medicamentos.presentacion = medicamento.presentacion
            this.medicamentos.dosis = medicamento.dosis
            this.medicamentos.via_administracion_id = medicamento.via_administracion_id
            this.medicamentos.tiempo_administracion = medicamento.tiempo_administracion
            this.medicamentos.frecuencia = medicamento.frecuencia
            this.medicamentos.indicacion = medicamento.indicacion
            this.medicamentos.id = medicamento.id
        },

        async updateMedicamentos() {
            if (this.verificarMedicamentos()) {
                this.busyUpdMedicamentos = true
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_medicamentos/${this.medicamentos.id}`, {
                        method: 'put',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.medicamentos),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.medicamentos = this.cmedicamentos
                        this.clearUpdMedicamentos()
                        this.obtenerDatosIndicaciones()
                        this.openSuccess = true
                        this.busyUpdMedicamentos = false
                        this.erroresMedicamentos = ""
                        this.errorMedicamentos = false
                    } else {
                        this.erroresMedicamentos = err
                        this.openError = true
                        this.busyUpdMedicamentos = false
                        this.errorMedicamentos = true
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.busyUpdMedicamentos = false
            }
        },

        /* Actualizar cuidados */
        clearUpdCuidado() {
            this.formCuidados = false
            this.saveCuidado = true
            this.updatingCuidado = false
            this.dateCuidado = true
            this.headerCuidado = true

            this.cuidados.indicacion = ''
            this.cuidados.id = ''
        },

        cancelUpdCuidado() {
            this.clearUpdCuidado()
        },

        editCuidado(cuidado) {
            console.log({ cuidado: cuidado });
            this.formCuidados = true
            this.headerCuidado = false
            this.updatingCuidado = true
            this.saveCuidado = false
            this.dateCuidado = false

            this.cuidados.indicacion = cuidado.indicacion
            this.cuidados.id = cuidado.id
        },

        async updateCuidado() {
            if (this.verificarCuidados()) {
                this.busyUpdCuidado = true
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_cuidados/${this.cuidados.id}`, {
                        method: 'put',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.cuidados),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.cuidados = this.ccuidados
                        this.clearUpdCuidado()
                        this.obtenerDatosIndicaciones()
                        this.openSuccess = true
                        this.busyUpdCuidado = false
                        this.erroresCuidados = ""
                        this.errorCuidados = false
                    } else {
                        this.busyUpdCuidado = false
                        this.erroresCuidados = err
                        this.openError = true
                        this.errorCuidados = true
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                this.busyUpdCuidado = false
            }
        },

        sendFecha(fecha) {
            const date = new Date(fecha).toLocaleDateString('es-mx', { weekday: "short", year: "numeric", month: "short", day: "numeric" })
            return date
        },

        verificar() {
            this.errores = {}
            if (this.infoIndicacion.otrasAlergias && this.infoIndicacion.otras_alergias == "" || this.infoIndicacion.otrasAlergias && this.infoIndicacion.otras_alergias == null) {
                this.errores.otras_alergias = ["El campo 'otras alergias' es requerido"]
                this.error = true
                console.log(".");
            }

            if (this.infoIndicacion.alergiaMed && this.infoIndicacion.alergias_medicamentos == "" || this.infoIndicacion.alergiaMed && this.infoIndicacion.alergias_medicamentos == null) {
                this.errores.alergias_medicamentos = ["El campo 'Alergias a medicamentos' es requerido"]
                this.error = true
                console.log(".");
            }
            // if (this.infoIndicacion.no_indicacion==""||this.infoIndicacion.no_indicacion==null) {
            //     this.errores.no=["El campo 'Número de indicación' es requerido"]
            //     this.error=true
            //     console.log(".");
            // }
            console.log(this.errores);
            if (this.error) {
                console.log(".");
                this.openError = true
                return false
            } else {
                return true
            }
        },
        verificarDieta() {
            this.erroresDieta = {}

            if (this.formDieta) {
                if (this.dieta.dieta_id == null) {
                    this.erroresDieta.dieta_id = ["El campo 'Dieta' es requerido"]
                    this.errorDieta = true
                }

                if (this.dieta.fecha == "") {
                    this.erroresDieta.fecha = ["El campo 'Fecha' es requerido"]
                    this.errorDieta = true
                }

                if (this.dieta.hora == "") {
                    this.erroresDieta.hora = ["El campo 'Hora' es requerido"]
                    this.errorDieta = true
                }

                if (this.dieta.indicacion == "") {
                    this.erroresDieta.indicacion = ["El campo 'Indicacion' es requerido"]
                    this.errorDieta = true
                } else if (this.dieta.indicacion.length < 5) {
                    this.erroresDieta.indicacion = ["El campo Indicación debe contener al menos 5 caracteres."]
                    this.errorDieta = true
                }

                console.log(this.erroresDieta);
                console.log(this.errorDieta);

                if (this.errorDieta) {
                    this.openError = true
                    return false
                } else {
                    return true
                }
            } else {
                console.log("error Dieta");
                return true
            }

        },
        verificarSoluciones() {
            this.erroresSoluciones = {}

            if (this.formSoluciones) {
                if (this.soluciones.tipo_solucion_id == null) {
                    this.erroresSoluciones.dieta_id = ["El campo 'Tipo de solución' es requerido"]
                    this.errorSoluciones = true
                }
                if (this.soluciones.volumen_solucion_id == null) {
                    this.erroresSoluciones.volumen_solucion_id = ["El campo 'Volumen de soluciónn' es requerido"]
                    this.errorSoluciones = true
                }
                if (this.soluciones.tiempo_aplicacion == "") {
                    this.erroresSoluciones.tiempo_aplicacion = ["El campo 'Tiempo de aplicación' es requerido"]
                    this.errorSoluciones = true
                } else if (this.soluciones.tiempo_aplicacion.length < 3) {
                    this.erroresSoluciones.tiempo_aplicacion = ["El campo Tiempo de aplicación debe contener al menos 3 caracteres. "]
                    this.errorSoluciones = true
                }
                if (this.soluciones.fecha == "") {
                    this.erroresSoluciones.dieta_id = ["El campo 'Fecha' es requerido"]
                    this.errorSoluciones = true
                }
                if (this.soluciones.hora == "") {
                    this.erroresSoluciones.hora = ["El campo 'Hora' es requerido"]
                    this.errorSoluciones = true
                }
                if (this.soluciones.indicacion == "") {
                    this.erroresSoluciones.indicacion = ["El campo 'Indicaciones de solución' es requerido"]
                    this.errorSoluciones = true
                } else if (this.soluciones.indicacion.length < 5) {
                    this.erroresSoluciones.indicacion = ["El campo Indicación debe contener al menos 5 caracteres"]
                    this.errorSoluciones = true
                }
                if (this.errorSoluciones) {
                    this.openError = true
                    return false
                } else {
                    console.log("error soluciones");
                    return true
                }
            } else {
                return true
            }

        },
        verificarMedicamentos() {
            this.erroresMedicamentos = {}
            if (this.formMedicamentos) {
                if (this.medicamentos.medicamento_id == null) {
                    this.erroresMedicamentos.medicamento_id = ["El campo 'Nombre de medicamento' es requerido"]
                    this.errorMedicamentos = true
                }
                if (this.medicamentos.dosis == "") {
                    this.erroresMedicamentos.dosis = ["El campo 'Dosis' es requerido"]
                    this.errorMedicamentos = true
                } else if (this.medicamentos.dosis.length < 2) {
                    this.erroresMedicamentos.dosis = ["El campo Dosis debe contener al menos 2 caracteres"]
                    this.errorMedicamentos = true
                }
                if (this.medicamentos.presentacion == "") {
                    this.erroresMedicamentos.presentacion = ["El campo 'Presentación' es requerido"]
                    this.errorMedicamentos = true
                } else if (this.medicamentos.presentacion.length < 5) {
                    this.erroresMedicamentos.presentacion = ["El campo Presentación debe contener al menos 5 caracteres"]
                    this.errorMedicamentos = true
                }

                if (this.medicamentos.via_administracion_id == null) {
                    this.erroresMedicamentos.via_administracion_id = ["El campo 'Vía de administración' es requerido"]
                    this.errorMedicamentos = true
                }
                if (this.medicamentos.tiempo_administracion == "") {
                    this.erroresMedicamentos.tiempo_administracion = ["El campo 'Tiempo de administración' es requerido"]
                    this.errorMedicamentos = true
                } else if (this.medicamentos.tiempo_administracion.length < 5) {
                    this.erroresMedicamentos.tiempo_administracion = ["El campo Tiempo administración debe contener al menos 5 caracteres"]
                    this.errorMedicamentos = true
                }

                if (this.medicamentos.frecuencia == "") {
                    this.erroresMedicamentos.frecuencia = ["El campo 'Frecuencia' es requerido"]
                    this.errorMedicamentos = true
                } else if (this.medicamentos.frecuencia.length < 5) {
                    this.erroresMedicamentos.frecuencia = ["El campo Frecuencia debe contener al menos 5 caracteres"]
                    this.errorMedicamentos = true
                }

                if (this.medicamentos.fecha == "") {
                    this.erroresMedicamentos.fecha = ["El campo 'Fecha' es requerido"]
                    this.errorMedicamentos = true
                }
                if (this.medicamentos.hora == "") {
                    this.erroresMedicamentos.hora = ["El campo 'Hora' es requerido"]
                    this.errorMedicamentos = true
                }
                if (this.medicamentos.indicacion == "") {
                    this.erroresMedicamentos.indicacion = ["El campo 'Indicaciones de medicamento' es requerido"]
                    this.errorMedicamentos = true
                } else if (this.medicamentos.indicacion.length < 5) {
                    this.erroresMedicamentos.indicacion = ["El campo Indicación debe contener al menos 5 caracteres"]
                    this.errorMedicamentos = true
                }

                if (this.errorMedicamentos) {
                    this.openError = true
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        verificarCuidados() {
            this.erroresCuidados = {}
            this.errorCuidados = false
            if (this.formCuidados) {
                if (this.cuidados.indicacion.length == "") {
                    this.erroresCuidados.indicacion = ["El campo 'Indicaciones de cuidados' es requerido"]
                    this.errorCuidados = true
                } else if (this.cuidados.indicacion.length < 5) {
                    this.erroresCuidados.indicacion = ["El campo Indicación debe contener al menos 5 caracteres"]
                    this.errorCuidados = true
                }
                if (this.cuidados.fecha == "") {
                    this.erroresCuidados.fecha = ["El campo 'Fecha' es requerido"]
                    this.errorCuidados = true
                }
                if (this.cuidados.hora == "") {
                    this.erroresCuidados.hora = ["El campo 'Hora' es requerido"]
                    this.errorCuidados = true
                }
                if (this.errorCuidados) {
                    this.openError = true
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },

        saveDieta() {
            this.addDieta()
        },

        saveSoluciones() {
            this.addSoluciones()
        },

        saveMedicamentos() {
            this.addMedicamentos()
        },

        saveCuidados() {
            this.addCuidados()
        },

        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true

            let urlim = `${url}/api/indicaciones_medicas/descargar/${this.infoIndicacion.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                }
            }).then(function (resp) {
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function (blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 500);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)
            })
        },
        async downloadPDF(blob) {
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.no_expediente + " Indicaciones médicas");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        setData(first) {
            this.getCatalogos();
            this.getMedicamentos();
            this.getFechaHora();
            this.obtenerPacientes();

            const pacienteEncrypt = localStorage.expedienteEncrypt
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente)
            this.changeDta = paciente.id
            try {
                if (paciente.tiene_alergias) {
                    this.infoIndicacion.otrasAlergias = true
                    this.infoIndicacion.otras_alergias = paciente.alergias;
                }
            } catch (error) {
                this.infoIndicacion.otrasAlergias = false
                this.infoIndicacion.otras_alergias = ""
            }

            console.log(paciente);

            // console.log(paciente);
            this.infoIndicacion.no_indicacion = paciente.no

            this.idResponsable = paciente.medico.id

            this.state.idExpediente = paciente.id
            this.state.idPaciente = paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id != paciente.id) {
                this.state.oldExp = true
            } else {
                this.state.oldExp = false
            }
            if (paciente.paciente.telefono == null) {
                paciente.paciente.telefono = "-----"
            }
            if (paciente.paciente.email == null) {
                paciente.paciente.email = "-----"
            }
            this.idPacienteSeleccionado = paciente.paciente.id
            this.infoIndicacion.expediente_id = paciente.id;
            this.datosPaciente = paciente.paciente
            this.cuarto = paciente.no_habitacion
            this.datosExpediente = paciente
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad = yyyy - year.getFullYear();
            this.btnGuardar = false;
            this.btnFirmar = true;
            if (first) {
                this.obtenerDatosIndicaciones(true)
            } else {
                this.obtenerDatosIndicaciones()
            }
            this.allExpedientes()
            // this.regAct()
        },

        firmar() {
            this.openFirmaPsw = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if (/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        getFechaHora() {
            var date = new Date;
            var minutes = ("0" + date.getMinutes()).slice(-2);
            var hour = ("0" + date.getHours()).slice(-2);
            var sec = ("0" + date.getSeconds()).slice(-2);
            this.dieta.hora = "" + hour + ":" + minutes + ":" + sec
            this.soluciones.hora = "" + hour + ":" + minutes + ":" + sec
            this.medicamentos.hora = "" + hour + ":" + minutes + ":" + sec
            this.cuidados.hora = "" + hour + ":" + minutes + ":" + sec

            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() + 1)).slice(-2);
            var yyyy = today.getFullYear();
            this.dieta.fecha = yyyy + '-' + mm + '-' + dd;
            this.soluciones.fecha = yyyy + '-' + mm + '-' + dd;
            this.medicamentos.fecha = yyyy + '-' + mm + '-' + dd;
            this.cuidados.fecha = yyyy + '-' + mm + '-' + dd;
            setTimeout(() => {
                this.getFechaHora()
            }, 1000);
        },

        dtaExpediente() {
            try {
                const pacienteEncrypt = localStorage.expedienteEncrypt
                const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
                const paciente = JSON.parse(expediente)
                this.idPacienteSeleccionado = paciente.paciente.id
                this.cuarto = paciente.no_habitacion
                this.getExpediente("getData")
            } catch (error) {
                console.log(error);
            }
        },

        async addDieta(first) {
            this.dieta.indicacion_medica_id = this.infoIndicacion.id
            this.busy = true
            this.errorDieta = false
            this.erroresDieta = []
            if (this.verificarDieta()) {
                console.log("tabien");
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_dietas/${this.infoIndicacion.id}`, {
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.dieta),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    console.log(data);
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.busy = false
                        this.erroresDieta = ""
                        this.errorDieta = false
                        this.dieta = this.cdieta
                        this.formDieta = false
                        if (this.hayDta) {
                            this.openSuccess = true
                            this.obtenerDatosIndicaciones()
                        }
                        this.clearUpdDieta()
                        this.scrollingAcordeon(1)
                    } else {
                        this.erroresDieta = err
                        if (!first) {
                            this.openError = true
                        }
                        this.errorDieta = true
                        this.busy = false
                    }

                    if (this.errores.password) {
                        console.log("te equivocaste");
                        this.btnGuardar = false;
                        this.btnFirmar = true;
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("tamal");
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy = false
            }
        },

        async addSoluciones(first) {
            this.soluciones.indicacion_medica_id = this.infoIndicacion.id
            this.busy = true
            this.errorSoluciones = false
            this.erroresSoluciones = []
            if (this.verificarSoluciones()) {
                console.log("tabien");
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_soluciones/${this.infoIndicacion.id}`, {
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.soluciones),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.busy = false
                        this.erroresSoluciones = ""
                        this.errorSoluciones = false
                        this.soluciones = this.csoluciones
                        this.formSoluciones = false
                        if (this.hayDta) {
                            this.openSuccess = true
                            this.obtenerDatosIndicaciones()
                        }
                        this.clearUpdSoluciones()
                        this.scrollingAcordeon(2)
                    } else {
                        this.erroresSoluciones = err
                        if (!first) {
                            this.openError = true
                        }
                        this.busy = false
                        this.errorSoluciones = true
                    }

                    if (this.errores.password) {
                        console.log("te equivocaste");
                        this.btnGuardar = false;
                        this.btnFirmar = true;
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("tamal");
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy = false
            }
        },

        async addMedicamentos(first) {
            this.medicamentos.indicacion_medica_id = this.infoIndicacion.id
            this.busy = true
            this.errorMedicamentos = false
            this.erroresMedicamentos = []
            if (this.verificarMedicamentos()) {
                console.log("tabien");
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_medicamentos/${this.infoIndicacion.id}`, {
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.medicamentos),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.busy = false
                        this.erroresMedicamentos = ""
                        this.errorMedicamentos = false
                        this.medicamentos = this.cmedicamentos
                        this.formMedicamentos = false
                        if (this.hayDta) {
                            this.openSuccess = true
                            this.obtenerDatosIndicaciones()
                        }
                        this.clearUpdMedicamentos()
                        this.scrollingAcordeon(3)
                    } else {
                        this.erroresMedicamentos = err
                        if (!first) {
                            this.openError = true
                        }
                        this.busy = false
                        this.errorMedicamentos = true
                    }

                    if (this.errores.password) {
                        console.log("te equivocaste");
                        this.btnGuardar = false;
                        this.btnFirmar = true;
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("tamal");
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy = false
            }
        },
        async addCuidados(first) {
            this.cuidados.indicacion_medica_id = this.infoIndicacion.id
            this.busy = true
            this.errorCuidados = false
            this.erroresCuidados = []
            if (this.verificarCuidados()) {
                console.log("tabien");
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas_cuidados/${this.infoIndicacion.id}`, {
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.cuidados),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });

                    if (warn) {
                        this.busy = false
                        this.erroresCuidados = ""
                        this.errorCuidados = false
                        this.cuidados = this.ccuidados
                        this.formCuidados = false
                        if (this.hayDta) {
                            this.openSuccess = true
                            this.obtenerDatosIndicaciones()
                        }
                        this.clearUpdCuidado()
                        this.scrollingAcordeon(4)
                    } else {
                        this.erroresCuidados = err
                        if (!first) {
                            this.openError = true
                        }
                        this.busy = false
                        this.errorCuidados = true
                    }

                    if (this.errores.password) {
                        console.log("te equivocaste");
                        this.btnGuardar = false;
                        this.btnFirmar = true;
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("tamal");
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy = false
            }
        },
        regAct() {
            setTimeout(() => {
                if (this.formCuidados) {
                    this.saveCuidados()
                }
                if (this.formDieta) {
                    this.saveDieta()
                }
                if (this.formSoluciones) {
                    this.saveSoluciones()
                }
                if (this.formMedicamentos) {
                    this.saveMedicamentos()
                }
            }, 300);
        },
        async registrarIndicaciones() {
            this.infoIndicacion.no = this.infoIndicacion.no_indicacion
            const pacienteEncrypt = localStorage.expedienteEncrypt;
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente);
            this.infoIndicacion.expediente_id = paciente.id
            this.busy = true
            this.error = false
            this.errores = []
            console.log([this.formDieta, this.formSoluciones, this.formMedicamentos, this.formCuidados]);
            this.error = false
            this.errorDieta = false
            this.errorSoluciones = false
            this.errorMedicamentos = false
            this.erroreCuidados = false
            var v1 = this.verificar()
            var v2 = this.verificarCuidados()
            var v3 = this.verificarDieta()
            var v4 = this.verificarMedicamentos()
            var v5 = this.verificarSoluciones()
            if (v1 && v2 && v3 && v4 && v5) {
                try {
                    const res = await fetch(`${url}/api/indicaciones_medicas`, {
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.infoIndicacion),
                    });
                    const data = res.json();
                    Vue.prototype.$error500 = res.status === 500
                    var promise = Promise.resolve(data);
                    var warn = false
                    var err = [];
                    await promise.then(function (val) {
                        if (val.class == "success") {
                            warn = true
                        } else {
                            err = val.errors
                        }
                    });
                    if (warn) {
                        this.firstSave = true
                        this.openSuccess = true
                        this.errores = ""
                        this.error = false
                        this.hayDta = true
                        this.loading = true
                        setTimeout(() => {
                            this.busy = false
                            if (this.firstSave) {
                                this.setData(true)
                            } else {
                                this.setData()
                            }
                        }, 500);
                    } else {
                        this.errores = err
                        this.openError = true
                        this.busy = false
                        this.error = true
                    }

                    if (this.errores.password) {
                        console.log("te equivocaste");
                        this.btnGuardar = false;
                        this.btnFirmar = true;
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy = false
            }
        },

        async obtenerDatosIndicaciones(first) {
            this.hayDta = false
            this.disableInput = false
            try {
                const res = await fetch(`${url}/api/indicaciones_medicas/${this.datosExpediente.id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    console.log(message);
                    throw new Error(message);
                }
                console.log(res);
                const data = await res.json();
                console.log("indicaciones");
                console.log(data);
                if (data.indicaciones_medicas) {
                    this.infoIndicacion = data.indicaciones_medicas
                    if (this.infoIndicacion.otras_alergias != null) {
                        this.infoIndicacion.otrasAlergias = true
                    }
                    if (this.infoIndicacion.alergias_medicamentos != null) {
                        this.infoIndicacion.alergiaMed = true
                    }

                    if (data.indicaciones_medicas.id != null) {
                        this.hayDta = true
                        this.disableInput = true
                        this.infoIndicacion.no = data.indicaciones_medicas.no_indicacion
                    } else {
                        this.infoIndicacion.no = ""
                    }
                    if (first) {
                        this.loading = true
                        if (this.formCuidados) {
                            this.saveCuidados(true)
                        }
                        if (this.formDieta) {
                            this.saveDieta(true)
                        }
                        if (this.formSoluciones) {
                            this.saveSoluciones(true)
                        }
                        if (this.formMedicamentos) {
                            this.saveMedicamentos(true)
                        }
                        setTimeout(() => {
                            this.loading = false
                        }, 500);
                    }
                    this.loading = false
                } else {
                    this.loading = false
                    this.hayDta = false
                    this.disableInput = false
                }
            } catch (error) {
                console.log(this.infoIndicacion);
                this.loading = false
                this.loading = false
                this.hayDta = false
                this.disableInput = false
                console.log(error);
            }
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.pacientes = data.pacientes;
                this.pacientes.unshift({ id: "", nombre: "Seleccionar paciente" });
            } catch (error) {
                console.log(error);
            }
        },

        getMedicamentos() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/medicamentos'))
            }).then((data) => {
                try {
                    const arrayFarm = data.medicamentos.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })

                    const newArray = arrayFarm.sort((a, b) => a.text.localeCompare(b.text))
                    this.farmacos = newArray

                    this.farmacos.unshift({ value: null, text: "seleccione una opción" })
                } catch (error) {
                    console.log("error en el get farmacos");
                    console.log(error);
                }
            })
        },

        getCatalogos() {
            //  INDICACIONES MEDICAS **********************************************************************************************************************************
            new Promise((resolve) => {
                resolve(getCatalogo('/api/indicaciones_medicas'))
            }).then((data) => {
                try {
                    this.dietas = data.dietas.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.dietas.unshift({ value: null, text: "seleccione una opción" })

                    this.tipoSolucion = data.tipos_solucion.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.tipoSolucion.unshift({ value: null, text: "seleccione una opción" })

                    this.volSolucion = data.volumen_solucion.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.volSolucion.unshift({ value: null, text: "seleccione una opción" })

                    this.viaAdministracion = data.vias_administracion.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.viaAdministracion.unshift({ value: null, text: "seleccione una opción" })
                } catch (error) {
                    console.log(error);
                }
            })

            new Promise((resolve) => {
                resolve(getCatalogo('/api/usuarios/logged'))
            }).then((data) => {
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            })
        },
        cerrarModal() {
            this.openSuccess = false
        },
        cerrarModalError() {
            console.log('cerrarModalError');
            this.openError = false
        },
        closeAddMedicamento() {
            this.addMedicamento = false
        },
        firma_psw(psw) {
            this.infoIndicacion.password = psw
            if (!this.hayDta) {
                console.log("");
            } else {
                this.updateData();
            }
            this.openFirmaPsw = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
        },
        savedMedicamento(){
            console.log('medic');
            this.getMedicamentos()
            this.addMedicamento = false
            this.openSuccess = true
        }
    },

    mounted() {
    },
    activated() {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        Object.assign(this.$data, this.$options.data());

        const egreso = localStorage.egreso
        if (egreso) {
            const egresoDE = JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado = egresoDE.egreso
        } else {
            router.push("/main")
        }

        this.loading = true
        this.setData()
        // setTimeout(() => {
        //     this.loading=false
        // }, 1500);
    }
}
</script>

<style scoped>
@media only screen and (min-width: 1200px) {
    .size_th {
        width: 20vw;
    }
}

@media only screen and (max-width: 1200px) {
    .size_th {
        width: 20vw;
    }
}

@media only screen and (max-width: 992px) {
    .size_th {
        width: 30vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th {
        width: 20vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th {
        width: auto;
    }
}

/* Tabla (Expediente, habitacíón, noInidcaciones y ultima modificación) */

@media only screen and (min-width: 1200px) {
    .size_th_sec_table {
        width: 12vw;
    }
}

@media only screen and (max-width: 1200px) {
    .size_th_sec_table {
        width: auto;
    }
}

@media only screen and (max-width: 992px) {
    .size_th_sec_table {
        width: auto;
    }
}

@media only screen and (max-width: 768px) {
    .size_th_sec_table {
        width: 20vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th_sec_table {
        width: auto;
    }
}

/* Medicamentos */
@media only screen and (min-width: 1200px) {
    .size_th_medicamentos {
        width: 20vw;
    }
}

@media only screen and (max-width: 1200px) {
    .size_th_medicamentos {
        width: 20vw;
    }
}

@media only screen and (max-width: 992px) {
    .size_th_medicamentos {
        width: 30vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th_medicamentos {
        width: 20vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th_medicamentos {
        width: auto;
    }
}

/* Acordeon */
.bg {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.bg_header {
    border: none !important;
    box-shadow: none !important;
    background-color: white !important;
}

.btnAccion {
    cursor: pointer;
}

.icon-color {
    color: rgb(169, 11, 11);
}</style>    