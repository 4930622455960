<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Información del paciente</h2>      
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>

        <b-container>
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expediente</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="editando" v-model="idExpediente" @change="changeExp(idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-row v-if="status=='edit'">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Registrar numero de expediente físico del paciente </p>
                        <label class="switch">
                            <input v-model="generarNoExp" value=false type="checkbox">
                            <!-- @click="datos.no_expediente=''" -->
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>

            <b-table-simple v-if="generarNoExp && status=='edit'" class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Expediente físico</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input maxlength="14" name="nh" autocomplete="off" :state="Vexp" v-model="no_expediente_legacy" v-on:keypress="soloNumerosExpediente($event)" v-on:keyup="soloNumerosExpedienteUp($event)" class="border-0" size="sm" placeholder="# Expediente físico" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p v-if="datos.no_expediente_legacy && status!='edit'" class="txt_secondary text-uppercase mt-1"><strong>No. expediente físico: </strong>{{ datos.no_expediente_legacy }}</p>

            <!-- <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expediente</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled v-model="datos.no_expediente_legacy" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <!-- all.fecha_ingreso.substring(2,4)+all.fecha_ingreso.substring(5,7)+'-'+noPaciente+'-'+ -->
            <p class="txt_secondary text-uppercase mt-1"><strong>Datos generales</strong></p>
            <!-- <b-button @click="generar()">
                generar
            </b-button> -->

            <b-table-simple class="table-sm border_table" id="divID">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled='status=="new"||status=="show"'  v-on:keypress="soloLetras($event)" v-model="datos.nombre" :state="validation" class="border-0" size="sm" placeholder="Ingresa nombre del paciente">>
                            </b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled='status=="new"||status=="show"' name="fechaNacimiento" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha" v-model="datos.fecha_nacimiento" @change="change(datos.fecha_nacimiento)" maxlength="10" class="border-0 input_disabled" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><span class="size-span">{{expediente.paciente_edad_years}}</span></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><span class="size-span">{{expediente.paciente_edad_months}}</span></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled='status=="new"||status=="show"' :state="genero_id" v-model="datos.genero.id" :options="genero" class="border-0 input_disabled" size="sm" placeholder="Seleccionar" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled='status=="new"||status=="show"' :state="estado_civil_id" v-model="selectedecivil" :options="ecivil" class="border-0 input_disabled" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                             <b-form-input :disabled='status=="new"||status=="show"' name="cpPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" :state="cp" v-model="datos.cp" class="border-0 input_disabled" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled='status=="new"||status=="show"' type="text" name="domicilioPaciente" autocomplete="off" :state="domicilio" v-on:keypress="rgexDomicilio($event)" v-model="datos.domicilio" class="border-0 input_disabled" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                             <b-form-input :disabled='status=="new"||status=="show"' type="text" name="coloniaPaciente" autocomplete="off" :state="colonia" v-model="datos.colonia" class="border-0 input_disabled" size="sm" placeholder="Ingresa colonia" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled='status=="new"||status=="show"' type="text" name="ciudadPaciente" autocomplete="off" v-on:keypress="soloLetras($event)" :state="ciudad" v-model="datos.ciudad" class="border-0 input_disabled" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled='status=="new"||status=="show"' name="edoPaciente" :state="estado" v-model="datos.estado.id" :options="dtaEstados"  @change="getMunicipiosPaciente(datos.estado.id)" class="border-0 input_disabled" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled='status=="new"||status=="show"' name="municipioPaciente" :state="municipio" v-model="datos.municipio.id" :options="dtaMunicipiosPaciente" class="border-0 input_disabled" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled='status=="new"||status=="show"' name="telefonoPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="10" :state="telefono" v-model="datos.telefono" class="border-0 input_disabled" size="sm" placeholder="-----" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled='status=="new"||status=="show"' :state="email" type="email" name="emailPaciente" autocomplete="off" v-model="datos.email" class="border-0 input_disabled" size="sm" placeholder="-----" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <!-- ERRORES  -->
            <b-row v-if="erF1" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err1,index) of erroresForm1" :key="index"><i class="fa fa-warning px-1" v-if="!err1.expediente_id" />| {{err1[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <!--    ERRORES/  -->

            <div class="d-flex justify-content-end mb-4" v-if="!disabled">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="update('paciente')" :disabled="busy">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            

            <p id="dtaResponsable" class="txt_secondary text-uppercase pt-3" v-if="!sinExp"><strong>Responsable</strong></p>

            <b-row v-if="(status=='new')">
                <b-col cols="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Misma ubicación</p>
                        <label class="switch">
                            <input v-model="mismaUbicacion" @change="getMismaUbicacion()" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>

            <b-table-simple class="table-sm" v-if="!sinExp">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="responsable" autocomplete="off" v-on:keypress="soloLetras($event)" :state="nombreR" v-model="responsable.nombre" class="border-0" size="sm" placeholder="Ingresa nombre de responsable del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" name="fechaResp" :state="fechaR" autocomplete="off" v-on:keypress="soloNumeros($event)"  v-model="responsable.fecha_nacimiento" @change="change(datos.fecha_nacimiento)" maxlength="10" class="border-0" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" :state="genero_idResp" v-model="responsable.genero.id" :options="genero" class="border-0" size="sm" placeholder="Seleccionar" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Parentesco</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="parentesco" autocomplete="off" v-on:keypress="soloLetras($event)" :state="parentezco" v-model="responsable.parentezco" class="border-0" size="sm" placeholder="Ingresa parentesco con el paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="(status=='show')" name="cpResp" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" :state="cpr" v-model="responsable.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="domicilioResp" autocomplete="off" :state="domicilior" v-on:keypress="rgexDomicilio($event)" v-model="responsable.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                             <b-form-input :disabled="(status=='show')" type="text" name="coloniaResp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="coloniar" v-model="responsable.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="ciudadResp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="ciudadr" v-model="responsable.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" name="edoResp" :state="estador" v-model="responsable.estado.id" :options="dtaEstados" @change="getMunicipiosResp(responsable.estado.id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" name="municipioResp" :state="municipioR" v-model="responsable.municipio.id" :options="dtaMunicipiosResp" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" name="telefonoResp" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="telefonor" maxlength="10" v-model="responsable.telefono" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="(status=='show')" type="email" :state="emailR" name="emailResp" autocomplete="off" v-model="responsable.email" class="border-0" size="sm" placeholder="Email no registrado" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <!-- ERRORES  -->
            <b-row v-if="erF2" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(errFact,index) of erroresForm2" :key="index"><i class="fa fa-warning px-1" v-if="!errFact.expediente_id" />| {{errFact[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <!--    ERRORES/  -->

            <div class="d-flex justify-content-end mb-4" v-if="!disabled">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="update('responsable')" :disabled="busy">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <p v-if="!sinExp" class="txt_secondary text-uppercase pt-3"><strong>Datos de facturación</strong></p>
            <b-row v-if="!sinExp">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Facturación</p>
                        <label class="switch">
                            <input :disabled="hayFactura" v-model="dtaFacturacion" @change="datosFacturacion()" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>
 
            <b-table-simple v-if="form_dta_facturacion && !sinExp" class="table-sm mt-3">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre / Razón social</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="nombreRazonSocial" v-model="dataFacturacion.razon_social" :state="VRazonSocial" autocomplete="off" v-on:keypress="soloLetras($event)" class="border-0" size="sm" placeholder="Ingresa nombre o razón social" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="email" name="emailFactura" v-model="dataFacturacion.email" :state="VFactEmail" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el email" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">RFC</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="RFC" v-model="dataFacturacion.rfc" :state="VRcf" @keyup="upperCase()" autocomplete="off" maxlength="13" class="border-0" size="sm" placeholder="Ingresa el RFC" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Método de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" name="metodoPago" v-model="dataFacturacion.metodo_pago_id" :options="metodoPago" :state="VMetodoPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Forma de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" name="formaPago" v-model="dataFacturacion.forma_pago_id" :options="formaPago" :state="VFormaPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Uso del CFDI</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" name="usoCFDI" v-model="dataFacturacion.uso_cfdi_id" :options="usoCfdi" :state="VUsoCFDI" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="domicilioFactura" v-model="dataFacturacion.domicilio" v-on:keypress="rgexDomicilio($event)" :state="VFactDomicilio" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el domicilio" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <!-- ERRORES  -->
            <b-row v-if="erFact" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err2,index) of erroresFormFact" :key="index"><i class="fa fa-warning px-1" v-if="!err2.expediente_id" />| {{err2[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <!--    ERRORES/  -->

            <div class="d-flex justify-content-end mb-4" v-if="!disabled && form_dta_facturacion">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button @click="update('factura')" class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <p v-if="!sinExp" class="txt_secondary text-uppercase pt-3"><strong>Datos médicos</strong></p>

             <b-table-simple class="table-sm" v-if="status!='new'">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Número de expediente</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="true" type="text" name="numHabitacion" autocomplete="off" v-model="datos.no_expediente" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm" v-if="!sinExp">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Fecha de ingreso</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="true" name="fechaI" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha_ingreso" v-model="expediente.fecha_ingreso" type="date" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Hora de ingreso</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <b-form-input :disabled="true" name="horaI" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="hora_ingreso" v-model="expediente.hora_ingreso" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <small class="txt_gray size-span">horas</small>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Número de habitación </b-th>
                        <b-td class="border_table">
                            <b-form-input v-show="status=='show'" :disabled="(status=='show')" name="numHabitacion" autocomplete="off" v-on:keypress="soloNumeros($event)"  v-model="expediente.habitacion_id" class="border-0" size="sm" placeholder="Ingresa número de habitación" />
                            <b-form-select v-show="status=='edit'" :state="no_habitacion" :disabled="(status=='show')" v-model="expediente.habitacion_id" :options="abit" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" :state="especialidad_id" v-model="expediente.especialidad_id" :options="dtaEspecialidades" @change="obtenerMedicosPerEspecialidad(expediente.especialidad_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                     <b-tr>
                        <b-th class="th_style">Médico tratante</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" :state="VmedicoId" v-model="expediente.medico_id" :options="dtaMedicos" placeholder="seleccionar" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Diagnóstico</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="diag" autocomplete="off" :state="diagnostico" v-model="expediente.diagnostico" class="border-0" size="sm" placeholder="Ingresa diagnóstico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Tratamiento</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="(status=='show')" name="edo" :state="VTratamiento" v-model="expediente.tratamiento_id" :options="dtaTratamiento" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Plan de manejo de tratamiento</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="(status=='show')" name="planTratamiento" autocomplete="off" :state="VPlanTratamiento" v-model="expediente.plan_manejo_tratamiento" class="border-0" size="sm" placeholder="Ingresa el plan de manejo de tratamiento" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Pronóstico</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="(status=='show')" :state="VPronostico" v-model="expediente.pronostico_id" :options="dtaPronostico" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="table-sm" v-if="!sinExp">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Alergias</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" v-model="expediente.tiene_alergias" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-row v-if="expediente.tiene_alergias">
                <b-col cols="12" sm="12" md="7" lg="7">
                    <div class="d-flex">
                        <span class="px-2 pt-2 txt_secondary">
                            ¿Cuál?
                        </span>
                        <b-form-input :disabled="(status=='show')" type="text" name="cualAlergia" autocomplete="off" :state="alergias" v-model="expediente.alergias" placeholder="Ingrese las alergias" class="float-right border_bottom" size="sm"></b-form-input>
                    </div>
                </b-col>
            </b-row>
            
            <p v-if="!sinExp" class="txt_secondary text-uppercase pt-3"><strong>Identificación de barreras</strong></p>
            <b-table-simple class="table-sm" v-if="!sinExp">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Escolaridad</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(status=='show')" :state="escolaridad" v-model="expediente.escolaridad_id" :options="escolaridades" text-field="nombre" value-field="id" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ocupación</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="ocupacion" autocomplete="off" :state="ocupacion" v-model="expediente.ocupacion" class="border-0" size="sm" placeholder="Ingresa ocupación laboral" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Religión</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="religion" autocomplete="off" v-on:keypress="soloLetras($event)" :state="religion" v-model="expediente.religion" class="border-0" size="sm"  placeholder="Ingresa religión" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Idioma</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(status=='show')" type="text" name="idioma" autocomplete="off" v-on:keypress="soloLetras($event)" :state="idioma" v-model="expediente.idioma" class="border-0" size="sm" placeholder="Ingresa idioma que usa para comunicarse" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm" v-if="!sinExp">
                <b-tbody >  
                    <b-tr>
                        <b-th class="th_style size_th_grupoVulnerable">Grupo vulnerable</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" v-model="expediente.grupo_vulnerable" @change="GB(expediente.grupo_vulnerable)" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para hablar</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" v-model="expediente.dificultar_hablar" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para oír</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" v-model="expediente.dificultad_oir" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.dificultad_oir">
                        <b-th class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Porta equipo de audición?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.porta_equipo_audicion" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex float-left">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.porta_equipo_audicion" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>    
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para ver</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" v-model="expediente.dificultad_ver" value=false type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>  
                    <b-tr v-if="expediente.dificultad_ver">
                        <b-th class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Usa lentes?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.usa_lentes" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.usa_lentes" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>              
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para caminar</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" type="checkbox" v-model="expediente.dificultad_caminar" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th v-if="expediente.dificultad_caminar" class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Utiliza algún tipo de apoyo?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.usa_apoyo" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.usa_apoyo" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-if="expediente.usa_apoyo" class="py-2 pr-3">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <div class="d-flex">
                                        <span class="px-2 pt-2 txt_secondary">
                                            ¿Cuál?
                                        </span>
                                        <b-form-input :disabled="(status=='show')" type="text" name="cualApoyo" autocomplete="off" v-on:keypress="soloLetras($event)" :state="tipo_apoyo" v-model="expediente.tipo_apoyo" class="float-right border_bottom" size="sm"></b-form-input>
                                    </div>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Dificultad para comprender indicaciones</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(status=='show')" type="checkbox" v-model="expediente.dificultar_comprender_indicaciones" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.grupo_vulnerable">
                        <b-th class="th_style">Condición psicológica o emocional inestable</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                 <input :disabled="(status=='show')" type="checkbox" v-model="expediente.tiene_condicion_psicologica" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="expediente.tiene_condicion_psicologica">
                        <b-th class="th_option_color">
                            <b-row>
                                <b-col cols="12" sm="12" md="5"  lg="5">
                                    ¿Está bajo algún tratamiento?
                                </b-col>
                                <b-col cols="12" sm="12" md="4"  lg="4">
                                    <b-row :disabled="(status=='show')">
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.bajo_tratamiento" v-bind:value="true" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex">
                                                <span class="px-2 txt_secondary rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="(status=='show')" v-model="expediente.bajo_tratamiento" v-bind:value="false" class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-if="expediente.bajo_tratamiento" class="py-2 pr-3">
                                <b-col cols="12" sm="12" md="12" lg="12">
                                    <div class="d-flex">
                                        <span class="px-2 pt-2 txt_secondary">
                                            ¿Cuál?
                                        </span>
                                        <b-form-input :disabled="(status=='show')" type="text" name="cualTratamiento" autocomplete="off" :state="tratamiento" v-model="expediente.tratamiento_psicologico" class="float-right border_bottom" size="sm"></b-form-input>
                                    </div>
                                </b-col>
                            </b-row> 
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <p v-if="!sinExp" class="txt_secondary pt-3"><strong>NOTAS A CONSIDERAR</strong> (de acuerdo a costumbres, valores y/o necesidades)</p>
            <b-form-textarea v-if="!sinExp" :disabled="(status=='show')" autocomplete="off" :state="notas" v-model="expediente.notas" class="txtArea_border mb-4" size="sm" no-resize rows="5" max-rows="5" />
            
            <!-- <div style="margin-top: -16px;" class="mb-4">
                <span class="txt_secondary size-span">Médico: Emmanuel Gomez Miron</span> <br>
                <span class="txt_secondary size-span">Fecha: 12/06/2022</span>
            </div> -->
            
            <!-- ERRORES  -->
            <b-row v-if="erF3" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err3,index) of erroresForm3" :key="index"><i class="fa fa-warning px-1" v-if="!err3.expediente_id" />| {{err3[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <!--    ERRORES/  -->


            <div class="d-flex justify-content-end mb-4" v-if="!disabled && !sinExp">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button @click="update('expediente')" class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
        </b-container>
<!--  -->
        <ul class="mt-2" v-if="status=='new'">
                <li>Se requiere firma del paciente</li>
                <li>Se requiere firma del responsable del paciente</li>
                <li>Se requiere nombre y firma de quien realizó el ingreso</li>
            </ul>
            <div v-if="status=='new' && btnGuardar" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="verificar">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="status=='new' && btnFirmar" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div>
<!--  -->
        
        <b-button v-if="status=='new'||status=='edit'" title="Cancelar registro" @click="resetForm()" variant="btn_red" class="float-btn-back pt-1">
            <span class="fa fa-close my-float-btn"></span>
        </b-button>
        <!--  -->
        <div v-if="((idPacienteSeleccionado != null || idPacienteSeleccionado != '') && dtaEgreso.firma.is_firmado==1 && (perfil == 1 || perfil == 2) && !openSuccess && !openFirmaPsw && !openError && !regNewExp && !loading)" class="sticky-container-exp" >
        <!-- acomodar nota a egreso-->
            <ul class="sticky-exp">
                <li @click="newExpedient()">
                    <img :src="require('../../assets/icons/newExp.png')" width="59" height="32">
                    <p>
                        <a>Nuevo expediente</a>
                    </p>
                </li>
            </ul>
        </div>

        <div class="sticky-container" v-if="((idPacienteSeleccionado != null || idPacienteSeleccionado != '') && dtaEgreso.firma.is_firmado==0 && !openSuccess && !openFirmaPsw && !openError && !regNewExp && !loading)">
            <ul class="sticky">
                <li v-if="lastExpediente" @click="editar">
                    <img :src="require('../../assets/icons/editar.png')"  width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <!-- <li>
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li> -->
            </ul>
        </div>

        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <NuevoExpediente :openRegExp="regNewExp" @nuevoExpediente="nuevoExpediente()"/>
        <Loading :show="loading"/>

    </b-container> 
</template>

<script>
import Loading from '@/components/Loader/Loading.vue'
import { getCatalogo } from '../../helpers/catalogos';
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import Foto from "@/components/Paciente/Foto.vue"
const urlec = `${url}/api/catalogos/estado_civil`
const link = `${url}/api/responsables_paciente/responsable/`;
const link2 = `${url}/api/datos_medicos/datos/`;
import CryptoJS from 'crypto-js'
import {url} from '../../../settings.js'
import {debounce} from 'lodash';
import router from '../../router/index.js';
import Vue from 'vue'
import {clinica} from '../../../settings'

export default {
    name: 'Informacion',
    components:{ Success, Error, Foto, FirmaPassword, NuevoExpediente, Loading },

    computed: {
        Vexp(){
            const patternExp = /^[0-9]{4}-[0-9]{5}-[0-9]{1,3}$/;
            // const patternExp = /^[0-9]{2,3}[.]{0,1}[0-9]{0,2}$/;
            return patternExp.test(this.no_expediente_legacy)
        },

        VPlanTratamiento() {
            return this.expediente.plan_manejo_tratamiento != ''
        },
        VPronostico() {
            return this.expediente.pronostico_id != null
        },
        genero_id(){
            return this.datos.genero.id !=null
        },
        genero_idResp(){
            return this.responsable.genero.id !=null
        },

        edad() {
            return this.datos.edad != ""
        },

        fecha() {
            return this.datos.fecha_nacimiento != ""
        },

        fechaR() {
            return this.responsable.fecha_nacimiento != ""
        },

        validation() {
            return this.datos.nombre.length >=10
        },

        estado_civil_id() {
            return this.selectedecivil != null
        },

        cp() {
            return this.datos.cp.length === 5 ? true : false
        },
        
        domicilio() {
            return this.datos.domicilio != ""
        },

        colonia() {
            return this.datos.colonia != ""
        },

        ciudad() {
            return this.datos.ciudad != ""
        },

        estado() {
            return this.datos.estado.id != null
        },

        municipio() {
            return this.datos.municipio.id != null
        },

        telefono() {
            let flag = null;
            if (this.datos.telefono) {
                if ((this.datos.telefono.length>0 && this.datos.telefono.length<10)) {
                    flag = false;
                }else if (this.datos.telefono.length == 10) {
                    flag=true;
                }else{
                    flag=null
                }
            }
            return flag;
        },

        email() {
            var flag = null
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.datos.email==""||this.datos.email==null) {
                flag=null
            }else if (pattern.test(this.datos.email)){
                flag=true
            }else{
                flag=false
            }
            return flag
        },

        emailR() {
            var flag = null
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.responsable.email==""||this.responsable.email==null) {
                flag=null
            }else if (pattern.test(this.responsable.email)){
                flag=true
            }else{
                flag=false
            }
            return flag
        },

        parentezco() {
            return this.responsable.parentezco != ""
        },

        nombreR() {
            return this.responsable.nombre.length >= 10
        },

        cpr() {
            return this.responsable.cp.length === 5 ? true : false
        },

        domicilior() {
            return this.responsable.domicilio != ""
        },

        estador() {
            return this.responsable.estado.id != null
        },

        municipioR() {
            return this.responsable.municipio.id != null
        },

        ciudadr() {
            return this.responsable.ciudad != ""
        },

        telefonor() {
            return this.responsable.telefono.length === 10 ? true : false
        },

        coloniar() {
            return this.responsable.colonia != ""
        },

        fecha_ingreso() {
            return this.expediente.fecha_ingreso != ""
        },

        hora_ingreso() {
            return this.expediente.hora_ingreso != ""
        },
        no_habitacion() {
            return this.expediente.habitacion_id != null
        },

        alergias() {
            return this.expediente.alergias != ""
        },

        especialidad_id() {
            return this.expediente.especialidad_id != null
        },

        diagnostico() {
            return this.expediente.diagnostico.length != 0
        },

        VTratamiento() {
            return this.expediente.tratamiento_id != null
        },

        VmedicoId() {
            return this.expediente.medico_id != null
        },

        escolaridad() {
            return this.expediente.escolaridad_id !=""
        },

        religion() {
            return this.expediente.religion != ""
        },

        ocupacion() {
            return this.expediente.ocupacion != ""
        },

        idioma() {
            return this.expediente.idioma != ""
        },

        tipo_apoyo() {
            return this.expediente.tipo_apoyo != ""
        },

        tratamiento() {
            return this.expediente.tratamiento_psicologico != ""
        },

        notas() {
            return this.expediente.notas != ""
        },

        /* Form datos de facturación */
        VRazonSocial() {
            return this.dataFacturacion.razon_social != ""
        },

        VRcf() {
            let flag = false;
            if (this.dataFacturacion.rfc.length >= 12) {
                flag=true;
            }
            return flag;
        },

        VFactEmail() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.dataFacturacion.email)
        },

        VMetodoPago() {
            return this.dataFacturacion.metodo_pago_id != null
        },

        VFormaPago() {
            return this.dataFacturacion.forma_pago_id != null
        },

        VUsoCFDI() {
            return this.dataFacturacion.uso_cfdi_id != null
        },

        VFactDomicilio() {
            return this.dataFacturacion.domicilio != ""
        }
    },

    watch: { 
        '$route.params.id': {
            handler: function() {
                this.loading=true
                this.isEgresado=false
                try {
                    if ((localStorage.view==='Informacion' || localStorage.view==='')) {
                        this.prevent()
                        this.setDatos()

                    }
                } catch (error) {
                    this.prevent()
                    this.setDatos()
                }
                setTimeout(() => {
                    this.$emit("toInfo")
                }, 200);
            },
            deep: true,
            immediate: true
        }
    },

    data:()=>({
        abit:[
                {'name':'Seleccionar habitacion', 'id':''},
                {'name':'1', 'id':'1'},
                {'name':'2', 'id':'2'},
                {'name':'3', 'id':'3'},
                {'name':'4', 'id':'4'},
                {'name':'5', 'id':'5'},
                {'name':'6', 'id':'6'},
                {'name':'7', 'id':'7'},
                {'name':'8', 'id':'8'},
                {'name':'9', 'id':'9'},
                {'name':'10', 'id':'10'},
                {'name':'11', 'id':'11'},
                {'name':'12', 'id':'12'},
                {'name':'13', 'id':'13'},
                {'name':'14', 'id':'14'},
                {'name':'15', 'id':'15'},
            ],
        clinica:clinica,
        pdfDone:{
            pdf1:false,
            pdf2:false,
            pdf3:false,
            pdfC1:false,
            pdfC2:false,
            pdfC3:false,
            pdf4:false,
            pdf5:false,
            pdf6:false, 
            pdf7:false,
            pdf8:false,
            pdf9:false,
            pdf10:false,
            pdf11:false,            
        },

        generarNoExp: false,
        no_expediente_legacy:"",
        isEgresado:false,
        perfil:"",
        mismaUbicacion: false,
        idExpediente:null,

        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },

        noPaciente:"",
        password:null,
        loading: true,
        regNewExp: false,

        sinExp:false,
        btnFirmar:true,
        btnGuardar:false,

        openFirmaPsw:false,
        editando:false,

        donePacientes:false,

        disabled:true,
        dtaEstados: [{value:null,text:'seleccione una opción'},{text:'...'}],
        dtaMunicipiosPaciente: [{value:null,text:'seleccione una opción'},{text:'...'}],
        dtaMunicipiosResp: [{value:null,text:'seleccione una opción'},{text:'...'}],
        dtaTratamiento: [{value:null,text:'seleccionar'},{text:'...'}],
        dtaMedicos: [{id:null,nombre:'seleccionar'},{text:'...'}], 
        dtaEspecialidades: [{value:null, text:'seleccionar'},{text: '...'}],
        escolaridades:[{id:"",nombre:'seleccionar'},{text:'...'}],
        dtaPronostico:[],
        
        idPacienteSeleccionado:null,
        erroresForm1:{},
        erroresForm2:{},
        erroresForm3:{},
        erroresFormFact:{},

        erF1:false,
        erF2:false,
        erF3:false,
        erFact:false,

        busy: false,
        openSuccess:false,
        openError:false,
        selectedecivil:null,
        pacientes:[],
        ecivil: [{value:null,text:'seleccione una opcion'},{text:'...'}],
        años:"",

        dataFacturacionVerificar: {
            razon_social: ["El campo razon social es requerido"],
            rfc:["El campo RFC es requerido"],
            email:["El campo email es requerido"],
            domicilio:["El campo domicilio es requerido"],
            metodo_pago_id:["El campo metodo de pago es requerido"],
            forma_pago_id:["El campo forma de pago es requerido"],
            uso_cfdi_id:["El campo uso del CFDI es requerido"],
        }, 
        erroresf1:{
            "nombre": [
                "Nombre del paciente es requerido"
            ],
            "genero_id": [
                "Genero del paciente es requerido"
            ],
            "fecha_nacimiento": [
                "Fecha de Nacimiento es requerida"
            ],
            "estado_civil_id": [
                "Estado civil es requerido"
            ],
            "cp": [
                "Código Postal es requrido"
            ],
            "domicilio": [
                "Domicilio es requrido"
            ],
            "colonia": [
                "Colonia es requerida"
            ],
            "ciudad": [
                "Ciudad es requerida"
            ],
            "estado_id": [
                "Estado es requerida"
            ],
            "municipio_id": [
                "Municipio es requerida"
            ],
            
        },

        erroresf2:{
            "nombre": [
                "El campo nombre del responsable es obligatorio."
            ],
            "fecha_nacimiento": [
                "Fecha de Nacimiento del responsable es requerida"
            ],
            "parentezco": [
                "El campo parentesco del responsable es obligatorio."
            ],
            "cp": [
                "El campo cp del responsable es obligatorio."
            ],
            "domicilio": [
                "El campo domicilio del responsable es obligatorio."
            ],
            "colonia": [
                "El campo colonia del responsable  es obligatorio."
            ],
            "ciudad": [
                "El campo ciudad del responsable  es obligatorio."
            ],
        },

        erroresf3:{
            "no_habitacion": [
                "El campo no habitacion es obligatorio."
            ],
            "diagnostico": [
                "El campo diagnostico es obligatorio."
            ],
            "especialidad_id": [
                "El campo especialidad id es obligatorio."
            ],
            "medico_id": [
                "El campo medico id es obligatorio."
            ],
            "escolaridad": [
                "El campo escolaridad es obligatorio."
            ],
            "religion": [
                "El campo religion es obligatorio."
            ],
            "ocupacion": [
                "El campo ocupacion es obligatorio."
            ],
            "idioma": [
                "El campo idioma es obligatorio."
            ],
            "notas": [
                "El campo notas es obligatorio."
            ],
            "tratamiento_id": [
                "El campo tratamiento es obligatorio."
            ],

        },

        genero:[],
        datos: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id:null,
                no_habitacion:null,
            }
        },
        lastExpediente:false,
        // datos:{
        //     password:"",
        //     edad:"",
        //     genero_id:"",
        //     nombre: "",
        //     fecha_nacimiento: "",
        //     estado_civil_id: "",
        //     cp: "",
        //     domicilio: "",
        //     colonia: "",
        //     ciudad: "",
        //     telefono: "",
        //     email: "",
        //     estado_id: null,
        //     municipio_id: null
        // },
        
        responsable:{
            password:"",
            paciente_id: "",
            email: "",
            fecha_nacimiento: "",
            genero:{
                id:null,
                nombre: "",
            },
            parentezco: "",
            nombre: "",
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            telefono: "",
            estado:{
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            genero_id:null,
            estado_id:null,
            municipio_id:null,
        },

        expediente:{
            id:"",
            password:"",
            paciente_id: "",
            fecha_ingreso: "",
            hora_ingreso: "",
            no_habitacion: "",
            habitacion_id: null,
            tiene_alergias: false,
            alergias: "",
            diagnostico: "",
            especialidad_id: null,
            medico_id: null,
            mayor_18_yaers: false,
            mayor_60_yaers: false,
            escolaridad_id: "",
            religion: "",
            ocupacion: "",
            idioma: "",
            grupo_vulnerable: false,
            dificultar_hablar: false,
            dificultad_oir: false,
            porta_equipo_audicion: false,
            dificultad_ver: false,
            usa_lentes: false,
            dificultad_caminar: false,
            usa_apoyo: false,
            tipo_apoyo: "",
            dificultar_comprender_indicaciones: false,
            tiene_condicion_psicologica: false,
            bajo_tratamiento: false,
            tratamiento_psicologico: "",
            notas: "",
            tratamiento_id: null,
            no_expediente:"",
            plan_manejo_tratamiento:"",
        },

        form_dta_facturacion: false,
        dataFacturacion: {
            responsable_id: null, 
            razon_social: "",
            rfc: "",
            email: "",
            domicilio: "",
            metodo_pago_id: null,
            forma_pago_id: null,
            uso_cfdi_id: null,
        },
        dtaFacturacion:false,
        metodoPago: [{value:null, text:'seleccione una opción'},{text: '...'}],
        formaPago: [{value:null, text:'seleccione una opción'},{text: '...'}],
        usoCfdi: [{value:null, text:'seleccione una opción'},{text: '...'}],

        medicos:[],
        datosPacienteSeleccionado:"",

        status: "show",

        allNotas:[],
        hayFactura:false,
        allExp:[],
        dtaEgreso:{
            firma:{
                fecha_firmado:null,
                firmado_por:null,
                is_firmado:0
            },
        },

    }),

    methods:{

        generar(){
 
        },

        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        async obtenerNotaDeEgreso(value) {
            try {
                const res = await fetch(`${url}/api/notas/egreso/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                var egreso = {egreso:false}
                if (data.egreso) {
                    this.isEgresado=true
                    this.dtaEgreso = data.egreso
                    egreso.egreso=true
                }else{
                    this.isEgresado=false
                    egreso.egreso=false
                    this.dtaEgreso = {
                        firma:{
                            fecha_firmado:null,
                            firmado_por:null,
                            is_firmado:0
                        }
                    }
                }
                var egresoLS = CryptoJS.AES.encrypt(JSON.stringify(egreso), 'secret key heal4701tech')
                localStorage.setItem('egreso', egresoLS);
           } catch (error) {
            console.log("");
           }
       },

        getMismaUbicacion() {
            if (this.mismaUbicacion == true) {
                this.responsable.domicilio = this.datos.domicilio
                this.responsable.cp = this.datos.cp
                this.responsable.colonia = this.datos.colonia
                this.responsable.ciudad = this.datos.ciudad
                this.responsable.estado.id = this.datos.estado.id
                this.getMunicipiosResp(this.datos.estado.id)
                this.disabled_select_mcpio_resp = false
                this.responsable.municipio.id = this.datos.municipio.id
            } else {
                this.responsable.domicilio = ""
                this.responsable.cp = ""
                this.responsable.colonia = ""
                this.responsable.ciudad = ""
                this.responsable.estado.id = null
                this.disabled_select_mcpio_resp = true
                this.responsable.municipio.id = null
            }
        },

        async getNotas(id) {
            try {
               const res = await fetch(`${url}/api/notas/${id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                 if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.allNotas=data.notas
           } catch (error) {
               console.log(error);
           }
        },

        changeExp(id){
            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            if (currentExp) {
                this.sinExp=false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.expediente=currentExp
                this.idExpediente=currentExp.id
                this.setData(true)
                if (this.datos.lastExpediente.expediente_id==currentExp.id) {
                    this.lastExpediente=true
                }else{
                    this.lastExpediente=false
                }
            }
            this.loading=false
        },

        //  GENERACION DE NUEVOS EXPEDIENTES //
        //status show-edit-new
        firmar(){
            this.openFirmaPsw = true;
        },
        newExpedient() {
            router.push("/registro/nuevo_expediente/"+this.datos.id) 
        },

        resetForm(){
            this.btnFirmar=true
            this.btnGuardar=false

            this.getExpediente();
            this.status='show'
            this.disabled=true
            this.editando=false

            this.erroresForm1={}
            this.erroresForm2={}
            this.erroresForm3={}
            this.erroresFormFact={}
            this.erF1=false
            this.erF2=false
            this.erF3=false
            this.erFact=false
        },

        prevent(){

            this.btnFirmar=true
            this.btnGuardar=false

            this.status='show'
            this.disabled=true
            this.editando=false

            this.erroresForm1={}
            this.erroresForm2={}
            this.erroresForm3={}
            this.erroresFormFact={}
            this.erF1=false
            this.erF2=false
            this.erF3=false
            this.erFact=false

        },



        // EXPEDIENTES FIN
       async PdfHojaAdmision(id) {
            var can = false;
            if (this.idPacienteSeleccionado == '') {
                this.toastPrint('danger');
            } else {        
                let urlim = `${url}/api/hoja_admision/admision/pdf/${id}`;
                return fetch(urlim, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                }).then(function(resp){
                    can = resp.ok
                    return resp.blob("paciente.pdf")
                }).then(function(blob) {
                    if (can) {
                        blob.name="paciente.pdf"
                        var w = 900
                        var h = 600
                        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                        const systemZoom = width / window.screen.availWidth;
                        const left = (width - w) / 2 / systemZoom + dualScreenLeft
                        const top = (height - h) / 2 / systemZoom + dualScreenTop

                        var url = URL.createObjectURL(blob);
                        var newWindow = window.open(url, "_blank",
                            `
                            scrollbars=yes,
                            width=${w / systemZoom}, 
                            height=${h / systemZoom}, 
                            top=${top}, 
                            left=${left}
                            `
                        )
                        setTimeout(function(){ 
                            newWindow.document.title = 'Número de expediente';
                        }, 100);
                    }
                });
            }
        },
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // jelpmi esto lo utilice pa editar 
        editar(){
            if (this.editando) {
                this.editando=false
                this.disabled=true
                this.status="show"
            }else{
                this.openFirmaPsw=true
                this.disabled=false
                this.status='edit'
            }
            // console.log(this.abit.find((element) => element.value ===this.expediente.habitacion_id));
            if (!this.abit.find((element) => element.value ===this.expediente.habitacion_id)) {
                this.abit.unshift({ value:this.expediente.habitacion_id, text:this.expediente.habitacion_id })
                this.abit.unshift({ value:null, text:"seleccione una opción" })
            }
        },

        update(form){
            switch (form) {
                case 'expediente':
                    this.expediente.password=this.password
                    this.updateExpediente()
                    break;
                
                case 'paciente':
                    this.datos.password=this.password
                    this.updatePaciente()
                    break;

                case 'responsable':
                    this.responsable.password=this.password
                    this.updateResponsable()
                    break;

                case 'factura':
                    this.dataFacturacion.password=this.password
                    if (this.dtaFacturacion) {
                        this.updateFacturacion()
                    }else{
                        this.registroFacturacion(this.responsable.id)
                    }
                    break;
            
                default:
                    break;
            }
        },

        async updateExpediente(){
            this.busy=true

            this.expediente.paciente_id=this.idPacienteSeleccionado
            this.expediente.hora_ingreso=this.expediente.hora_ingreso.substring(0,5)
            this.expediente.password=""
            this.expediente.notas=this.expediente.notas==''?'Sin notas a considerar':this.expediente.notas
            try {
                const res = await fetch(`${url}/api/datos_medicos/update/${this.expediente.id}`, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.expediente)
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err =[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.resetForm()
                    this.allExpedientes();
                    // this.getExpediente()
                    this.openSuccess=true
                    this.erroresForm3={}
                    this.erF3=false
                }else{
                    this.erroresForm3=err
                    this.erF3=true
                    this.openError=true
                    this.busy=false
                }

                if (this.erroresForm3.password) {
                    setTimeout(() => {
                        this.openError=false
                        this.openFirmaPsw=true
                    }, 2000);
                }

            } catch (error) {
                this.erroresForm1=err
                this.busy=false
            }
        },

        async updatePaciente(){
            this.busy=true 
            this.datos.genero_id=this.datos.genero.id
            this.datos.municipio_id=this.datos.municipio.id
            this.datos.estado_id=this.datos.estado.id
            this.datos.estado_civil_id=this.selectedecivil

            if (this.generarNoExp) {
                this.datos.no_expediente=this.no_expediente_legacy
            }else{
                this.datos.no_expediente=""
            }

            try {
                const res = await fetch(`${url}/api/pacientes/update/${this.idPacienteSeleccionado}`, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err =[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
               
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.resetForm()
                    // this.getExpediente()
                    this.erroresForm1={}
                    this.erF1=false
                }else{
                    this.openError=true
                    this.erroresForm1=err
                    this.erF1=true
                     this.busy=false
                }

                if (this.erroresForm1.password) {
                    setTimeout(() => {
                        this.openError=false
                        this.openFirmaPsw=true
                    }, 2000);
                }

            } catch (error) {
                this.busy=false
            }
        },

        async updateResponsable(){
            this.busy=true
            this.responsable.genero_id=this.responsable.genero.id
            this.responsable.estado_id=this.responsable.estado.id
            this.responsable.municipio_id=this.responsable.municipio.id
            try {
                const res = await fetch(`${url}/api/responsables_paciente/update/${this.responsable.id}`, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.responsable)
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err =[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
               
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.resetForm()
                    // this.getExpediente()
                    this.erroresForm2={}
                    this.erF2=false
                }else{
                    this.openError=true
                    this.erroresForm2=err
                    this.erF2=true
                     this.busy=false
                }

                if (this.erroresForm2.password) {
                    setTimeout(() => {
                        this.openError=false
                        this.openFirmaPsw=true
                    }, 2000);
                }

            } catch (error) {
                this.erroresForm1=err
                this.busy=false
            }
        },

        async updateFacturacion(){
            this.busy=true
            this.dataFacturacion.responsable_id=this.responsable.id
            try {
                const res = await fetch(`${url}/api/facturacion/${this.dataFacturacion.id}`, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataFacturacion)
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err =[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
               
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.resetForm()
                    this.getExpediente()
                    this.erroresFormFact={}
                    this.erFact=false
                }else{
                    this.openError=true
                    this.erroresFormFact=err
                    this.erFact=true
                     this.busy=false
                }

                if (this.erroresFormFact.password) {
                    setTimeout(() => {
                        this.openError=false
                        this.openFirmaPsw=true
                    }, 2000);
                }

            } catch (error) {
                this.erroresForm1=err
                this.busy=false
            }
        },
        // jelpmi esto lo utilice pa editar 
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        datosFacturacion() {
            try {
                if (this.dtaFacturacion == true) {
                    this.form_dta_facturacion = true;
                } else {
                    this.form_dta_facturacion = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        upperCase() {
            this.dataFacturacion.rfc = this.dataFacturacion.rfc.toUpperCase();
        },

        async registroFacturacion(id) {
            this.busy=true
            this.dataFacturacion.responsable_id=id
            this.responsable.password=this.password
            try {
                const res = await fetch(`${url}/api/facturacion`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataFacturacion),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var warn = false
                var err =[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err = val.errors
                    }
                });
               
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.erroresFormFact={}
                    this.erFact=false
                    if (!this.hayFactura) {
                        this.resetForm()
                        this.getExpediente()
                        this.hayFactura=false
                    }
                }else{
                    this.openError=true
                    this.erroresFormFact=err
                    this.erFact=true
                     this.busy=false
                }

                if (this.erroresFormFact.password) {
                    setTimeout(() => {
                        this.openError=false
                        this.openFirmaPsw=true
                    }, 2000);
                }

            } catch (error) {
                console.log(error);
                this.busy=false
            }
        },

        async obtenerCatalogoFactura() {
            try {
                const res = await fetch(`${url}/api/catalogos/facturacion`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.formaPago = data.forma_pago.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.formaPago.unshift({value:null, text:"seleccione una opción"})

                this.metodoPago = data.metodo_pago.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.metodoPago.unshift({value:null, text:"seleccione una opción"})

                this.usoCfdi = data.uso_cfdi.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.usoCfdi.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        async getEstados() {
            try {
                const res = await fetch(`${url}/api/catalogos/estados`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaEstados=data.estados.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaEstados.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        async getMunicipiosPaciente(idEstado) {
            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEstado}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMunicipiosPaciente=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosPaciente.unshift({value:null,text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        async getMunicipiosResp(idEdo) {
            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEdo}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMunicipiosResp=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosResp.unshift({value:null,text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        /* Obtener datos a partir del cp */
        searchDataCP: debounce(function(){
            if (this.datos.cp.length > 4) {
                fetch(`${url}api/catalogos/codigo_postal/${this.datos.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    this.datosCP = data.asentamientos;
                })
            }
        },500),

        async getTratamiento() {
            try {
                const res = await fetch(`${url}/api/catalogos/tratamientos`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaTratamiento=data.tratamientos.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaTratamiento.unshift({value:null,text:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerMedicosPerEspecialidad(idEspecialidad) {
            this.expediente.medico_id = null
            try {
                const res = await fetch(`${url}/api/catalogos/medicos/${idEspecialidad}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMedicos=data.medicos
                this.dtaMedicos.unshift({id:null,nombre:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaEspecialidades = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
                this.dtaEspecialidades.unshift({ value: null, text: 'seleccionar' })
            } catch (error) {
                console.log(error);
            }
        },
        async getGeneros() {
            try {
                const res = await fetch(`${url}/api/catalogos/genero`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.genero=data.genero.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.genero.unshift({value:null, text:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },

        async getHabitaciones() {
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/habitacion'))
            }).then((data)=>{
                // function comparar(a, b) {
                //     return a.id - b.id;
                // }
                this.abit=data.habitaciones.map(item => {
                    return {
                        value: item.id,
                        text: item.name
                    };
                })
                
            })
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        rgexDomicilio(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z0-9-ZÀ-ÿ \u00f1\u00d1]*$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },


        getNumber(id){
            localStorage.removeItem("expedienteEncrypt")
            if (this.donePacientes) {
                var copiaP = this.pacientes
                const paciente = copiaP.find(paciente=>paciente.id==id)
                if (paciente) {
                    if (paciente.id==id) {
                        paciente.telefono=""
                    }
                    this.datosPacienteSeleccionado=paciente
                    this.datos=paciente
                    this.getMunicipiosPaciente(this.datosPacienteSeleccionado.estado.id)
                    this.selectedecivil=paciente.estado_civil.id
                    var year = new Date(this.datos.fecha_nacimiento)
                    var today = new Date();
                    var yyyy = today.getFullYear();
                    this.datos.edad= yyyy-year.getFullYear();
                    this.getExpediente();   
                }else{
                    setTimeout(() => {
                       router.push("/pacientes/1") 
                    }, 2000);
                    alert("Paciente inexistente")
                }
            }else{
                setTimeout(() => {
                    this.getNumber(id)
                }, 1000);
            }
        },
        verificar(){
            this.erF2=false
            this.erF3=false
            this.erFact=false
            this.erroresForm2={}
            this.erroresForm3={}
            this.erroresFormFact={}

            if (this.dtaFacturacion) {
                for (const property in this.dataFacturacionVerificar) {
                    if (`${this.dataFacturacion[property]}`==`${null}` || `${this.dataFacturacion[property]}`=="") {
                        this.erroresFormFact[property]=[`${this.dataFacturacionVerificar[property]}`]
                        this.openError=true
                        this.erFact=true
                    }
                }
                if (!(this.dataFacturacion.razon_social.length>3&&this.dataFacturacion.razon_social.length<=250)) {
                    this.erroresFormFact.razon10=["El campo razon social debe contener almenos 4 caracteres y no mas de 250"]
                    this.openError=true
                    this.erFact=true
                }
                if (this.dataFacturacion.rfc.length<13) {
                    this.erroresFormFact.rfc10=["El campo RFC debe contener 13 caracteres"]
                    this.openError=true
                    this.erFact=true
                }
            }

            if (this.responsable.nombre.length<10) {
                this.erroresForm2.nombre10=["El campo nombre de responsable debe contener almenos 10 caracteres"]
                this.openError=true
                this.erF2=true
            }
            for (const property in this.erroresf2) {
                if (`${this.responsable[property]}`==`${null}` || `${this.responsable[property]}`=="") {
                    this.erroresForm2[property]=[`${this.erroresf2[property]}`]
                    this.openError=true
                    this.erF2=true
                }
            }
            for (const property in this.erroresf3) {
                if (`${this.expediente[property]}`==`${null}` || `${this.expediente[property]}`=="") {
                    this.erroresForm3[property]=[`${this.erroresf3[property]}`]
                    this.openError=true
                    this.erF3=true
                }
            }
            
            if (this.responsable.cp.length<5 ) {
                this.erroresForm2.cp=["El codigo postal del responsable es requerido"]
                this.openError=true
                this.erF2=false
            }
            if (this.responsable.genero.id==null ) {
                this.erroresForm2.genero=["El Genero del responsable es requerido"]
                this.openError=true
                this.erF2=true
            }
            if (this.responsable.municipio.id==null ) {
                this.erroresForm2.municipio=["El municipio del responsable es requerido"]
                this.openError=true
                this.erF2=true
            }
            if (this.responsable.estado.id==null ) {
                this.erroresForm2.estado=["El estado del responsable es requerido"]
                this.openError=true
                this.erF2=true
            }
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.responsable.email && !pattern.test(this.responsable.email)&&this.responsable.email.length>0) {
                this.erroresForm2.email=["El formato email del responsable es incorrecto"]
                this.openError=true
                this.erF2=true
            }
            if (this.responsable.telefono.length!=10 ) {
                this.erroresForm2.telefono=["El telefono del responsable es requerido y debe de contener 10 digitos"]
                this.openError=true
                this.erF2=true
            }
           
            if (this.expediente.medico_id==null ) {
                this.erroresForm3.medico_id=["El medico tratante es requerido"]
                this.openError=true
                this.erF3=true
            }

            if (this.expediente.tiene_condicion_psicologica && this.expediente.bajo_tratamiento && this.expediente.tratamiento_psicologico=="" ) {
                this.erroresForm3.tratamiento=["El campo bajo tratamiento es requerido"]
                this.openError=true
                this.erF3=true
            }

            if (this.expediente.dificultad_caminar && this.expediente.usa_apoyo && this.expediente.tipo_apoyo=="" ) {
                this.erroresForm3.tipo_apoyo=["El campo tipo de apoyo es requerido"]
                this.openError=true
                this.erF3=true
            }

            if (this.expediente.especialidad_id ==null) {
               this.erroresForm3.especialidad_id=["El campo especialidad es requerido"]
                this.openError=true
                this.erF3=true
            }

            if (this.expediente.alergias == "" && this.expediente.tiene_alergias) {
               this.erroresForm3.alergias=["El campo alergias es requerido"]
                this.openError=true
                this.erF3=true
            }
            if (this.expediente.escolaridad_id == "" || this.expediente.escolaridad_id==null) {
                this.erroresForm3.escolaridad_id=["El campo escolaridad es requerido"]
                this.openError=true
                this.erF3=true
            }

            if (!this.erF3&&!this.erF2&&!this.erFact) {
                if (this.status=='new') {
                    this.registroResponsable(this.idPacienteSeleccionado)
                }
            }else{
                this.btnGuardar = false;
                this.btnFirmar = true;
                this.busy=false
            }
        },

        async obtenerPronostico() {
            try {
                const res = await fetch(`${url}/api/catalogos/pronostico`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.dtaPronostico = data.pronostico.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaPronostico.unshift({value:null, text:"seleccione una opción"})
            } catch(error) {
                console.log(error);
            }
        },

        GB(){
            if (!this.expediente.grupo_vulnerable) {
                this.expediente.dificultar_hablar=false
                this.expediente.porta_equipo_audicion=false
                this.expediente.dificultad_ver=false
                this.expediente.usa_lentes=false
                this.expediente.dificultad_caminar=false 
                this.expediente.usa_apoyo=false;
                this.expediente.dificultad_oir=false
                this.expediente.porta_equipo_audicion=false
                this.expediente.dificultar_comprender_indicaciones=false
                this.expediente.tiene_condicion_psicologica=false
                this.expediente.bajo_tratamiento=false
                this.expediente.tratamiento_psicologico=""
            }
        },
        
        async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${urlec}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();


                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift( { value: null, text: 'seleccione una opcion' },)


            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${url}/api/pacientes`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.pacientes=data.pacientes;
                this.donePacientes=true

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async getExpediente() {
            try {
                const res = await fetch(`${link2}${this.idPacienteSeleccionado}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.expediente) {
                    this.sinExp=false
                    var expediente = CryptoJS.AES.encrypt(JSON.stringify(data.expediente), 'secret key heal4701tech')
                    localStorage.setItem('expedienteEncrypt', expediente);
                    this.expediente=data.expediente
                    this.idExpediente=data.expediente.id
                    this.lastExpediente=true
                    this.setData(true)
                }else{
                    this.loading=false
                    this.regNewExp = true
                    Vue.prototype.$sinExp = true
                    this.sinExp=true
                }
            } catch (error) {
                router.push("/pacientes/1")
                console.log(error);
            }
        },

        async getResponsable() {
            try {
                const res = await fetch(`${link}${this.idPacienteSeleccionado}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.getMunicipiosResp(data.responsable.estado.id)
                this.responsable=data.responsable
                this.getFacturacion(data.responsable.id)

            } catch (error) {
                console.log(error);
            }
        },

        async getFacturacion(id) {
            try {
                const res = await fetch(`${url}/api/facturacion/${id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.facturacion) {
                    this.dtaFacturacion=true
                    this.form_dta_facturacion = true;
                    this.dataFacturacion.razon_social=data.facturacion[(data.facturacion).length-1].razon_social
                    this.dataFacturacion.rfc=data.facturacion[(data.facturacion).length-1].rfc
                    this.dataFacturacion.email=data.facturacion[(data.facturacion).length-1].email
                    this.dataFacturacion.domicilio=data.facturacion[(data.facturacion).length-1].domicilio
                    this.dataFacturacion.metodo_pago_id=data.facturacion[(data.facturacion).length-1].metodo_pago.id
                    this.dataFacturacion.forma_pago_id=data.facturacion[(data.facturacion).length-1].forma_pago.id
                    this.dataFacturacion.uso_cfdi_id=data.facturacion[(data.facturacion).length-1].uso_cfdi.id
                    this.dataFacturacion.id=data.facturacion[(data.facturacion).length-1].id
                }else{
                    this.hayFactura=false
                    this.dtaFacturacion=false
                    this.form_dta_facturacion = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        change:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           

            this.expediente.paciente_edad_years = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());

            this.expediente.paciente_edad_months = (function() {
                if(today.getMonth() >= dob.getMonth()) {
                    if(today.getDate() >= dob.getDate()) {
                        return today.getMonth() - dob.getMonth();
                    }
                    else {
                        if((today.getMonth() - 1) >= dob.getMonth()) {
                            return (today.getMonth() - 1) - dob.getMonth();
                        }
                        else {
                            return ((today.getMonth() - 1) + 12) - dob.getMonth();
                        }
                    }
                }
                else {
                    if(today.getDate() >= dob.getDate()) {
                        return (today.getMonth() + 12) - dob.getMonth();
                    }
                    else {
                        return ((today.getMonth() - 1) + 12) - dob.getMonth();
                    }
                }
            }());

            if (this.datos.fecha_nacimiento!="") {
                this.datos.edad = this.year
                
            }else{
                this.datos.edad=""
            }
		},

        setFechaHora(){
            var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.expediente.hora_ingreso=""+hour+":"+minutes
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();

            this.expediente.fecha_ingreso = yyyy + '-' + mm + '-' + dd ;
        },
        
        async getEscolaridades() {
            try {
                const res = await fetch(`${url}/api/catalogos/escolaridades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.escolaridades=data.escolaridades
                this.escolaridades.unshift({id:"",nombre:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },
        setData(status){
            if (status) {
                try {
                    const pacienteEncrypt=localStorage.expedienteEncrypt
                    const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
                    const paciente=JSON.parse(expediente)
                    console.log(paciente);
                    this.expediente=paciente
                    console.log(this.expediente);
                    
                    this.expediente.especialidad_id=paciente.especialidad.id
                    this.getMunicipiosPaciente(paciente.paciente.estado.id)
                    this.obtenerMedicosPerEspecialidad(paciente.especialidad.id)
                    this.expediente.medico_id=paciente.medico.id
                    this.expediente.especialidad_id=paciente.especialidad.id
                    this.expediente.tiene_alergias=paciente.tiene_alergias==1
                    this.expediente.mayor_18_yaers=paciente.mayor_18_yaers==1
                    this.expediente.mayor_60_yaers=paciente.mayor_60_yaers==1
                    this.expediente.grupo_vulnerable=paciente.grupo_vulnerable==1
                    this.expediente.dificultar_hablar=paciente.dificultar_hablar==1
                    this.expediente.dificultad_oir=paciente.dificultad_oir==1
                    this.expediente.porta_equipo_audicion=paciente.porta_equipo_audicion==1
                    this.expediente.dificultad_ver=paciente.dificultad_ver==1
                    this.expediente.usa_lentes=paciente.usa_lentes==1
                    this.expediente.dificultad_caminar=paciente.dificultad_caminar==1
                    this.expediente.usa_apoyo=paciente.usa_apoyo==1
                    this.expediente.dificultar_comprender_indicaciones=paciente.dificultar_comprender_indicaciones==1
                    this.expediente.tiene_condicion_psicologica=paciente.tiene_condicion_psicologica==1
                    this.expediente.bajo_tratamiento=paciente.bajo_tratamiento==1
                    this.expediente.escolaridad_id=paciente.escolaridad.id
                    this.expediente.tratamiento_id=paciente.tratamiento.id
                    this.expediente.tratamiento_psicologico=paciente.tratamiento_psicologico
                    this.expediente.pronostico_id=paciente.pronostico_id.id
                    this.idPacienteSeleccionado=paciente.paciente.id
                    this.datosPacienteSeleccionado=paciente.paciente
                    this.generarNoExp=paciente.paciente.no_expediente_legacy?true:false
                    this.no_expediente_legacy=paciente.paciente.no_expediente_legacy?paciente.paciente.no_expediente_legacy:""
                    this.datos=this.datosPacienteSeleccionado
                    this.selectedecivil=this.datos.estado_civil.id
                    var year = new Date(this.datos.fecha_nacimiento)
                    var today = new Date();
                    var yyyy = today.getFullYear();
                    this.datos.edad= yyyy-year.getFullYear();
                    this.getResponsable()
                    this.getNotas(this.expediente.id)
                    this.loading=false
                    this.obtenerNotaDeEgreso(paciente.paciente.lastExpediente.expediente_id);
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                } catch (error) {
                    console.log("");
                }
            }
        },

        setDatos(){
            // console.trace()
            this.idPacienteSeleccionado=this.$route.params.id
            // this.state.idPaciente=this.$route.params.id
            this.obtenerPacientes()
            this.obtenerEspecialidades()
            this.getEscolaridades()
            this.setFechaHora()
            this.obtenerEstadoCivil()
            this.getEstados()
            this.getTratamiento()
            this.obtenerCatalogoFactura()
            this.getGeneros()
            this.getHabitaciones()
            this.obtenerPronostico()
            this.allExpedientes()
            this.getAdmision()
            if (localStorage.expedienteEncrypt) {
                this.obtenerNotaDeEgreso(JSON.parse(CryptoJS.AES.decrypt(localStorage.expedienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)).paciente.lastExpediente.expediente_id);
            }
            
            if ( this.state.idPaciente!=this.$route.params.id || this.status.idExpediente=="" || !localStorage.expedienteEncrypt) {
                this.getNumber(this.idPacienteSeleccionado);
                this.state.idPaciente=this.$route.params.id
            }else{
                try {
                    this.changeExp((JSON.parse(CryptoJS.AES.decrypt(localStorage.expedienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)).id));
                } catch (error) {
                    console.log(error);
                }
                }
                this.noPaciente=this.idPacienteSeleccionado
                for (let i = 0; i < 5-this.idPacienteSeleccionado.length; i++) {
                    this.noPaciente="0".concat(this.noPaciente)
                }
        },
        async getAdmision() {
            try {
                const res = await fetch(`${url}/api/hoja_admision/admision/`+this.$route.params.id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                Vue.prototype.$procedimientoPaciente = data.data.procedimiento
            } catch (error) {
                Vue.prototype.$procedimientoPaciente = ""
            }
        },

        soloNumerosExpediente(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;

            }
        },

        soloNumerosExpedienteUp(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (this.no_expediente_legacy.length==4 && charCode!=8 ) {
                this.no_expediente_legacy=this.no_expediente_legacy+"-"
            }
            if (this.no_expediente_legacy.length==5 && charCode!=8 && this.no_expediente_legacy.slice(4,5)!='-') {
                const x = this.no_expediente_legacy.substring(0,4)+"-"+this.no_expediente_legacy.substring(4,this.no_expediente_legacy.length)
                this.no_expediente_legacy = x
            }
            if (this.no_expediente_legacy.length==10 && charCode!=8 ) {
                this.no_expediente_legacy=this.no_expediente_legacy+"-"
            }
            if (this.no_expediente_legacy.length==11 && charCode!=8 && this.no_expediente_legacy.slice(10,11)!='-') {
                const x = this.no_expediente_legacy.substring(0,10)+"-"+this.no_expediente_legacy.substring(10,this.no_expediente_legacy.length)
                this.no_expediente_legacy = x
            }
        },

        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            console.log('cerrarModalError');
            this.openError=false
        },
        firma_psw(psw){
            this.editando = true
            this.openFirmaPsw=false;
            this.password=psw
            this.btnGuardar = true;
            this.btnFirmar = false;
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw=false;
            this.disabled=true
            this.status="show"

            this.erroresForm1={}
            this.erroresForm2={}
            this.erroresForm3={}
            this.erroresFormFact={}
            this.erF1=false
            this.erF2=false
            this.erF3=false
            this.erFact=false
        },
        nuevoExpediente(){
            this.regNewExp = false
            this.newExpedient()
        },
    },

    mounted(){
        const perfil =  CryptoJS.AES.decrypt(localStorage.perfil, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
        this.perfil=perfil
    },
    beforeDestroy(){
        localStorage.removeItem("expedienteEncrypt")
    },
    destroyed() {  
        this.$destroy();
    },
    activated(){
        this.prevent()
        this.setDatos()
        // this.obtenerPacientes()
        // this.obtenerEspecialidades()
        // this.getEscolaridades()
        // this.setFechaHora()
        // this.obtenerEstadoCivil()
        // this.getEstados()
        // this.getTratamiento()
        // this.obtenerCatalogoFactura()
        // this.getGeneros()
        // this.getHabitaciones()
        // this.obtenerPronostico()
        // this.allExpedientes()
        // this.getAdmision()
    },
    deactivated(){
    },
}
</script>

<style scoped>
    @media only screen and (max-width: 900px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (min-width: 900px) {
        .size_th {
            width: 15vw;
        }
    }

    @media only screen and (max-width: 1000px) {
        .size_th_grupoVulnerable {
            width: 100vw;
        }
    }

    @media only screen and (min-width: 1000px) {
        .size_th_grupoVulnerable {
            width: 32vw;
        }
    }

    .btn-print-ha {
        background-color: #d0eeff;
        border-radius: 10px;
        font-size: .9rem;
    }
</style>