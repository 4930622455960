<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Nota de consulta</h2>      
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>

        <b-container class="py-4">
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_th th_style">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota de consulta guardado correctamente
                    </div>
                </b-alert>
            </div>

            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Información del paciente</strong></p>
            <b-row >
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row> -->
            <Datos :changeData="changeDta"/>
            <!-- <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th th_style">Nombre</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Fecha de nacimiento</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Genero</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.paciente_edad_years">
                                <b-th class="th_style">Edad</b-th>
                                <b-td class="border_table">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_years}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">años</span>
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_months}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">meses</span>
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.id">
                                <b-th class="th_style">Idioma</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.idioma}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.id">
                                <b-th class="th_style">Religion</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.religion}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Estado civil</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Código postal</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Domicilio</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Colonia/Comunidad</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Ciudad</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Estado</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Municipio</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Teléfono</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Email</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row> -->
           
            <p class="txt_secondary mt-2">Motivo de consulta</p>
            <b-form-textarea v-model="dtaConsulta.motivo_consulta" :state="motivoConsulta" :disabled="(disableInput || state.oldExp || isEgresado)" name="motivoConsulta" autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />
        
            <p class="txt_secondary mt-3">Exploración física</p>
            <b-form-textarea v-model="dtaConsulta.exploracion_fisica" :disabled="(disableInput || state.oldExp || isEgresado)" name="exploracion_fisica" autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />
             
            <b-row class="mt-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th_medidas th_style">Peso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.peso" :state="peso" :disabled="(disableInput || state.oldExp || isEgresado)" @keyup="calculoIMCandASC()" maxlength="4" type="text" name="peso" autocomplete="off" v-on:keypress="soloNumeros()" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">kg</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Talla</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.talla" :state="talla" :disabled="(disableInput || state.oldExp || isEgresado)" @keyup="calculoIMCandASC()" maxlength="4" type="text" name="talla" autocomplete="off" v-on:keypress="soloNumeros()" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="size_th_last_table th_style">ASC</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.asc" :state="asc" disabled type="text" name="ASC" autocomplete="off" v-on:keypress="soloNumeros($event)" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">m²</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">I.M.C</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.imc" :state="imc" disabled type="text" name="imc" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">Kg/altura²</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.frecuencia_cardiaca" :state="freCardiaca" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="fc" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.frecuencia_respiratoria" :state="frecRespiratoria" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="fr" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style size_th_frecuencias">Tensión arterial</b-th>
                                <b-td class="border_table">                     
                                    <div class="d-flex">
                                        <div class="px-2">
                                            <b-form-input v-model="tension1" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="ta1" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" class="border-0 text-center" size="sm" placeholder="#"/>
                                        </div>
                                        <div class="txt_gray">/</div>
                                        <div class="px-2">
                                            <b-form-input type="text" name="ta2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension2" :disabled="(disableInput || state.oldExp || isEgresado)" :state="tensionArterial2" @keyup="tensionArterial()" class="border-0 text-center" size="sm" placeholder="#"/>
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">SPO2</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.saturacion_oxigeno" :state="saturacionOxigeno" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="SPO2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">%</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.temperatura" :state="temperatura" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="temperatura" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Perimetro de abdomen</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-model="dtaConsulta.perimetro_abdomden" :state="perimetroAbdomen" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="perimetroAbdomen" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="size_th_last_table th_style">Nivel de atención</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="dtaConsulta.nivel_atencion" :state="nivelAtencion" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="nivelAtencion" autocomplete="off" class="border-0" size="sm" maxlength="50" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm mt-4">
                        <b-tbody>
                            <b-tr>
                                <b-th class="size_table th_style">Medico consultante</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="medico_id" v-model="dtaConsulta.medico_consultante_id" :options="medicos" name="text" value="value" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
<!--             
            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm mt-4">
                        <b-tbody>
                            <b-tr>
                                <b-th class="size_table th_style">Parámetro desintoxicaciones</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="dtaConsulta.parametro_desintoxicaciones" :state="parDesintoxicacion" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="parametroDesintoxicacion" autocomplete="off" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="size_table th_style">Parámetro estimulación</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="dtaConsulta.parametro_estimulacion" :state="parEstimulacion" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="parametroEstimulacion" autocomplete="off" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="size_table th_style">Parámetro rehabilita</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="dtaConsulta.parametro_rehabilita" :state="parRehabilita" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="parametroDesintoxicacion" autocomplete="off" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row> -->

            <p class="txt_secondary mt-3">Padecimiento actual</p>
            <b-form-textarea v-model="dtaConsulta.padecimiento_actual" :state="padecimientoActual" :disabled="(disableInput || state.oldExp || isEgresado)" name="padecimientoActual" autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />
        
            <p class="txt_secondary mt-3">Impresión diagnóstica</p>
            <b-form-textarea v-model="dtaConsulta.impresion_diagnostico" :state="impresionDiag" :disabled="(disableInput || state.oldExp || isEgresado)" name="impresionDiag" autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />
            
            <p class="txt_secondary mt-3">Plan terapéutico / Resultados esperados</p>
            <b-form-textarea v-model="dtaConsulta.plan_terapeutico" :state="planTerapeutico" :disabled="(disableInput || state.oldExp || isEgresado)" name="motivoConsulta" autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" />

            <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openAlertPresion && !openError && !openSuccess && !loading)">
                <ul class="sticky">
                    <!-- <li v-if="!dtaExist" @click="preGuardar()">
                        <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                        <p>
                            <a>Preguardar</a>
                        </p>
                    </li> -->
                    <li  v-if="(dtaExist && updating && !state.oldExp && !isEgresado)" @click="editar()">
                        <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                        <p>
                            <a>Editar</a>
                        </p>
                    </li>
                    <li v-if="dtaExist && updating" @click="imprimir()">
                        <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                        <p>
                            <a>Imprimir</a>
                        </p>
                    </li>
                </ul>
            </div>


            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err.expediente_id" />| {{err[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <div v-if="showButton">

                <div v-if="(!dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                    <b-col cols="12" sm="12" md="5" lg="5">
                        <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="createNotaConsulta()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
        </div>

            <div v-if="(actualizar && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(cancelar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
        </b-container>

        <AltertPresionArterial :abrir="openAlertPresion" @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()"/>
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";
import { url } from "../../../settings"
import { getCatalogo } from '../../helpers/catalogos'
import router from "../../router";

import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
import Loading from '@/components/Loader/Loading.vue'
import Success from '@/components/Modals/Success.vue'
import Error from '@/components/Modals/Error.vue'
import Foto from '@/components/Paciente/Foto.vue'
import CryptoJS from 'crypto-js'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'

export default {
    components: { Datos, Foto, Success, Error, Loading, AltertPresionArterial },
    name: 'NotaDeConsulta',
    data: () => ({
        showButton:true,
        changeDta:null,
        isEgresado:false,
        openError: false,
        openSuccess: false,

        error: false,
        errores: [],
        busy: false,
        disableInput: false,
        
        openAlertPresion: false,
        dtaExist: false,
        btnGuardar: false,
        updating: true,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,

        tension2: '',
        tension1: '',
        info: false,
        loading: false,
        
        no_expediente: '',
        idPacienteSeleccionado: '',
        datosExpediente:{},
        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        state:{
            idExpediente: '',
            idPaciente: '',
            oldExp:false,
        },

        dtaConsulta: {
            id: null,
            motivo_consulta: '',
            padecimiento_actual: '',
            plan_terapeutico: '',
            peso: '',
            talla: '',
            temperatura: '',
            saturacion_oxigeno: '',
            frecuencia_cardiaca: '',
            frecuencia_respiratoria: '',
            tension_arterial: '',
            imc: '',
            perimetro_abdomden: '',
            asc: '',
            nivel_atencion: '',
            parametro_desintoxicaciones: '',
            parametro_estimulacion: '',
            parametro_rehabilita: '',
            exploracion_fisica: '',
            impresion_diagnostico: '',
            medico_consultante_id: null,
        },

        cleanDta: {
            id: null,
            expediente_id: null,
            motivo_consulta: '',
            padecimiento_actual: '',
            plan_terapeutico: '',
            peso: '',
            talla: '',
            temperatura: '',
            saturacion_oxigeno: '',
            frecuencia_cardiaca: '',
            frecuencia_respiratoria: '',
            tension_arterial: '',
            imc: '',
            perimetro_abdomden: '',
            asc: '',
            nivel_atencion: '',
            parametro_desintoxicaciones: '',
            parametro_estimulacion: '',
            parametro_rehabilita: '',
            exploracion_fisica: '',
            impresion_diagnostico: '',
            medico_consultante_id: null,
        },
        allExp:[],
        medicosIE: [],
        medicos: [],
        id_Exp: '',
    }),

    computed: {
        motivoConsulta() {
            return this.dtaConsulta.motivo_consulta != ''
        },
        padecimientoActual() {
            return this.dtaConsulta.padecimiento_actual != ''
        },
        planTerapeutico() {
            return this.dtaConsulta.plan_terapeutico != ''
        },
        peso() {
            return this.dtaConsulta.peso != ''
        },
        talla() {
            const patternExp = /^[0-9]{1,3}[.]{0,1}[0-9]{0,2}$/;
            return patternExp.test(this.dtaConsulta.talla)
        },
        temperatura() {
            return this.dtaConsulta.temperatura != ''
        },
        saturacionOxigeno() {
            return this.dtaConsulta.saturacion_oxigeno != ''
        },
        freCardiaca() {
            return this.dtaConsulta.frecuencia_cardiaca != ''
        },
        frecRespiratoria() {
            return this.dtaConsulta.frecuencia_respiratoria != ''
        },
        tensionArterial2() {
            return this.tension2 != '' && this.tension1 != ''
        },
        imc() {68
            return this.dtaConsulta.imc != ''
        },
        perimetroAbdomen() {
            return this.dtaConsulta.perimetro_abdomden != ''
        },
        asc() {
            return this.dtaConsulta.asc != ''
        },
        nivelAtencion() {
            return this.dtaConsulta.nivel_atencion != ''
        },
        parDesintoxicacion() {
            return this.dtaConsulta.parametro_desintoxicaciones != ''
        },
        parEstimulacion() {
            return this.dtaConsulta.parametro_estimulacion != ''
        },
        parRehabilita() {
            return this.dtaConsulta.parametro_rehabilita != ''
        },
        impresionDiag() {
            return this.dtaConsulta.impresion_diagnostico != ''
        },
        medico_id() {
            return this.dtaConsulta.medico_consultante_id != null
        }
    },

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        async createNotaConsulta() {
            this.dtaConsulta.tension_arterial=this.tension1+"/"+this.tension2
            this.dtaConsulta.expediente_id = this.id_Exp
            console.log({Data: this.dtaConsulta});
            this.error=false
            this.errores=[]
            this.busy = true
            try {
                const res = await fetch(`${url}/api/notas/consulta_externa`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaConsulta),
                });
                const data = res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err =[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.setData()
                }else{
                    this.error=true
                    this.errores=err
                    this.openError=true
                    this.busy=false
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getNotaConsulta(id) {
            try {
                const res = await fetch(`${url}/api/notas/consulta_externa/${id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json()
                if (data.status == false) {
                    this.disableInput = false
                    this.dtaExist = false
                    this.obtenerExploracionFisica(id)
                    this.obtenerInterrogatorio(id)
                } else {
                    this.dtaExist = true
                    this.disableInput = true

                    this.dtaConsulta = data.nota_consulta_externa
                    this.dtaConsulta.id = data.nota_consulta_externa.id
                    this.dtaConsulta.medico_consultante_id = data.nota_consulta_externa.medico_consultante_id.id
                    this.dtaConsulta.talla = (data.nota_consulta_externa.talla).toFixed(2)

                    var ta = this.dtaConsulta.tension_arterial
                    var ta1 = ta.indexOf("/")
                    this.tension1 = ta.substring(0,ta1)
                    this.tension2 = ta.substring(ta1+1,ta.length)
                } 
                var ide = localStorage.getItem("perfil");
                    CryptoJS.AES.decrypt(ide, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)=='3'?this.showButton=true:this.showButton=false
                    console.log(this.showButton);
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log(error);
            }
        },
        async obtenerExploracionFisica(value) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/exploracion_fisica/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                 if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log(data);
                this.dtaConsulta.peso=data.nota_exploracion_fisica.peso?data.nota_exploracion_fisica.peso:""
                this.dtaConsulta.talla=data.nota_exploracion_fisica.talla?data.nota_exploracion_fisica.talla:""
                this.calculoIMCandASC()
                
            } catch (error) {
                console.log(error);
            }
        },
        async obtenerInterrogatorio(value) {
            
            try {
                const res = await fetch(`${url}/api/historia_clinica/interrogatorio/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.dtaConsulta.padecimiento_actual=data.nota_interogatorio.padecimiento_actual?data.nota_interogatorio.padecimiento_actual:""
            } catch (error) {
                console.log(error);
            }
        },
        async updateData() {
            console.log({DATAAA:this.dtaConsulta});
            this.error=false
            this.errores=[]
            this.busyUpdate = true
            this.dtaConsulta.tension_arterial=this.tension1+"/"+this.tension2
            delete this.dtaConsulta.can
            delete this.dtaConsulta.created_by
            this.dtaConsulta.expediente_id = this.id_Exp
            console.log({DATAENV: this.dtaConsulta});
            try {
                const res = await fetch(`${url}/api/notas/consulta_externa/${this.dtaConsulta.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaConsulta)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.setData()
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.errores=err
                    this.error=true
                    this.busyUpdate = false
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },

        cancelEdit() {
            this.setData()
            // this.getNotaConsulta()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            this.actualizar = true
            this.cancelar = true
            this.updating = false
            this.disableInput = false
        },

        async imprimir() {   
            // var can = false;
            var este = this;
            this.loading = true    
            let urlim = `${url}/api/notas/consulta_externa/pdf/${this.id_Exp}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)

            })
        },

        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.no_expediente+" Nota de consulta");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json();
                this.allExp=data.expedientes
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        changeExp(id){
            console.log({ELID: id});
            this.cleanData()
            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp=false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta=currentExp.id
                this.setData(true)
            }
            this.loading=false
        },

        cleanData() {
            this.dtaConsulta = this.cleanDta
            this.tension1 = ''
            this.tension2 = ''
        },

        calculoIMCandASC() {
            if (this.dtaConsulta.peso == '' || this.dtaConsulta.talla == '' || this.dtaConsulta.talla > 250.0 || this.dtaConsulta.talla < .999 || isNaN(this.dtaConsulta.talla)) {
                this.dtaConsulta.imc = ''
                this.dtaConsulta.asc = ''
            } else {
                const RE = /^[0-9]+([,][0-9]+)?$/;
                if (RE.test(this.dtaConsulta.talla)) { 
                    let talla2 = 0
                    talla2 = (this.dtaConsulta.talla/100)
                    console.log(this.dtaConsulta.talla/100);
                    if(isNaN(talla2)) {
                        this.dtaConsulta.imc = ''
                        this.dtaConsulta.asc = ''
                    } else {
                        this.dtaConsulta.imc = (this.dtaConsulta.peso / Math.pow(talla2, 2)).toFixed(2)
                        this.dtaConsulta.asc = Math.sqrt(((this.dtaConsulta.peso*talla2)/3600)*100).toFixed(4)
                    }
                } else {
                    let talla1 = 0
                    talla1 = (this.dtaConsulta.talla*100)/100
                    if (isNaN(talla1)) {
                        this.dtaConsulta.imc = ''
                        this.dtaConsulta.asc = ''
                    } else {
                        this.dtaConsulta.imc = (this.dtaConsulta.peso / Math.pow(talla1, 2)).toFixed(2)
                        this.dtaConsulta.asc = Math.sqrt(((this.dtaConsulta.peso*talla1)/3600)*100).toFixed(4)
                    }
                }
            }
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        tensionArterial() {
            if (this.tension2.length >= 2 && this.tension1.length >= 2) {
                setTimeout(() => {
                if (this.tension1 < 100 || this.tension1 > 140 || this.tension2 < 60 || this.tension2 > 90) {
                        this.openAlertPresion = true
                }
                }, 500);
            }
        },

        setData(){            
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.changeDta=paciente.id
            this.no_expediente=paciente.no_expediente

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id
            console.log({expOLD: paciente.paciente.lastExpediente.expediente_id});
            console.log({ELEXP: paciente.id});
            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                this.state.oldExp=true
                console.log('STATE: true');
            }else{
                this.state.oldExp=false
                console.log('STATE: false');
            }

            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }

            this.idPacienteSeleccionado=paciente.paciente.id
            this.id_Exp=paciente.id;
            console.log({EXP: paciente.id});
            this.datosPaciente=paciente.paciente
            this.datosExpediente=paciente
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.btnGuardar = false;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.allExpedientes()
            this.obtenerMedicos()
            this.getNotaConsulta(paciente.id)
        },

        //  Medicos internos externos **********************************************************************************************************************************
        obtenerMedicos() {
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/medicos_internos_externos'))
            }).then((data)=>{
                try {
                    this.medicos = data.medicos.map(item => {
                        return {
                            value: item.medico_interno_id,
                            text: item.nombre
                        }
                    })
                    this.medicos.unshift( { value: null, text: 'seleccione una opción' })
                } catch (error) {
                    console.log(error)
                }
            })
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        cerrarModalAlertPresionArterial(){
            this.openAlertPresion = false
        }
    },

    activated(){
        Object.assign(this.$data, this.$options.data());

        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }

        this.loading=true
        this.setData()
    },

    mounted() {
    },
}

</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 20vw;
        }
        .size_table {
            width: 25vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 20vw;
        }
        .size_table {
            width: 28vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
        .size_table {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 20vw;
        }
        .size_table {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
        .size_table {
            width: auto;
        }
    }
</style>
