<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">hoja de ingreso</h2>   
            </b-col>
        </b-row>
        
        <b-container>

            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_table th_style">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(dtaIngreso.firma.is_firmado==1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Hoja de ingreso firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(dtaIngreso.firma.is_firmado==0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la hoja de ingreso
                    </div>
                </b-alert>
            </div>

            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row> -->

            <Datos :changeData="changeDta"/>

            <!-- <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_table">Nombre</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                            <b-th class="th_style">Género</b-th>
                            <b-td class="border_table">
                                <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                            </b-td>
                        </b-tr>
                    <b-tr v-if="info && datosExpediente.id">
                        <b-th class="th_style">Idioma</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.idioma}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.id">
                        <b-th class="th_style">Religion</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.religion}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                             <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Colonia/Comunidad</b-th>
                        <b-td class="border_table">
                             <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_table">Número de expediente</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.no_expediente}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->
            <!-- LISTADO DE EXPEDIENTES -->
            <p class="txt_secondary text-uppercase mt-3">
                <strong>Información genereal</strong>
            </p>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <!-- <b-tr>
                                <b-th class="th_style size_th">Fecha de nacimiento</b-th>
                                <b-td class="border_table">
                                    <b-form-input name="fechaNacimiento" autocomplete="off" v-model="datosPaciente.fecha_nacimiento" type="date" disabled readonly class="border-0 input-disabled" size="sm"/>
                                </b-td>
                            </b-tr> -->
                            <b-tr>
                                <b-th class="th_style size_th">Fecha de ingreso</b-th>
                                <b-td class="border_table">
                                    <b-form-input disabled name="fechaIngreso" v-model="fecha" type="date" class="border-0 input-disabled" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hora</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input disabled name="hora" v-model="hora" autocomplete="off" type="time" min="00:00:01" max="24:59:59" class="border-0 input-disabled" size="sm"></b-form-input>
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">hora</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <!-- <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Quirófano</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-on:change="autoSave()" name="quirofano" autocomplete="off" type="text" class="border-0" size="sm" placeholder="sala" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->
                </b-col>
            </b-row>

            <b-table-simple class="table-responsive table-sm mt-3">
                <b-thead>
                    <b-tr>
                        <b-th class="th_style th-signos-vitales">T.A.</b-th>
                        <b-th class="th_style th-signos-vitales">F.C.</b-th>
                        <b-th class="th_style th-signos-vitales">F.R.</b-th>
                        <b-th class="th_style th-signos-vitales">T.</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>  
                    <b-tr>
                        <b-td class="border_table">
                            <div class="d-flex">
                                <div>
                                    <b-form-input v-on:change="autoSavePA()" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="ta1" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension1" class="text-center border-0" size="sm" placeholder="#"/>
                                </div>
                                <div class="txt_gray px-1">/</div>
                                <div>
                                    <b-form-input v-on:change="autoSavePA()" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="ta2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" :state="tension_arterial" v-model="tension2" @keyup="tensionArterial()" class="text-center border-0" size="sm" placeholder="#"/>
                                </div>
                            </div>
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="fc" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="valFC" v-model="dtaIngreso.frecuencia_cardiaca" maxlength="6" class="border-0" size="sm"/>
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="fr" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="valFr" v-model="dtaIngreso.frecuencia_respiratoria" maxlength="6" class="border-0" size="sm"/>
                        </b-td>
                        <b-td class="border_table">
                            <b-form-input v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="temperatura" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="valTemp" v-model="dtaIngreso.temperatura" maxlength="6" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="table-sm mt-4">
                <div style="">
                    <small class="txt_gray">* Los siguientes datos provienen del historial clinico</small>
                </div>
                <b-tr>
                    <b-th class="th_style">Antecedentes heredo familiares</b-th>
                </b-tr>
                <b-tr>
                    <b-td class="border_table">
                        <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="antecedentesHeredado" autocomplete="off" :state="valAntFam" v-model="dtaIngreso.antecedentes_familiares" class="border-0"  size="sm" no-resize  rows="3" max-rows="3" />
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="th_style">Antecedentes personales no patológicos</b-th>
                </b-tr>
                <b-tr>
                    <b-td class="border_table">
                        <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="antecedentNoPat" autocomplete="off" :state="valAntNoPat" v-model="dtaIngreso.antecedentes_no_patologicos" class="border-0"  size="sm" no-resize  rows="3" max-rows="3" />
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="th_style">Antececdentes personales patológicos</b-th>
                </b-tr>
                <b-tr>
                    <b-td class="border_table">
                        <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="antecedentPat" autocomplete="off" :state="valAntPat" v-model="dtaIngreso.antecedentes_patologicos" class="border-0"  size="sm" no-resize  rows="3" max-rows="3" />
                    </b-td>
                </b-tr>
            </b-table-simple>

            <p class="txt_secondary mt-3">Resumen (padecimiento actual, exploración física)</p>
            <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="resumenPadecimiento" class="txtArea_border" autocomplete="off" :state="valResumen" v-model="dtaIngreso.resumen" size="sm" no-resize  rows="3" max-rows="3" />
            
            <p class="txt_secondary mt-3">Paraclínicos</p>
            <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="paraclinicos" class="txtArea_border" autocomplete="off" :state="valParaclinicos" v-model="dtaIngreso.paraclinicos" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Imagenología</p>
            <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="imagenologia" class="txtArea_border" autocomplete="off" :state="valImagenologia" v-model="dtaIngreso.imagenologia" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Diagnósticos de ingreso</p>
            <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="diagIngreso" class="txtArea_border" autocomplete="off" :state="valDiagIngreso" v-model="dtaIngreso.diagnostico_ingreso" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Plan</p>
            <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="plan" class="txtArea_border" autocomplete="off" :state="valPlan" v-model="dtaIngreso.plan" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Tratamiento</p>
            <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="tratamiento" class="txtArea_border" autocomplete="off" :state="valTratamiento" v-model="dtaIngreso.tratamiento" size="sm" no-resize  rows="3" max-rows="3" />

            <b-table-simple class="table-sm mt-3">
            <p class="txt_secondary">Pronóstico</p>
                <b-tr>
                    <b-td colspan="2" class="border_table">
                        <b-form-textarea v-on:change="autoSave()" :disabled="(disableInput || state.oldExp || isEgresado)" name="pronostico" autocomplete="off" :state="valPronostico" v-model="dtaIngreso.pronostico" class="border-0"  size="sm" no-resize  rows="3" max-rows="3" />
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="th_style size_table">Nombre del médico</b-th>
                    <b-td class="border_table">
                        <b-form-input v-model="dtaMedico.nombre" name="nomMedico" autocomplete="off" type="text" disabled readonly class="border-0 input-disabled" size="sm" placeholder="Nombre del médico tratante" />
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="th_style">Especialidad</b-th>
                    <b-td class="border_table">
                        <b-form-input v-model="dtaMedico.especialidad" name="especialidad" autocomplete="off" type="text" disabled readonly class="border-0 input-disabled" size="sm" placeholder="especialidad" />
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="th_style">Cédula</b-th>
                    <b-td class="border_table">
                        <b-form-input v-model="dtaMedico.cedula" name="cedula" autocomplete="off" type="text" disabled readonly class="border-0 input-disabled" size="sm" placeholder="Cédula profesional del médico tratante" />
                    </b-td>
                </b-tr>
            </b-table-simple>

            <b-table-simple class="table-sm mt-3">
                <b-thead>
                    <b-tr>
                        <b-th class="th_style" colspan="2">Datos del tutor, responsable legal o familiar responsable</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_table">Nombre</b-th>
                        <b-td class="border_table">
                            <b-form-input name="nombre" autocomplete="off" v-model="dtaResponsable.nombre" disabled readonly class="border-0 input-disabled" placeholder="nombre" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-input name="genero" v-model="dtaResponsable.genero.nombre" autocomplete="off" disabled readonly class="border-0 input-disabled" placeholder="género" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="dtaResponsable.fecha_nacimiento">
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input v-model="year" name="edad" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" disabled readonly class="border-0 input-disabled" size="sm" />
                                        <span class="mt-1 txt_gray">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <b-form-input name="domicilio" autocomplete="off" v-model="dtaResponsable.domicilio" disabled readonly class="border-0 input-disabled" placeholder="domicilio" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input name="cp" autocomplete="off" v-model="dtaResponsable.cp" disabled readonly class="border-0 input-disabled" placeholder="c.p." size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia /Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input name="colonia" autocomplete="off" v-model="dtaResponsable.colonia" disabled readonly class="border-0 input-disabled" placeholder="colonia/comunidad" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input name="ciudad" v-model="dtaResponsable.ciudad" autocomplete="off" disabled readonly class="border-0 input-disabled" placeholder="ciudad" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-input name="estado" v-model="dtaResponsable.estado.nombre" autocomplete="off" disabled readonly class="border-0 input-disabled" placeholder="estado" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2"><small>{{dtaResponsable.municipio.nombre}}</small></span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input name="teléfono" v-model="dtaResponsable.telefono" autocomplete="off" disabled readonly class="border-0 input-disabled" placeholder="teléfono" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Correo electrónico</b-th>
                        <b-td class="border_table">
                            <b-form-input name="teléfono" v-model="dtaResponsable.email" autocomplete="off" disabled readonly class="border-0 input-disabled" placeholder="SIN REGISTRO" size="sm"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div v-if="dtaIngreso.firma.is_firmado==1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico: {{dtaIngreso.firma.firmado_por.nombre}}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha: {{dtaIngreso.firma.fecha_firmado}}</span>
            </div>

            <b-row v-if="error" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err1,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err1.expediente_id" />| {{err1[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="(!dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="hojaIngreso()" :disabled="busy">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <!-- <div class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="hojaIngreso()" :disabled="busy">GUARDAR</button>
                    </b-overlay>
                </b-col>
                <b-col  v-if="medicoResponsable.id==currentMedico.id" cols="12" sm="12" md="5" lg="5">
                    <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->

            <div v-if="(dtaIngreso.firma.is_firmado==0 && !actualizar && !state.oldExp && idResponsable===usuario.id && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                     <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>


            <div v-if="(actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <!-- <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->
            <!-- <div v-if="btnFirmar" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->
            <div v-if="(cancelar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
        </b-container>
        <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openFirmaPsw && !openAlertPresion && !openSuccess && !openError && !openPreSave && !regNewExp && !pswUpdate && !loading)">
            <ul class="sticky">
                <li v-if="(!dtaExist && !state.oldExp && !isEgresado)" @click="preGuardar()">
                    <img :src="require('../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating && !state.oldExp && dtaIngreso.firma.is_firmado==0 && !isEgresado)" @click="editar()">
                    <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating)" @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <AltertPresionArterial :abrir="openAlertPresion" @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <NuevoExpediente :openRegExp="regNewExp"/>
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading"/>

    </b-container>    
</template>

<script>
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";
import { url } from "../../../settings";
import CryptoJS from 'crypto-js';
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import Loading from '@/components/Loader/Loading.vue'
import router from '../../router';
import Datos from '@/components/Paciente/Visualizar/Datos.vue'
export default {
    name: 'HojaDeIngreso',
    components: { Datos, FirmaPassword, AltertPresionArterial, Success, Error, DatosPreguardados, NuevoExpediente, FirmaPswUpdate, Loading },

    data: () => ({
        changeDta:null,
        isEgresado:false,
        usuario:{
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable:null,

        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },
        allExp:[],

        fecha:"",
        hora:"",
        regNewExp: false,

        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,

        loading:true,

        openError: false,
        openSuccess:false,
        error:false,
        errores:[],
        openAlertPresion: false,
        info: false,
        btnGuardar: false,
        btnFirmar: true,
        busy: false,
        idPacienteSeleccionado: "",
        datosExpediente:{},
        openFirmaPsw: false,
        tension1:"",
        tension2:"",

        dtaMedico:{
            nombre:"",
            especialidad:"",
            cedula:""
        },

        "genero": [
        {
            "id": 1,
            "nombre": "Mujer"
        },
        {
            "id": 2,
            "nombre": "Hombre"
        },
        {
            "id": 3,
            "nombre": "Otro"
        }
    ],

        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        dtaResponsable:{
            password:"",
            paciente_id: "",
            email: "",
            fecha_nacimiento: "",
            genero:{
                id:null,
                nombre: "",
            },
            parentezco: "",
            nombre: "",
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            telefono: "",
            estado:{
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
        },

        dtaIngreso: {
            id: '',
            password: '',
            expediente_id: '',
            tension_arterial: '',
            frecuencia_cardiaca: '',
            frecuencia_respiratoria: '',
            temperatura: '',
            antecedentes_familiares: '',
            antecedentes_no_patologicos: '',
            antecedentes_patologicos: '',
            resumen: '',
            paraclinicos: '',
            imagenologia: '',
            diagnostico_ingreso: '',
            plan: '',
            tratamiento: '',
            pronostico: '',
            firma:{
                fecha_firmado:null,
                firmado_por:{
                    id:null,
                    nombre:""
                },
                is_firmado:0
            },
        },

        datosPreGuardados:[],
        showDta:false,
        openPreSave:false,

        medicoResponsable:{
            id:"",
            nombre:"",
        },
        currentMedico:{
            id:"",
            nombre:"",
        },
        year:""
    }),

    computed: {
        validation() {
            return this.idPacienteSeleccionado != ""
        },

        tension_arterial() {
            if (this.tension1.length!=0 && this.tension2.length !=0) {
                return true
            }
            return false;
        },

        valFC() {
            return this.dtaIngreso.frecuencia_cardiaca.length != 0
        },

        valFr() {
            return this.dtaIngreso.frecuencia_respiratoria.length != 0
        },

        valTemp() {
            return this.dtaIngreso.temperatura.length != 0
        },

        valSpo2() {
            return this.dtaIngreso.saturacion_oxigeno.length != 0
        },

        valAntFam() {
            return this.dtaIngreso.antecedentes_familiares != ""
        },

        valAntNoPat() {
            return this.dtaIngreso.antecedentes_no_patologicos != ""
        },

        valAntPat() {
            return this.dtaIngreso.antecedentes_patologicos != ""
        },

        valResumen() {
            return this.dtaIngreso.resumen != ""
        },

        valParaclinicos() {
            return this.dtaIngreso.paraclinicos != ""
        },

        valImagenologia() {
            return this.dtaIngreso.imagenologia != ""
        },

        valDiagIngreso() {
            return this.dtaIngreso.diagnostico_ingreso != ""
        },

        valPlan() {
            return this.dtaIngreso.plan != ""
        },

        valTratamiento() {
            return this.dtaIngreso.tratamiento != ""
        },

        valPronostico() {
            return this.dtaIngreso.pronostico != ""
        }
    },

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        changeExp(id){
            this.clearData()
            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp=false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta=currentExp.id
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);
                console.log("");

            } catch (error) {
                console.log("el error es"+error);
            }
        },


        autoSave(){
            console.log("doAutoSaveEveryChangeInProgres");
        },
        autoSavePA(){
            if (((this.tension1!=""&&this.tension1!=null)&&this.tension2!=""&&this.tension2!=null)) {
                console.log("AutoSaveTACorrectly");
            }
        },
        POSTFirmarNota(psw){
            console.log("firmando nota por el medico responsable con la contraseña:" +psw);
        },
        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
            title: "Datos preguardados",
            variant: variant,
            solid: true
            })
        },

        preGuardar() {
            var existe=false;
            this.dtaIngreso.tension_arterial = this.tension1+"/"+this.tension2
            try {
                if (localStorage.preGuardadoHIngreso) {
                    console.log("si hay datos");
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoHIngreso)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            this.dtaIngreso.password=""
             var i =0;
            for (let val of this.datosPreGuardados) {
                console.log("valor: "+val.expediente_id);
                if (this.dtaIngreso.expediente_id===val.expediente_id) {
                    console.log("ya esta we");
                    this.datosPreGuardados[i]=this.dtaIngreso
                    existe=true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push(this.dtaIngreso);
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoHIngreso', dta);
            this.makeToast('success');
            console.log(this.datosPreGuardados);
        },
        
        verificarPreGuardado(){
            try {
                if (localStorage.preGuardadoHIngreso) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoHIngreso)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.dtaIngreso.expediente_id===val.expediente_id) {
                    if (this.showDta === true) {
                        this.dtaIngreso=val
                        var ta = this.dtaIngreso.tension_arterial
                            var ta1 = ta.indexOf("/")
                            this.tension1=ta.substring(0,ta1)
                            this.tension2 = ta.substring(ta1+1,ta.length)
                    } else {
                        this.openPreSave = true
                    }
                }
            }
        },

        cancelEdit() {
            this.getHojaIngreso()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            // this.pswUpdate = true
            // this.pswUpdate = false
            // this.dtaIngreso.password = psw
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.disableInput = false
            this.updating = false
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.error=false
            this.errores=[]
            this.busyUpdate = true

            this.dtaIngreso.tension_arterial=this.tension1+"/"+this.tension2
            this.dtaIngreso.expediente_id = this.dtaIngreso.expediente.id
            console.log({ID: this.dtaIngreso.expediente_id});
            try {
                const res = await fetch(`${url}/api/hoja_ingreso/${this.dtaIngreso.expediente_id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaIngreso)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    console.log({DATA_UPD: val});
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.getHojaIngreso()
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.error=true
                    this.errores=err
                    this.busyUpdate = false
                    if (this.errores.password) {
                        this.btnFirmaUpdate = true
                        this.disableInput = false
                        this.actualizar = false
                        this.busyUpdate = false
                    } else {
                        this.busyUpdate=false
                        this.actualizar = true
                        this.cancelar = true
                    }
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },

        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/hoja_ingreso/pdf/${this.dtaIngreso.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente+" hoja de ingreso");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        async getHojaIngreso() {
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/hoja_ingreso/${this.dtaIngreso.expediente_id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json()
                if (data.status == false) {
                    this.disableInput = false
                    this.btnFirmar = true
                    this.btnGuardar = true
                } else {
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnFirmar = false
                    this.btnGuardar = false
                } 
                
                if (data.data) {
                    this.dtaIngreso=data.data
                    var ta = this.dtaIngreso.tension_arterial
                    var ta1 = ta.indexOf("/")
                    this.tension1=ta.substring(0,ta1)
                    this.tension2 = ta.substring(ta1+1,ta.length)
                    this.dtaIngreso.expediente_id=data.data.expediente.id
                }else{
                    this.obtenerDatosAntecedentes(this.dtaIngreso.expediente_id)
                    this.obtenerNoPatologicos(this.dtaIngreso.expediente_id)
                    this.verificarPreGuardado()
                }
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false  
                console.log("error de la hoja");
                console.log(error);
            }
        },
        async obtenerDatosAntecedentes(value) {
            try {
               const res = await fetch(`${url}/api/historia_clinica/antecedentes/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.nota_antecedentes) {
                    // familiares
                    data.nota_antecedentes.diabetes_mellitus==1? this.dtaIngreso.antecedentes_familiares+=' diabetes mellitus,':""
                    data.nota_antecedentes.hipertencion_arterial==1? this.dtaIngreso.antecedentes_familiares+=' hipertencion arterial,':""
                    data.nota_antecedentes.tuberculosis_pulmonar==1? this.dtaIngreso.antecedentes_familiares+=' tuberculosis pulmonar,':""
                    data.nota_antecedentes.obesidad==1? this.dtaIngreso.antecedentes_familiares+=' obesidad,':""
                    data.nota_antecedentes.neoplasias==1? this.dtaIngreso.antecedentes_familiares+=' neoplasias,':""
                    data.nota_antecedentes.cardiopatias==1? this.dtaIngreso.antecedentes_familiares+=' cardiopatias,':""
                    data.nota_antecedentes.familiares_tabaquismo==1? this.dtaIngreso.antecedentes_familiares+=' familiares tabaquismo,':""
                    data.nota_antecedentes.familiares_alcoholismo==1? this.dtaIngreso.antecedentes_familiares+=' familiares alcoholismo,':""
                    data.nota_antecedentes.familiares_dependencia_drogas_medicamentos==1? this.dtaIngreso.antecedentes_familiares+=' dependencia drogas o medicamentos,':""
                    data.nota_antecedentes.familiares_alergias==1? this.dtaIngreso.antecedentes_familiares+=' alergias,':""
                    data.nota_antecedentes.malformaciones_congenitas==1? this.dtaIngreso.antecedentes_familiares+=' malformaciones congenitas,':""
                    data.nota_antecedentes.disfunciones_familiares==1? this.dtaIngreso.antecedentes_familiares+=' disfunciones familiares,':""
                    data.nota_antecedentes.familiares_otros==1?(data.nota_antecedentes.familiares_cual===null? "" : this.dtaIngreso.antecedentes_familiares+=' otros: '+data.nota_antecedentes.familiares_cual):""
                    this.dtaIngreso.antecedentes_familiares=this.dtaIngreso.antecedentes_familiares.trim().toUpperCase()


                    // patologicos
                    data.nota_antecedentes.medicos==1?this.dtaIngreso.antecedentes_patologicos+=' medicos,':""
                    data.nota_antecedentes.quirurgicos==1?this.dtaIngreso.antecedentes_patologicos+=' quirurgicos,':""
                    data.nota_antecedentes.transfucionales==1?this.dtaIngreso.antecedentes_patologicos+=' transfucionales,':""
                    data.nota_antecedentes.personales_alergias==1?this.dtaIngreso.antecedentes_patologicos+=' personales alergias,':""
                    data.nota_antecedentes.personales_tabaquismo==1?this.dtaIngreso.antecedentes_patologicos+=' personales tabaquismo,':""
                    data.nota_antecedentes.personales_alcoholismo==1?this.dtaIngreso.antecedentes_patologicos+=' personales alcoholismo,':""
                    data.nota_antecedentes.personales_dependencia_drogas_medicamentos==1?this.dtaIngreso.antecedentes_patologicos+=' dependencia drogas medicamentos,':""
                    data.nota_antecedentes.personales_otros==1? (data.nota_antecedentes.personales_cual==null? "" : this.dtaIngreso.antecedentes_patologicos+=' otros: '+data.nota_antecedentes.personales_cual):""
                    this.dtaIngreso.antecedentes_patologicos=this.dtaIngreso.antecedentes_patologicos.trim().toUpperCase()
                }
           } catch (error) {
                console.log(error);
           }
        },
        async obtenerNoPatologicos(value) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/no_patologicos/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.nota_no_patologicos) {

                    this.dtaIngreso.antecedentes_no_patologicos+=' calidad alimentacion: '+data.nota_no_patologicos.calidad_alimentacion_id.nombre+','
                    this.dtaIngreso.antecedentes_no_patologicos+=' calidad habitacion: '+data.nota_no_patologicos.calidad_habitacion_id.nombre+','
                    this.dtaIngreso.antecedentes_no_patologicos+=' calidad higiene: '+data.nota_no_patologicos.calidad_higiene_id.nombre+','
                    data.nota_no_patologicos.sedentarismo?this.dtaIngreso.antecedentes_no_patologicos+=' sedentarismo: si':this.dtaIngreso.antecedentes_no_patologicos+=' sedentarismo: no'
                    this.dtaIngreso.antecedentes_no_patologicos=this.dtaIngreso.antecedentes_no_patologicos.trim().toUpperCase()

                }
                
            } catch (error) {
                this.loading=false
                console.log(error);
            }
        },

        changeAge:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           

            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());
		},

        async getUserData() {
            try {
                const res = await fetch(`${url}/api/usuarios/logged`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        setData(){
            this.getUserData()
            this.setFecha()
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.changeDta=paciente.id
            this.idResponsable=paciente.medico.id
            this.dtaIngreso.diagnostico_ingreso=paciente.diagnostico?paciente.diagnostico:""
            this.dtaIngreso.plan=paciente.diagnostico?paciente.plan_manejo_tratamiento:""
            this.dtaIngreso.pronostico=paciente.pronostico_id.nombre?paciente.pronostico_id.nombre:""

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                this.state.oldExp=true
            }else{
                this.state.oldExp=false
            }

            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }

            this.idPacienteSeleccionado=paciente.paciente.id
            this.dtaIngreso.expediente_id=paciente.id;
            this.datosPaciente=paciente.paciente
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.datosExpediente=paciente
            this.dtaResponsable=paciente.responsable_paciente
            try {
                this.dtaMedico=paciente.medico
                this.dtaMedico.especialidad=paciente.especialidad.nombre
            } catch (error) {
                console.log("");
            }
            this.btnGuardar = false;
            this.btnFirmar = true;
            this.medicoResponsable.id=paciente.medico.id
            this.medicoResponsable.nombre=paciente.medico.nombre
            this.currentMedico.id=localStorage.id
            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear()
            this.getHojaIngreso()
            setTimeout(() => {
                if (this.dtaResponsable.fecha_nacimiento) {
                    this.changeAge(paciente.responsable_paciente.fecha_nacimiento)
                }
            }, 500);
            this.allExpedientes();
        },

        firmar() {
            this.openFirmaPsw = true;
        },

        async hojaIngreso() {
            this.error=false
            this.errores=[]
            this.busy=true

            this.dtaIngreso.tension_arterial=this.tension1+"/"+this.tension2
            
            /* console.log(this.dtaIngreso); */

            try {
                const res = await fetch(`${url}/api/hoja_ingreso`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaIngreso),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log({DATA_POST: val});
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.openSuccess=true
                    this.busy=false
                    this.getHojaIngreso()
                }else{
                    this.error=true
                    this.errores=err
                    this.busy=false
                    this.openError=true   
                }

                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }
            } catch (error) {
                this.busy=false
                this.openError=true
                console.log(error);
            }
            console.log(this.errores);
            this.busy=false
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        tensionArterial() {
            if (this.tension2.length >= 2 && this.tension1.length >= 2) {
                setTimeout(() => {
                if (this.tension1 < 100 || this.tension1 > 140 || this.tension2 < 60 || this.tension2 > 90) {
                        this.openAlertPresion = true
                }
                }, 500);
            }
        },

        clearData() {
            this.showDta=false
            this.dtaIngreso.id = '',
            this.dtaIngreso.password = '',
            this.dtaIngreso.expediente_id = '',
            this.dtaIngreso.tension_arterial = '',
            this.dtaIngreso.frecuencia_cardiaca = '',
            this.dtaIngreso.frecuencia_respiratoria = '',
            this.dtaIngreso.temperatura = '',
            this.dtaIngreso.antecedentes_familiares = '',
            this.dtaIngreso.antecedentes_no_patologicos = '',
            this.dtaIngreso.antecedentes_patologicos = '',
            this.dtaIngreso.resumen = '',
            this.dtaIngreso.paraclinicos = '',
            this.dtaIngreso.imagenologia = '',
            this.dtaIngreso.diagnostico_ingreso = '',
            this.dtaIngreso.plan = '',
            this.dtaIngreso.tratamiento = '',
            this.dtaIngreso.pronostico = '',
            this.tension1 = '',
            this.tension2 = ''
        },
        
        setFecha(){
            var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.hora=""+hour+":"+minutes+":00"
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.fecha = yyyy + '-' + mm + '-' + dd ;
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        cerrarModalAlertPresionArterial(){
            this.openAlertPresion = false
        },
        firma_psw(psw){
            this.dtaIngreso.password = psw
            if (!this.dtaExist) {
                this.hojaIngreso()
            }else{
                this.updateData();
            }
            this.openFirmaPsw=false
        },
        showDtaPreSave(){
                this.showDta = true
                this.verificarPreGuardado()
                this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
        }
    },

    mounted() {
    },

    activated(){
        Object.assign(this.$data, this.$options.data());
        this.loading=true

        ////////
        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }

        this.setData()
        
    },
    deactivated(){

    }
}
</script>

<style scoped>
    @media only screen and (min-width: 960px) {
        .size_th {
            width: 10vw;
        }
    }

    @media only screen and (max-width: 960px) {
        .size_th {
            width: 15vw;
        }
    }
    
    @media only screen and (max-width: 1220px) {
        .th-signos-vitales {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    @media only screen and (min-width: 768px) {
        .th-signos-vitales {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @media only screen and (min-width: 1200px) {
        .size_table {
            width: 25vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_table {
            width: 28vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_table {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_table {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_table {
            width: auto;
        }
    }
</style>