<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Nota de ingreso</h2>      
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>

        <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
        <b-table-simple class="table-sm border_table">
            <b-tbody>  
                <b-tr>
                    <b-th class="th_style size_th">Expedientes</b-th>
                    <b-td class="border_table">
                        <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                            <template>
                                <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        
        <div class="sticky-top px-3" v-if="(isEgresado && !loading)">
            <b-alert show dismissible variant="warning">
                El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
            </b-alert>
        </div>

        <div v-else-if="(dtaExist && !loading)" class="sticky-top px-3">
            <b-alert show dismissible variant="info">
                <div class="d-flex" v-if="(datos.firma.is_firmado==1)">
                    <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                    Nota firmada y guardada correctamente
                </div>
                <div class="d-flex" v-if="(datos.firma.is_firmado==0)">
                    <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                    Guardado correctamente, se requiere firmar la nota
                </div>
            </b-alert>
        </div>

        <Datos :changeData="changeDta"/>

        <b-row class="py-4">
            <b-col cols="12" sm="12" md="2" lg="2">
                <p class="txt_secondary text-uppercase pl-3"><strong>Ingreso</strong></p>
            </b-col>
            <b-col cols="5" sm="6" md="5" lg="5">
                <div class="d-flex">
                    <span class="pl-3 pr-2 txt_gray">
                        Programado
                    </span>
                    <b-form-radio v-model="datos.ingreso_urgencia" v-bind:value="false" :disabled="(disableInput || state.oldExp || isEgresado)" class="rbtn_color" size="md" />
                </div>
            </b-col>
            <b-col cols="5" sm="6" md="5" lg="5">
                <div class="d-flex">
                    <span class="px-2 txt_gray">
                        Por urgencia
                    </span>
                    <b-form-radio v-model="datos.ingreso_urgencia" v-bind:value="true" :disabled="(disableInput || state.oldExp || isEgresado)" class="rbtn_color" size="md" />
                </div>
            </b-col>
        </b-row>

        <b-container>

            <div v-if="datos.ingreso_urgencia">
            <p class="txt_secondary text-uppercase pt-3"><strong>En caso de accidente</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th_accidente">¿Corre peligro la vida?</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input v-model="datos.corre_peligro_vida" type="checkbox" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary pt-2"><strong>Clasificación de lesiones</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th_accidente">¿Tardará más de 15 días en sanar?</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input v-model="datos.tardara_mas_quince_dias_sanar" type="checkbox" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">¿Quedarán secuelas?</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input v-model="datos.quedaran_secuelas" type="checkbox" value=false>
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            </div>

            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Información del paciente</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Genero</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.paciente_edad_years">
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_years}}</span></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_months}}</span></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.id">
                        <b-th class="th_style">Idioma</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.idioma}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info && datosExpediente.id">
                        <b-th class="th_style">Religion</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.religion}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                             <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Colonia/Comunidad</b-th>
                        <b-td class="border_table">
                             <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="info">
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                           <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->

            <!-- <b-table-simple class="table-sm" v-if="datosExpediente.id">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Número de expediente</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosPaciente.no_expediente}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datosExpediente.tiene_alergias===1">
                        <b-th class="th_style">Alergias</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.alergias}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.especialidad.nombre}}</span>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Médico tratante</b-th>
                        <b-td class="border_table">
                            <span class="pl-2 size-span">{{datosExpediente.medico.nombre}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->

            <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Fecha</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="fecha" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha" v-model="datos.fecha_ingreso" type="date" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Hora</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="5">
                                    <div class="d-flex">
                                         <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="hora" autocomplete="off" type="time" min="00:01" max="24:59" v-on:keypress="soloNumeros($event)" :state="hour" v-model="datos.hora_ingreso" class="border-0" size="sm"></b-form-input>
                                        <span class="float-right px-2 txt_gray">
                                            <span class="txt_gray size-span">horas</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-th class="th_style">No. expediente</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="5">
                                    <div class="d-flex">
                                        <span class="pl-2 size-span">{{datosPaciente.noExpediente}}</span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr> -->
                </b-tbody>
            </b-table-simple>
            
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th_medidas th_style">Peso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="peso" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" :state="peso" v-model="datos.peso" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">kg</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Talla</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="talla" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" :state="talla" v-model="datos.talla" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="temperatura" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" :state="temperatura" v-model="datos.temperatura" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">SPO2</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="SPO2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" :state="saturacion_oxigeno" v-model="datos.saturacion_oxigeno" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">%</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="fc" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" :state="frecuencia_cardiaca" v-model="datos.frecuencia_cardiaca" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="fr" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" :state="frecuencia_respiratoria" v-model="datos.frecuencia_respiratoria" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th_frecuencias">Tensión arterial</b-th>
                                <b-td class="border_table">                     
                                    <div class="d-flex">
                                        <div class="px-2">
                                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="ta1" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="datos.tension_arterial" class="border-0 text-center" size="sm" placeholder="#"/>
                                        </div>
                                        <div class="txt_gray">/</div>
                                        <div class="px-2">
                                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="ta2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension2" :state="tension_arterial" @keyup="tensionArterial()" class="border-0 text-center" size="sm" placeholder="#"/>
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <p class="txt_secondary">Resumen de interrogatorio y exploración física</p>
            <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" autocomplete="off" :state="registro_interrogatorio_exploracion_fisica" v-model="datos.registro_interrogatorio_exploracion_fisica" class="txtArea_border" size="sm" no-resize  rows="2" max-rows="2" />

            <p class="txt_secondary text-uppercase pt-3"><strong>Estado mental</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th_last_table">Abertura ocular</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="ocular_id" v-model="datos.ocular_id" :options="escalas_glasgow" value-field="id" text-field="nombre" @change="onChange(datos.ocular_id)" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Respuesta verbal</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="verbal_id" v-model="datos.verbal_id" :options="escalas_glasgow" value-field="id" text-field="nombre" @change="onChange(datos.verbal_id)" class="border-0" size="sm"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Respuesta motriz</b-th>
                        <b-td class="border_table">
                             <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="motriz_id" v-model="datos.motriz_id" :options="escalas_glasgow" value-field="id" text-field="nombre" @change="onChange(datos.motriz_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Escala Glasgow</b-th>
                        <b-td class="border_table">
                            <span class="ml-2 size-span">{{datos.ocular_id+datos.verbal_id+datos.motriz_id}}</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary">Estudios de laboratorio y gabinete</p>
            <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="estudiosLab" autocomplete="off" :state="estudios_laboratorio_gabinete" class="txtArea_border" v-model="datos.estudios_laboratorio_gabinete" size="sm" no-resize  rows="3" max-rows="3" />

            <p class="txt_secondary mt-3">Tratamiento</p>
            <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="tratamiento" autocomplete="off" :state="tratamiento" class="txtArea_border" v-model="datos.tratamiento" size="sm" no-resize rows="3" max-rows="3" />
            
            <b-table-simple class="table-sm mt-4">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_th_last_table th_style">Pronóstico</b-th>
                        <b-td class="border_table">
                             <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="pronostico_id" v-model="datos.pronostico_id" :options="pronostico" value-field="id" text-field="nombre" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div v-if="datos.firma.is_firmado==1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico: {{datos.firma.firmado_por.nombre}}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha: {{datos.firma.fecha_firmado}}</span>
            </div>

            
            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err.expediente_id" />| {{err[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <div v-if="(!dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="notaDeIngreso()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <!-- <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->
            <div v-if="(datos.firma.is_firmado==0 && !actualizar && !state.oldExp && idResponsable===usuario.id && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                     <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(cancelar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button  @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
        </b-container>

        <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openFirmaPsw && !openSuccess && !openError && !openAlertPresion && !openPreSave && !regNewExp && !pswUpdate && !loading)">
            <ul class="sticky">
                <li v-if="(!dtaExist && !state.oldExp && !isEgresado)" @click="preGuardar()">
                    <img :src="require('../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating && !state.oldExp && datos.firma.is_firmado==0 && !isEgresado)" @click="editar()">
                    <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating)" @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <AltertPresionArterial :abrir="openAlertPresion" @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()"/>
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <NuevoExpediente :openRegExp="regNewExp"/>
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import Foto from "@/components/Paciente/Foto.vue"
import { url } from "../../../settings";
import router from '../../router';
// const link = `${url}/api/datos_medicos/datos/`;
import Error from "@/components/Modals/Error.vue";
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import Success from "@/components/Modals/Success.vue";
import CryptoJS from 'crypto-js'
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import Loading from '@/components/Loader/Loading.vue'
import { getCatalogo } from '../../helpers/catalogos'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'

export default {
    name: "NotaDeIngreso",
    components: { Datos, Foto, Error, Success, FirmaPassword, AltertPresionArterial, DatosPreguardados, NuevoExpediente, FirmaPswUpdate, Loading },

    computed: {
        hour() {
            return this.datos.hora_ingreso.length != 0
        },

        fecha() {
            return this.datos.fecha_ingreso != 0
        },

        validation() {
            return this.idPacienteSeleccionado != ""

        },

        tension_arterial() {
            if (this.datos.tension_arterial.length!=0 && this.tension2.length !=0) {
                return true
            }
            return false;
        },

        frecuencia_cardiaca() {
            return this.datos.frecuencia_cardiaca.length !=0;
        },

        temperatura() {
            return this.datos.temperatura.length !=0;
        },

        frecuencia_respiratoria() {
            return this.datos.frecuencia_respiratoria.length !=0;
        },

        saturacion_oxigeno() {
            return this.datos.saturacion_oxigeno.length !=0;
        },

        peso() {
            return this.datos.peso.length !=0;
        },

        talla() {
            return this.datos.talla.length !=0;
        },

        registro_interrogatorio_exploracion_fisica() {
            return this.datos.registro_interrogatorio_exploracion_fisica.length !=0;
        },

        ocular_id() {
            return this.datos.ocular_id !=null;
        },

        verbal_id() {
            return this.datos.verbal_id !=null;
        },

        motriz_id() {
            return this.datos.motriz_id !=null;
        },

        estudios_laboratorio_gabinete() {
            return this.datos.estudios_laboratorio_gabinete.length !=0;
        },

        tratamiento() {
            return this.datos.tratamiento.length !=0;
        },
        
        pronostico_id() {
            return this.datos.pronostico_id !=null;
        },
    },

    data:()=>({
        changeDta:null,
        isEgresado:false,
        usuario:{
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable:null,


        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },
        allExp:[],

        loading:true,
        regNewExp: false,

        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,

        done:false,
        info:false,
        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,
        openAlertPresion: false,
        pucho:false,
        error:false,
        errores:[],
        busy: false,
        openSuccess:false,
        openError:false,
        idPacienteSeleccionado: "",
        nombres: [],
        tension2:"",

        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        datos:{
            id: '',
            expediente_id: "",
            password: '',
            ingreso_urgencia: false,
            corre_peligro_vida: false,
            tardara_mas_quince_dias_sanar: false,
            quedaran_secuelas: false,
            fecha_ingreso: "",
            hora_ingreso: "",
            tension_arterial: "",
            frecuencia_cardiaca: "",
            temperatura: "",
            frecuencia_respiratoria: "",
            saturacion_oxigeno:"",
            peso: "",
            talla: "",
            registro_interrogatorio_exploracion_fisica: "",
            ocular_id: null,
            verbal_id: null,
            motriz_id: null,
            estudios_laboratorio_gabinete: "",
            tratamiento: "", 
            pronostico_id: null,
            escalas_glasgow:0,
            firma:{
                fecha_firmado:null,
                firmado_por:{
                    id:null,
                    nombre:""
                },
                is_firmado:0
            },
        },

        escalas_glasgow: [],
        pronostico:[],
        datosPreGuardados:[],
        showDta: false,
        openPreSave: false,
        datosExpediente:{},
    }),

    methods:{
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        changeExp(id){
            this.clearData()
            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp=false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta=currentExp.id
                this.setData(true)
            }
            this.loading=false
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        cancelEdit() {
            this.getNotaDeIngreso()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            // this.pswUpdate = true
            this.pswUpdate = false
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.disableInput = false
            this.updating = false
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.datos.hora_ingreso=this.datos.hora_ingreso.substring(0,5)
            this.datos.tension_arterial = this.datos.tension_arterial+"/"+this.tension2
            this.error=false
            this.errores=[]
            this.busy=true
            this.busyUpdate = true;
            try {
                const res = await fetch(`${url}/api/nota_ingreso/update/${this.datos.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.getNotaDeIngreso()
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.busy=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.error=true
                    this.errores=err
                    this.busyUpdate = false
                    var ta = this.datos.tension_arterial
                    this.datos.tension_arterial=""
                    var ta1 = ta.indexOf("/")
                    this.datos.tension_arterial=ta.substring(0,ta1)
                    this.tension2 = ta.substring(ta1+1,ta.length)
                    this.done=true
                    if (this.errores.password) {
                        this.btnFirmaUpdate = true
                        this.disableInput = false
                        this.actualizar = false
                        this.busyUpdate = false
                        this.busy=false
                    } else {
                        this.busy=false
                        this.busyUpdate=false
                        this.actualizar = true
                        this.cancelar = true
                    }
                }
            } catch (error) {
                this.busyUpdate = false
                this.openError = true
                console.log(error)
            }
        },

        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true  
            let urlim = `${url}/api/nota_ingreso/pdf/${this.datos.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente+" Nota de ingreso");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
            title: "Datos preguardados",
            variant: variant,
            solid: true
            })
        },
      
        preGuardar() {
            var existe=false;
            this.datos.tension_arterial=this.datos.tension_arterial+"/"+this.tension2
            try {
                if (localStorage.preGuardadoNIngreso) {
                    console.log("si hay datos");
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoNIngreso)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            this.datos.password=""
             var i =0;
            for (let val of this.datosPreGuardados) {
                console.log("valor: "+val.expediente_id);
                if (this.datos.expediente_id===val.expediente_id) {
                    console.log("ya esta we");
                    this.datosPreGuardados[i]=this.datos
                    existe=true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push(this.datos);
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNIngreso', dta);
            this.makeToast('success');
            console.log(this.datosPreGuardados);
        },
        verificarPreGuardado(){
            /* this.openPreSave = true */
            try {
                if (localStorage.preGuardadoNIngreso) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoNIngreso)
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id===val.expediente_id) {
                    if (this.showDta === true) {
                        this.datos=val
                        try {
                            var ta = this.datos.tension_arterial
                            var ta1 = ta.indexOf("/")
                            this.datos.tension_arterial=ta.substring(0,ta1)
                            this.tension2 = ta.substring(ta1+1,ta.length)
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        this.openPreSave = true
                    }
                }
            }
        },

        firmar() {
            this.openFirmaPsw = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        onChange(){
            this.datos.escalas_glasgow=this.datos.ocular_id+this.datos.verbal_id+this.datos.motriz_id
            console.log({Resultado: this.datos.escalas_glasgow});
        },
        changeedad:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           
            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());
            if (this.datosPaciente.fecha_nacimiento!="") {
                this.datosPaciente.edad = this.year
                
            }else{
                this.datos.edad=""
            }
		},
        // async change(){
        //     this.clearData()
        //     try {
        //         const res = await fetch(`${link}${this.idPacienteSeleccionado}`, {
        //             method: 'get',
        //             headers: {
        //                 "Access-Control-Allow-Origin": "*",
        //                 'Content-Type': 'application/json',
        //                 'X-Requested-With': 'XMLHttpRequest',
        //                 'Authorization': "Bearer "+ localStorage.getItem("token"),
        //             }
        //         });
        //         if (!res.ok) {
        //             const message = `An error has occured: ${res.status} - ${res.statusText}`;
        //             throw new Error(message);
        //         }
        //         const data = await res.json();
        //         // localStorage.setItem("expediente_id",JSON.stringify(data.expediente))
        //         var expediente = CryptoJS.AES.encrypt(JSON.stringify(data.expediente), 'secret key heal4701tech')
        //         localStorage.setItem('expedienteEncrypt', expediente);
        //         this.datos.expediente_id=data.expediente.id
        //         console.log(data.expediente.paciente);
        //         this.datosPaciente=data.expediente.paciente
        //         this.changeedad(this.datosPaciente.fecha_nacimiento)
        //         this.getNotaDeIngreso()
        //         this.onChange()
        //         this.setData()
        //     } catch (error) {
        //         console.log("el error es"+error);
        //     }
        // },
        async getNotaDeIngreso() {
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/nota_ingreso/`+this.datos.expediente_id, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log(data);
                if (data.status == false) {
                    this.disableInput = false
                    this.btnFirma = true
                } else {
                    console.log("ENTRE");
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnFirmar = false
                    this.btnGuardar = false
                } 
                
                if (data.nota_ingreso) {
                    this.datos=data.nota_ingreso
                    this.datos.escalas_glasgow=this.datos.ocular_id+this.datos.verbal_id+this.datos.motriz_id

                    if (data.nota_ingreso.ingreso_urgencia == 1) {
                        this.datos.ingreso_urgencia = true;
                    } else {
                        this.datos.ingreso_urgencia = false;
                    }
                    
                    var ta = this.datos.tension_arterial
                    this.datos.tension_arterial=""
                    var ta1 = ta.indexOf("/")
                    this.datos.tension_arterial=ta.substring(0,ta1)
                    this.tension2 = ta.substring(ta1+1,ta.length)
                    this.done=true
                }else{
                    console.log("entrell");
                    this.verificarPreGuardado()
                    this.done=false
                }
                if (!this.done) {
                    this.clearData();
                }
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log("");
            }
        },

        clearData(){
            this.tension2=""
            this.datos.password= ''
            this.datos.ingreso_urgencia= false
            this.datos.corre_peligro_vida= false
            this.datos.tardara_mas_quince_dias_sanar= false
            this.datos.quedaran_secuelas= false
            this.datos.fecha_ingreso= ""
            this.datos.hora_ingreso= ""
            this.datos.tension_arterial= ""
            this.datos.frecuencia_cardiaca= ""
            this.datos.temperatura= ""
            this.datos.frecuencia_respiratoria= ""
            this.datos.saturacion_oxigeno=""
            this.datos.peso= ""
            this.datos.talla= ""
            this.datos.registro_interrogatorio_exploracion_fisica= ""
            this.datos.ocular_id= null
            this.datos.verbal_id= null
            this.datos.motriz_id= null
            this.datos.estudios_laboratorio_gabinete= ""
            this.datos.tratamiento= ""
            this.datos.pronostico_id= null
            this.datos.escalas_glasgow=0
            this.setFechaHora();
            this.showDta = false
            this.btnGuardar = false;
            this.btnFirmar = true;
        },

       async notaDeIngreso() {
            this.busy=true
            this.datos.tension_arterial=this.datos.tension_arterial+"/"+this.tension2
            this.datos.hora_ingreso=this.datos.hora_ingreso.substring(0,5)
            this.error=false
            this.errores=[]
            try {
                const res = await fetch(`${url}/api/nota_ingreso/store`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.setFechaHora()
                    this.busy=false
                    this.openSuccess=true
                    this.getNotaDeIngreso()
                }else{
                    this.error=true
                    this.errores=err
                    this.openError=true
                    this.openError=true
                    this.busy=false
                    var ta = this.datos.tension_arterial
                    this.datos.tension_arterial=""
                    var ta1 = ta.indexOf("/")
                    this.datos.tension_arterial=ta.substring(0,ta1)
                    this.tension2 = ta.substring(ta1+1,ta.length)
                    this.done=true
                }

                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }

            } catch (error) {
                this.openError=true
                this.busy=false
                console.log(error);
            }
       },

       setFechaHora(){
           var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.datos.hora_ingreso=""+hour+":"+minutes
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha_ingreso = yyyy + '-' + mm + '-' + dd ;
       },

       tensionArterial() {
            if (this.tension2.length >= 2 && this.datos.tension_arterial.length >= 2) {   
                setTimeout(() => {
                if (this.tension1 < 100 || this.datos.tension_arterial > 140 || this.tension2 < 60 || this.tension2 > 90) {
                        this.openAlertPresion = true
                }
                }, 500);
            }
        },
        
        setData(){


            this.setFechaHora();
            this.getCatalogos()


            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.changeDta=paciente.id
            this.idResponsable=paciente.medico.id

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                this.state.oldExp=true
            }else{
                this.state.oldExp=false
            }

            this.datos.expediente_id=paciente.id
            console.log(paciente);
            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.idPacienteSeleccionado=paciente.paciente.id
            this.datos.expediente_id=paciente.id;
            this.datosPaciente=paciente.paciente
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.datosExpediente=paciente
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.getNotaDeIngreso()
            this.allExpedientes()
        },

        getCatalogos() {
            //  PRONÓSTICO **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/pronostico'))
            }).then((data)=>{
                try {
                    this.pronostico = data.pronostico
                    this.pronostico.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })

            //  ESCALA DE GLASGOW **********************************************************************************************************************************            
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/escalas_glasgow'))
            }).then((data)=>{
                console.log({Data: data});
                try {
                    this.escalas_glasgow = data.escalas_glasgow
                    this.escalas_glasgow.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error);
                }
            })
            //usuario log *************************** 
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/usuarios/logged'))
            }).then((data)=>{
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            })
        },
        // Events $on
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            console.log('cerrarModalError');
            this.openError=false
        },
        cerrarModalAlertPresionArterial(){
            this.openAlertPresion = false
        },
        firma_psw(psw){
            this.datos.password = psw
            if (!this.dtaExist) {
                this.notaDeIngreso()
            }else{
                this.updateData();
            }
            this.openFirmaPsw=false
        },
        showDtaPreSave(){
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false   
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
            this.busy=false
        }
    },
 
    mounted(){

    },
    activated(){

        Object.assign(this.$data, this.$options.data());
        this.loading=true
        this.showDta === false
        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }
        this.setData()
        
    }

}


</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th_last_table {
            width: 25vw;
        }

        .size_th_accidente {
            width: 20vw;
        }

        .size_th_frecuencias {
            width: 10vw;
        }

        .size_th {
            width: 10vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th_last_table {
            width: 20vw;
        }

        .size_th_accidente {
            width: 30vw;
        }

        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th_last_table {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th_last_table {
            width: 20vw;
        }

        .size_th_accidente {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th_last_table {
            width: auto;
        }

        .size_th_accidente {
            width: auto;
        }

        .size_th_frecuencias {
            width: auto;
        }
    }


    /* Tabla de tallas y medidas */
    @media only screen and (max-width: 500px) {
        .size_th_medidas {
            width: 30vw;
        }
    }

    @media only screen and (min-width: 500px) {
        .size_th_medidas {
            width: 30vw;
        }
    }

    @media only screen and (min-width: 800px) {
        .size_th_medidas {
            width: 10vw;
        }
    }

    @media only screen and (min-width: 768px) {
        .size_th_frecuencias {
            width: 10vw;
        }
    }
</style>