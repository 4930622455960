import { url } from '../../settings'

export const getCatalogo = async (endpoint) => {
    try {
        const res = await fetch(`${url}${endpoint}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': "Bearer "+ localStorage.getItem("token"),
            }
        })
        const data = await res.json();
        return data 
    } catch (error) {
        console.log(error);
    }
}