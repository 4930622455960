<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Hoja de admisión</h2>   
            </b-col>
        </b-row>
        <b-container>
            <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                             <b-form-input :disabled="exist" v-model="paciente.nombre" :state="nombre" type="text" name="nombrePaciente" autocomplete="off" v-on:keypress="soloLetras($event)" class="border-0 text-value-capitalize" size="sm" placeholder="Ingresa nombre del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" :state="genero_id" v-model="paciente.genero.id" :options="genero" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="fechaPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha" v-model="paciente.fecha_nacimiento" @change="change(paciente.fecha_nacimiento)" maxlength="10" class="border-0" type="date" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input  disabled name="agePaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.edad" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input  disabled name="monthPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.meses" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="ecPaciente"  :state="estado_civil_id" v-model="paciente.estado_civil.id" :options="ecivil" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Religión</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="religion" autocomplete="off" :state="v_religion" v-model="paciente.religion" class="border-0" size="sm" placeholder="Ingresa la religión" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">CURP</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="curp" autocomplete="off" maxlength="18" @keyup="curpUpperCase()" :state="v_curp" v-model="paciente.curp" class="border-0" size="sm" placeholder="Ingresa la CURP" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Derechohabiente</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="derechoHabiente" autocomplete="off" :state="derechoHabiente" v-model="paciente.derechohabiente" class="border-0" size="sm" placeholder="Ingresa el derechohabiente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" list="input-list" name="cp" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" @input="searchDataCP" :state="cp" v-model="paciente.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="exist" type="text" name="domicilioPaciente" autocomplete="off" v-on:keypress="rgexDomicilio($event)" :state="domicilio"  v-model="paciente.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-th class="th_style">Número interior</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="numInterior" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.numInt" maxlength="5" :state="numInt" class="border-0" size="sm" placeholder="Ingresa el número interior" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Número exterior</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="numExterior" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.numExt" maxlength="5" :state="numExt" class="border-0" size="sm" placeholder="Ingresa el número exterior" />
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" list="my-list-id" type="text" name="coloniaPaciente" autocomplete="off" :state="colonia" v-model="paciente.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                            <datalist id="my-list-id">
                            <option v-for="{size, index} in listaAsentamientos" :key="index">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="ciudadPaciente" autocomplete="off" v-on:keypress="soloLetras($event)" :state="ciudad" v-model="paciente.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="edoPaciente" :state="estado" v-model="paciente.estado.id" :options="dtaEstados" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="municipioPaciente" :state="municipio" v-model="paciente.municipio.id" :options="dtaMunicipiosPaciente" value-field="id" text-field="nombre" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" name="telefonoPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="paciente.telefono" :state="telefono" class="border-0" size="sm" placeholder="Sin registro" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos médicos</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Alergias</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" v-model="paciente.alergias" type="text" name="alergias" autocomplete="off" class="border-0" size="sm" placeholder="Default: No manifiesta" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Diagnostico</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" v-model="admision.diagnostico" :state="diagnostico" type="text" name="diagnostico" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el diagnostico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Procedimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" v-model="admision.procedimiento" :state="procedimiento" type="text" name="procedimiento" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el procedimiento" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Información del responsable</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="resp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="nombreR" v-model="admision.responsable_paciente" class="border-0" size="sm" placeholder="Ingresa nombre de responsable del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Parentesco</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="parentesco" autocomplete="off" v-on:keypress="soloLetras($event)" :state="parentesco" v-model="admision.parentesco_paciente" class="border-0" size="sm" placeholder="Ingresa parentesco con el paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono del responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="telefonoResp" autocomplete="off" :state="telefonoResp" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="admision.telefono_responsable" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos de facturación</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Facturación</p>
                        <label class="switch">
                            <input :disabled="exist" v-model="dtaFacturacion" @change="datosFacturacion()" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>

            <b-table-simple v-if="form_dta_facturacion" class="table-sm mt-3">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre / Razón social</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="nombreRazonSocial" v-model="dataFacturacion.razon_social" :state="VRazonSocial" autocomplete="off" v-on:keypress="soloLetras($event)" class="border-0" size="sm" placeholder="Ingresa nombre o razón social" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Responsable de la cuenta</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="exist" type="text" name="respCuenta" autocomplete="off" v-on:keypress="soloLetras($event)" :state="VRespCuenta"  v-model="dataFacturacion.responsable_cuenta" class="border-0" size="sm" placeholder="Ingresa el responsable" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="telefonoFact" autocomplete="off" :state="VTelefonoFact" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="dataFacturacion.telefono" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="email" name="emailFactura" v-model="dataFacturacion.email" :state="VFactEmail" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el email" />
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style">RFC</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="RFC" v-model="dataFacturacion.rfc" :state="VRcf" @keyup="upperCase()" autocomplete="off" maxlength="13" class="border-0" size="sm" placeholder="Ingresa el RFC" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" list="input-list" name="cpFacturacion" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" @input="searchDataCPFacturacion()" :state="cpFac" v-model="dataFacturacion.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono del responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="telefonoResp" autocomplete="off" :state="VTelefonoFactResp" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="dataFacturacion.telefono_responsable" class="border-0" size="sm" placeholder="Ingresa teléfono del responsable" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="exist" type="text" name="domicilioFacturacion" autocomplete="off" v-on:keypress="rgexDomicilio($event)" :state="VDomicilioFac"  v-model="dataFacturacion.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" list="my-list-id" type="text" name="coloniaPaciente" autocomplete="off" :state="VColoniaFac" v-model="dataFacturacion.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                            <datalist id="my-list-id">
                            <option v-for="size in listaAsentamientosFac" :key="size">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="exist" type="text" name="ciudadPaciente" autocomplete="off" v-on:keypress="soloLetras($event)" :state="VCiudadFac" v-model="dataFacturacion.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="edoPaciente" :state="VEstadoFac" v-model="dataFacturacion.estado_id" :options="dtaEstadosFac"  @change="getMunicipiosFacturacion(dataFacturacion.estado_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select name="municipioPaciente" :disabled="exist" :state="VMunicipioFac" v-model="dataFacturacion.municipio_id" :options="dtaMunicipiosFac" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Método de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="metodoPago" v-model="dataFacturacion.metodo_pago_id" :options="metodoPago" :state="VMetodoPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Forma de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="formaPago" v-model="dataFacturacion.forma_pago_id" :options="formaPago" :state="VFormaPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Uso del CFDI</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="exist" name="usoCFDI" v-model="dataFacturacion.uso_cfdi_id" :options="usoCfdi" :state="VUsoCFDI" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Información de ingreso</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <!-- <b-tr>
                        <b-th class="th_style size_th">Fecha de ingreso</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <b-form-input :disabled="exist" name="fechaIngreso" v-model="fechaIngreso" type="date" class="border-0 input-disabled" size="sm"/>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Hora</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <div class="d-flex">
                                        <b-form-input :disabled="exist" name="hora" v-model="horaIngreso" autocomplete="off" type="time" min="00:00:01" max="24:59:59" class="border-0 input-disabled" size="sm"></b-form-input>
                                        <span class="float-right px-1 txt_gray">
                                            <small class="txt_gray">hora</small>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="exist" :state="especialidad_id" v-model="admision.especialidad_id" :options="dtaEspecialidades" @change="obtenerMedicosPerEspecialidad(admision.especialidad_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Médico que interna</b-th>
                        <b-td class="border_table">
                            <b-form-select :state="VmedicoId" :disabled="exist" v-model="admision.medico_id" :options="dtaMedicos" class="border-0" size="sm" />
                        </b-td>
                        <!-- <b-td class="border_table justify-content-center text-center my-auto">
                            <button disabled class="border-0 bg_secondary txt_white rounded-circle pd-add-medic" title="Añadir médico" @click="modalAddMedic()">
                                <i class="fa fa-plus fa-md" />
                            </button>
                        </b-td> -->
                    </b-tr>
                    <!-- <b-tr>
                        <b-th class="th_style size_th">Médico que interna</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled v-model="admision.medico_id" type="text" name="nomMedico" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el nombre" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Especialidad</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled v-model="admision.especialidad_id" type="text" name="especialidad" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa la especialidad" />
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style size_th">Ingresado a</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="exist" v-model="admision.ingresado_a" :state="ingresado_a" type="text" name="ingresadoA" autocomplete="off" class="border-0" size="sm" placeholder="Ingresado a"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Admitido por</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="exist" v-model="admision.admitido_por" :state="admitido_por" type="text" name="admitidoPor" autocomplete="off" class="border-0" size="sm" placeholder="Admitido por"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error" class="mt-3 mb-4">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" />| {{err}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div v-if="!exist" class="d-flex justify-content-end my-4">
            <b-col cols="12" sm="12" md="5" lg="5">
                <b-overlay
                :show="busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                >
                    <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="verificar()">GUARDAR</button>
                </b-overlay>
            </b-col>
        </div>
        <div v-if="editando" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
        <div class="sticky-container" v-if="(exist && !openSuccess && !openError && !openAddMedic && !loading)">
            <ul class="sticky">
                <li @click="editar">
                    <img :src="require('../../../assets/icons/editar.png')"  width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li @click="PdfHojaAdmision(paciente.id)">
                    <img src="../../../assets/icons/imprimir.png" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <RegistroMedico :openModalAddMedic="openAddMedic" @getMedicPerEsp="getMedicPerEsp($event)"/>
        <Loading :show="loading"/>
    </b-container> 
</template>

<script>
import Vue from 'vue'
import CryptoJS from 'crypto-js'
import PDFMerger from 'pdf-merger-js'
import { url } from '../../../../settings.js'
import { debounce } from 'lodash'
import Success from '@/components/Modals/Success.vue'
import Error from '@/components/Modals/Error.vue'
import RegistroMedico from '@/components/Medico/Registro.vue'
import Loading from '@/components/Loader/Loading.vue'

export default {
    name: 'Visualizar',
    components: { Success, Error, RegistroMedico, Loading },

    data: () => ({
        exist: false,
        editando:false,

        loading:true,
        openAddMedic: false,
        openSuccess: false,
        openError: false,

        error: false,
        errores: [],

        disabled_select_medico: true,
        dtaEspecialidades: [{value:null, text:'seleccionar'},{text: '...'}],
        dtaMedicos: [{value:null,text:'seleccionar'},{text:'...'}],

        disabled_select_mcpio_paciente: true,
        selectedecivil:null,
        dtaMunicipiosPaciente: [{id:null,value:'seleccione una opción'}],
        dtaEstados: [{value:null,text:'seleccione una opción'}],
        genero: [{value:null, text:'seleccionar'}],
        ecivil: [{value:null,text:'seleccione una opción'}],
        listaAsentamientos:[],
        listaAsentamientosFac:[],

        dtaFacturacion: false,
        form_dta_facturacion: false,
        metodoPago: [{value:null, text:'seleccione una opción'}],
        formaPago: [{value:null, text:'seleccione una opción'}],
        usoCfdi: [{value:null, text:'seleccione una opción'}],
        dtaEstadosFac: [{value:null,text:'seleccione una opción'}],
        dtaMunicipiosFac: [{value:null, text:'seleccione una opción'}],

        fechaIngreso:"",
        horaIngreso:"",

        disabled_select_mcpio_factura: true,

        busy: false,

        paciente: {
            id:null,
            genero: {id: null},
            edad: '',
            meses: '',
            nombre: '',
            alergias: '',
            fecha_nacimiento: '',
            estado_civil: {id:null},
            religion: '',
            curp: '',
            derechohabiente: '',
            cp: '',
            domicilio: '',
            colonia: '',
            ciudad: '',
            telefono: '',
            email: '',
            estado:{id: null,},
            municipio: {id: null,},
        },

        dataFacturacion: {
            paciente_id: '',
            razon_social: '',
            cp: '',
            email: '',
            domicilio: '',
            colonia: '',
            ciudad: '',
            estado_id: null,
            municipio_id: null,
            rfc: '',
            telefono: '',
            responsable_cuenta: '',
            telefono_responsable: '',
            metodo_pago_id: null,
            forma_pago_id: null,
            uso_cfdi_id: null,
        },

        admision:{
            paciente_id: '',
            facturacion_id: '',
            diagnostico: '',
            procedimiento: '',
            especialidad_id: null,
            medico_id: null,
            ingresado_a: '',
            admitido_por: '',
            responsable_paciente: '',
            parentesco_paciente: '',
            telefono_responsable: '',
        },
    }),

    computed: {
        telefono() {
            let flag = true;
            if (this.paciente.telefono&&!this.regexNumber(this.paciente.telefono)) {
                flag = false;
            }

            if (this.paciente.telefono&&this.paciente.telefono.length < 10) {
                flag=false;
            }

            if (this.paciente.telefono==null||this.paciente.telefono=="") {
                flag=null;
            }


            return flag;
        },
        
        Vedad() {
            return this.admision.edad != ""
        },

        Vmeses() {
            return this.paciente.meses != ""
        },

        fecha() {
            return this.paciente.fecha_nacimiento != ""
        },

        genero_id(){
            return this.paciente.genero.id !=null
        },

        nombre() {
            return this.paciente.nombre.length >=10
        },

        estado_civil_id() {
            return this.paciente.estado_civil.id != null
        },

        v_religion() {
            return this.paciente.religion != ''
        },

        v_curp() {
            return this.paciente.curp.length == 18
        },

        derechoHabiente() {
            return this.paciente.derechohabiente != ''
        },

        cp() {
            let flag = true;
            if (!this.regexNumber(this.paciente.cp)) {
                flag = false;
            }

            if (this.paciente.cp.length !== 5) {
                flag = false;
            }
            return flag;
        },
        
        domicilio() {
            return this.paciente.domicilio != ""
        },

        numInt() {
            return this.paciente.numInt != ""
        },

        numExt() {
            return this.paciente.numExt != ""
        },

        colonia() {
            return this.paciente.colonia != ""
        },

        ciudad() {
            return this.paciente.ciudad != ""
        },

        estado() {
            return this.paciente.estado.id != null
        },

        municipio() {
            return this.paciente.municipio.id != null
        },

        /* Form datos de facturación */
        cpFac() {
            let flag = true;
            if (!this.regexNumber(this.dataFacturacion.cp)) {
                flag = false;
            }

            if (this.dataFacturacion.cp.length !== 5) {
                flag = false;
            }
            return flag;
        },

        VTelefonoFact() {
            let flag = true;
            if (!this.regexNumber(this.dataFacturacion.telefono)) {
                flag = false;
            }

            if (this.dataFacturacion.telefono.length !== 10) {
                flag=false;
            }
            return flag;
        },

        VTelefonoFactResp() {
            let flag = true;
            if (!this.regexNumber(this.dataFacturacion.telefono_responsable)) {
                flag = false;
            }

            if (this.dataFacturacion.telefono_responsable.length !== 10) {
                flag=false;
            }
            return flag;
        },

        VRespCuenta() {
            return this.dataFacturacion.responsable_cuenta.length >= 10
        },

        VColoniaFac() {
            return this.dataFacturacion.colonia != ""
        },

        VCiudadFac() {
            return this.dataFacturacion.ciudad != ""
        },

        VEstadoFac() {
            return this.dataFacturacion.estado_id != null
        },

        VMunicipioFac() {
            return this.dataFacturacion.municipio_id != null
        },

        VDomicilioFac() {
            return this.dataFacturacion.domicilio != ""
        },

        VRazonSocial() {
            return this.dataFacturacion.razon_social != ""
        },

        VRcf() {
            let flag = false;
            if (this.dataFacturacion.rfc.length >= 12) {
                flag=true;
            }
            return flag;
        },

        VFactEmail() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.dataFacturacion.email)
        },

        VMetodoPago() {
            return this.dataFacturacion.metodo_pago_id != null
        },

        VFormaPago() {
            return this.dataFacturacion.forma_pago_id != null
        },

        VUsoCFDI() {
            return this.dataFacturacion.uso_cfdi_id != null
        },

        /* Admision */
        nombreR() {
            return this.admision.responsable_paciente.length >= 10
        },

        parentesco() {
            return this.admision.parentesco_paciente != ""
        },

        telefonoResp() {
            let flag = true;
            if (!this.regexNumber(this.admision.telefono_responsable)) {
                flag = false;
            }

            if (this.admision.telefono_responsable.length !== 10) {
                flag=false;
            }
            return flag;
        },

        especialidad_id() {
            return this.admision.especialidad_id != null
        },

        VmedicoId() {
            return this.admision.medico_id != null
        },

        diagnostico() {
            return this.admision.diagnostico != ''
        },

        procedimiento() {
            return this.admision.procedimiento != ''
        },

        ingresado_a() {
            return this.admision.ingresado_a != ''
        },

        admitido_por() {
            return this.admision.admitido_por != ''
        }
    },

    methods: {
        verificar() {
            this.busy=true
            this.errores = []
            this.error = false
            // const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const patternExp = /^[0-9]{4}-[0-9]{5}-[0-9]{1,3}$/;
            /* Datos del paciente */
            if (this.paciente.nombre == '') {
                this.errores.push('El nombre es obligatorio')
            }
            if (this.generarNoExp && !patternExp.test(this.paciente.no_expediente)) {
                this.errores.push('formato de expediente incorrecto(AAAA-NNNNN-N)')
            }
            if (this.paciente.nombre.length < 10) {
                this.errores.push('Ingrese el nombre completo')
            }
            if (this.paciente.genero.id == null) {
                this.errores.push('El género es obligatorio')
            }
            if (this.paciente.fecha_nacimiento == '') {
                this.errores.push('La fecha de nacimiento es obligatorio')
            }
            if (this.paciente.estado_id == '') {
                this.errores.push('El estado civil es obligatorio')
            }
            if (this.paciente.religion == '') {
                this.errores.push('La religion es obligatorio')
            }
            if (this.paciente.curp == '') {
                this.errores.push('La curp es obligatoria')
            } else if (this.paciente.curp.length < 18) {
                this.errores.push('Curp inválida')
            }
            if (this.paciente.derechohabiente == '') {
                this.errores.push('El derechohabiente es obligatoria')
            }
            if (this.paciente.cp == '') {
                this.errores.push('El código postal es obligatorio')
            } else if (this.paciente.cp.length < 5) {
                this.errores.push('Código postal inválido')
            }
            if (this.paciente.domicilio == '') {
                this.errores.push('El domicilio es obligatorio')
            }
            if (this.paciente.colonia == '') {
                this.errores.push('La colonia es obligatorio')
            }
            if (this.paciente.ciudad == '') {
                this.errores.push('La ciudad es obligatorio')
            }
            if (this.paciente.estado_id == '') {
                this.errores.push('El estado es obligatorio')
            }
            if (this.paciente.municipio_id == '') {
                this.errores.push('El municipio es obligatorio')
            }

            if (this.paciente.telefono && this.paciente.telefono.length!=10 && this.paciente.telefono.length>0) {
                this.errores.push('El campo telefono de paciente debe contener 10 digitos')
            }
            
            /* Mas dato de admision */
            /* if (this.admision.facturacion_id == '') {
                this.errores.push('La factura_id es obligatoria')
            } */
            if (this.admision.diagnostico == '') {
                this.errores.push('El diagnóstico es obligatorio')
            }
            if (this.admision.procedimiento == '') {
                this.errores.push('El procedimiento es obligatorio')
            }
            if (this.admision.especialidad_id == null) {
                this.errores.push('La especialidad es obligatorio')
            }
            if (this.admision.medico_id == null) {
                this.errores.push('El médico es obligatorio')
            }
            if (this.admision.ingresado_a == '') {
                this.errores.push('Especifique el ingreso')
            }
            if (this.admision.admitido_por == '') {
                this.errores.push('Especifique quien lo admitio')
            }
            if (this.admision.responsable_paciente == '') {
                this.errores.push('El resposable del paciente es obligatorio')
            } else if (this.admision.responsable_paciente < 10) {
                this.errores.push('Ingrese el nombre completo del responsable')
            }
            if (this.admision.parentesco_paciente == '') {
                this.errores.push('El parentesco es obligatorio')
            }
            if (this.admision.telefono_responsable == '') {
                this.errores.push('El teléfono del responsable es obligatorio')
            } else if (this.admision.telefono_responsable.length < 10) {
                this.errores.push('Teléfono del responsable inválido')
            }
            // this.error = true

            /* Datos de la factura */
            if (this.form_dta_facturacion == true) {
                if (this.dataFacturacion.razon_social == '') {
                    this.errores.push('Nombre / razón social es obligatorio')
                }
                if (this.dataFacturacion.responsable_cuenta == '') {
                    this.errores.push('Responsable es obligatorio')
                }
                if (this.dataFacturacion.responsable_cuenta.length<=9) {
                    this.errores.push('Responsable debe de contener minimo 10 caracteres')
                }
                // if (!pattern.test(this.dataFacturacion.email)) {
                //     this.errores.push('Correo inválido')
                // }
                if (this.dataFacturacion.telefono == '') {
                    this.errores.push('El teléfono es obligatorio')
                } else if (this.dataFacturacion.telefono.length < 10) {
                    this.errores.push('Teléfono inválido')
                }
                if (this.dataFacturacion.rfc == '') {
                    this.errores.push('RFC es obligatorio')
                } else if (this.dataFacturacion.rfc.length < 12) {
                    this.errores.push('RFC inválido')   
                }
                if (this.dataFacturacion.cp == '') {
                    this.errores.push('El código postal es obligatorio')
                }
                if (this.dataFacturacion.cp.length < 5) {
                    this.errores.push('Código postal inválido')
                }
                if (this.dataFacturacion.telefono_responsable == '') {
                    this.errores.push('El teléfono del responsable es obligatorio')
                } else if (this.dataFacturacion.telefono_responsable.length < 10) {
                    this.errores.push('Teléfono del responsable inválido')
                }
                if (this.dataFacturacion.domicilio == '') {
                    this.errores.push('El domicilio es obligatorio')
                }
                if (this.dataFacturacion.ciudad == '') {
                    this.errores.push('La ciudad es obligatorio')
                }
                if (this.dataFacturacion.estado_id == null) {
                    this.errores.push('El estado es obligatorio')
                }
                if (this.dataFacturacion.municipio_id == null) {
                    this.errores.push('El municipio es obligatorio')
                }
                if (this.dataFacturacion.metodo_pago_id == null) {
                    this.errores.push('El método de pago es obligatorio')
                }
                if (this.dataFacturacion.forma_pago_id == null) {
                    this.errores.push('La forma de pago es obligatorio')
                }
                if (this.dataFacturacion.uso_cfdi_id == null) {
                    this.errores.push('El uso de CFDI es obligatorio')
                }
            }

            if (this.errores.length == 0) {
                this.updatePaciente()
            }else{
                this.error=true
                this.busy=false
            }
        },

        editar(){
            this.exist = false
            this.editando = true
        },

        cancelEdit(){
            this.exist = true
            this.editando = false
            this.form_dta_facturacion=false
            this.dtaFacturacion = false
            this.loading=true
            this.getAdmision()
        },

        async updatePaciente() {
            this.busy=true
            if (this.paciente.alergias === '') {
                this.paciente.alergias = 'No manifiesta'
            }
            this.paciente.estado_civil_id=this.paciente.estado_civil.id
            this.paciente.genero_id=this.paciente.genero.id
            this.paciente.estado_id=this.paciente.estado.id
            this.paciente.municipio_id=this.paciente.municipio.id
            this.paciente.no_expediente=""
            try {
                const res = await fetch(`${url}/api/hoja_admision/pacientes/`+this.$route.params.id, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.paciente),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500

                var promise = Promise.resolve(data);
                var id = ""
                var warn = false
                let er = false
                let permisos = true
                await promise.then(function(val) {
                    if (val.message.includes("Sin permiso")) {
                        permisos=false
                    }else{
                        permisos=true
                    }
                    if (val.class=="success") {
                        id = val.paciente.id 
                        warn = true
                    }else if(val.errors){
                        er=val.errors
                    }
                });
                if (warn) {
                    if (this.form_dta_facturacion) {
                        this.registroFacturacion(id)                        
                    } else {
                        this.dtaAdmision(null,id);
                    }
                }else if (!permisos) {
                    if (this.form_dta_facturacion) {
                        this.registroFacturacion(this.paciente.id)                        
                    } else {
                        this.dtaAdmision(null,this.paciente.id);
                    }
                }else if(er){
                    if (er.no_expediente) {
                        this.errores.push("Formato de expediente incorrecto (AAAA-NNNNN-N)")
                        this.error=true
                        this.busy=false
                        this.openError=true
                    }
                }

            } catch (error) {
                this.busy=false
                console.log(error);
            }
        },
        async registroFacturacion(id) {
            this.dataFacturacion.paciente_id = id
            try {
                const res = await fetch(`${url}/api/hoja_admision/datos_facturacion`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataFacturacion),
                });
                const data = res.json()
                Vue.prototype.$error500 = res.status===500
                let ids = ""
                let warn = false
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        ids = val.facturacion.id
                        warn = true
                    }
                });
                if (warn) {
                    this.dtaAdmision(ids, this.dataFacturacion.paciente_id)
                }
            } catch (error) {
                this.busy = false
                console.log(error)
            }
            
        },

        async dtaAdmision(idFactura, idPaciente) {
            this.busy = true
            this.admision.facturacion_id = idFactura
            this.admision.paciente_id = idPaciente
            try {
                const res = await fetch(`${url}/api/hoja_admision/admision`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.admision),
                })
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var resp = ""
                await promise.then(function(val) {
                    console.log(val);
                    resp = val
                });
                if (resp.class=="success") {
                    this.busy = false
                    this.saved=true;
                    this.openSuccess=true
                    this.cancelEdit()
                    this.loading=true
                    this.getAdmision()
                } else {
                    this.busy = false
                }
            } catch (error) {
                this.busy = false
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaEspecialidades = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
                this.dtaEspecialidades.unshift({ value: null, text: 'seleccionar' })
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerMedicosPerEspecialidad(idEspecialidad) {
            
            if (this.admision.especialidad_id != null) {
                this.disabled_select_medico = false;
            } else {
                this.disabled_select_medico = true;
            }

            try {
                const res = await fetch(`${url}/api/catalogos/medicos/${idEspecialidad}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMedicos=data.medicos.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMedicos.unshift({value:null,text:"seleccionar"})
                console.log(this.dtaMedicos);
            } catch (error) {
                console.log(error);
            }
        },

        searchDataCP: debounce(function(){
            if (this.paciente.cp.length > 4) {
                fetch(`${url}/api/catalogos/codigo_postal/${this.paciente.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if (data.asentamientos.length>0) {
                        var estado=""
                        var municipio=""
                        this.listaAsentamientos=[]
                        this.paciente.colonia=""
                        data.asentamientos.forEach(element => {
                            estado =  element.estado.id
                            municipio = element.municipio.id
                            this.listaAsentamientos.push(element.asentamiento)
                        })
                        this.paciente.estado.id=estado
                        this.getMunicipiosPaciente(estado)
                        this.paciente.municipio.id=municipio
                    }else{
                        console.log("no hay datos cp");
                    }
                })
            }
        },1000),

        searchDataCPFacturacion: debounce(function(){
            if (this.dataFacturacion.cp.length > 4) {
                fetch(`${url}/api/catalogos/codigo_postal/${this.dataFacturacion.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if (data.asentamientos.length>0) {
                        this.dataFacturacion.colonia=""
                        var estado="";
                        var municipio="";
                        this.listaAsentamientosFac=[];
                        data.asentamientos.forEach(element => {
                            estado =  element.estado.id
                            municipio = element.municipio.id
                            this.listaAsentamientosFac.push(element.asentamiento)
                        });
                        this.dataFacturacion.estado_id=estado
                        this.getMunicipiosFacturacion(estado)
                        this.dataFacturacion.municipio_id=municipio
                    }
                })
            }
        },500),

        async getMunicipiosFacturacion(idEstado) {
            if (this.dataFacturacion.estado_id != null) {
                this.disabled_select_mcpio_factura = false
            } else {
                this.disabled_select_mcpio_factura = true
            }

            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEstado}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.dtaMunicipiosFac=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosFac.unshift({value:null,text:"seleccione una opción"})
            } catch (error) {
                console.log(error)
            }
        },

        async getMunicipiosPaciente(idEstado) {
            // if (this.paciente.estado_id != null) {
            //     this.disabled_select_mcpio_paciente = false
            // } else {
            //     this.disabled_select_mcpio_paciente = true
            // }
            console.log("entre en el get municipio"+idEstado);
            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEstado}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                console.log(data);
                this.dtaMunicipiosPaciente=data.municipios
                this.dtaMunicipiosPaciente.unshift({id:null,nombre:"seleccione una opción"})
            } catch (error) {
                console.log(error)
            }
        },

        async getGeneros() {
            try {
                const res = await fetch(`${url}/api/catalogos/genero`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.genero=data.genero.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.genero.unshift({value:null, text:"seleccionar"})
            } catch (error) {
                console.log(error)
            }
        },

         async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${url}/api/catalogos/estado_civil`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift( { value: null, text: 'seleccione una opcion' })


            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async getEstados() {
            try {
                const res = await fetch(`${url}/api/catalogos/estados`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.dtaEstados=data.estados.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaEstados.unshift({value:null, text:"seleccione una opción"})

                this.dtaEstadosFac = data.estados.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaEstadosFac.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerCatalogoFactura() {
            try {
                const res = await fetch(`${url}/api/catalogos/facturacion`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.formaPago = data.forma_pago.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.formaPago.unshift({value:null, text:"seleccione una opción"})

                this.metodoPago = data.metodo_pago.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.metodoPago.unshift({value:null, text:"seleccione una opción"})

                this.usoCfdi = data.uso_cfdi.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.usoCfdi.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        modalAddMedic() {
            this.openAddMedic = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event
            var charCode = (evt.which) ? evt.which : evt.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode)
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true
            } else {
                evt.preventDefault()
            }
        },

        rgexDomicilio(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z0-9-ZÀ-ÿ \u00f1\u00d1]*$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        regexNumber(number){
            let reg = new RegExp('^[0-9]*$')
            return reg.test(number)
        },

        getDate() {
            var date = new Date
            var minutes = ("0"+date.getMinutes()).slice(-2)
            var hour = ("0"+date.getHours()).slice(-2)
            this.horaIngreso=""+hour+":"+minutes+":00"
            var today = new Date()
            var dd = ("0" + (today.getDate())).slice(-2)
            var mm = ("0" + (today.getMonth() +1)).slice(-2)
            var yyyy = today.getFullYear()
            this.fechaIngreso = yyyy + '-' + mm + '-' + dd 
        },

        datosFacturacion() {
            if (this.dtaFacturacion == true) {
                this.form_dta_facturacion = true
            } else {
                this.form_dta_facturacion = false
            }
        },

        upperCase() {
            this.dataFacturacion.rfc = this.dataFacturacion.rfc.toUpperCase()
        },
        
        curpUpperCase() {
            this.paciente.curp = this.paciente.curp.toUpperCase()
        },

        change:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           
            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());

            this.month = (function() {
                if(today.getMonth() >= dob.getMonth()) {
                    if(today.getDate() >= dob.getDate()) {
                        return today.getMonth() - dob.getMonth();
                    }
                    else {
                        if((today.getMonth() - 1) >= dob.getMonth()) {
                            return (today.getMonth() - 1) - dob.getMonth();
                        }
                        else {
                            return ((today.getMonth() - 1) + 12) - dob.getMonth();
                        }
                    }
                }
                else {
                    if(today.getDate() >= dob.getDate()) {
                        return (today.getMonth() + 12) - dob.getMonth();
                    }
                    else {
                        return ((today.getMonth() - 1) + 12) - dob.getMonth();
                    }
                }
            }());

            if (this.paciente.fecha_nacimiento!="") {
                this.paciente.edad = this.year
                this.paciente.meses = this.month
            }else{
                this.paciente.edad=""
                this.paciente.meses=""
            }
		},
        async getAdmision() {
            try {
                const res = await fetch(`${url}/api/hoja_admision/admision/`+this.$route.params.id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()

                if (data.data) {
                    console.log(data.data);
                    this.exist=true
                    this.paciente=data.data.paciente
                    this.change(this.paciente.fecha_nacimiento)
                    this.admision=data.data
                    this.admision.especialidad_id=data.data.especialidad.id
                    this.obtenerMedicosPerEspecialidad(this.admision.especialidad_id)
                    this.admision.medico_id=data.data.medico_id.id
                    if (data.data.facturacion) {
                        this.loading=false
                        this.dtaFacturacion=true
                        this.form_dta_facturacion=true
                        this.dataFacturacion=data.data.facturacion
                        this.getMunicipiosFacturacion(data.data.facturacion.estado_id)
                    }
                    this.loading=false
                    this.getMunicipiosPaciente(this.paciente.estado.id)
                }else{
                    this.altDataPAciente()
                }

            } catch (error) {
                this.loading=false
                console.log(error)
            }
        },

        altDataPAciente(){
            this.exist=false;
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            console.log(paciente);

            this.paciente.genero.id=paciente.paciente.genero.id ? paciente.paciente.genero.id : null
            this.paciente.nombre=paciente.paciente.nombre ? paciente.paciente.nombre : ''
            this.paciente.alergias=paciente.alergias ? paciente.alergias : ''
            this.paciente.fecha_nacimiento=paciente.paciente.fecha_nacimiento ? paciente.paciente.fecha_nacimiento : ''
            this.change(paciente.paciente.fecha_nacimiento)

            this.paciente.estado_civil.id=paciente.paciente.estado_civil.id ? paciente.paciente.estado_civil.id : null
            this.paciente.religion=paciente.religion ? paciente.religion : ''
            this.paciente.curp=paciente.paciente.curp ? paciente.paciente.curp : ''
            this.paciente.derechohabiente=paciente.paciente.derechohabiente ? paciente.paciente.derechohabiente : ''
            this.paciente.cp=paciente.paciente.cp ? paciente.paciente.cp : ''
            this.paciente.domicilio=paciente.paciente.domicilio ? paciente.paciente.domicilio : ''
            this.paciente.colonia=paciente.paciente.colonia ? paciente.paciente.colonia : ''
            this.paciente.ciudad=paciente.paciente.ciudad ? paciente.paciente.ciudad : ''
            this.paciente.telefono=paciente.paciente.telefono ? paciente.paciente.telefono : ''
            this.paciente.email=paciente.paciente.email ? paciente.paciente.email : ''
            this.paciente.estado.id=paciente.paciente.estado.id ? paciente.paciente.estado.id :null
            this.getMunicipiosPaciente(paciente.paciente.estado.id)

            this.paciente.municipio.id=paciente.paciente.municipio.id ? paciente.paciente.municipio.id : null

            this.admision.responsable_paciente=paciente.responsable_paciente.nombre? paciente.responsable_paciente.nombre: ''
            this.admision.parentesco_paciente=paciente.responsable_paciente.parentezco? paciente.responsable_paciente.parentezco: ''
            this.admision.telefono_responsable=paciente.responsable_paciente.telefono? paciente.responsable_paciente.telefono: ''
            this.admision.diagnostico = paciente.diagnostico ? paciente.diagnostico : ""
            this.admision.especialidad_id = paciente.especialidad.id ? paciente.especialidad.id : null
            this.obtenerMedicosPerEspecialidad(paciente.especialidad.id)
            this.admision.medico_id = paciente.medico.id ? paciente.medico.id : null

            this.getFacturacion(paciente.responsable_paciente.id)

            this.loading=false

        },

        async getFacturacion(id) {
            try {
                const res = await fetch(`${url}/api/facturacion/${id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.facturacion) {
                    this.dtaFacturacion=true

                    const pacienteEncrypt=localStorage.expedienteEncrypt
                    const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
                    const paciente=JSON.parse(expediente)
                    console.log(paciente);

                    this.form_dta_facturacion = true;
                    // this.dataFacturacion.razon_social=data.facturacion[(data.facturacion).length-1].razon_social
                    // this.dataFacturacion.rfc=data.facturacion[(data.facturacion).length-1].rfc
                    // this.dataFacturacion.email=data.facturacion[(data.facturacion).length-1].email
                    // this.dataFacturacion.domicilio=data.facturacion[(data.facturacion).length-1].domicilio
                    // this.dataFacturacion.metodo_pago_id=data.facturacion[(data.facturacion).length-1].metodo_pago.id
                    // this.dataFacturacion.forma_pago_id=data.facturacion[(data.facturacion).length-1].forma_pago.id
                    // this.dataFacturacion.uso_cfdi_id=data.facturacion[(data.facturacion).length-1].uso_cfdi.id
                    // this.dataFacturacion.id=data.facturacion[(data.facturacion).length-1].id

                    this.dataFacturacion.razon_social = data.facturacion[(data.facturacion).length-1].razon_social?data.facturacion[(data.facturacion).length-1].razon_social: ''
                    this.dataFacturacion.email = data.facturacion[(data.facturacion).length-1].email?data.facturacion[(data.facturacion).length-1].email: ''
                    this.dataFacturacion.domicilio = paciente.responsable_paciente.domicilio ? paciente.responsable_paciente.domicilio : ""
                    this.dataFacturacion.colonia = paciente.responsable_paciente.colonia ? paciente.responsable_paciente.colonia : ""
                    this.dataFacturacion.ciudad = paciente.responsable_paciente.ciudad ? paciente.responsable_paciente.ciudad : ""
                    this.dataFacturacion.estado_id = paciente.responsable_paciente.estado.id ? paciente.responsable_paciente.estado.id : null
                    this.getMunicipiosFacturacion(this.dataFacturacion.estado_id)
                    this.dataFacturacion.municipio_id = paciente.responsable_paciente.municipio.id ? paciente.responsable_paciente.municipio.id : null
                    this.dataFacturacion.rfc = data.facturacion[(data.facturacion).length-1].rfc ? data.facturacion[(data.facturacion).length-1].rfc : ""
                    this.dataFacturacion.telefono = paciente.paciente.telefono?paciente.paciente.telefono:""
                    this.dataFacturacion.responsable_cuenta = paciente.responsable_paciente.nombre ? paciente.responsable_paciente.nombre : ""
                    this.dataFacturacion.telefono_responsable = paciente.responsable_paciente.telefono?paciente.responsable_paciente.telefono:""
                    this.dataFacturacion.metodo_pago_id = data.facturacion[(data.facturacion).length-1].metodo_pago.id?data.facturacion[(data.facturacion).length-1].metodo_pago.id:null
                    this.dataFacturacion.forma_pago_id = data.facturacion[(data.facturacion).length-1].forma_pago.id?data.facturacion[(data.facturacion).length-1].forma_pago.id:null
                    this.dataFacturacion.uso_cfdi_id = data.facturacion[(data.facturacion).length-1].uso_cfdi.id?data.facturacion[(data.facturacion).length-1].uso_cfdi.id:null

                }else{
                    this.hayFactura=false
                    this.dtaFacturacion=false
                    this.form_dta_facturacion = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async PdfHojaAdmision(id) {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/hoja_admision/admision/pdf/${id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)
            });
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.paciente.no_expediente+" Hoja de admisión");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        getMedicPerEsp(idEsp){
            console.log(`getMedicPerEsp`);
            this.obtenerMedicosPerEspecialidad(idEsp)
            this.admision.especialidad_id = idEsp
            this.openAddMedic = false
            this.openSuccess = true
        }

    },

    mounted() { 
        // PARA EL TEST
        // Object.assign(this.$data, this.$options.data());
        // this.getDate()
        // this.getEstados()
        // this.obtenerEstadoCivil()
        // this.getGeneros()
        // this.obtenerCatalogoFactura()
        // this.obtenerEspecialidades()

        // this.loading=true
        // this.getAdmision()
    },
    activated(){
        // PARA EL NORMAL
        Object.assign(this.$data, this.$options.data());
        this.getDate()
        this.getEstados()
        this.obtenerEstadoCivil()
        this.getGeneros()
        this.obtenerCatalogoFactura()
        this.obtenerEspecialidades()

        this.loading=true
        this.getAdmision()
    }
}
</script>

<style scoped>
    @media only screen and (max-width: 900px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (min-width: 900px) {
        .size_th {
            width: 15vw;
        }
    }
</style>