<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Hoja de admisión</h2>   
            </b-col>
        </b-row>
        <b-container>

            <div v-if="reIngreso">
                <span class="txt_secondary">Reingreso</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-file-text-o mr-1"></i>Último expediente: {{lastExp}}</span> <br>
                <small class="txt_gray">* El expediente se generará automaticamente</small>
            </div>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                             <b-form-input :disabled="saved"  v-model="paciente.nombre" :state="nombre" type="text" name="nombrePaciente" autocomplete="off" v-on:keypress="soloLetras($event)" @change="existePaciente()" class="border-0 text-value-capitalize" size="sm" placeholder="Ingresa nombre del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Género</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved"   :state="genero_id" v-model="paciente.genero_id" :options="genero" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  name="fechaPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha" v-model="paciente.fecha_nacimiento" @change="change(paciente.fecha_nacimiento)" maxlength="10" class="border-0" type="date" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input disabled name="agePaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.edad" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <b-form-input disabled name="monthPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.meses" class="border-0" size="sm" />
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado civil</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved"   name="ecPaciente"  :state="estado_civil_id" v-model="paciente.estado_civil_id" :options="ecivil" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Religión</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  name="religion" autocomplete="off" :state="v_religion" v-model="paciente.religion" class="border-0" size="sm" placeholder="Ingresa la religión" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">CURP</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  name="curp" autocomplete="off" maxlength="18" @keyup="curpUpperCase()" :state="v_curp" v-model="paciente.curp" class="border-0" size="sm" placeholder="Ingresa la CURP" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Derechohabiente</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  name="derechoHabiente" autocomplete="off" :state="derechoHabiente" v-model="paciente.derechohabiente" class="border-0" size="sm" placeholder="Ingresa el derechohabiente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  list="input-list" name="cp" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" @input="searchDataCP" :state="cp" v-model="paciente.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="saved"  type="text" name="domicilioPaciente" autocomplete="off" v-on:keypress="rgexDomicilio($event)" :state="domicilio" v-model="paciente.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-th class="th_style">Número interior</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" name="numInterior" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.numInt" maxlength="5" :state="numInt" class="border-0" size="sm" placeholder="Ingresa el número interior" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Número exterior</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" name="numExterior" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="paciente.numExt" maxlength="5" :state="numExt" class="border-0" size="sm" placeholder="Ingresa el número exterior" />
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  list="my-list-id" type="text" name="coloniaPaciente" autocomplete="off" :state="colonia" v-model="paciente.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                            <datalist id="my-list-id">
                            <option v-for="size in listaAsentamientos" :key="size">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  type="text" name="ciudadPaciente" autocomplete="off" v-on:keypress="soloLetras($event)" :state="ciudad" v-model="paciente.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved"   name="edoPaciente" :state="estado" v-model="paciente.estado_id" :options="dtaEstados"  @change="getMunicipiosPaciente(paciente.estado_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select name="municipioPaciente" :disabled="disabled_select_mcpio_paciente || reIngreso || saved" :state="municipio" v-model="paciente.municipio_id" :options="dtaMunicipiosPaciente" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved"  name="telefonoPaciente" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="paciente.telefono" :state="telefono" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos médicos</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Alergias</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" v-model="paciente.alergias" type="text" name="alergias" autocomplete="off" class="border-0" size="sm" placeholder="Default: No manifiesta" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Diagnostico</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" v-model="admision.diagnostico" :state="diagnostico" type="text" name="diagnostico" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el diagnostico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Procedimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" v-model="admision.procedimiento" :state="procedimiento" type="text" name="procedimiento" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el procedimiento" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Información del responsable</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="resp" autocomplete="off" v-on:keypress="soloLetras($event)" :state="nombreR" v-model="admision.responsable_paciente" class="border-0 text-value-capitalize" size="sm" placeholder="Ingresa nombre de responsable del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Parentesco</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="parentesco" autocomplete="off" v-on:keypress="soloLetras($event)" :state="parentesco" v-model="admision.parentesco_paciente" class="border-0" size="sm" placeholder="Ingresa parentesco con el paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono del responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="telefonoResp" autocomplete="off" :state="telefonoResp" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="admision.telefono_responsable" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos de facturación</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Facturación</p>
                        <label class="switch">
                            <input :disabled="saved" v-model="dtaFacturacion" @change="datosFacturacion()" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row>

            <b-table-simple v-if="form_dta_facturacion" class="table-sm mt-3">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre / Razón social</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="nombreRazonSocial" v-model="dataFacturacion.razon_social" :state="VRazonSocial" autocomplete="off" v-on:keypress="soloLetras($event)" class="border-0" size="sm" placeholder="Ingresa nombre o razón social" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Responsable de la cuenta</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="saved" type="text" name="respCuenta" autocomplete="off" v-on:keypress="soloLetras($event)" :state="VRespCuenta"  v-model="dataFacturacion.responsable_cuenta" class="border-0" size="sm" placeholder="Ingresa el responsable" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="telefonoFact" autocomplete="off" :state="VTelefonoFact" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="dataFacturacion.telefono" class="border-0" size="sm" placeholder="Ingresa teléfono" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Email</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="email" name="emailFactura" v-model="dataFacturacion.email" :state="VFactEmail" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el email" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">RFC</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="RFC" v-model="dataFacturacion.rfc" :state="VRcf" @keyup="upperCase()" autocomplete="off" maxlength="13" class="border-0" size="sm" placeholder="Ingresa el RFC" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" list="input-list" name="cpFacturacion" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" @input="searchDataCPFacturacion()" :state="cpFac" v-model="dataFacturacion.cp" class="border-0" size="sm" placeholder="Ingresa código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Teléfono del responsable</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="telefonoResp" autocomplete="off" :state="VTelefonoFactResp" v-on:keypress="soloNumeros($event)" maxlength="10" v-model="dataFacturacion.telefono_responsable" class="border-0" size="sm" placeholder="Ingresa teléfono del responsable" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                           <b-form-input :disabled="saved" type="text" name="domicilioFacturacion" autocomplete="off" v-on:keypress="rgexDomicilio($event)" :state="VDomicilioFac"  v-model="dataFacturacion.domicilio" class="border-0" size="sm" placeholder="Ingresa calle y número" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia / Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" list="my-list-id" type="text" name="coloniaPaciente" autocomplete="off" :state="VColoniaFac" v-model="dataFacturacion.colonia" class="border-0" size="sm" placeholder="Ingresa colonia" />
                            <datalist id="my-list-id">
                            <option v-for="size in listaAsentamientosFac" :key="size">{{ size }}</option>
                            </datalist>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" type="text" name="ciudadPaciente" autocomplete="off" v-on:keypress="soloLetras($event)" :state="VCiudadFac" v-model="dataFacturacion.ciudad" class="border-0" size="sm" placeholder="Ingresa ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Estado</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved" name="edoPaciente" :state="VEstadoFac" v-model="dataFacturacion.estado_id" :options="dtaEstadosFac"  @change="getMunicipiosFacturacion(dataFacturacion.estado_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Municipio</b-th>
                        <b-td class="border_table">
                            <b-form-select name="municipioPaciente" :disabled="disabled_select_mcpio_factura || saved" :state="VMunicipioFac" v-model="dataFacturacion.municipio_id" :options="dtaMunicipiosFac" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Método de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved" name="metodoPago" v-model="dataFacturacion.metodo_pago_id" :options="metodoPago" :state="VMetodoPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Forma de pago</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved" name="formaPago" v-model="dataFacturacion.forma_pago_id" :options="formaPago" :state="VFormaPago" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Uso del CFDI</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="saved" name="usoCFDI" v-model="dataFacturacion.uso_cfdi_id" :options="usoCfdi" :state="VUsoCFDI" class="border-0" size="sm"></b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Información de ingreso</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <!-- <b-tr>
                        <b-th class="th_style size_th">Fecha de ingreso</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <b-form-input :disabled="saved" name="fechaIngreso" v-model="fechaIngreso" type="date" class="border-0 input-disabled" size="sm"/>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Hora</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="5" lg="5">
                                    <div class="d-flex">
                                        <b-form-input :disabled="saved" name="hora" v-model="horaIngreso" autocomplete="off" type="time" min="00:00:01" max="24:59:59" class="border-0 input-disabled" size="sm"></b-form-input>
                                        <span class="float-right px-1 txt_gray">
                                            <small class="txt_gray">hora</small>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-select :disabled="saved" :state="especialidad_id" v-model="admision.especialidad_id" :options="dtaEspecialidades" @change="obtenerMedicosPerEspecialidad(admision.especialidad_id)" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Médico que interna</b-th>
                        <b-td class="border_table">
                            <b-form-select :state="VmedicoId" :disabled="disabled_select_medico || saved" v-model="admision.medico_id" :options="dtaMedicos" class="border-0" size="sm" />
                        </b-td>
                        <b-td class="border_table justify-content-center text-center my-auto">
                            <button :disabled="disabled_select_medico || saved" class="border-0 bg_secondary txt_white rounded-circle pd-add-medic" title="Añadir médico" @click="modalAddMedic()">
                                <i class="fa fa-plus fa-md" />
                            </button>
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-th class="th_style size_th">Médico que interna</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" v-model="admision.medico_id" type="text" name="nomMedico" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el nombre" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Especialidad</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="saved" v-model="admision.especialidad_id" type="text" name="especialidad" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa la especialidad" />
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style size_th">Ingresado a</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="saved" v-model="admision.ingresado_a" :state="ingresado_a" type="text" name="ingresadoA" autocomplete="off" class="border-0" size="sm" placeholder="Ingresado a"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Admitido por</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="saved" v-model="admision.admitido_por" :state="admitido_por" type="text" name="admitidoPor" autocomplete="off" class="border-0" size="sm" placeholder="Admitido por"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" />| {{err}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div class="d-flex justify-content-end mb-4">
            <b-col cols="12" sm="12" md="5" lg="5">
                <b-overlay
                :show="busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                >
                    <button :hidden="saved" class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="verificar()">GUARDAR</button>
                </b-overlay>
            </b-col>
        </div>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <RegistroMedico :openModalAddMedic="openAddMedic" @closeModalAddMedic="closeModalAddMedic()" @getMedicPerEsp="getMedicPerEsp($event)"/>
        <Loading :show="loading"/>
        <PacientesExistentes ref="pacientesExistentes" :openPacienteExistente="openMdlNewExp" @cerrarPacienteExistente="cerrarPacienteExistente"/>
    </b-container> 
</template>

<script>

import Vue from 'vue'
import { getCatalogo } from '../../helpers/catalogos'
import PacientesExistentes from '@/components/Modals/PacientesExistentes.vue'
import { url } from '../../../settings.js'
import { debounce } from 'lodash'
import Success from '@/components/Modals/Success.vue'
import Error from '@/components/Modals/Error.vue'
import RegistroMedico from '@/components/Medico/Registro.vue'
import router from '../../router'
import Loading from '@/components/Loader/Loading.vue'
// import router from '../../router/index.js'

export default {
    name: 'VerHojaDeAdmision',
    components: { Success, Error, RegistroMedico, Loading, PacientesExistentes },

    data: () => ({
        saved: false,
        pacientes:[],
        openMdlNewExp:false,
        loading:false,
        reIngreso:false,
        lastExp:"",

        openAddMedic: false,
        openSuccess: false,
        openError: false,

        error: false,
        errores: [],

        disabled_select_medico: true,
        dtaEspecialidades: [{value:null, text:'seleccionar'},{text: '...'}],
        dtaMedicos: [{value:null,text:'seleccionar'},{text:'...'}],

        disabled_select_mcpio_paciente: true,
        selectedecivil:null,
        dtaMunicipiosPaciente: [{value:null,text:'seleccione una opción'}],
        dtaEstados: [{value:null,text:'seleccione una opción'}],
        genero: [{value:null, text:'seleccionar'}],
        ecivil: [{value:null,text:'seleccione una opción'}],
        listaAsentamientos:[],
        listaAsentamientosFac:[],

        dtaFacturacion: false,
        form_dta_facturacion: false,
        metodoPago: [{value:null, text:'seleccione una opción'}],
        formaPago: [{value:null, text:'seleccione una opción'}],
        usoCfdi: [{value:null, text:'seleccione una opción'}],
        dtaEstadosFac: [{value:null,text:'seleccione una opción'}],
        dtaMunicipiosFac: [{value:null, text:'seleccione una opción'}],

        fechaIngreso:"",
        horaIngreso:"",

        disabled_select_mcpio_factura: true,

        busy: false,
        generarNoExp:false,

        paciente: {
            id:null,
            genero_id: null,
            edad: '',
            meses: '',
            nombre: '',
            alergias: '',
            fecha_nacimiento: '',
            estado_civil_id: null,
            religion: '',
            curp: '',
            derechohabiente: '',
            cp: '',
            domicilio: '',
            colonia: '',
            ciudad: '',
            telefono:"",
            email: '',
            estado_id: null,
            municipio_id: null,
            no_expediente:"",
            no_expediente_legacy:""
        },

        dataFacturacion: {
            paciente_id: '',
            razon_social: '',
            cp: '',
            email: '',
            domicilio: '',
            colonia: '',
            ciudad: '',
            estado_id: null,
            municipio_id: null,
            rfc: '',
            telefono: '',
            responsable_cuenta: '',
            telefono_responsable: '',
            metodo_pago_id: null,
            forma_pago_id: null,
            uso_cfdi_id: null,
        },

        admision:{
            paciente_id: '',
            facturacion_id: '',
            diagnostico: '',
            procedimiento: '',
            especialidad_id: null,
            medico_id: null,
            ingresado_a: '',
            admitido_por: '',
            responsable_paciente: '',
            parentesco_paciente: '',
            telefono_responsable: '',
        },
    }),

    computed: {

        Vexp(){
            const patternExp = /^[0-9]{4}-[0-9]{5}-[0-9]{1,3}$/;
            // const patternExp = /^[0-9]{2,3}[.]{0,1}[0-9]{0,2}$/;
            return patternExp.test(this.paciente.no_expediente_legacy)
        },

        Vedad() {
            return this.admision.edad != ""
        },

        Vmeses() {
            return this.paciente.meses != ""
        },

        fecha() {
            return this.paciente.fecha_nacimiento != ""
        },

        genero_id(){
            return this.paciente.genero_id !=null
        },

        nombre() {
            return this.paciente.nombre.length >=10
        },

        estado_civil_id() {
            return this.paciente.estado_civil_id != null
        },

        v_religion() {
            return this.paciente.religion != ''
        },

        v_curp() {
            return this.paciente.curp == "" ? null : this.paciente.curp.length==18
        },

        derechoHabiente() {
            return this.paciente.derechohabiente != ''
        },

        cp() {
            let flag = true;
            if (!this.regexNumber(this.paciente.cp)) {
                flag = false;
            }

            if (this.paciente.cp.length !== 5) {
                flag = false;
            }
            return flag;
        },
        
        domicilio() {
            return this.paciente.domicilio != ""
        },

        numInt() {
            return this.paciente.numInt != ""
        },

        numExt() {
            return this.paciente.numExt != ""
        },

        colonia() {
            return this.paciente.colonia != ""
        },

        ciudad() {
            return this.paciente.ciudad != ""
        },

        estado() {
            return this.paciente.estado_id != null
        },

        municipio() {
            return this.paciente.municipio_id != null
        },

        /* Form datos de facturación */
        cpFac() {
            let flag = true;
            if (!this.regexNumber(this.dataFacturacion.cp)) {
                flag = false;
            }

            if (this.dataFacturacion.cp.length !== 5) {
                flag = false;
            }
            return flag;
        },

        VTelefonoFact() {
            let flag = true;
            if (!this.regexNumber(this.dataFacturacion.telefono)) {
                flag = false;
            }

            if (this.dataFacturacion.telefono.length !== 10) {
                flag=false;
            }
            return flag;
        },

        VTelefonoFactResp() {
            let flag = true;
            if (!this.regexNumber(this.dataFacturacion.telefono_responsable)) {
                flag = false;
            }

            if (this.dataFacturacion.telefono_responsable.length !== 10) {
                flag=false;
            }
            return flag;
        },

        VRespCuenta() {
            return this.dataFacturacion.responsable_cuenta.length>=10
        },

        VColoniaFac() {
            return this.dataFacturacion.colonia != ""
        },

        VCiudadFac() {
            return this.dataFacturacion.ciudad != ""
        },

        VEstadoFac() {
            return this.dataFacturacion.estado_id != null
        },

        VMunicipioFac() {
            return this.dataFacturacion.municipio_id != null
        },

        VDomicilioFac() {
            return this.dataFacturacion.domicilio != ""
        },

        VRazonSocial() {
            return this.dataFacturacion.razon_social != ""
        },

        VRcf() {
            let flag = false;
            if (this.dataFacturacion.rfc.length >= 12) {
                flag=true;
            }
            return flag;
        },

        VFactEmail() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.dataFacturacion.email)
        },

        VMetodoPago() {
            return this.dataFacturacion.metodo_pago_id != null
        },

        VFormaPago() {
            return this.dataFacturacion.forma_pago_id != null
        },

        VUsoCFDI() {
            return this.dataFacturacion.uso_cfdi_id != null
        },

        /* Admision */
        nombreR() {
            return this.admision.responsable_paciente.length >= 10
        },

        parentesco() {
            return this.admision.parentesco_paciente != ""
        },

        telefonoResp() {
            let flag = true;
            if (!this.regexNumber(this.admision.telefono_responsable)) {
                flag = false;
            }

            if (this.admision.telefono_responsable.length !== 10) {
                flag=false;
            }
            return flag;
        },

        telefono() {
            let flag = null;
            if (this.paciente.telefono) {
                if ((this.paciente.telefono.length>0 && this.paciente.telefono.length<10)) {
                    flag = false;
                }else if (this.paciente.telefono.length == 10) {
                    flag=true;
                }else{
                    flag=null
                }
            }
            return flag;
        },

        especialidad_id() {
            return this.admision.especialidad_id != null
        },

        VmedicoId() {
            return this.admision.medico_id != null
        },

        diagnostico() {
            return this.admision.diagnostico != ''
        },

        procedimiento() {
            return this.admision.procedimiento != ''
        },

        ingresado_a() {
            return this.admision.ingresado_a != ''
        },

        admitido_por() {
            return this.admision.admitido_por != ''
        }
    },

    methods: {

        verificar() {
            this.busy=true
            this.errores = []
            this.error = false
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const patternExp = /^[0-9]{4}-[0-9]{1,5}-[0-9]{1,3}$/;
            /* Datos del paciente */
            if (this.paciente.nombre == '') {
                this.errores.push('El nombre es obligatorio')
            }
            if (this.generarNoExp && !patternExp.test(this.paciente.no_expediente_legacy)) {
                this.errores.push('formato de expediente incorrecto(AAAA-NNNNN-N)')
            }
            if (this.paciente.nombre.length < 10) {
                this.errores.push('Ingrese el nombre completo')
            }
            if (this.paciente.genero_id == null) {
                this.errores.push('El género es obligatorio')
            }
            if (this.paciente.fecha_nacimiento == '') {
                this.errores.push('La fecha de nacimiento es obligatorio')
            }
            if (this.paciente.estado_id == '') {
                this.errores.push('El estado civil es obligatorio')
            }
            if (this.paciente.religion == '') {
                this.errores.push('La religion es obligatorio')
            }
            // if (this.paciente.curp.length < 18 && this.paciente.curp.length > 0 ) {
            //     this.errores.push('La curp es obligatoria')
            // } else 
            if (this.paciente.curp.length > 1 && this.paciente.curp.length < 18) {
                this.errores.push('Curp inválida')
            }
            if (this.paciente.derechohabiente == '') {
                this.errores.push('El derechohabiente es obligatoria')
            }
            if (this.paciente.cp == '') {
                this.errores.push('El código postal es obligatorio')
            } else if (this.paciente.cp.length < 5) {
                this.errores.push('Código postal inválido')
            }
            if (this.paciente.domicilio == '') {
                this.errores.push('El domicilio es obligatorio')
            }
            if (this.paciente.colonia == '') {
                this.errores.push('La colonia es obligatorio')
            }
            if (this.paciente.ciudad == '') {
                this.errores.push('La ciudad es obligatorio')
            }
            if (this.paciente.estado_id == '') {
                this.errores.push('El estado es obligatorio')
            }
            if (this.paciente.municipio_id == '') {
                this.errores.push('El municipio es obligatorio')
            }

            if (this.paciente.telefono && this.paciente.telefono.length!=10 && this.paciente.telefono.length>0) {
                this.errores.push('El campo telefono de paciente debe contener 10 digitos')
            }
            
            /* Mas dato de admision */
            /* if (this.admision.facturacion_id == '') {
                this.errores.push('La factura_id es obligatoria')
            } */
            if (this.admision.diagnostico == '') {
                this.errores.push('El diagnóstico es obligatorio')
            }
            if (this.admision.procedimiento == '') {
                this.errores.push('El procedimiento es obligatorio')
            }
            if (this.admision.especialidad_id == null) {
                this.errores.push('La especialidad es obligatorio')
            }
            if (this.admision.medico_id == null) {
                this.errores.push('El médico es obligatorio')
            }
            if (this.admision.ingresado_a == '') {
                this.errores.push('Especifique el ingreso')
            }
            if (this.admision.admitido_por == '') {
                this.errores.push('Especifique quien lo admitio')
            }
            if (this.admision.responsable_paciente == '') {
                this.errores.push('El resposable del paciente es obligatorio')
            } else if (this.admision.responsable_paciente < 10) {
                this.errores.push('Ingrese el nombre completo del responsable')
            }
            if (this.admision.parentesco_paciente == '') {
                this.errores.push('El parentesco es obligatorio')
            }
            if (this.admision.telefono_responsable == '') {
                this.errores.push('El teléfono del responsable es obligatorio')
            } else if (this.admision.telefono_responsable.length < 10 || isNaN(this.admision.telefono_responsable)) {
                this.errores.push('Teléfono del responsable inválido')
            }
            // this.error = true

            /* Datos de la factura */
            if (this.form_dta_facturacion == true) {
                if (this.dataFacturacion.razon_social == '') {
                    this.errores.push('Nombre / razón social es obligatorio')
                }
                if (this.dataFacturacion.responsable_cuenta == '') {
                    this.errores.push('Responsable es obligatorio')
                }
                if (this.dataFacturacion.responsable_cuenta.length<=9) {
                    this.errores.push('Responsable debe de contener minimo 10 caracteres')
                }
                if (!pattern.test(this.dataFacturacion.email)) {
                    this.errores.push('Correo inválido')
                }
                if (this.dataFacturacion.telefono == '') {
                    this.errores.push('El teléfono es obligatorio')
                } else if (this.dataFacturacion.telefono.length < 10) {
                    this.errores.push('Teléfono inválido')
                }
                if (this.dataFacturacion.rfc == '') {
                    this.errores.push('RFC es obligatorio')
                } else if (this.dataFacturacion.rfc.length < 12) {
                    this.errores.push('RFC inválido')   
                }
                if (this.dataFacturacion.cp == '') {
                    this.errores.push('El código postal es obligatorio')
                }
                if (this.dataFacturacion.cp.length < 5) {
                    this.errores.push('Código postal inválido')
                }
                if (this.dataFacturacion.telefono_responsable == '') {
                    this.errores.push('El teléfono del responsable es obligatorio')
                } else if (this.dataFacturacion.telefono_responsable.length < 10) {
                    this.errores.push('Teléfono del responsable inválido')
                }
                if (this.dataFacturacion.domicilio == '') {
                    this.errores.push('El domicilio es obligatorio')
                }
                if (this.dataFacturacion.ciudad == '') {
                    this.errores.push('La ciudad es obligatorio')
                }
                if (this.dataFacturacion.estado_id == null) {
                    this.errores.push('El estado es obligatorio')
                }
                if (this.dataFacturacion.municipio_id == null) {
                    this.errores.push('El municipio es obligatorio')
                }
                if (this.dataFacturacion.metodo_pago_id == null) {
                    this.errores.push('El método de pago es obligatorio')
                }
                if (this.dataFacturacion.forma_pago_id == null) {
                    this.errores.push('La forma de pago es obligatorio')
                }
                if (this.dataFacturacion.uso_cfdi_id == null) {
                    this.errores.push('El uso de CFDI es obligatorio')
                }
            }

            if (this.errores.length == 0) {
                this.error = false
                if (!this.reIngreso) {
                    this.guardar()
                }else{
                    this.updatePaciente()
                }
            }else{
                this.error=true
                this.busy=false
                this.openError=true
            }
        },

        existePaciente(){
            let arr = (this.paciente.nombre).split(" ");
            let res = this.pacientes
            let aux = res
            let finded = false
            let similitudes = 0
            for (const val of arr) {
                aux = res.filter(p => ((p.nombre).toLowerCase()).includes(val.toLowerCase()))
                if ((aux!=undefined && aux.length>0) ) {
                    similitudes = similitudes+1
                    res = aux
                    finded=true
                }else{
                    console.log("algotamal");
                }
            }
            if ((this.paciente.nombre.length>=10 && res.length>0 && finded && similitudes>arr.length-2)||(this.paciente.nombre.length>=10 && res.length>0 && finded && similitudes>4)) {
                this.openMdlNewExp = true
                this.$refs.pacientesExistentes.existPaciente(res)
            }
        },

        async guardar() {
            this.busy=true
            if (this.paciente.alergias === '') {
                this.paciente.alergias = 'No manifiesta'
            }
            if (this.paciente.curp === '') {
                this.paciente.curp = 'XXXXXXXXXXXXXXXXXX'
            }
            try {
                const res = await fetch(`${url}/api/hoja_admision/pacientes`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.paciente),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                console.log(data);
                var promise = Promise.resolve(data);
                var id = ""
                var warn = false
                let er = false
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        id = val.paciente.id 
                        warn = true
                    }else if(val.errors){
                        er=val.errors
                    }
                });
                if (warn) {
                    if (this.form_dta_facturacion) {
                        this.registroFacturacion(id)                        
                    } else {
                        this.dtaAdmision(null,id);
                    }
                }else if(er){
                    if (er.no_expediente) {
                        this.errores.push("Formato de expediente incorrecto (AAAA-NNNNN-N)")
                        this.error=true
                        this.busy=false
                        this.openError=true
                    }
                }
            } catch (error) {
                this.openError=true
                Vue.prototype.$error500 = true
                this.busy=false
                console.log(error);
            }
        },
        async updatePaciente() {
            this.busy=true
            if (this.paciente.alergias === '') {
                this.paciente.alergias = 'No manifiesta'
            }
            if (this.paciente.curp === '') {
                this.paciente.curp = 'XXXXXXXXXXXXXXXXXX'
            }
            this.paciente.no_expediente=""
            try {
                const res = await fetch(`${url}/api/hoja_admision/pacientes/`+this.paciente.id, {
                    method: 'put',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.paciente),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var promise = Promise.resolve(data);
                var id = ""
                var warn = false
                let er = false
                let permisos = true
                await promise.then(function(val) {
                    if (val.message.includes("Sin permiso")) {
                        permisos=false
                    }else{
                        permisos=true
                    }
                    if (val.class=="success") {
                        id = val.paciente.id 
                        warn = true
                    }else if(val.errors){
                        er=val.errors
                    }
                });
                if (warn) {
                    if (this.form_dta_facturacion) {
                        this.registroFacturacion(id)                        
                    } else {
                        this.dtaAdmision(null,id);
                    }
                }else if(er){
                    if (er.no_expediente) {
                        this.errores.push("Formato de expediente incorrecto (AAAA-NNNNN-N)")
                        this.error=true
                        this.busy=false
                        this.openError=true
                    }
                }
                if (!permisos) {
                    console.log("entre error permisos");
                    if (this.form_dta_facturacion) {
                        this.registroFacturacion(this.paciente.id)                        
                    } else {
                        this.dtaAdmision(null,this.paciente.id);
                    }
                }

            } catch (error) {
                this.openError=true
                Vue.prototype.$error500 = true
                this.busy=false
                console.log(error);
            }
        },

        async registroFacturacion(id) {
            this.dataFacturacion.paciente_id = id
            try {
                const res = await fetch(`${url}/api/hoja_admision/datos_facturacion`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataFacturacion),
                });
                const data = res.json()
                console.log(data);
                let ids = ""
                let warn = false
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        ids = val.facturacion.id
                        warn = true
                    }
                });
                if (warn) {
                    this.dtaAdmision(ids, this.dataFacturacion.paciente_id)
                }
            } catch (error) {
                this.busy = false
                console.log(error)
            }
            
        },

        async dtaAdmision(idFactura, idPaciente) {
            this.busy = true
            this.admision.facturacion_id = idFactura
            this.admision.paciente_id = idPaciente
            try {
                const res = await fetch(`${url}/api/hoja_admision/admision`, {
                    method: 'post',
                    headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.admision),
                })
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                if (Vue.prototype.$error500) {
                    this.openError=true
                }
                var promise = Promise.resolve(data);
                var resp = ""
                await promise.then(function(val) {
                    resp = val
                });
                if (resp.class=="success") {
                    this.busy = false
                    Vue.prototype.$deAdmision=true
                    Vue.prototype.$idPacienteAdmision=idPaciente
                    this.$emit("savedAdmision")
                    // ,idPaciente,this.reIngreso
                    this.saved=true;
                } else {
                    this.busy = false
                }
            } catch (error) {
                this.busy = false
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaEspecialidades = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
                this.dtaEspecialidades.unshift({ value: null, text: 'seleccionar' })
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerMedicosPerEspecialidad(idEspecialidad) {
            this.admision.medico_id = null
            
            if (this.admision.especialidad_id != null) {
                this.disabled_select_medico = false;
            } else {
                this.disabled_select_medico = true;
                this.admision.medico_id = null
            }

            try {
                const res = await fetch(`${url}/api/catalogos/medicos/${idEspecialidad}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.dtaMedicos=data.medicos.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMedicos.unshift({value:null,text:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },

        searchDataCP: debounce(function(){
            if (this.paciente.cp.length > 4) {
                fetch(`${url}/api/catalogos/codigo_postal/${this.paciente.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if (data.asentamientos.length>0) {
                        var estado=""
                        var municipio=""
                        this.listaAsentamientos=[]
                        this.paciente.colonia=""
                        data.asentamientos.forEach(element => {
                            estado =  element.estado.id
                            municipio = element.municipio.id
                            this.listaAsentamientos.push(element.asentamiento)
                        })
                        this.paciente.estado_id=estado
                        this.getMunicipiosPaciente(estado)
                        this.paciente.municipio_id=municipio
                    }else{
                        console.log("no hay datos cp");
                    }
                })
            }
        },500),

        searchDataCPFacturacion: debounce(function(){
            if (this.dataFacturacion.cp.length > 4) {
                fetch(`${url}/api/catalogos/codigo_postal/${this.dataFacturacion.cp}`,{
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    if (data.asentamientos.length>0) {
                        this.dataFacturacion.colonia=""
                        var estado="";
                        var municipio="";
                        this.listaAsentamientosFac=[];
                        data.asentamientos.forEach(element => {
                            estado =  element.estado.id
                            municipio = element.municipio.id
                            this.listaAsentamientosFac.push(element.asentamiento)
                        });
                        this.dataFacturacion.estado_id=estado
                        this.getMunicipiosFacturacion(estado)
                        this.dataFacturacion.municipio_id=municipio
                    }
                })
            }
        },500),

        async getMunicipiosFacturacion(idEstado) {
            if (this.dataFacturacion.estado_id != null) {
                this.disabled_select_mcpio_factura = false
            } else {
                this.disabled_select_mcpio_factura = true
            }

            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEstado}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.dtaMunicipiosFac=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosFac.unshift({value:null,text:"seleccione una opción"})
            } catch (error) {
                console.log(error)
            }
        },

        async getMunicipiosPaciente(idEstado) {
            if (this.paciente.estado_id != null) {
                this.disabled_select_mcpio_paciente = false
            } else {
                this.disabled_select_mcpio_paciente = true
            }

            try {
                const res = await fetch(`${url}/api/catalogos/municipios/${idEstado}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.dtaMunicipiosPaciente=data.municipios.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaMunicipiosPaciente.unshift({value:null,text:"seleccione una opción"})
            } catch (error) {
                console.log(error)
            }
        },

        async getGeneros() {
            try {
                const res = await fetch(`${url}/api/catalogos/genero`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.genero=data.genero.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.genero.unshift({value:null, text:"seleccionar"})
            } catch (error) {
                console.log(error)
            }
        },

         async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${url}/api/catalogos/estado_civil`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift( { value: null, text: 'seleccione una opcion' })


            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async getEstados() {
            try {
                const res = await fetch(`${url}/api/catalogos/estados`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                })
                const data = await res.json()
                this.dtaEstados=data.estados.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaEstados.unshift({value:null, text:"seleccione una opción"})

                this.dtaEstadosFac = data.estados.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.dtaEstadosFac.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerCatalogoFactura() {
            try {
                const res = await fetch(`${url}/api/catalogos/facturacion`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.formaPago = data.forma_pago.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.formaPago.unshift({value:null, text:"seleccione una opción"})

                this.metodoPago = data.metodo_pago.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.metodoPago.unshift({value:null, text:"seleccione una opción"})

                this.usoCfdi = data.uso_cfdi.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.usoCfdi.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },

        modalAddMedic() {
            this.openAddMedic = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event
            var charCode = (evt.which) ? evt.which : evt.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },

        soloNumerosExpediente(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode!=45) {
                evt.preventDefault();
            } else {
                return true;

            }
        },

        soloNumerosExpedienteUp(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (this.paciente.no_expediente.length==4 && charCode!=8 ) {
                this.paciente.no_expediente=this.paciente.no_expediente+"-"
            }
            if (this.paciente.no_expediente.length==5 && charCode!=8 && this.paciente.no_expediente.slice(4,5)!='-') {
                const x = this.paciente.no_expediente.substring(0,4)+"-"+this.paciente.no_expediente.substring(4,this.paciente.no_expediente.length)
                this.paciente.no_expediente = x
            }
            if (this.paciente.no_expediente.length==10 && charCode!=8 ) {
                this.paciente.no_expediente=this.paciente.no_expediente+"-"
            }
            if (this.paciente.no_expediente.length==11 && charCode!=8 && this.paciente.no_expediente.slice(10,11)!='-') {
                const x = this.paciente.no_expediente.substring(0,10)+"-"+this.paciente.no_expediente.substring(10,this.paciente.no_expediente.length)
                this.paciente.no_expediente = x
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode)
            if(/^[A-Za-z-zA-ZÀ-ÿ.\u00f1\u00d1 ]+$/.test(char)) {
                return true 
            } else {
                evt.preventDefault()
            }
        },

        rgexDomicilio(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z0-9-ZÀ-ÿ \u00f1\u00d1]*$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        regexNumber(number){
            let reg = new RegExp('^[0-9]*$')
            return reg.test(number)
        },

        getDate() {
            var date = new Date
            var minutes = ("0"+date.getMinutes()).slice(-2)
            var hour = ("0"+date.getHours()).slice(-2)
            this.horaIngreso=""+hour+":"+minutes+":00"
            var today = new Date()
            var dd = ("0" + (today.getDate())).slice(-2)
            var mm = ("0" + (today.getMonth() +1)).slice(-2)
            var yyyy = today.getFullYear()
            this.fechaIngreso = yyyy + '-' + mm + '-' + dd 
        },

        datosFacturacion() {
            if (this.dtaFacturacion == true) {
                this.form_dta_facturacion = true
            } else {
                this.form_dta_facturacion = false
            }
        },

        upperCase() {
            this.dataFacturacion.rfc = this.dataFacturacion.rfc.toUpperCase()
        },
        
        curpUpperCase() {
            this.paciente.curp = this.paciente.curp.toUpperCase()
        },

        change:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           
            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());

            this.month = (function() {
                if(today.getMonth() >= dob.getMonth()) {
                    if(today.getDate() >= dob.getDate()) {
                        return today.getMonth() - dob.getMonth();
                    }
                    else {
                        if((today.getMonth() - 1) >= dob.getMonth()) {
                            return (today.getMonth() - 1) - dob.getMonth();
                        }
                        else {
                            return ((today.getMonth() - 1) + 12) - dob.getMonth();
                        }
                    }
                }
                else {
                    if(today.getDate() >= dob.getDate()) {
                        return (today.getMonth() + 12) - dob.getMonth();
                    }
                    else {
                        return ((today.getMonth() - 1) + 12) - dob.getMonth();
                    }
                }
            }());

            if (this.paciente.fecha_nacimiento!="") {
                this.paciente.edad = this.year
                this.paciente.meses = this.month
            }else{
                this.paciente.edad=""
                this.paciente.meses=""
            }
		},

        async getDataPaciente(id) {
            try {
                const res = await fetch(`${url}/api/hoja_admision/admision/`+id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.data.paciente) {
                    this.reIngreso=true
                    this.paciente=data.data.paciente
                    this.change(this.paciente.fecha_nacimiento)
                    this.paciente.estado_civil_id=data.data.paciente.estado_civil.id
                    this.paciente.genero_id=data.data.paciente.genero.id
                    this.paciente.estado_id=data.data.paciente.estado.id
                    this.getMunicipiosPaciente(this.paciente.estado_id)
                    this.paciente.municipio_id=data.data.paciente.municipio.id
                    this.lastExp=data.data.paciente.no_expediente
                    this.getDataPacienteAlt(id,false)
                }else{
                    this.getDataPacienteAlt(id,true)
                }
            } catch (error) {
                this.getDataPacienteAlt(id,true)
            }
        },
        async getDataPacienteAlt(id,onlyData) {
            try {
                const res = await fetch(`${url}/api/pacientes/show/`+id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.paciente) {
                    if (onlyData) {
                        this.loading=false
                        this.reIngreso=true
                        this.paciente.nombre=data.paciente.nombre
                        this.paciente.edad=data.paciente.edad
                        this.paciente.id=data.paciente.id
                        this.paciente.alergias=data.paciente.alergias?data.paciente.alergias:""
                        this.paciente.fecha_nacimiento=data.paciente.fecha_nacimiento
                        this.paciente.religion=data.paciente.religion?data.paciente.religion:""
                        this.paciente.cp=data.paciente.cp
                        this.paciente.domicilio=data.paciente.domicilio
                        this.paciente.colonia=data.paciente.colonia
                        this.paciente.ciudad=data.paciente.ciudad
                        this.paciente.telefono=data.paciente.telefono
                        this.paciente.email=data.paciente.email
                        this.change(this.paciente.fecha_nacimiento)
                        this.paciente.estado_civil_id=data.paciente.estado_civil.id
                        this.paciente.genero_id=data.paciente.genero.id
                        this.paciente.estado_id=data.paciente.estado.id
                        this.getMunicipiosPaciente(this.paciente.estado_id)
                        this.paciente.municipio_id=data.paciente.municipio.id
                        this.lastExp=data.paciente.no_expediente
                        if (data.paciente.lastExpediente.expediente_id) {
                            this.verifyNotaEgresoFirmada(data.paciente.lastExpediente.expediente_id)
                        }else{
                            this.loading=false
                        }
                    }else{
                        if (data.paciente.lastExpediente.expediente_id) {
                            this.verifyNotaEgresoFirmada(data.paciente.lastExpediente.expediente_id)
                        }else{
                            this.loading=false
                        }
                    }
                }else{
                    Object.assign(this.$data, this.$options.data());
                    this.cancel()
                    router.push("/registro").catch(()=>{});
                }
            } catch (error) {
                Object.assign(this.$data, this.$options.data());
                this.cancel()
                router.push("/registro").catch(()=>{});
            }
        },
        async verifyNotaEgresoFirmada(id) {
            try {
                const res = await fetch(`${url}/api/notas/egreso/`+id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log(data);

                if (data.egreso.firma.is_firmado==1) {
                    this.loading=false
                }else{
                    Object.assign(this.$data, this.$options.data());
                    this.cancel()
                    router.push("/registro").catch(()=>{});
                }
            } catch (error) {
                Object.assign(this.$data, this.$options.data());
                this.cancel()
                console.log(error);
                router.push("/registro").catch(()=>{});
            }
        },
        getPacientes(){
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/pacientes'))
            }).then((data)=>{
                try {
                    this.pacientes=data.pacientes
                    this.donePacientes=true
                } catch (error) {
                    console.log(error);
                }
            })
        },
        cancel(){
            this.getDate()
            this.getEstados()
            this.obtenerEstadoCivil()
            this.getGeneros()
            this.obtenerCatalogoFactura()
            this.obtenerEspecialidades()
            this.getPacientes()
        },
        // Event $ on
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        closeModalAddMedic(){
            this.openAddMedic = false
        },
        cerrarPacienteExistente(){
            this.openMdlNewExp = false
        },
        getMedicPerEsp(idEsp){
            this.admision.especialidad_id = idEsp
            this.obtenerMedicosPerEspecialidad(idEsp)
            this.openAddMedic = false
            this.openSuccess = true
        }
    },

    mounted() {
    },
    beforeCreate(){
        console.log(this.$route.params);
        if (this.$route.params.id && isNaN(this.$route.params.id)) {
            router.push("/registro").catch(()=>{});
        }
    },
    activated(){
        if (this.$route.params.id && this.$route.path.includes('/registro/nuevo_expediente/')) {
            this.reIngreso=false
            Vue.prototype.$reIngreso=true
            this.loading=true
            this.getDataPaciente(this.$route.params.id)
        }else{
            Vue.prototype.$reIngreso=false
            this.loading=false
            this.reIngreso=false
        }
        this.getDate()
        this.getEstados()
        this.obtenerEstadoCivil()
        this.getGeneros()
        this.obtenerCatalogoFactura()
        this.obtenerEspecialidades()
        this.getPacientes()

    }
}
</script>

<style scoped>
    @media only screen and (max-width: 900px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (min-width: 900px) {
        .size_th {
            width: 15vw;
        }
    }
    .text-value-capitalize::placeholder {
        text-transform: none;
    }

    .text-value-capitalize {
        text-transform: capitalize;
    }

    .autoExp {
        color: gray;
    }
</style>