<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3 title_forms">Nota post-operatoria</h2>
            </b-col>
            <b-col class="d-none d-lg-block d-md-block" cols="12" sm="12" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto />
                </div>
            </b-col>
        </b-row>
        <b-container class="mt-4">
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente"
                                @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id"
                                        :value="all.id">{{ all.no_expediente }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong
                        class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(datos.firma.is_firmado == 1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(datos.firma.is_firmado == 0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <Datos :changeData="changeDta" />

            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Información del paciente</strong></p> -->
            <!-- <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <p class="txt_secondary mr-2">Mostrar información del paciente</p>
                        <label class="switch">
                            <input v-model="info" value=false type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col cols="12">
                    <!-- <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style size_th">Nombre</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Fecha de nacimiento</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.fecha_nacimiento}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Genero</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.genero.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.paciente_edad_years">
                                <b-th class="th_style">Edad</b-th>
                                <b-td class="border_table">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_years}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">años</span>
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="4" lg="3">
                                            <div class="d-flex">
                                                <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_months}}</span></span>
                                                <span class="float-right px-1 py-auto">
                                                    <span class="txt_gray size-span">meses</span>
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.id">
                                <b-th class="th_style">Idioma</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.idioma}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info && datosExpediente.id">
                                <b-th class="th_style">Religion</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.religion}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Estado civil</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.estado_civil.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Código postal</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.cp}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Domicilio</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.domicilio}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Colonia/Comunidad</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.colonia}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Ciudad</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.ciudad}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Estado</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.estado.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Municipio</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.municipio.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Teléfono</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.telefono}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="info">
                                <b-th class="th_style">Email</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.email}}</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->

                    <!-- <b-table-simple class="table-sm" v-if="datosExpediente.id">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style size_th">Número de expediente</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosPaciente.no_expediente}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="datosExpediente.tiene_alergias===1">
                                <b-th class="th_style">Alergias</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.alergias}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Especialidad (Área de procedimiento)</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.especialidad.nombre}}</span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Médico tratante</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2 size-span">{{datosExpediente.medico.nombre}}</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->

                    <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th">Fecha</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="date"
                                        name="fecha" autocomplete="off" :state="fecha" v-model="datos.fecha"
                                        class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hora</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="time"
                                            min="00:00:01" max="23:59:59" name="hora" autocomplete="off" :state="hour"
                                            v-model="datos.hora" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">horas</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Quirófano</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="quirofano"
                                        autocomplete="off" :state="sala" v-model="datos.sala" type="text" class="border-0"
                                        size="sm" placeholder="# de sala" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Clasificación de la herida</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="clasificacion_herida_id" v-model="datos.clasificacion_herida_id"
                                        :options="dtaClasificacionHeridas" value-field="id" text-field="nombre"
                                        class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th_diag">Diagnóstico preoperatorio</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="diagnostico_pre_operatorio_id" v-model="datos.diagnostico_pre_operatorio_id"
                                        :options="dtaDiagnosticos" value-field="id" text-field="nombre" class="border-0"
                                        size="sm" placeholder="Seleccionar" />
                                </b-td>
                                <b-td class="border_table justify-content-center text-center my-auto">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)"
                                        class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                        title="Añadir diagnóstico preoperatorio" @click="addDiagnosticos()">
                                        <i class="fa fa-plus fa-md" />
                                    </button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Operación planeada</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="operacion_planeada_id" v-model="datos.operacion_planeada_id"
                                        :options="dtaOperaciones" value-field="id" text-field="nombre" class="border-0"
                                        size="sm" placeholder="Seleccionar" />
                                </b-td>
                                <b-td class="border_table justify-content-center text-center my-auto">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)"
                                        class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                        title="Añadir operación planeada" @click="addOperaciones()">
                                        <i class="fa fa-plus fa-md" />
                                    </button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Operación realizada</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="operacion_realizada_id" v-model="datos.operacion_realizada_id"
                                        :options="dtaOperaciones" value-field="id" text-field="nombre" class="border-0"
                                        size="sm" placeholder="Seleccionar" />
                                </b-td>
                                <b-td class="border_table justify-content-center text-center my-auto">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)"
                                        class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                        title="Añadir operación realizada" @click="addOperaciones()">
                                        <i class="fa fa-plus fa-md" />
                                    </button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Diagnóstico postoperatorio</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="diagnostico_post_operatorio_id"
                                        v-model="datos.diagnostico_post_operatorio_id" :options="dtaDiagnosticos"
                                        value-field="id" text-field="nombre" class="border-0" size="sm"
                                        placeholder="Seleccionar" />
                                </b-td>
                                <b-td class="border_table justify-content-center text-center my-auto">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)"
                                        class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                        title="Añadir diagnóstico postoperatorio" @click="addDiagnosticos()">
                                        <i class="fa fa-plus fa-md" />
                                    </button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Edo. Postqx. inmediato</b-th>
                                <b-td colspan="2" class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="edoPostqx" autocomplete="off" :state="edo_postqx_inmediato"
                                        v-model="datos.edo_postqx_inmediato" class="border-0" size="sm"
                                        placeholder="Edo. Postqx inmediato" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Pronóstico</b-th>
                                <b-td colspan="2" class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="pronostico_id" v-model="datos.pronostico_id" :options="pronostico"
                                        value-field="id" text-field="nombre" class="border-0" size="sm"
                                        placeholder="Seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Sangrado aproximado</b-th>
                                <b-td colspan="2" class="border_table">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="5" lg="5">
                                            <div class="d-flex">
                                                <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)"
                                                    name="sangradoAproximado" v-on:keypress="soloNumeros($event)"
                                                    type="text" autocomplete="off" :state="sangrado_apoximado"
                                                    v-model="datos.sangrado_apoximado" class="border-0" size="sm"
                                                    maxlength="5" placeholder="" />
                                                <span class="float-right px-1 txt_gray">
                                                    <span class="txt_gray size-span">ml</span>
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>


            <b-row class="mt-3">
                <b-col cols="12">
                    <p class="txt_secondary text-uppercase"><strong>Médicos</strong></p>
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th_diag">Cirujano (a)</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="cirujano_id" v-model="datos.cirujano_id" :options="medicos" name="text"
                                        value="value" class="border-0" size="sm" placeholder="Seleccionar" />
                                </b-td>
                                <b-td rowspan="3"
                                    class="border_table justify-content-center text-center my-auto mx-auto vertical_align">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)"
                                        class="border-0 bg_secondary txt_white rounded-circle pd-add-medic"
                                        title="Añadir médico" @click="modalAddMedic()">
                                        <i class="fa fa-plus fa-md" />
                                    </button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Instrumentista</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="instrumentista" v-model="selectInstrumentista" :options="medicosIE"
                                        class="border-0" size="sm" placeholder="Seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Anestesiólogo</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="anestesiologo" v-model="selectAnestesiologo" :options="medicosIE"
                                        class="border-0" size="sm" placeholder="Seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Circulante</b-th>
                                <b-td colspan="2" class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :state="circulante_id" v-model="datos.circulante_id" :options="medicos" name="text"
                                        value="value" class="border-0" size="sm" placeholder="Seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th colspan="3" class="th_style">Ayudantes</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3" class="border_table">
                                    <!-- <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="ayudantes" v-model="selectAyudantes" :options="medicosIE" class="border-0" size="sm" placeholder="Seleccionar" /> -->
                                    <div class="multiselect">
                                        <div class="selectBox" @click="showCheckboxes()">
                                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                                v-model="selectMedicos" :options="options" class="border-0" size="sm" />
                                            <div class="overSelect"></div>
                                        </div>
                                        <div class="px-2" v-if="expanded" id="checkboxes">
                                            <b-form-group class="py-auto my-auto">
                                                <div v-for="medico in medicosIE" :key="medico.value">
                                                    <b-form-checkbox v-model="seleccionados" v-if="medico.value"
                                                        :value="medico.value">
                                                        {{ medico.text }}
                                                    </b-form-checkbox>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="px-2" v-if="!expanded" id="checkboxes">
                                            <div class="py-auto my-auto" v-for="medico in medicosIE" :key="medico.value">
                                                <div v-for="(s, index) in seleccionados" :key="index">
                                                    <span class="txt_secondary size-span" v-if="(medico.value == s)"><i
                                                            class="fa fa-user mr-1"></i>{{ medico.text }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="mb-4">
                                        <span class="txt_secondary">Seleccionados:</span><br>
                                        <span class="txt_secondary size-span"><i class="fa fa-user mr-1" v-for="(s, index) in seleccionados" :key="index"></i>{{s}}</span>
                                    </div> -->
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mt-3" cols="12">
                    <p class="txt_secondary">Complicaciones</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="complicaciones"
                        autocomplete="off" :state="complicaciones" v-model="datos.complicaciones" class="txtArea_border"
                        size="sm" no-resize debounce="500" rows="3" max-rows="3" />
                </b-col>
            </b-row>
            <b-table-simple class="table-sm mt-4">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th_textil">Cuenta material textil</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(disableInput || state.oldExp || isEgresado)"
                                    v-model="datos.material_textil" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr :hidden="!datos.material_textil">
                        <b-th class="th_option_color">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="enfermera_id"
                                v-model="enfermera_selected" :options="medicos" name="text" value-field="value"
                                text-field="text" class="border-0" size="sm" />
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row>
                <b-col class="mt-3" cols="12">
                    <p class="txt_secondary">Envío de piezas o biopsias a patología</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="EnvioPiezas"
                        autocomplete="off" :state="envio_piezas_biopsias" v-model="datos.envio_piezas_biopsias"
                        class="txtArea_border" size="sm" no-resize debounce="500" rows="3" max-rows="3" />
                </b-col>
                <b-col class="mt-3" cols="12">
                    <p class="txt_secondary">Otros hallazgos importantes</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="otrosHallazgos"
                        autocomplete="off" :state="otros_hallazgos" v-model="datos.otros_hallazgos" class="txtArea_border"
                        size="sm" no-resize debounce="500" rows="3" max-rows="3" />
                </b-col>
                <b-col class="my-3" cols="12">
                    <p class="txt_secondary">Descripción de técnica quirúrgica</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="descripcion"
                        autocomplete="off" :state="descripcion_tecnica_quirurgica" v-model="datos.tecnica_quirurgica"
                        class="txtArea_border" size="sm" debounce="500" rows="8" max-rows="8" />
                </b-col>
            </b-row>

            <div v-if="datos.firma.is_firmado == 1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico:
                    {{ datos.firma.firmado_por.nombre }}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha:
                    {{ datos.firma.fecha_firmado }}</span>
            </div>

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err, index) of errores" :key="index"><i class="fa fa-warning px-1"
                                    v-if="!err.expediente_id" />| {{ err[0] }} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="(!dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy"
                            @click="postOperatoria()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busyUpdate" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()"
                            :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <!-- <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div> -->

            <div v-if="(datos.firma.is_firmado == 0 && !actualizar && !state.oldExp && idResponsable === usuario.id && !isEgresado)"
                class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y
                            GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="(cancelar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
        </b-container>
        <div class="sticky-container"
            v-if="(idPacienteSeleccionado != '' && !openFirmaPsw && !openSuccess && !openError && !openPreSave && !openAddOperaciones && !openAddDiag && !regNewExp && !pswUpdate && !loading && !openAddMedic)">
            <ul class="sticky">
                <li v-if="(!dtaExist && !state.oldExp && !isEgresado)" @click="preGuardar()">
                    <img :src="require('../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating && !state.oldExp && datos.firma.is_firmado == 0 && !isEgresado)"
                    @click="editar()">
                    <img :src="require('../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="dtaExist && updating" @click="imprimir()">
                    <img :src="require('../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()" />
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()" />
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <Operacion :modalOperaciones="openAddOperaciones" @closeAddOperaciones="closeAddOperaciones()" @savedOperation="savedOperation()"/>
        <Diagnostico :modalDiagnostico="openAddDiag" 
        @savedDiag="savedDiag()"
        @closeAddDiagnostico="closeAddDiagnostico()"
        />
        <NuevoExpediente :openRegExp="regNewExp" />
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading" />
        <RegistroMedico :openModalAddMedic="openAddMedic" @closeModalAddMedic="closeModalAddMedic()"
            @getMedicPerEsp="getMedicPerEsp()" />

    </b-container>
</template>

<script>
import Datos from '@/components/Paciente/Visualizar/Datos.vue'
import Vue from 'vue'
import PDFMerger from 'pdf-merger-js';
import router from '../../router';
import Loading from '@/components/Loader/Loading.vue'
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import { url } from "../../../settings";
const urlp = `${url}/api/pacientes`
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import Foto from "@/components/Paciente/Foto.vue"
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import CryptoJS from 'crypto-js'
import Operacion from '@/components/Catalogos/Registrar/Operacion.vue'
import Diagnostico from '@/components/Catalogos/Registrar/Diagnostico.vue'
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import RegistroMedico from '@/components/Medico/Registro.vue'
import { getCatalogo } from '../../helpers/catalogos'

export default {
    name: "NotaPostOperatoria",
    components: { Datos, Foto, Success, Error, FirmaPassword, DatosPreguardados, Operacion, Diagnostico, NuevoExpediente, FirmaPswUpdate, Loading, RegistroMedico },

    computed: {
        hour() {
            return this.datos.hora.length != 0
        },

        fecha() {
            return this.datos.fecha != 0
        },

        validation() {
            return this.idPacienteSeleccionado != (null && "")
        },

        diagnostico_pre_operatorio_id() {
            if (this.datos.diagnostico_pre_operatorio_id != (null && "")) {
                return true
            } else {
                return false
            }
        },

        clasificacion_herida_id() {
            return this.datos.clasificacion_herida_id != (null && "")
        },

        operacion_planeada_id() {
            return this.datos.operacion_planeada_id != (null && "")
        },

        operacion_realizada_id() {
            return this.datos.operacion_realizada_id != (null && "")
        },

        diagnostico_post_operatorio_id() {
            return this.datos.diagnostico_post_operatorio_id != (null && "")
        },

        cirujano_id() {
            return this.datos.cirujano_id != (null && "")
        },
        ayudantes() {
            return this.selectAyudantes != (null && "")
        },

        instrumentista() {
            return this.selectInstrumentista != (null && "")
        },

        anestesiologo() {
            return this.selectAnestesiologo != (null && "")
        },

        circulante_id() {
            return this.datos.circulante_id != (null && "")
        },

        edo_postqx_inmediato() {
            return this.datos.edo_postqx_inmediato.length != 0
        },

        pronostico_id() {
            return this.datos.pronostico_id != (null && "")
        },

        sangrado_apoximado() {
            return this.datos.sangrado_apoximado != (null && "")
        },

        tecnica_quirurgica() {
            return this.datos.tecnica_quirurgica.length != 0
        },

        complicaciones() {
            return this.datos.complicaciones.length != 0
        },

        enfermera_id() {
            return this.enfermera_selected != null
        },

        envio_piezas_biopsias() {
            return this.datos.envio_piezas_biopsias.length != 0
        },

        otros_hallazgos() {
            return this.datos.otros_hallazgos.length != 0
        },

        descripcion_tecnica_quirurgica() {
            return this.datos.tecnica_quirurgica.length != 0
        },
        sala() {
            return this.datos.sala.length != 0
        },
    },

    data: () => ({
        changeDta: null,
        isEgresado: false,

        /////////
        seleccionados: [],
        expanded: false,
        selectMedicos: null,
        options: [{ value: null, text: 'Médicos' }],
        /////////

        usuario: {
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable: null,

        state: {
            idExpediente: "",
            idPaciente: "",
            oldExp: false,
        },
        allExp: [],

        openAddMedic: false,
        regNewExp: false,

        loading: true,

        openAddDiag: false,
        openAddOperaciones: false,

        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,

        showDta: false,
        datosPreGuardados: [],
        info: false,
        disabled: true,
        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,
        openPreSave: false,
        error: false,
        errores: "",
        cuarto: "",
        busy: false,
        openSuccess: false,
        openError: false,
        nombre: "",
        pacientes: [{}],
        nombres: [],
        idPacienteSeleccionado: "",
        selectAyudantes: null,
        selectAnestesiologo: null,
        selectInstrumentista: null,
        dtaClasificacionHeridas: [],
        dtaOperaciones: [],
        dtaDiagnosticos: [],

        datosPaciente: {
            password: "",
            edad: "",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id: null,
                nombre: "",
            },
            municipio: {
                id: null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero: {
                id: null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        enfermera_selected: null,

        datos: {
            sala: "",
            expediente_id: null,
            clasificacion_herida_id: null,
            password: '',
            fecha: "",
            hora: "",
            diagnostico_pre_operatorio_id: null,
            operacion_planeada_id: null,
            operacion_realizada_id: null,
            diagnostico_post_operatorio_id: null,
            cirujano_id: null,
            circulante_interno: true,
            circulante_id: null,
            edo_postqx_inmediato: "",
            pronostico_id: null,
            sangrado_apoximado: null,
            complicaciones: "",
            material_textil: false,
            enfermera_id: null,
            envio_piezas_biopsias: "",
            otros_hallazgos: "",
            tecnica_quirurgica: "",
            descripcion_tecnica_quirurgica: "",
            instrumentista: {
                esInterno: false,
                medico_interno_id: null,
                medico_externo_id: null
            },
            anestesiologo: {
                esInterno: true,
                medico_interno_id: null,
                medico_externo_id: null
            },
            ayudantes: [
                {
                    esInterno: true,
                    medico_interno_id: null,
                    medico_externo_id: null
                }
            ],
            firma: {
                fecha_firmado: null,
                firmado_por: {
                    id: null,
                    nombre: ""
                },
                is_firmado: 0
            },
        },
        datosExpediente: {},
        pronostico: [],
        medicos: [],
        medicosIE: [{}],
    }),

    methods: {
        verNotaDeEgreso() {
            this.$emit('toEgreso')
        },

        ff(v) {
            console.log(v);
            this.datos.enfermera_id = v
        },
        changeExp(id) {
            this.clearData()
            this.loading = true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp = false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta = currentExp.id
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/` + this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp = data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es" + error);
            }
        },

        addOperaciones() {
            this.openAddOperaciones = true
        },

        addDiagnosticos() {
            this.openAddDiag = true
        },

        cancelEdit() {
            this.setData()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            // this.pswUpdate = true
            this.pswUpdate = false
            // this.datos.password = psw
            this.actualizar = true
            this.cancelar = true
            this.btnFirmaUpdate = false
            this.disableInput = false
            this.updating = false
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.error = false
            this.errores = []
            this.busyUpdate = true
            this.datos.ayudantes = []
            if (this.enfermera_selected === 0) {
                this.datos.enfermera_id = null
                this.datos.material_textil = false
            } else {
                this.datos.enfermera_id = this.enfermera_selected
            }

            for (let val of this.medicosIE) {
                for (let m of this.seleccionados) {
                    if (val.value == m) {
                        if (val.medico_externo_id == null) {
                            val.esInterno = true
                        } else {
                            val.esInterno = false
                        }
                        this.datos.ayudantes.push({ esInterno: val.esInterno, medico_interno_id: val.medico_interno_id, medico_externo_id: val.medico_externo_id })
                    }
                }
                // if (val.value==this.selectAyudantes) {
                //     if (val.medico_externo_id==null) {
                //         val.esInterno=true
                //     }else{
                //         val.esInterno=false
                //     }
                //     this.datos.ayudantes=[{esInterno:val.esInterno,medico_interno_id: val.medico_interno_id, medico_externo_id:val.medico_externo_id}]
                // }
                if (val.value == this.selectAnestesiologo) {
                    if (val.medico_externo_id == null) {
                        val.esInterno = true
                    } else {
                        val.esInterno = false
                    }
                    this.datos.anestesiologo = { esInterno: val.esInterno, medico_interno_id: val.medico_interno_id, medico_externo_id: val.medico_externo_id }
                }
                if (val.value == this.selectInstrumentista) {
                    if (val.medico_externo_id == null) {
                        val.esInterno = true
                    } else {
                        val.esInterno = false
                    }
                    this.datos.instrumentista = { esInterno: val.esInterno, medico_interno_id: val.medico_interno_id, medico_externo_id: val.medico_externo_id }
                }
            }

            try {
                const res = await fetch(`${url}/api/notas/post-operatoria/update/${this.datos.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status === 500
                var warn = false
                var err = []
                var promise = Promise.resolve(data)
                await promise.then(function (val) {
                    console.log(val);
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.setData()
                    this.expanded = false
                    this.openSuccess = true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate = false
                    this.actualizar = false
                    this.cancelar = false
                } else {
                    this.openError = true
                    this.error = true
                    this.errores = err
                    this.busyUpdate = false
                    if (this.errores.password) {
                        this.btnFirmaUpdate = true
                        this.disableInput = false
                        this.actualizar = false
                        this.busyUpdate = false
                    } else {
                        this.busyUpdate = false
                        this.actualizar = true
                        this.cancelar = true
                    }
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },

        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
                title: "Datos preguardados",
                variant: variant,
                solid: true
            })
        },

        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true

            let urlim = `${url}/api/notas/post-operatoria/descargar/${this.datos.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                }
            }).then(function (resp) {
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function (blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob) {
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente + " Nota post-operatoria");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        preGuardar() {
            console.log("pregusrdado");
            var existe = false;
            try {
                if (localStorage.preGuardadoNPostOperatoria) {
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNPostOperatoria)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            this.datos.password = ""
            var i = 0;
            try {
                for (let val of this.datosPreGuardados) {
                    if (this.datos.expediente_id === val.datos.expediente_id) {
                        this.datosPreGuardados[i] = { "datos": this.datos, "instrumentista": this.selectInstrumentista, "anestesiologo": this.selectAnestesiologo, "ayudantes": this.selectAyudantes }
                        existe = true
                    }
                    i++
                }
            } catch (error) {
                console.log("");
            }
            if (!existe) {
                this.datosPreGuardados.push({ "datos": this.datos, "instrumentista": this.selectInstrumentista, "anestesiologo": this.selectAnestesiologo, "ayudantes": this.selectAyudantes });
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNPostOperatoria', dta);
            this.makeToast('success');
            console.log(this.datosPreGuardados);
        },

        verificarPreGuardado() {
            try {
                if (localStorage.preGuardadoNPostOperatoria) {
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNPostOperatoria)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id === val.datos.expediente_id) {
                    if (this.showDta === true) {
                        this.datos = val.datos
                        this.selectAyudantes = val.ayudantes
                        this.selectAnestesiologo = val.anestesiologo
                        this.selectInstrumentista = val.instrumentista
                    } else {
                        this.openPreSave = true
                    }
                }
            }
        },

        modalAddMedic() {
            this.openAddMedic = true;
        },

        setData() {
            this.obtenerPronostico();
            this.obtenerMedicos();
            this.obtenerPacientes();
            this.obtenerClasificacionHeridas();
            this.obtenerDiagnosticosPreAndPost();
            this.obtenerOperacionesPlaneadasRealizadas();

            console.log("entre aqui");
            const pacienteEncrypt = localStorage.expedienteEncrypt
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente)
            this.idResponsable = paciente.medico.id
            this.state.idExpediente = paciente.id
            this.state.idPaciente = paciente.paciente.id
            this.changeDta = paciente.id
            if (paciente.paciente.lastExpediente.expediente_id != paciente.id) {
                this.state.oldExp = true
            } else {
                this.state.oldExp = false
            }


            if (paciente.paciente.telefono == null) {
                paciente.paciente.telefono = "-----"
            }
            if (paciente.paciente.email == null) {
                paciente.paciente.email = "-----"
            }
            this.idPacienteSeleccionado = paciente.paciente.id
            this.datos.expediente_id = paciente.id;
            this.datosPaciente = paciente.paciente
            this.datosExpediente = paciente

            this.datosPaciente.noExpediente = paciente.paciente.no_expediente
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad = yyyy - year.getFullYear();

            this.obtenerNotaPostOperatoria(this.datos.expediente_id)
            this.btnGuardar = false;
            this.btnFirmar = true;
            // this.setFechaHora();
            this.allExpedientes()
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        firmar() {
            this.openFirmaPsw = true;
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.pacientes = data.pacientes
                this.pacientes.unshift({ id: "", nombre: "Seleccionar paciente" })
            } catch (error) {
                console.log("el error es" + error);
            }
        },

        async postOperatoria() {
            this.error = false
            this.errores = []
            this.busy = true
            this.datos.ayudantes = []
            for (let val of this.medicosIE) {
                for (let m of this.seleccionados) {
                    if (val.value == m) {
                        if (val.medico_externo_id == null) {
                            val.esInterno = true
                        } else {
                            val.esInterno = false
                        }
                        this.datos.ayudantes.push({ esInterno: val.esInterno, medico_interno_id: val.medico_interno_id, medico_externo_id: val.medico_externo_id })
                    }
                }
                // if (val.value==this.selectAyudantes) {
                //     if (val.medico_externo_id==null) {
                //         val.esInterno=true
                //     }else{
                //         val.esInterno=false
                //     }
                //     this.datos.ayudantes=[{esInterno:val.esInterno,medico_interno_id: val.medico_interno_id, medico_externo_id:val.medico_externo_id}]
                // }
                if (val.value == this.selectAnestesiologo) {
                    if (val.medico_externo_id == null) {
                        val.esInterno = true
                    } else {
                        val.esInterno = false
                    }
                    this.datos.anestesiologo = { esInterno: val.esInterno, medico_interno_id: val.medico_interno_id, medico_externo_id: val.medico_externo_id }
                }
                if (val.value == this.selectInstrumentista) {
                    if (val.medico_externo_id == null) {
                        val.esInterno = true
                    } else {
                        val.esInterno = false
                    }
                    this.datos.instrumentista = { esInterno: val.esInterno, medico_interno_id: val.medico_interno_id, medico_externo_id: val.medico_externo_id }
                }
            }
            console.log(this.datos.ayudantes);
            if (this.enfermera_selected === 0) {
                this.datos.enfermera_id = null
            } else {
                this.datos.enfermera_id = this.enfermera_selected
            }
            try {
                const res = await fetch(`${url}/api/notas/post-operatoria/store`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var warn = false
                var err = []
                var promise = Promise.resolve(data);
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.setData()
                    this.expanded = false
                    this.openSuccess = true
                    this.busy = false
                    this.dtaExist = true
                    this.disableInput = true

                } else {
                    this.error = true
                    this.errores = err
                    this.busy = false
                    this.openError = true
                }

                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }
            } catch (error) {
                this.busy = false
                this.openError = true
                console.log(error);
            }
            this.busy = false
        },

        clearData() {
            this.btnGuardar = false;
            this.btnFirmar = true;
            this.showDta = false
            this.datos.sala = ""
            this.datos.expediente_id = null
            this.datos.fecha = ""
            this.datos.hora = ""
            this.datos.diagnostico_pre_operatorio_id = null
            this.datos.operacion_planeada_id = null
            this.datos.operacion_realizada_id = null
            this.datos.diagnostico_post_operatorio_id = null
            this.datos.cirujano_id = null
            this.datos.circulante_interno = true
            this.datos.circulante_id = null
            this.datos.edo_postqx_inmediato = ""
            this.datos.pronostico_id = null
            this.datos.sangrado_apoximado = null
            this.datos.complicaciones = ""
            this.datos.material_textil = false
            this.datos.enfermera_id = null
            this.datos.envio_piezas_biopsias = ""
            this.datos.otros_hallazgos = ""
            this.datos.tecnica_quirurgica = ""
            this.datos.descripcion_tecnica_quirurgica = ""
            this.selectAyudantes = null
            this.selectAnestesiologo = null
            this.selectInstrumentista = null
            this.datos.clasificacion_herida_id = null
            this.setFechaHora();
            this.expanded = false
        },

        setFechaHora() {
            var date = new Date;
            var minutes = ("0" + date.getMinutes()).slice(-2);
            var hour = ("0" + date.getHours()).slice(-2);
            this.datos.hora = "" + hour + ":" + minutes + ":00"
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() + 1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha = yyyy + '-' + mm + '-' + dd;
        },

        async obtenerNotaPostOperatoria(value) {
            this.seleccionados = []
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/notas/post-operatoria/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.status == false) {
                    this.setFechaHora();
                    this.disableInput = false
                    this.btnFirmar = true
                } else {
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnFirmar = false
                    this.btnGuardar = false
                }

                if (data.post_operatoria) {
                    try {
                        this.datos = data.post_operatoria;
                        this.datos.diagnostico_pre_operatorio_id = data.post_operatoria.diagnostico_preoperatorio.id;
                        this.datos.clasificacion_herida_id = data.post_operatoria.clasificacion_herida.id;
                        this.datos.operacion_planeada_id = data.post_operatoria.operacion_planeada.id;
                        this.datos.operacion_realizada_id = data.post_operatoria.operacion_realizada.id;
                        this.datos.cirujano_id = data.post_operatoria.cirujano.id;
                        this.datos.circulante_id = data.post_operatoria.circulante.id;
                        this.datos.pronostico_id = data.post_operatoria.pronostico.id;

                        this.datos.enfermera_id = data.post_operatoria.enfermera.id;
                        if (this.datos.enfermera_id === 0) {
                            this.enfermera_selected = null
                        } else {
                            this.enfermera_selected = this.datos.enfermera_id
                        }
                        this.datos.diagnostico_post_operatorio_id = data.post_operatoria.diagnostico_post_operatorio.id;
                    } catch (error) {
                        console.log("");
                    }

                    if (data.post_operatoria.material_textil == 1) {
                        this.datos.material_textil = true
                    } else {
                        this.datos.material_textil = false
                    }
                    this.seleccionados = []

                    setTimeout(() => {
                        // console.log(this.medicosIE);
                        // console.log(data.post_operatoria.ayudantes[0]);
                        // console.log(data.post_operatoria.instrumentista);
                        // console.log(data.post_operatoria.anestesiologo);
                        // console.log(data.post_operatoria.enfermera_id.id)


                        for (const val of this.medicosIE) {
                            for (const m of data.post_operatoria.ayudantes) {
                                if (val.medico_interno_id == m.ayudante_interno_id && val.medico_externo_id == m.ayudante_externo_id) {
                                    this.seleccionados.push(val.value);
                                }
                            }
                            // if (val.medico_interno_id==data.post_operatoria.ayudantes[0].ayudante_interno_id && val.medico_externo_id==data.post_operatoria.ayudantes[0].ayudante_externo_id) {
                            //     this.selectAyudantes=val.value;
                            // }
                            if (val.medico_interno_id == data.post_operatoria.instrumentista.medico_interno_id && val.medico_externo_id == data.post_operatoria.instrumentista.medico_externo_id) {
                                this.selectInstrumentista = val.value;
                            }
                            if (val.medico_interno_id == data.post_operatoria.anestesiologo.medico_interno_id && val.medico_externo_id == data.post_operatoria.anestesiologo.medico_externo_id) {
                                this.selectAnestesiologo = val.value;
                            }
                        }
                        // console.log(this.medicos);
                        for (const val of this.medicos) {
                            if (data.post_operatoria.enfermera_id && val.value === data.post_operatoria.enfermera_id.id) {
                                this.datos.enfermera_id = val.value;
                            }
                        }
                    }, 500);
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.loading = false

                    // if (this.datos.enfermera_id==0) {
                    //     this.datos.material_textil =  false
                    //     this.datos.enfermera_id =  null
                    // }
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.loading = false
                    this.verificarPreGuardado()
                    this.setFechaHora()
                }
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading = false
                console.log(error);
            }
        },

        //  Medicos internos externos **********************************************************************************************************************************
        obtenerMedicos() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/medicos_internos_externos'))
            }).then((data) => {
                try {
                    console.log(data);
                    this.medicos = data.medicos.map(item => {
                        return {
                            value: item.medico_interno_id,
                            text: item.nombre
                        }
                    })
                    this.medicos.unshift({ value: null, text: 'seleccione una opción' })

                    let i = 0
                    this.medicosIE = data.medicos.map(item => {
                        return {
                            text: item.nombre,
                            esInterno: item.esInterno,
                            medico_interno_id: item.medico_interno_id,
                            medico_externo_id: item.medico_externo_id,
                        };
                    });
                    for (const val of this.medicosIE) {
                        i = i + 1
                        val.value = i
                    }
                    this.medicosIE.unshift({ value: null, text: 'seleccione una opción' })
                } catch (error) {
                    console.log(error)
                }
            })
        },

        //  CLASIFICACIÓN DE HERIDAS  **********************************************************************************************************************************
        obtenerClasificacionHeridas() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/clasificacion_heridas'))
            }).then((data) => {
                try {
                    this.dtaClasificacionHeridas = data.clasificacion_herida
                    this.dtaClasificacionHeridas.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error)
                }
            })
        },

        //  DIAGNOSTICOS POST Y PRE **********************************************************************************************************************************
        obtenerDiagnosticosPreAndPost() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/diagnosticos'))
            }).then((data) => {
                try {
                    this.dtaDiagnosticos = data.diagnosticos
                    this.dtaDiagnosticos.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error)
                }
            })
        },

        //  OPERACIONES PLANEADAS Y REALIZADAS **********************************************************************************************************************************
        obtenerOperacionesPlaneadasRealizadas() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/operaciones'))
            }).then((data) => {
                try {
                    this.dtaOperaciones = data.operaciones
                    this.dtaOperaciones.unshift({ id: null, nombre: 'seleccione una opción' })
                } catch (error) {
                    console.log(error)
                }
            })
        },

        //  PRONÓSTICO **********************************************************************************************************************************
        obtenerPronostico() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/catalogos/pronostico'))
            }).then((data) => {
                try {
                    this.pronostico = data.pronostico
                    this.pronostico.unshift({ id: null, nombre: 'seleccione una opcion' })
                } catch (error) {
                    console.log(error);
                }
            })

            new Promise((resolve) => {
                resolve(getCatalogo('/api/usuarios/logged'))
            }).then((data) => {
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            })

        },
        showCheckboxes() {
            if (!(this.disableInput || this.state.oldExp)) {
                this.expanded = !this.expanded
            }
        },

        cerrarModal() {
            this.openSuccess = false
        },
        cerrarModalError() {
            console.log('cerrarModalError');
            this.openError = false
        },
        closeModalAddMedic() {
            this.openAddMedic = false
        },
        getMedicPerEsp() {
            this.obtenerMedicos()
            this.openAddMedic = false
            this.openSuccess = true
        },
        firma_psw(psw) {
            this.datos.password = psw
            if (!this.dtaExist) {
                this.postOperatoria()
            } else {
                this.updateData();
            }
            this.openFirmaPsw = false
        },
        showDtaPreSave(){
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
        },
        closeAddOperaciones(){
            this.openAddOperaciones = false
        },
        savedOperation(){
            this.openAddOperaciones = false
            this.obtenerOperacionesPlaneadasRealizadas()
            this.openSuccess = true

        },
        closeAddDiagnostico(){
            this.openAddDiag = false
        },

        savedDiag(){
            this.openAddDiag = false
            this.obtenerDiagnosticosPreAndPost()
            this.openSuccess = true
        }

    },

    mounted() {


    },

    activated() {
        Object.assign(this.$data, this.$options.data());

        const egreso = localStorage.egreso
        if (egreso) {
            const egresoDE = JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado = egresoDE.egreso
        } else {
            router.push("/main")
        }

        this.loading = true
        this.setData()
        this.showDta === false

    },
    deactivated() {
        this.cancelEdit()
    }
}
</script>

<style scoped>
@media only screen and (min-width: 1100px) {
    .size_th {
        width: 18vw;
    }
}

@media only screen and (max-width: 1100px) {
    .size_th {
        width: 22vw;
    }
}

@media only screen and (max-width: 992px) {
    .size_th {
        width: 25vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th {
        width: 26vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th {
        width: auto;
    }
}

/* Diagnosticos */

@media only screen and (min-width: 1100px) {
    .size_th_diag {
        width: 18vw;
    }
}

@media only screen and (max-width: 1100px) {
    .size_th_diag {
        width: auto;
    }
}

/* Material textil */
@media only screen and (min-width: 1200px) {
    .size_th_textil {
        width: 18vw;
    }
}

@media only screen and (max-width: 1200px) {
    .size_th_textil {
        width: 30vw;
    }
}

@media only screen and (max-width: 950px) {
    .size_th_textil {
        width: auto;
    }
}

.multiselect {
    width: auto;
}

.selectBox {
    cursor: pointer;
    position: relative;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    border: 1px #dadada solid;
}</style>

